package studio.lostjoker.smartdealer.ui.poker.devices.table

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.WindowInsets
import androidx.compose.foundation.layout.displayCutout
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.windowInsetsPadding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Logout
import androidx.compose.material.icons.filled.CheckCircle
import androidx.compose.material.icons.filled.Menu
import androidx.compose.material.icons.filled.PauseCircle
import androidx.compose.material.icons.filled.PlayCircle
import androidx.compose.material.icons.filled.Settings
import androidx.compose.material.icons.filled.Tune
import androidx.compose.material3.DrawerValue
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.ModalBottomSheet
import androidx.compose.material3.Tab
import androidx.compose.material3.TabRow
import androidx.compose.material3.Text
import androidx.compose.material3.rememberDrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableIntStateOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.lifecycle.compose.LocalLifecycleOwner
import cafe.adriel.voyager.core.model.rememberScreenModel
import cafe.adriel.voyager.core.screen.Screen
import cafe.adriel.voyager.core.screen.ScreenKey
import cafe.adriel.voyager.core.screen.uniqueScreenKey
import cafe.adriel.voyager.navigator.LocalNavigator
import com.benasher44.uuid.Uuid
import dev.gitlive.firebase.Firebase
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_devices_settings
import studio.lostjoker.smartdealer.domain.poker.model.PokerSettings
import studio.lostjoker.smartdealer.domain.poker.model.PokerVariant
import studio.lostjoker.smartdealer.domain.poker.model.RingGameSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentSettings
import studio.lostjoker.smartdealer.firebase.FirebaseAnalytics
import studio.lostjoker.smartdealer.firebase.FirebaseParamsBuilder
import studio.lostjoker.smartdealer.firebase.analytics
import studio.lostjoker.smartdealer.helpers.Platform
import studio.lostjoker.smartdealer.helpers.getPlatform
import studio.lostjoker.smartdealer.helpers.screenshotHelper
import studio.lostjoker.smartdealer.notification.LocalNotificationService
import studio.lostjoker.smartdealer.preferences.PreferenceEnums
import studio.lostjoker.smartdealer.preferences.rememberUserPreferences
import studio.lostjoker.smartdealer.sharesheet.shareSheet
import studio.lostjoker.smartdealer.ui.common.dialog.LeaveGameConfirmationDialog
import studio.lostjoker.smartdealer.ui.common.dialog.TerminateGameConfirmationDialog
import studio.lostjoker.smartdealer.ui.components.ScrimBoxGame
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltBlue
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltGreen
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltRed
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.Board
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.Clock
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.GameCode
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.Pots
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.ScreenOrientationNotSupported
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.TableSeating
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.TableOnlineLayout
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.WinningHandDetails
import studio.lostjoker.smartdealer.ui.poker.devices.player.PokerViewState
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.CardBackStyle
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.CardStyle
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.FloatingActionButtons
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.LateRegistrationEndPeriodReminder
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.NavigationDrawer
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.Payments
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.PlayerTableSeat
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.PlayerTableOnlineSeat
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.RingGameSettings
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.TableFelt
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.TableLayout
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.TournamentSettings
import studio.lostjoker.smartdealer.ui.poker.devices.table.components.BlindLevel
import studio.lostjoker.smartdealer.ui.poker.devices.table.components.RingSummary
import studio.lostjoker.smartdealer.ui.poker.devices.table.components.TournamentSummary
import studio.lostjoker.smartdealer.ui.poker.enum.BottomSheetState
import studio.lostjoker.smartdealer.ui.poker.enum.CardBackStyle
import studio.lostjoker.smartdealer.ui.poker.enum.CardLayout
import studio.lostjoker.smartdealer.ui.poker.enum.CardStyle
import studio.lostjoker.smartdealer.ui.poker.enum.Device
import studio.lostjoker.smartdealer.ui.poker.enum.DeviceMode
import studio.lostjoker.smartdealer.ui.poker.enum.GameFormat
import studio.lostjoker.smartdealer.ui.poker.enum.ScreenOrientation
import studio.lostjoker.smartdealer.ui.poker.enum.TableDeviceSettings
import studio.lostjoker.smartdealer.ui.poker.enum.TableFelt
import studio.lostjoker.smartdealer.ui.poker.home.HomeScreen
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme
import kotlin.math.ceil

internal data class TableDeviceScreen(
    val userId: String,
    val gameId: Uuid,
    val gameCode: String,
    val deviceMode: DeviceMode,
    val gameplayBaseUrl: String,
    val apiBaseUrl: String,
) : Screen {

    override val key: ScreenKey = uniqueScreenKey

    @Composable
    override fun Content() {
        val navigator = LocalNavigator.current
        val lifecycleOwner = LocalLifecycleOwner.current
        val notificationService = LocalNotificationService.current

        val userPreferences = rememberUserPreferences(PreferenceEnums.Storage.Poker.key)

        val tableDeviceViewModel: TableDeviceViewModel = rememberScreenModel(key) {
            TableDeviceViewModel(
                userId = userId,
                gameId = gameId,
                gameCode = gameCode,
                deviceMode = deviceMode,
                notificationService = notificationService,
                gameplayBaseUrl = gameplayBaseUrl,
                apiBaseUrl = apiBaseUrl,
                preferenceDelegate = userPreferences,
            )
        }

        val stateFlow = tableDeviceViewModel.state.collectAsState(PokerViewState())
        val state = stateFlow.value

        LaunchedEffect(lifecycleOwner) {
            launch {
                lifecycleOwner.lifecycle.currentStateFlow.collect {
                    tableDeviceViewModel.updateApplicationState(it)
                }
            }
        }

        TableDeviceScreenView(
            state = state,
            deal = tableDeviceViewModel::deal,
            changeVerticalDisplay = tableDeviceViewModel::changeVerticalDisplay,
            changeTableFelt = tableDeviceViewModel::changeTableFelt,
            changeCardStyle = tableDeviceViewModel::changeCardStyle,
            changeCardBackStyle = tableDeviceViewModel::changeCardBackStyle,
            changeBottomSheetState = tableDeviceViewModel::changeBottomSheetState,
            updatePlayerAccount = tableDeviceViewModel::updatePlayerAccount,
            updatePaymentTransfer = tableDeviceViewModel::updatePaymentTransfer,
            startTournament = tableDeviceViewModel::startTournament,
            updateRingGameSettings = tableDeviceViewModel::updateRingGameSettings,
            updateTournamentSettings = tableDeviceViewModel::updateTournamentSettings,
            pause = tableDeviceViewModel::pauseTournament,
            resume = tableDeviceViewModel::resumeTournament,
            terminateGame = tableDeviceViewModel::terminateGame,
            leaveGame = {
                tableDeviceViewModel.leaveGame()
                navigator?.push(HomeScreen(gameplayBaseUrl, apiBaseUrl))
            },
            updateWinningHandDetails = tableDeviceViewModel::updateWinningHandDetails,
        )
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun TableDeviceScreenView(
    state: PokerViewState,
    deal: () -> Unit = {},
    changeVerticalDisplay: (verticalDisplay: Boolean) -> Unit = {},
    changeTableFelt: (tableFelt: TableFelt) -> Unit = {},
    changeCardStyle: (cardStyle: CardStyle) -> Unit = {},
    changeCardBackStyle: (cardBackStyle: CardBackStyle) -> Unit = {},
    changeBottomSheetState: (bottomSheetState: BottomSheetState?) -> Unit = {},
    updatePlayerAccount: (playerId: String, bot: Boolean) -> Unit = { _, _ -> },
    updatePaymentTransfer: (playerId: String, bot: Boolean) -> Unit = { _, _ -> },
    startTournament: () -> Unit = {},
    updateRingGameSettings: (ringGameSettings: RingGameSettings, pokerSettings: PokerSettings) -> Unit = { _, _ -> },
    updateTournamentSettings: (tournamentSettings: TournamentSettings, pokerSettings: PokerSettings) -> Unit = { _, _ -> },
    pause: () -> Unit = {},
    resume: () -> Unit = {},
    terminateGame: () -> Unit = {},
    leaveGame: () -> Unit = {},
    updateWinningHandDetails: (winningHandDetails: WinningHandDetails) -> Unit = {},
) {
    val analytics = Firebase.analytics
    val screenshotHelper = screenshotHelper()
    val shareSheet = shareSheet()

    val coroutineScope = rememberCoroutineScope()

    val drawerState = rememberDrawerState(initialValue = DrawerValue.Closed)
    var openNavigationDrawer by remember(drawerState.isOpen) { mutableStateOf(drawerState.isOpen) }

    var openUpdateGameSettings by remember { mutableStateOf(false) }

    val platform = getPlatform()

    val filteredSettings = TableDeviceSettings.entries.filter { it != TableDeviceSettings.TableLayout }

    val settings = when (platform) {
        Platform.ANDROID, Platform.IOS -> if (PokerAppTheme.isPhone()) filteredSettings else TableDeviceSettings.entries
        Platform.WEB -> filteredSettings
    }

    val verticalDisplay = state.settings.verticalDisplay || platform == Platform.WEB

    var settingsSelectedTabIndex by remember { mutableIntStateOf(0) }

    var openTerminateGameConfirmationDialog by remember(state.gameCode) { mutableStateOf(false) }
    var openLeaveGameConfirmationDialog by remember(state.gameCode) { mutableStateOf(false) }

    var lateRegEndPeriodReminderCountdown by remember(state.handCount) { mutableStateOf(3) }
    var showLateRegEndPeriodReminder by remember(state.handCount) { mutableStateOf(false) }

    var winningPlayer by remember { mutableStateOf<Player?>(null) }

    winningPlayer = state.players.singleOrNull { it.seat == state.winningHandDetails.seat }

    val allSeats = MutableList(state.tableSize) { -1 }
    val occupiedSeats = state.players.map { it.seat }

    occupiedSeats.forEach { seat ->
        if (allSeats.getOrNull(seat) != null) {
            allSeats[seat] = seat
        }
    }

    val midSeatIndex = ceil(allSeats.size / 2f).toInt()
    val topSeats = allSeats.subList(0, midSeatIndex)
    val bottomSeats = allSeats.subList(midSeatIndex, allSeats.size)

    state.players.forEach { player ->
        LaunchedEffect(player.playerId, player.screenName, player.avatarUrl) {
            if (player.playerId != null && player.screenName == null && player.avatarUrl == null) {
                updatePlayerAccount(player.playerId, player.bot)
                updatePaymentTransfer(player.playerId, player.bot)
            }
        }
    }

    LaunchedEffect(state.gameEnded, state.lastBlindLevelBeforeLateRegEnds, state.lastBlindLevelBeforeRebuyEnds, state.handCount, lateRegEndPeriodReminderCountdown) {
        if (!state.gameEnded && (state.lastBlindLevelBeforeLateRegEnds || state.lastBlindLevelBeforeRebuyEnds) && state.checkHandsLeft(numberOfHands = 3)) {
            if (lateRegEndPeriodReminderCountdown > 0) {
                showLateRegEndPeriodReminder = true
                delay(1000)
                lateRegEndPeriodReminderCountdown--
            } else {
                showLateRegEndPeriodReminder = false
            }
        }
        if (state.gameEnded) {
            showLateRegEndPeriodReminder = false
        }
    }

    LaunchedEffect(key1 = state.gameEnded) {
        if (state.gameEnded) {
            analytics.logEvent(
                FirebaseAnalytics.Event.GAME_COMPLETE,
                listOf(
                    FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, state.gameId.toString()),
                    FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, state.gameCode),
                    FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                    FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, state.tableId.toString()),
                    FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_TIME, state.gameTime.offset.toString()),
                ),
            )
        }
    }

    Box(
        modifier = Modifier
            .background(
                when (state.settings.tableFelt) {
                    TableFelt.Red -> tableFeltRed
                    TableFelt.Green -> tableFeltGreen
                    TableFelt.Blue -> tableFeltBlue
                },
            ),
    ) {
        if (!state.gameStarted) {
            BoxWithConstraints(
                modifier = Modifier.takeIf { PokerAppTheme.isPhone() }
                    ?.windowInsetsPadding(WindowInsets.displayCutout)?.padding(bottom = PokerAppTheme.dimensions.grid_1_5)
                    ?: Modifier,
            ) {
                if (maxHeight > maxWidth || !PokerAppTheme.isPhone()) {
                    Column {
                        Box(
                            modifier = Modifier.fillMaxWidth(),
                            contentAlignment = Alignment.Center,
                        ) {
                            GameCode(
                                gameCode = state.gameCode,
                                titleTextStyle = PokerAppTheme.typography.titleLarge,
                                gameCodeTextStyle = PokerAppTheme.typography.displayLarge,
                                onShareGameCode = remember(state.gameId, state.gameCode, state.tableId) {
                                    { gameCode ->
                                        analytics.logEvent(
                                            FirebaseAnalytics.Event.SHARE_GAME_CODE,
                                            listOf(
                                                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, state.gameId.toString()),
                                                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, state.gameCode),
                                                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                                                FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, state.tableId.toString()),
                                            ),
                                        )
                                        shareSheet.shareText(gameCode)
                                    }
                                }
                            )
                            Icon(
                                modifier = Modifier
                                    .align(Alignment.CenterEnd)
                                    .padding(end = PokerAppTheme.dimensions.grid_1_5)
                                    .size(PokerAppTheme.dimensions.grid_4)
                                    .pointerInput(Unit) {
                                        detectTapGestures(
                                            onPress = {
                                                openLeaveGameConfirmationDialog = true
                                            },
                                        )
                                    },
                                imageVector = Icons.AutoMirrored.Default.Logout,
                                contentDescription = "Leave",
                                tint = Color.LightGray,
                            )
                        }
                        TableSeating(
                            device = Device.Table,
                            deviceMode = state.deviceMode,
                            tableSize = state.tableSize,
                            players = state.players,
                            gameCode = state.gameCode,
                            startTournament = startTournament,
                        )
                    }
                } else {
                    ScreenOrientationNotSupported(ScreenOrientation.Landscape)
                }
            }
        } else {
            BoxWithConstraints(
                modifier = Modifier.takeIf { PokerAppTheme.isPhone() }
                    ?.windowInsetsPadding(WindowInsets.displayCutout)?.padding(bottom = PokerAppTheme.dimensions.grid_1_5)
                    ?: Modifier,
            ) {
                if (!state.gameEnded) {
                    val maxWidth = maxWidth
                    val maxHeight = maxHeight
                    if (maxHeight < maxWidth) {
                        if (!PokerAppTheme.isPhone()) {
                            Box {
                                Column {
                                    BoxWithConstraints(
                                        modifier = Modifier
                                            .padding(PokerAppTheme.dimensions.grid_0_5)
                                            .fillMaxWidth()
                                            .weight(0.25f),
                                    ) {
                                        Row(
                                            modifier = Modifier
                                                .fillMaxSize(),
                                            verticalAlignment = Alignment.CenterVertically,
                                            horizontalArrangement = Arrangement.SpaceEvenly,
                                        ) {
                                            Column(
                                                modifier = Modifier
                                                    .fillMaxWidth()
                                                    .weight(0.15f),
                                                verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_0_25, Alignment.CenterVertically),
                                                horizontalAlignment = Alignment.CenterHorizontally,
                                            ) {
                                                Clock(
                                                    gameTime = state.gameTime,
                                                    titleTextStyle = PokerAppTheme.typography.titleMedium,
                                                    timeTextStyle = PokerAppTheme.typography.titleLarge,
                                                )
                                                BlindLevel(
                                                    gameFormat = state.gameFormat,
                                                    currentBlindLevel = state.currentBlindLevel,
                                                    blindLevelHandCountRange = state.blindLevelHandCountRange,
                                                    handCount = state.handCount,
                                                )
                                                if (state.host && state.gameFormat == GameFormat.RingGame) {
                                                    Icon(
                                                        modifier = Modifier
                                                            .size(PokerAppTheme.dimensions.grid_4)
                                                            .pointerInput(Unit) {
                                                                detectTapGestures(
                                                                    onPress = {
                                                                        openTerminateGameConfirmationDialog = true
                                                                    },
                                                                )
                                                            },
                                                        imageVector = Icons.Filled.CheckCircle,
                                                        contentDescription = "Terminate",
                                                        tint = Color.LightGray,
                                                    )
                                                }
                                            }
                                            BoxWithConstraints(
                                                modifier = Modifier
                                                    .fillMaxWidth()
                                                    .weight(0.7f),
                                            ) {
                                                val maxPlayerTableSeatWidth = (maxWidth * 0.7f)  / 5.5f
                                                Row(
                                                    modifier = Modifier
                                                        .fillMaxSize(),
                                                    horizontalArrangement = Arrangement.SpaceEvenly,
                                                    verticalAlignment = Alignment.CenterVertically,
                                                ) {
                                                    topSeats.forEachIndexed { index, seat ->
                                                        val player = state.players.singleOrNull { it.seat == seat }
                                                        PlayerTableSeat(
                                                            device = Device.Table,
                                                            player = player,
                                                            seat = index,
                                                            playerAtTurnSeat = state.playerAtTurnSeat,
                                                            cardStyle = state.settings.cardStyle,
                                                            cardBackStyle = state.settings.cardBackStyle,
                                                            playingHand = state.playingHand,
                                                            winningHandDetails = state.winningHandDetails,
                                                            roundEnded = state.roundEnded,
                                                            tableSize = state.tableSize,
                                                            maxPlayerTableSeatWidth = maxPlayerTableSeatWidth,
                                                            tableFelt = state.settings.tableFelt,
                                                            pokerVariant = state.pokerSettings?.variant ?: PokerVariant.Texas,
                                                            actionTime = state.actionTime,
                                                            fractionDigits = state.fractionDigits,
                                                        )
                                                    }
                                                }
                                            }
                                            Column(
                                                modifier = Modifier
                                                    .fillMaxWidth()
                                                    .weight(0.15f),
                                                verticalArrangement = Arrangement.spacedBy(5.dp, Alignment.CenterVertically),
                                                horizontalAlignment = Alignment.CenterHorizontally,
                                            ) {
                                                val iconSize = (maxWidth * 0.15f) / 4.5f
                                                GameCode(
                                                    gameCode = state.gameCode,
                                                    titleTextStyle = PokerAppTheme.typography.titleMedium,
                                                    gameCodeTextStyle = PokerAppTheme.typography.titleLarge,
                                                    iconSize = iconSize,
                                                    onShareGameCode = remember(state.gameId, state.gameCode, state.tableId) {
                                                        { gameCode ->
                                                            analytics.logEvent(
                                                                FirebaseAnalytics.Event.SHARE_GAME_CODE,
                                                                listOf(
                                                                    FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, state.gameId.toString()),
                                                                    FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, state.gameCode),
                                                                    FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                                                                    FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, state.tableId.toString()),
                                                                ),
                                                            )
                                                            shareSheet.shareText(gameCode)
                                                        }
                                                    }
                                                )
                                                Row(
                                                    verticalAlignment = Alignment.CenterVertically,
                                                    horizontalArrangement = Arrangement.spacedBy(5.dp),
                                                ) {
                                                    Icon(
                                                        modifier = Modifier
                                                            .size(iconSize)
                                                            .pointerInput(Unit) {
                                                                detectTapGestures(
                                                                    onPress = {
                                                                        settingsSelectedTabIndex = 0
                                                                        changeBottomSheetState(BottomSheetState.Settings)
                                                                    },
                                                                )
                                                            },
                                                        imageVector = Icons.Default.Settings,
                                                        contentDescription = "Settings",
                                                        tint = Color.LightGray,
                                                    )
                                                    if (state.host) {
                                                        Icon(
                                                            modifier = Modifier
                                                                .size(iconSize)
                                                                .pointerInput(Unit) {
                                                                    detectTapGestures(
                                                                        onPress = {
                                                                            openUpdateGameSettings = true
                                                                        },
                                                                    )
                                                                },
                                                            imageVector = Icons.Filled.Tune,
                                                            contentDescription = "Update Game Settings",
                                                            tint = Color.LightGray,
                                                        )
                                                        Icon(
                                                            modifier = Modifier
                                                                .size(iconSize)
                                                                .pointerInput(state.gamePaused) {
                                                                    detectTapGestures(
                                                                        onPress = {
                                                                            analytics.logEvent(
                                                                                if (state.gamePaused) {
                                                                                    FirebaseAnalytics.Event.RESUME_GAME
                                                                                } else {
                                                                                    FirebaseAnalytics.Event.PAUSE_GAME
                                                                                },
                                                                                listOf(
                                                                                    FirebaseParamsBuilder.StringParameter(
                                                                                        FirebaseAnalytics.Param.GAME_ID,
                                                                                        state.gameId.toString(),
                                                                                    ),
                                                                                    FirebaseParamsBuilder.StringParameter(
                                                                                        FirebaseAnalytics.Param.GAME_CODE,
                                                                                        state.gameCode,
                                                                                    ),
                                                                                    FirebaseParamsBuilder.StringParameter(
                                                                                        FirebaseAnalytics.Param.DEVICE_TYPE,
                                                                                        Device.Table.toString(),
                                                                                    ),
                                                                                    FirebaseParamsBuilder.StringParameter(
                                                                                        FirebaseAnalytics.Param.TABLE_ID,
                                                                                        state.tableId.toString(),
                                                                                    ),
                                                                                ),
                                                                            )
                                                                            if (state.gamePaused) {
                                                                                resume()
                                                                            } else {
                                                                                pause()
                                                                            }
                                                                        },
                                                                    )
                                                                },
                                                            imageVector = if (state.gamePaused) {
                                                                Icons.Default.PlayCircle
                                                            } else {
                                                                Icons.Default.PauseCircle
                                                            },
                                                            contentDescription = "Play/Pause",
                                                            tint = Color.LightGray,
                                                        )
                                                    }
                                                    Icon(
                                                        modifier = Modifier
                                                            .size(iconSize)
                                                            .pointerInput(Unit) {
                                                                detectTapGestures(
                                                                    onPress = {
                                                                        openLeaveGameConfirmationDialog = true
                                                                    },
                                                                )
                                                            },
                                                        imageVector = Icons.AutoMirrored.Default.Logout,
                                                        contentDescription = "Leave",
                                                        tint = Color.LightGray,
                                                    )
                                                }
                                            }
                                        }
                                    }
                                    Column(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .weight(0.5f),
                                    ) {
                                        Row(
                                            modifier = Modifier
                                                .fillMaxWidth()
                                                .weight(0.15f),
                                            horizontalArrangement = Arrangement.Center,
                                            verticalAlignment = Alignment.CenterVertically,
                                        ) {
                                            Pots(
                                                deckStart = state.deckStart,
                                                players = state.players,
                                                potContributions = state.potContributions,
                                                potShares = state.potShares,
                                                degrees = 0f,
                                                amountTextStyle = PokerAppTheme.typography.displaySmall,
                                                roundEnded = state.roundEnded,
                                                playingHand = state.playingHand,
                                                fractionDigits = state.fractionDigits,
                                                updateWinningHandDetails = updateWinningHandDetails,
                                            )
                                        }
                                        Row(
                                            modifier = Modifier
                                                .fillMaxWidth()
                                                .weight(
                                                    if (verticalDisplay) {
                                                        0.85f
                                                    } else {
                                                        0.7f
                                                    },
                                                ),
                                        ) {
                                            Board(
                                                device = Device.Table,
                                                firstHand = state.handIndex == 0,
                                                communityCards = state.communityCards,
                                                cardLayout = if (verticalDisplay) {
                                                    CardLayout.SingleSidedSimple
                                                } else {
                                                    CardLayout.DoubleSidedSimple
                                                },
                                                cardStyle = state.settings.cardStyle,
                                                cardBackStyle = state.settings.cardBackStyle,
                                                playingHand = state.playingHand,
                                                winningPlayer = winningPlayer,
                                                winningCards = state.winningHandDetails.cards,
                                                gameEnded = state.gameEnded,
                                                gamePaused = state.gamePaused,
                                                verticalDisplay = verticalDisplay,
                                                deal = deal,
                                            )
                                        }
                                        if (!verticalDisplay) {
                                            Row(
                                                modifier = Modifier
                                                    .fillMaxWidth()
                                                    .weight(0.15f),
                                                horizontalArrangement = Arrangement.Center,
                                                verticalAlignment = Alignment.CenterVertically,
                                            ) {
                                                Pots(
                                                    deckStart = state.deckStart,
                                                    players = state.players,
                                                    potContributions = state.potContributions,
                                                    potShares = state.potShares,
                                                    degrees = 180f,
                                                    amountTextStyle = PokerAppTheme.typography.displaySmall,
                                                    roundEnded = state.roundEnded,
                                                    playingHand = state.playingHand,
                                                    fractionDigits = state.fractionDigits,
                                                    updateWinningHandDetails = {},
                                                )
                                            }
                                        }
                                    }
                                    BoxWithConstraints(
                                        modifier = Modifier
                                            .padding(PokerAppTheme.dimensions.grid_0_5)
                                            .fillMaxWidth()
                                            .weight(0.25f),
                                    ) {
                                        Row(
                                            modifier = Modifier
                                                .fillMaxSize()
                                                .rotate(180f),
                                            verticalAlignment = Alignment.CenterVertically,
                                            horizontalArrangement = Arrangement.SpaceEvenly,
                                        ) {
                                            Column(
                                                modifier = Modifier
                                                    .fillMaxWidth()
                                                    .weight(0.15f),
                                                verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_0_25, Alignment.CenterVertically),
                                                horizontalAlignment = Alignment.CenterHorizontally,
                                            ) {
                                                if (!verticalDisplay) {
                                                    Clock(
                                                        gameTime = state.gameTime,
                                                        titleTextStyle = PokerAppTheme.typography.titleMedium,
                                                        timeTextStyle = PokerAppTheme.typography.titleLarge,
                                                    )
                                                    BlindLevel(
                                                        gameFormat = state.gameFormat,
                                                        currentBlindLevel = state.currentBlindLevel,
                                                        blindLevelHandCountRange = state.blindLevelHandCountRange,
                                                        handCount = state.handCount,
                                                    )
                                                    if (state.host && state.gameFormat == GameFormat.RingGame) {
                                                        Icon(
                                                            modifier = Modifier
                                                                .size(PokerAppTheme.dimensions.grid_4)
                                                                .pointerInput(Unit) {
                                                                    detectTapGestures(
                                                                        onPress = {
                                                                            openTerminateGameConfirmationDialog = true
                                                                        },
                                                                    )
                                                                },
                                                            imageVector = Icons.Filled.CheckCircle,
                                                            contentDescription = "Terminate",
                                                            tint = Color.LightGray,
                                                        )
                                                    }
                                                }
                                            }
                                            BoxWithConstraints(
                                                modifier = Modifier
                                                    .rotate(if (verticalDisplay) -180f else 0f)
                                                    .fillMaxWidth()
                                                    .weight(0.7f),
                                            ) {
                                                val maxPlayerTableSeatWidth = (maxWidth * 0.7f)  / 5.5f
                                                Row(
                                                    modifier = Modifier
                                                        .fillMaxSize(),
                                                    horizontalArrangement = Arrangement.SpaceEvenly,
                                                    verticalAlignment = Alignment.CenterVertically,
                                                ) {
                                                    if (verticalDisplay) bottomSeats.reverse()
                                                    bottomSeats.forEachIndexed { index, seat ->
                                                        val player = state.players.singleOrNull { it.seat == seat }
                                                        PlayerTableSeat(
                                                            device = Device.Table,
                                                            player = player,
                                                            seat = if (verticalDisplay) {
                                                                allSeats.size - index - 1
                                                            } else {
                                                                allSeats.size - bottomSeats.size + index
                                                            },
                                                            playerAtTurnSeat = state.playerAtTurnSeat,
                                                            cardStyle = state.settings.cardStyle,
                                                            cardBackStyle = state.settings.cardBackStyle,
                                                            playingHand = state.playingHand,
                                                            winningHandDetails = state.winningHandDetails,
                                                            roundEnded = state.roundEnded,
                                                            tableSize = state.tableSize,
                                                            maxPlayerTableSeatWidth = maxPlayerTableSeatWidth,
                                                            tableFelt = state.settings.tableFelt,
                                                            pokerVariant = state.pokerSettings?.variant ?: PokerVariant.Texas,
                                                            actionTime = state.actionTime,
                                                            fractionDigits = state.fractionDigits,
                                                        )
                                                    }
                                                }
                                            }
                                            Column(
                                                modifier = Modifier
                                                    .fillMaxWidth()
                                                    .weight(0.15f),
                                                verticalArrangement = Arrangement.spacedBy(5.dp, Alignment.CenterVertically),
                                                horizontalAlignment = Alignment.CenterHorizontally,
                                            ) {
                                                val iconSize = (maxWidth * 0.15f) / 4.5f
                                                if (!verticalDisplay) {
                                                    GameCode(
                                                        gameCode = state.gameCode,
                                                        titleTextStyle = PokerAppTheme.typography.titleMedium,
                                                        gameCodeTextStyle = PokerAppTheme.typography.titleLarge,
                                                        iconSize = iconSize,
                                                        onShareGameCode = remember(state.gameId, state.gameCode, state.tableId) {
                                                            { gameCode ->
                                                                analytics.logEvent(
                                                                    FirebaseAnalytics.Event.SHARE_GAME_CODE,
                                                                    listOf(
                                                                        FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, state.gameId.toString()),
                                                                        FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, state.gameCode),
                                                                        FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                                                                        FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, state.tableId.toString()),
                                                                    ),
                                                                )
                                                                shareSheet.shareText(gameCode)
                                                            }
                                                        }
                                                    )
                                                    Row(
                                                        verticalAlignment = Alignment.CenterVertically,
                                                        horizontalArrangement = Arrangement.spacedBy(5.dp),
                                                    ) {
                                                        Icon(
                                                            modifier = Modifier
                                                                .size(iconSize)
                                                                .pointerInput(Unit) {
                                                                    detectTapGestures(
                                                                        onPress = {
                                                                            settingsSelectedTabIndex = 0
                                                                            changeBottomSheetState(BottomSheetState.Settings)
                                                                        },
                                                                    )
                                                                },
                                                            imageVector = Icons.Default.Settings,
                                                            contentDescription = "Settings",
                                                            tint = Color.LightGray,
                                                        )
                                                        if (state.host) {
                                                            Icon(
                                                                modifier = Modifier
                                                                    .size(iconSize)
                                                                    .pointerInput(Unit) {
                                                                        detectTapGestures(
                                                                            onPress = {
                                                                                openUpdateGameSettings = true
                                                                            },
                                                                        )
                                                                    },
                                                                imageVector = Icons.Filled.Tune,
                                                                contentDescription = "Update Game Settings",
                                                                tint = Color.LightGray,
                                                            )
                                                            Icon(
                                                                modifier = Modifier
                                                                    .size(iconSize)
                                                                    .pointerInput(state.gamePaused) {
                                                                        detectTapGestures(
                                                                            onPress = {
                                                                                if (state.gamePaused) {
                                                                                    resume()
                                                                                } else {
                                                                                    pause()
                                                                                }
                                                                            },
                                                                        )
                                                                    },
                                                                imageVector = if (state.gamePaused) {
                                                                    Icons.Default.PlayCircle
                                                                } else {
                                                                    Icons.Default.PauseCircle
                                                                },
                                                                contentDescription = "Play/Pause",
                                                                tint = Color.LightGray,
                                                            )
                                                        }
                                                        Icon(
                                                            modifier = Modifier
                                                                .size(iconSize)
                                                                .pointerInput(Unit) {
                                                                    detectTapGestures(
                                                                        onPress = {
                                                                            openLeaveGameConfirmationDialog = true
                                                                        },
                                                                    )
                                                                },
                                                            imageVector = Icons.AutoMirrored.Default.Logout,
                                                            contentDescription = "Leave",
                                                            tint = Color.LightGray,
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            ScreenOrientationNotSupported(ScreenOrientation.Landscape)
                        }
                    } else {
                        Column {
                            Box(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .weight(0.05f),
                            ) {
                                if (platform == Platform.WEB) {
                                    Icon(
                                        modifier = Modifier
                                            .align(Alignment.CenterStart)
                                            .padding(start = PokerAppTheme.dimensions.grid_1_5)
                                            .size(PokerAppTheme.dimensions.grid_3)
                                            .pointerInput(Unit) {
                                                detectTapGestures(
                                                    onPress = {
                                                        settingsSelectedTabIndex = 0
                                                        changeBottomSheetState(BottomSheetState.Settings)
                                                    },
                                                )
                                            },
                                        imageVector = Icons.Default.Settings,
                                        contentDescription = "Settings",
                                        tint = Color.LightGray,
                                    )
                                    Icon(
                                        modifier = Modifier
                                            .align(Alignment.CenterEnd)
                                            .padding(end = PokerAppTheme.dimensions.grid_1_5)
                                            .size(PokerAppTheme.dimensions.grid_3)
                                            .pointerInput(Unit) {
                                                detectTapGestures(
                                                    onPress = {
                                                        openLeaveGameConfirmationDialog = true
                                                    },
                                                )
                                            },
                                        imageVector = Icons.AutoMirrored.Default.Logout,
                                        contentDescription = "Leave",
                                        tint = Color.LightGray,
                                    )
                                } else {
                                    Icon(
                                        modifier = Modifier
                                            .align(Alignment.CenterStart)
                                            .padding(start = PokerAppTheme.dimensions.grid_1_5, top = PokerAppTheme.dimensions.grid_2)
                                            .size(PokerAppTheme.dimensions.grid_4)
                                            .pointerInput(Unit) {
                                                detectTapGestures(
                                                    onPress = {
                                                        coroutineScope.launch {
                                                            drawerState.apply {
                                                                if (isClosed) {
                                                                    openNavigationDrawer = true
                                                                    open()
                                                                } else {
                                                                    close()
                                                                }
                                                            }
                                                        }
                                                    },
                                                )
                                            },
                                        imageVector = Icons.Filled.Menu,
                                        contentDescription = "Menu",
                                        tint = Color.LightGray,
                                    )
                                }
                                Row(
                                    modifier = Modifier.fillMaxSize(),
                                    horizontalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_1_5, Alignment.CenterHorizontally),
                                    verticalAlignment = Alignment.CenterVertically,
                                ) {
                                    GameCode(
                                        gameCode = state.gameCode,
                                        titleTextStyle = PokerAppTheme.typography.labelSmall,
                                        gameCodeTextStyle = PokerAppTheme.typography.titleSmall,
                                        iconSize = PokerAppTheme.dimensions.grid_2,
                                        onShareGameCode = remember(state.gameId, state.gameCode, state.tableId) {
                                            { gameCode ->
                                                analytics.logEvent(
                                                    FirebaseAnalytics.Event.SHARE_GAME_CODE,
                                                    listOf(
                                                        FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, state.gameId.toString()),
                                                        FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, state.gameCode),
                                                        FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                                                        FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, state.tableId.toString()),
                                                    ),
                                                )
                                                shareSheet.shareText(gameCode)
                                            }
                                        }
                                    )
                                    Clock(
                                        gameTime = state.gameTime,
                                        titleTextStyle = PokerAppTheme.typography.labelSmall,
                                        timeTextStyle = PokerAppTheme.typography.titleSmall,
                                    )
                                    studio.lostjoker.smartdealer.ui.poker.devices.player.components.BlindLevel(
                                        gameFormat = state.gameFormat,
                                        currentBlindLevel = state.currentBlindLevel,
                                        blindLevelHandCountRange = state.blindLevelHandCountRange,
                                        handCount = state.handCount,
                                    )
                                }
                            }
                            Box(
                                modifier = Modifier
                                    .fillMaxWidth()
                                    .weight(0.95f),
                                contentAlignment = Alignment.Center,
                            ) {
                                TableOnlineLayout(
                                    modifier = Modifier.fillMaxSize(),
                                    contentSize = if (PokerAppTheme.isPhone() || platform == Platform.WEB) 90.dp else 160.dp,
                                ) {
                                    (0..<allSeats.size).forEach { seat ->
                                        Column(
                                            horizontalAlignment = Alignment.CenterHorizontally,
                                            verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_0_25, Alignment.CenterVertically),
                                        ) {
                                            PlayerTableOnlineSeat(
                                                player = state.players.singleOrNull { it.seat == seat },
                                                seat = seat,
                                                playerAtTurnSeat = state.playerAtTurnSeat,
                                                cardStyle = state.settings.cardStyle,
                                                cardBackStyle = state.settings.cardBackStyle,
                                                playingHand = state.playingHand,
                                                winningHandDetails = state.winningHandDetails,
                                                roundEnded = state.roundEnded,
                                                tableFelt = state.settings.tableFelt,
                                                actionTime = state.actionTime,
                                                fractionDigits = state.fractionDigits,
                                            )
                                        }
                                    }
                                }
                                Column(
                                    modifier = Modifier
                                        .fillMaxWidth(0.5f)
                                        .fillMaxHeight(0.6f),
                                ) {
                                    Box(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .weight(0.4f),
                                    ) {
                                        Pots(
                                            tableOnlineLayout = true,
                                            deckStart = state.deckStart,
                                            players = state.players,
                                            potContributions = state.potContributions,
                                            potShares = state.potShares,
                                            degrees = 0f,
                                            amountTextStyle = PokerAppTheme.typography.titleSmall,
                                            roundEnded = state.roundEnded,
                                            playingHand = state.playingHand,
                                            fractionDigits = state.fractionDigits,
                                            updateWinningHandDetails = updateWinningHandDetails,
                                        )
                                    }
                                    Box(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .weight(0.2f),
                                    ) {
                                        Board(
                                            device = Device.Player,
                                            firstHand = state.handIndex == 0,
                                            communityCards = state.communityCards,
                                            cardLayout = state.settings.cardLayout,
                                            cardStyle = state.settings.cardStyle,
                                            cardBackStyle = state.settings.cardBackStyle,
                                            playingHand = state.playingHand,
                                            winningPlayer = winningPlayer,
                                            winningCards = state.winningHandDetails.cards,
                                            displayHandResult = false,
                                            gameEnded = state.gameEnded,
                                            gamePaused = state.gamePaused,
                                            borderColor = Color.Transparent,
                                            deal = {},
                                        )
                                    }
                                    Box(
                                        modifier = Modifier
                                            .fillMaxWidth()
                                            .weight(0.4f),
                                    ) {
                                        // Placeholder
                                    }
                                }
                            }
                        }
                        if (platform != Platform.WEB) {
                            Column(
                                modifier = Modifier
                                    .align(Alignment.BottomStart)
                                    .padding(start = PokerAppTheme.dimensions.grid_1_5, bottom = PokerAppTheme.dimensions.grid_1),
                            ) {
                                FloatingActionButtons(
                                    device = Device.Table,
                                    onClickPause = pause,
                                    onClickResume = resume,
                                    onClickHandSummary = { },
                                    onClickSettings = {
                                        settingsSelectedTabIndex = 0
                                        changeBottomSheetState(BottomSheetState.Settings)
                                    },
                                    gamePaused = state.gamePaused,
                                    host = state.host,
                                )
                            }
                        }
                    }
                }
                AnimatedVisibility(
                    visible = state.gameEnded,
                    enter = fadeIn(animationSpec = tween(durationMillis = 1000)),
                    exit = fadeOut(animationSpec = tween(durationMillis = 1000)),
                ) {
                    val titleTextStyle: TextStyle
                    val descriptionTextStyle: TextStyle
                    val buttonTextStyle: TextStyle

                    if (maxHeight < maxWidth && !PokerAppTheme.isPhone()) {
                        titleTextStyle = PokerAppTheme.typography.titleLarge
                        descriptionTextStyle = PokerAppTheme.typography.bodyLarge
                        buttonTextStyle = PokerAppTheme.typography.labelLarge
                    } else {
                        titleTextStyle = PokerAppTheme.typography.titleSmall
                        descriptionTextStyle = PokerAppTheme.typography.bodySmall
                        buttonTextStyle = PokerAppTheme.typography.labelSmall
                    }

                    when (state.gameFormat) {
                        GameFormat.SitAndGo -> {
                            TournamentSummary(
                                players = state.players,
                                titleTextStyle = titleTextStyle,
                                descriptionTextStyle = descriptionTextStyle,
                                buttonTextStyle = buttonTextStyle,
                                payments = {
                                    changeBottomSheetState(BottomSheetState.Payments)
                                },
                                shareNow = {
                                    analytics.logEvent(
                                        FirebaseAnalytics.Event.SCREENSHOT_TOURNAMENT_SUMMARY,
                                        listOf(
                                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, state.gameId.toString()),
                                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, state.gameCode),
                                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, state.tableId.toString()),
                                        ),
                                    )
                                    val path = screenshotHelper.takeScreenshot()
                                    path?.let { shareSheet.shareImage(path, null, null) }
                                },
                                leaveGame = {
                                    openLeaveGameConfirmationDialog = true
                                },
                            )
                        }
                        GameFormat.RingGame -> {
                            RingSummary(
                                players = state.players,
                                titleTextStyle = titleTextStyle,
                                descriptionTextStyle = descriptionTextStyle,
                                buttonTextStyle = buttonTextStyle,
                                payments = {
                                    changeBottomSheetState(BottomSheetState.Payments)
                                },
                                shareNow = {
                                    analytics.logEvent(
                                        FirebaseAnalytics.Event.SCREENSHOT_RING_SUMMARY,
                                        listOf(
                                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_ID, state.gameId.toString()),
                                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.GAME_CODE, state.gameCode),
                                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.DEVICE_TYPE, Device.Table.toString()),
                                            FirebaseParamsBuilder.StringParameter(FirebaseAnalytics.Param.TABLE_ID, state.tableId.toString()),
                                        ),
                                    )
                                    val path = screenshotHelper.takeScreenshot()
                                    path?.let { shareSheet.shareImage(path, null, null) }
                                },
                                leaveGame = {
                                    openLeaveGameConfirmationDialog = true
                                },
                            )
                        }
                    }
                }
                AnimatedVisibility(
                    visible = showLateRegEndPeriodReminder,
                    enter = fadeIn(animationSpec = tween(durationMillis = 1000)),
                    exit = fadeOut(animationSpec = tween(durationMillis = 1000)),
                ) {
                    LateRegistrationEndPeriodReminder(
                        lastBlindLevelBeforeRebuyEnds = state.lastBlindLevelBeforeRebuyEnds,
                        blindLevelHandCountRange = state.blindLevelHandCountRange,
                        handCount = state.handCount,
                    )
                }
            }
        }

        if (state.connecting) {
            ScrimBoxGame()
        }

        if (openUpdateGameSettings) {
            when (state.gameFormat) {
                GameFormat.SitAndGo -> {
                    if (state.tournamentSettings != null && state.pokerSettings != null) {
                        TournamentSettings(
                            players = state.players,
                            currentBlindLevel = state.currentBlindLevel,
                            tournamentSettings = state.tournamentSettings,
                            pokerSettings = state.pokerSettings,
                            onUpdateTournamentSettings = updateTournamentSettings,
                            onCloseTournamentSettings = {
                                openUpdateGameSettings = false
                            },
                        )
                    } else {
                        openUpdateGameSettings = false
                    }
                }
                GameFormat.RingGame -> {
                    if (state.ringGameSettings != null && state.pokerSettings != null) {
                        RingGameSettings(
                            players = state.players,
                            ringGameSettings = state.ringGameSettings,
                            pokerSettings = state.pokerSettings,
                            onUpdateRingGameSettings = updateRingGameSettings,
                            onCloseRingGameSettings = {
                                openUpdateGameSettings = false
                            },
                        )
                    } else {
                        openUpdateGameSettings = false
                    }
                }
            }
        }

        if (openTerminateGameConfirmationDialog) {
            TerminateGameConfirmationDialog(
                gamePaused = state.gamePaused,
                playingHand = state.playingHand,
                closeDialog = { openTerminateGameConfirmationDialog = false },
                terminateGame = terminateGame,
            )
        }

        if (openLeaveGameConfirmationDialog) {
            LeaveGameConfirmationDialog(
                closeDialog = { openLeaveGameConfirmationDialog = false },
                leaveGame = leaveGame,
            )
        }

        if (openNavigationDrawer) {
            NavigationDrawer(
                drawerState = drawerState,
                device = Device.Table,
                gameFormat = state.gameFormat,
                onClickSitOut = { },
                onClickTopUp = { },
                onClickTutorial = { },
                onClickHelp = { },
                onClickUpdateTournamentSettings = {
                    coroutineScope.launch {
                        drawerState.close()
                        openUpdateGameSettings = true
                    }
                },
                onClickUpdateRingGameSettings = {
                    coroutineScope.launch {
                        drawerState.close()
                        openUpdateGameSettings = true
                    }
                },
                onClickTerminateGame = {
                    coroutineScope.launch {
                        drawerState.close()
                        openTerminateGameConfirmationDialog = true
                    }
                },
                onClickLeaveGame = {
                    coroutineScope.launch {
                        drawerState.close()
                        openLeaveGameConfirmationDialog = true
                    }
                },
                host = state.host,
            )
        }

        state.bottomSheetState?.let {
            ModalBottomSheet(
                modifier = Modifier.fillMaxSize(),
                onDismissRequest = {
                    changeBottomSheetState(null)
                },
            ) {
                when (state.bottomSheetState) {
                    BottomSheetState.HandSummary -> {}

                    BottomSheetState.TournamentOverview -> {}

                    BottomSheetState.RingOverview -> {}

                    BottomSheetState.Settings -> {
                        Column(
                            modifier = Modifier.fillMaxSize(),
                            horizontalAlignment = Alignment.CenterHorizontally,
                        ) {
                            Text(
                                text = stringResource(Res.string.poker_devices_settings),
                                style = PokerAppTheme.typography.titleLarge,
                                color = PokerAppTheme.colors.onBackground,
                                fontWeight = FontWeight.Bold,
                            )
                            TabRow(
                                selectedTabIndex = settingsSelectedTabIndex,
                            ) {
                                settings.forEachIndexed { index, setting ->
                                    Tab(
                                        selected = settingsSelectedTabIndex == index,
                                        onClick = { settingsSelectedTabIndex = index },
                                        text = { Text(text = stringResource(setting.title)) },
                                    )
                                }
                            }
                            when (settings[settingsSelectedTabIndex]) {
                                TableDeviceSettings.TableLayout -> TableLayout(
                                    verticalDisplay = verticalDisplay,
                                    changeVerticalDisplay = changeVerticalDisplay,
                                )

                                TableDeviceSettings.TableFelt -> TableFelt(
                                    tableFelt = state.settings.tableFelt,
                                    changeTableFelt = changeTableFelt,
                                )

                                TableDeviceSettings.CardStyle -> CardStyle(
                                    cardStyle = state.settings.cardStyle,
                                    changeCardStyle = changeCardStyle,
                                )

                                TableDeviceSettings.CardBackStyle -> CardBackStyle(
                                    cardBackStyle = state.settings.cardBackStyle,
                                    changeCardBackStyle = changeCardBackStyle,
                                )
                            }
                        }
                    }

                    BottomSheetState.Help -> {}

                    BottomSheetState.Payments -> Payments(
                        gameFormat = state.gameFormat,
                        players = state.players,
                        payments = state.payments,
                        fractionDigits = state.fractionDigits,
                    )
                }
            }
        }
    }
}
