package studio.lostjoker.smartdealer.gameplay.protocol.converter


import studio.lostjoker.smartdealer.domain.Card


fun protocol.Card.toDomain(): Card = when (this) {
    is protocol.Card.Joker -> Card.Joker(color)
    is protocol.Card.Ranked -> toDomain()
}

fun protocol.Card.Ranked.toDomain() = Card.Ranked(
    color = color,
    rank = rank.toDomain(),
    suit = suit.toDomain(),
)
