package studio.lostjoker.smartdealer.helpers

import androidx.compose.runtime.Composable
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

@Composable
actual fun calculateCutoutHeight(inGame: Boolean): Dp {
    return 0.dp
}
