package studio.lostjoker.smartdealer.gameplay.protocol.converter


import studio.lostjoker.smartdealer.domain.Player
import studio.lostjoker.smartdealer.domain.Seat


fun protocol.Seat?.toDomain(): Seat = when (this) {
    null -> Seat.Empty
    is protocol.Seat.Occupied -> Seat.Occupied(
        player = when (player) {
            is protocol.Player.Bot -> Player.Bot(
                botId = player.id,
            )

            is protocol.Player.Human -> Player.Human(
                userId = player.id,
            )
        },
        away = away,
    )
}
