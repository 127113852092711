@file:UseSerializers(
    UuidStringSerializer::class,
    IntClosedRangeStringSerializer::class,
)

package protocol.poker

import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import protocol.IntClosedRangeStringSerializer
import protocol.UuidStringSerializer

@Serializable
data class TournamentSettings(
    val minPlayers: Int,
    val maxPlayers: Int,
    val startTrigger: TournamentStartTrigger,
    val registrationStack: Fixed2,
    val registration: TournamentRegistrationSettings,
    val lateRegistration: TournamentLateRegistration,
    val rebuy: TournamentRebuyConfig,
    val prize: TournamentPrizeSettings,
)

@Serializable
sealed interface TournamentStartTrigger {
    @Serializable
    data object Manual : TournamentStartTrigger
}

@Serializable
sealed interface TournamentRegistrationSettings {
    @Serializable
    data class ImplicitBuyIn(
        val buyIn: Fixed2,
    ) : TournamentRegistrationSettings
}

@Serializable
sealed interface TournamentLateRegistration {
    @Serializable
    data object Disabled : TournamentLateRegistration

    @Serializable
    data class BlindLevels(val numberOfLevels: Int) : TournamentLateRegistration
}

@Serializable
sealed interface TournamentRebuyConfig {
    @Serializable
    data object Disabled : TournamentRebuyConfig

    @Serializable
    data class Enabled(
        val rebuyAvailability: RebuyAvailability,
        val rebuyLimit: Int,
        val maxStackForRebuy: Fixed2,
    ) : TournamentRebuyConfig
}

@Serializable
sealed interface RebuyAvailability {
    @Serializable
    data object Unlimited : RebuyAvailability

    @Serializable
    data class NumberOfBlindLevels(val number: Int) : RebuyAvailability
}

@Serializable
sealed interface TournamentPrizeSettings {
    @Serializable
    data class Payout(
        val payoutStructure: Map<ClosedRange<Int>, Map<Int, Double>>,
    ) : TournamentPrizeSettings
}
