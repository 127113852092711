package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun TableOpenSeat(
    seat: Int,
    avatarSize: Dp,
) {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,
    ) {
        Text(
            text = stringResource(Res.string.table_seating_seat) + " ${seat + 1}",
            style = PokerAppTheme.typography.labelSmall,
            color = Color.Black,
        )
        Box(
            modifier = Modifier
                .size(avatarSize)
                .clip(CircleShape)
                .border(
                    width = PokerAppTheme.dimensions.grid_0_25,
                    color = Color.White,
                    shape = CircleShape,
                ),
            contentAlignment = Alignment.Center,
        ) {
            Text(
                text = stringResource(Res.string.table_seating_open_seat),
                style = PokerAppTheme.typography.labelSmall,
                color = Color.White,
            )
        }
    }
}
