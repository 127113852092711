package studio.lostjoker.smartdealer.ui.poker.join_game

import com.benasher44.uuid.Uuid
import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiErrorCode

data class JsJoinGameState (
    val gameCode: String? = null,
    val errorMessage: String? = null,
    val errorCode: GameManagementApiErrorCode? = null,
    val gameId: Uuid? = null,
    val urlGameCode: String? = null
)
