package studio.lostjoker.smartdealer.ui.theme

import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.ColorScheme
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Shapes
import androidx.compose.material3.Typography
import androidx.compose.material3.windowsizeclass.WindowHeightSizeClass
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.runtime.staticCompositionLocalOf
import androidx.compose.ui.unit.dp
import studio.lostjoker.smartdealer.ui.poker.enum.Device


val LocalDarkTheme = compositionLocalOf<MutableState<Boolean>> { error("LocalDarkTheme not set") }

@Composable
internal fun PokerAppTheme(
    windowWidthSizeClass: WindowWidthSizeClass,
    windowHeightSizeClass: WindowHeightSizeClass = WindowHeightSizeClass.Expanded,
    darkTheme: Boolean = isSystemInDarkTheme(),
    content: @Composable () -> Unit,
) {
    val colors = if (darkTheme) darkColorScheme else lightColorScheme

    val shapes = Shapes(
        extraSmall = RoundedCornerShape(4.dp),
        small = RoundedCornerShape(8.dp),
        medium = RoundedCornerShape(12.dp),
        large = RoundedCornerShape(16.dp),
        extraLarge = RoundedCornerShape(24.dp),
    )

    val dimensions = when (windowWidthSizeClass) {
        WindowWidthSizeClass.Compact -> compactDimensions
        WindowWidthSizeClass.Medium -> mediumDimensions
        WindowWidthSizeClass.Expanded -> expandedDimensions
        else -> compactDimensions
    }

    val typography = Typography() // TODO: Update typography depending on screen size if necessary

    val fontSizes = when (windowWidthSizeClass) {
        WindowWidthSizeClass.Compact -> compactFontSizes
        WindowWidthSizeClass.Medium -> mediumFontSizes
        WindowWidthSizeClass.Expanded -> expandedFontSizes
        else -> compactFontSizes
    }

    CompositionLocalProvider(
        localAppColors provides colors,
        localAppWindowWidthSizeClass provides windowWidthSizeClass,
        localAppWindowHeightSizeClass provides windowHeightSizeClass,
        localAppDimensions provides dimensions,
        localAppTypography provides typography,
        localAppFontSizes provides fontSizes,
    ) {
        MaterialTheme(
            colorScheme = colors,
            shapes = shapes,
            typography = typography,
            content = content,
        )
    }
}

object PokerAppTheme {
    val colors: ColorScheme
        @Composable
        get() = localAppColors.current
    val windowWidthSizeClass: WindowWidthSizeClass
        @Composable
        get() = localAppWindowWidthSizeClass.current
    val windowHeightSizeClass: WindowHeightSizeClass
        @Composable
        get() = localAppWindowHeightSizeClass.current
    val dimensions: Dimensions
        @Composable
        get() = localAppDimensions.current
    val typography: Typography
        @Composable
        get() = localAppTypography.current
    val fontSizes: FontSizes
        @Composable
        get() = localAppFontSizes.current
    @Composable
    fun isPhone(): Boolean {
        return (windowWidthSizeClass == WindowWidthSizeClass.Compact || windowHeightSizeClass == WindowHeightSizeClass.Compact)
                || !(windowWidthSizeClass == WindowWidthSizeClass.Expanded || windowHeightSizeClass == WindowHeightSizeClass.Expanded)
    }
    @Composable
    fun getDevice(): Device {
        return if (isPhone()) Device.Player else Device.Table
    }
}

private val localAppColors = staticCompositionLocalOf {
    lightColorScheme
}

private val localAppWindowWidthSizeClass = staticCompositionLocalOf {
    WindowWidthSizeClass.Compact
}

private val localAppWindowHeightSizeClass = staticCompositionLocalOf {
    WindowHeightSizeClass.Compact
}

private val localAppDimensions = staticCompositionLocalOf {
    compactDimensions
}

private val localAppTypography = staticCompositionLocalOf {
    Typography()
}

private val localAppFontSizes = staticCompositionLocalOf {
    compactFontSizes
}
