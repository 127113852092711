package studio.lostjoker.smartdealer.ui.poker.enum

import org.jetbrains.compose.resources.StringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_game_settings_table_felt_blue
import smartdealer.appshared.generated.resources.poker_game_settings_table_felt_green
import smartdealer.appshared.generated.resources.poker_game_settings_table_felt_red

enum class TableFelt(val resource: StringResource) {
    Green(Res.string.poker_game_settings_table_felt_green),
    Red(Res.string.poker_game_settings_table_felt_red),
    Blue(Res.string.poker_game_settings_table_felt_blue),
}
