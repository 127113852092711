package studio.lostjoker.smartdealer.ui.components

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.Dp
import org.jetbrains.compose.resources.painterResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView.*
import studio.lostjoker.smartdealer.domain.Rank
import studio.lostjoker.smartdealer.domain.Suit.*
import studio.lostjoker.smartdealer.ui.components.card_layout.CardDoubleSidedFull
import studio.lostjoker.smartdealer.ui.components.card_layout.CardDoubleSidedSimple
import studio.lostjoker.smartdealer.ui.components.card_layout.CardSingleSidedFull
import studio.lostjoker.smartdealer.ui.components.card_layout.CardSingleSidedSimple
import studio.lostjoker.smartdealer.ui.components.card_layout.CardSingleSidedSquare
import studio.lostjoker.smartdealer.ui.poker.common.helpers.fourColoredDeckClubs
import studio.lostjoker.smartdealer.ui.poker.common.helpers.fourColoredDeckDiamonds
import studio.lostjoker.smartdealer.ui.poker.common.helpers.fourColoredDeckHearts
import studio.lostjoker.smartdealer.ui.poker.common.helpers.fourColoredDeckSpades
import studio.lostjoker.smartdealer.ui.poker.enum.CardBackStyle
import studio.lostjoker.smartdealer.ui.poker.enum.CardLayout
import studio.lostjoker.smartdealer.ui.poker.enum.CardStyle

@Composable
fun Card(
    modifier: Modifier = Modifier,
    dealtCard: DealtRankedCardPlayerView,
    cardLayout: CardLayout = CardLayout.DoubleSidedFull,
    cardStyle: CardStyle = CardStyle.Original,
    cardBackStyle: CardBackStyle = CardBackStyle.Red,
    cardWidth: Dp,
    cardHeightMultiplier: Float = 1.4f,
    winningCard: Boolean = true,
    folded: Boolean = false
) {

    val card = when (dealtCard) {
        is FaceDown -> null
        is FaceUp -> dealtCard.card
    }

    if (card == null) {
        val cardLayoutModifier = when (cardLayout) {
            CardLayout.SingleSidedSquare -> {
                modifier
                    .border(
                        border = BorderStroke(cardWidth * 0.015f, Color.White),
                    )
                    .width(cardWidth)
                    .height(cardWidth)
            }

            else -> {
                modifier
                    .clip(RoundedCornerShape(cardWidth * 0.1f))
                    .border(
                        border = BorderStroke(cardWidth * 0.015f, Color.White),
                        shape = RoundedCornerShape(cardWidth * 0.1f),
                    )
                    .width(cardWidth)
                    .height(cardWidth * cardHeightMultiplier)
            }
        }
        Box {
            Image(
                painter = painterResource(
                    when (cardBackStyle) {
                        CardBackStyle.Red -> Res.drawable.card_back_red_cropped
                        CardBackStyle.Green -> Res.drawable.card_back_green_cropped
                        CardBackStyle.Blue -> Res.drawable.card_back_blue_cropped
                        CardBackStyle.Orange -> Res.drawable.card_back_orange_cropped
                    },
                ),
                contentDescription = "Card",
                modifier = cardLayoutModifier,
                contentScale = ContentScale.Crop,
            )
            if (folded) {
                Column(
                    modifier = cardLayoutModifier
                        .background(Color.DarkGray.copy(alpha = 0.5f)),
                ) { /* DO NOTHING */ }
            }
        }

    } else {

        val cardFaceValueImage = when (card.rank) {
            Rank.Two -> Res.drawable.card_value_two
            Rank.Three -> Res.drawable.card_value_three
            Rank.Four -> Res.drawable.card_value_four
            Rank.Five -> Res.drawable.card_value_five
            Rank.Six -> Res.drawable.card_value_six
            Rank.Seven -> Res.drawable.card_value_seven
            Rank.Eight -> Res.drawable.card_value_eight
            Rank.Nine -> Res.drawable.card_value_nine
            Rank.Ten -> Res.drawable.card_value_ten
            Rank.Q -> Res.drawable.card_value_queen
            Rank.J -> Res.drawable.card_value_jack
            Rank.K -> Res.drawable.card_value_king
            Rank.A -> Res.drawable.card_value_ace
        }

        val cardBackgroundColor = when (card.suit) {
            Diamonds -> when (cardStyle) {
                CardStyle.Original -> Color.White
                CardStyle.FourColoredSuit -> Color.White
                CardStyle.FourColoredBackground -> fourColoredDeckDiamonds
            }

            Hearts -> when (cardStyle) {
                CardStyle.Original -> Color.White
                CardStyle.FourColoredSuit -> Color.White
                CardStyle.FourColoredBackground -> fourColoredDeckHearts
            }

            Spades -> when (cardStyle) {
                CardStyle.Original -> Color.White
                CardStyle.FourColoredSuit -> Color.White
                CardStyle.FourColoredBackground -> fourColoredDeckSpades
            }

            Clubs -> when (cardStyle) {
                CardStyle.Original -> Color.White
                CardStyle.FourColoredSuit -> Color.White
                CardStyle.FourColoredBackground -> fourColoredDeckClubs
            }
        }

        val suitImage = when (card.suit) {
            Diamonds -> Res.drawable.suit_diamonds
            Hearts -> Res.drawable.suit_hearts
            Spades -> Res.drawable.suit_spades
            Clubs -> Res.drawable.suit_clubs
        }

        val suitColor = when (card.suit) {
            Diamonds -> when (cardStyle) {
                CardStyle.Original -> Color.Red
                CardStyle.FourColoredSuit -> fourColoredDeckDiamonds
                CardStyle.FourColoredBackground -> Color.White
            }

            Hearts -> when (cardStyle) {
                CardStyle.Original -> Color.Red
                CardStyle.FourColoredSuit -> fourColoredDeckHearts
                CardStyle.FourColoredBackground -> Color.White
            }

            Spades -> when (cardStyle) {
                CardStyle.Original -> Color.Black
                CardStyle.FourColoredSuit -> fourColoredDeckSpades
                CardStyle.FourColoredBackground -> Color.White
            }

            Clubs -> when (cardStyle) {
                CardStyle.Original -> Color.Black
                CardStyle.FourColoredSuit -> fourColoredDeckClubs
                CardStyle.FourColoredBackground -> Color.White
            }
        }

        when (cardLayout) {
            CardLayout.SingleSidedSquare -> {
                CardSingleSidedSquare(
                    modifier = modifier,
                    cardFaceValueImage = cardFaceValueImage,
                    cardBackgroundColor = cardBackgroundColor,
                    cardSize = cardWidth,
                    suitImage = suitImage,
                    suitColor = suitColor,
                    winningCard = winningCard,
                    folded = folded,
                )
            }

            CardLayout.SingleSidedSimple -> {
                CardSingleSidedSimple(
                    modifier = modifier,
                    cardFaceValueImage = cardFaceValueImage,
                    cardBackgroundColor = cardBackgroundColor,
                    cardWidth = cardWidth,
                    cardHeightMultiplier = cardHeightMultiplier,
                    suitImage = suitImage,
                    suitColor = suitColor,
                    winningCard = winningCard,
                    folded = folded,
                )
            }

            CardLayout.SingleSidedFull -> {
                CardSingleSidedFull(
                    modifier = modifier,
                    cardFaceValueImage = cardFaceValueImage,
                    cardBackgroundColor = cardBackgroundColor,
                    cardWidth = cardWidth,
                    cardHeightMultiplier = cardHeightMultiplier,
                    suitImage = suitImage,
                    suitColor = suitColor,
                    winningCard = winningCard,
                    folded = folded,
                )
            }

            CardLayout.DoubleSidedSimple -> {
                CardDoubleSidedSimple(
                    modifier = modifier,
                    cardFaceValueImage = cardFaceValueImage,
                    cardBackgroundColor = cardBackgroundColor,
                    cardWidth = cardWidth,
                    cardHeightMultiplier = cardHeightMultiplier,
                    suitImage = suitImage,
                    suitColor = suitColor,
                    winningCard = winningCard,
                    folded = folded,
                )
            }

            CardLayout.DoubleSidedFull -> {
                CardDoubleSidedFull(
                    modifier = modifier,
                    cardFaceValueImage = cardFaceValueImage,
                    cardBackgroundColor = cardBackgroundColor,
                    cardWidth = cardWidth,
                    cardHeightMultiplier = cardHeightMultiplier,
                    suitImage = suitImage,
                    suitColor = suitColor,
                    winningCard = winningCard,
                    folded = folded,
                )
            }
        }
    }
}
