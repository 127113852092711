package studio.lostjoker.smartdealer.notification

import androidx.compose.runtime.Composable
import androidx.compose.runtime.compositionLocalOf

expect class NotificationService {
    fun notify (title: String, content: String)
}

@Composable
expect fun buildNotificationService() : NotificationService

val LocalNotificationService = compositionLocalOf<NotificationService> { error("NotificationService not set") }

@Composable
fun rememberNotificationService() : NotificationService {
    return buildNotificationService()
}
