package studio.lostjoker.smartdealer.helpers

import androidx.compose.material3.SnackbarDuration
import androidx.compose.material3.SnackbarHostState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.runtime.rememberCoroutineScope
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch

//TODO: this needs to be refactored. Once we have a DI library, doesn't need to be a Singleton
class SnackbarDelegate(
    private var snackbarHostState: SnackbarHostState? = null,
    private var coroutineScope: CoroutineScope? = null,
) {
    fun showSnackbar(
        message: String,
        actionLabel: String? = null,
        dismissible: Boolean = false,
        duration: SnackbarDuration = SnackbarDuration.Short,
    ) {
        coroutineScope?.launch {
            snackbarHostState?.showSnackbar(message, actionLabel, dismissible, duration)
        }
    }
}

val LocalSnackbar = compositionLocalOf<SnackbarDelegate> { error("Snackbar not set") }

@Composable
fun rememberSnackBarDelegate(snackbarHostState: SnackbarHostState? = null) : SnackbarDelegate {
    val scope = rememberCoroutineScope()
    return SnackbarDelegate(snackbarHostState, scope)
}
