package protocol

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object UuidStringSerializer : KSerializer<Uuid> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("uuid", PrimitiveKind.STRING)

    override fun deserialize(decoder: Decoder): Uuid {
        return uuidFrom(decoder.decodeString())
    }

    override fun serialize(encoder: Encoder, value: Uuid) {
        encoder.encodeString(value.toString())
    }
}

object IntClosedRangeStringSerializer : KSerializer<ClosedRange<Int>> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("int-closed-range", PrimitiveKind.STRING)

    override fun deserialize(decoder: Decoder): ClosedRange<Int> {
        val components = decoder.decodeString().split("..")
        return IntRange(components[0].toInt(), components[1].toInt())
    }

    override fun serialize(encoder: Encoder, value: ClosedRange<Int>) {
        encoder.encodeString("${value.start}..${value.endInclusive}")
    }
}
