package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.animate
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Visibility
import androidx.compose.material.icons.filled.VisibilityOff
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.geometry.RoundRect
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.PathMeasure
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.kamel.image.KamelImage
import io.kamel.image.asyncPainterResource
import kotlinx.coroutines.delay
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.avatar_profile
import smartdealer.appshared.generated.resources.avatar_robot
import smartdealer.appshared.generated.resources.loading_screen_name
import smartdealer.appshared.generated.resources.poker_devices_sitting_out
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView.FaceDown
import studio.lostjoker.smartdealer.getUserLanguage
import studio.lostjoker.smartdealer.helpers.Platform
import studio.lostjoker.smartdealer.helpers.format
import studio.lostjoker.smartdealer.helpers.getPlatform
import studio.lostjoker.smartdealer.ui.components.Card
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorStack
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltBlueColor2
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltGreenColor2
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltRedColor2
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.FlipCard
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.PlayerAction
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.PositionButton
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.TableOpenSeat
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.animatedBorder
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.WinningHandDetails
import studio.lostjoker.smartdealer.ui.poker.devices.player.PokerViewState
import studio.lostjoker.smartdealer.ui.poker.enum.CardBackStyle
import studio.lostjoker.smartdealer.ui.poker.enum.CardLayout
import studio.lostjoker.smartdealer.ui.poker.enum.CardStyle
import studio.lostjoker.smartdealer.ui.poker.enum.TableFelt
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

@Composable
fun PlayerTableOnlineSeat(
    player: Player? = null,
    actualPlayer: Player? = null,
    seat: Int = -1,
    playerAtTurnSeat: Int = -1,
    cardStyle: CardStyle,
    cardBackStyle: CardBackStyle,
    playingHand: Boolean = false,
    winningHandDetails: WinningHandDetails = WinningHandDetails(),
    roundEnded: Boolean = false,
    maxPlayerTableSeatWidth: Dp = 90.dp,
    tableFelt: TableFelt = TableFelt.Green,
    actionTime: PokerViewState.ActionTime = PokerViewState.ActionTime(Instant.DISTANT_PAST, Duration.ZERO, null),
    revealCardsTrigger: Boolean = false,
    revealCardsSelection: Set<Int> = emptySet(),
    revealCards: Boolean = false,
    fractionDigits: Int = 0,
    onRevealCard: (Int) -> Unit = {},
) {
    val platform = getPlatform()
    val userLanguage = getUserLanguage()

    var flipRotation by remember { mutableStateOf(0f) }

    LaunchedEffect(key1 = player?.showdown) {
        if (player?.showdown == true) {
            animate(initialValue = 0f, targetValue = 180f, animationSpec = tween(durationMillis = 1000)) { value: Float, _: Float ->
                flipRotation = value
            }
        }
    }

    val playerTableSeatWidth: Dp
    val playerTableSeatAvatarSize: Dp
    val playerTableSeatCardSize: Dp
    val playerTableSeatPlayerInfoHeight: Dp
    val playerTableSeatPositionButtonSize: Dp
    val playerTableSeatActionIconSize: Dp
    val playerTableSeatPlayerInfoTextStyle: TextStyle
    val playerTableSeatActionTextStyle: TextStyle

    if (PokerAppTheme.isPhone() || platform == Platform.WEB) {
        playerTableSeatWidth = minOf(PokerAppTheme.dimensions.playerTableSeatPlayerDeviceWidth, maxPlayerTableSeatWidth)
        playerTableSeatAvatarSize = PokerAppTheme.dimensions.playerTableSeatPlayerDeviceAvatarSize
        playerTableSeatCardSize = PokerAppTheme.dimensions.playerTableSeatPlayerDeviceCardSize
        playerTableSeatPlayerInfoHeight = PokerAppTheme.dimensions.playerTableSeatPlayerDevicePlayerInfoHeight
        playerTableSeatPositionButtonSize = PokerAppTheme.dimensions.playerTableSeatPlayerDevicePositionButtonSize
        playerTableSeatActionIconSize = PokerAppTheme.dimensions.grid_1_5
        playerTableSeatPlayerInfoTextStyle = PokerAppTheme.typography.labelSmall
        playerTableSeatActionTextStyle = PokerAppTheme.typography.labelSmall
    }
    else {
        val tableSizeFactor = 1.2f
        playerTableSeatWidth = minOf(PokerAppTheme.dimensions.playerTableSeatTableDeviceWidth, maxPlayerTableSeatWidth) * tableSizeFactor
        playerTableSeatAvatarSize = PokerAppTheme.dimensions.playerTableSeatTableDeviceAvatarSize * tableSizeFactor
        playerTableSeatCardSize = PokerAppTheme.dimensions.playerTableSeatTableDeviceCardSize * tableSizeFactor
        playerTableSeatPlayerInfoHeight = PokerAppTheme.dimensions.playerTableSeatTableDevicePlayerInfoHeight
        playerTableSeatPositionButtonSize = PokerAppTheme.dimensions.playerTableSeatTableDevicePositionButtonSize
        playerTableSeatActionIconSize = PokerAppTheme.dimensions.grid_2_5
        playerTableSeatPlayerInfoTextStyle = PokerAppTheme.typography.titleMedium
        playerTableSeatActionTextStyle = PokerAppTheme.typography.titleMedium
    }

    val playerTableSeatAlpha = if (player?.eliminated == true && player.holeCards.isEmpty()) 0.5f else 1f

    val playerRemainingStack by remember { mutableStateOf(Animatable(0f)) }

    LaunchedEffect(playingHand, player?.remainingStack) {
        if (player != null) {
            if (playingHand) {
                playerRemainingStack.snapTo(player.remainingStack.asFloat())
            } else {
                if (!playerRemainingStack.isRunning && playerRemainingStack.value != player.remainingStack.asFloat()) {
                    delay(1000)
                    playerRemainingStack.animateTo(
                        targetValue = player.remainingStack.asFloat(),
                        animationSpec = tween(durationMillis = 750),
                    )
                }
            }
        }
    }

    val offset = actionTime.offset
    val pauseInstant = actionTime.pauseInstant

    var currentTimeoutDuration by remember { mutableStateOf(Duration.ZERO) }
    val currentProgress by remember { mutableStateOf(Animatable(1f)) }
    val pathWithProgress by remember { mutableStateOf(Path()) }
    val pathMeasure by remember { mutableStateOf(PathMeasure()) }

    val isPlayerAtTurn = player?.seat == playerAtTurnSeat

    LaunchedEffect(key1 = isPlayerAtTurn) {
        if (isPlayerAtTurn) {
            if (pauseInstant != null) {
                val pauseDuration = pauseInstant - offset
                if (pauseDuration > currentTimeoutDuration) {
                    currentTimeoutDuration = pauseDuration
                }
            } else {
                while (true) {
                    try {
                        currentTimeoutDuration = System.now() - offset
                        currentProgress.animateTo(
                            targetValue = ((actionTime.timeout - currentTimeoutDuration) / actionTime.timeout).toFloat(),
                            animationSpec = tween(durationMillis = 1000, easing = LinearEasing),
                        )
                    } catch (e: Exception) {
                        break
                    }
                }
            }
        }
    }

    val playerInfoCornerRadius = PokerAppTheme.dimensions.grid_0_5
    val playerInfoBorderWidth = PokerAppTheme.dimensions.grid_0_25

    val playerInfoModifier = if (isPlayerAtTurn) {
        if (actionTime.timeout == Int.MAX_VALUE.seconds) {
            Modifier.animatedBorder(
                borderColors = listOf(Color.Transparent, Color.Yellow),
                backgroundColor = Color.Transparent,
                shape = RoundedCornerShape(playerInfoCornerRadius),
                borderWidth = playerInfoBorderWidth,
                animationDurationInMillis = 3000,
            )
        } else {
            Modifier
                .padding(playerInfoBorderWidth)
                .drawWithCache {
                    val path = Path()
                    path.addRoundRect(
                        RoundRect(
                            rect = Rect(offset = Offset.Zero, size = size),
                            cornerRadius = CornerRadius(playerInfoCornerRadius.toPx(), playerInfoCornerRadius.toPx()),
                        ),
                    )

                    pathWithProgress.reset()

                    pathMeasure.setPath(
                        path = path,
                        forceClosed = false,
                    )

                    pathMeasure.getSegment(
                        startDistance = 0f,
                        stopDistance = pathMeasure.length * currentProgress.value,
                        destination = pathWithProgress,
                        startWithMoveTo = true,
                    )

                    onDrawWithContent {
                        drawContent()
                        drawPath(
                            path = path,
                            style = Stroke(playerInfoBorderWidth.toPx()),
                            color = Color.LightGray,
                        )
                        drawPath(
                            path = pathWithProgress,
                            style = Stroke(playerInfoBorderWidth.toPx()),
                            color = Color.Yellow,
                        )
                    }
                }
        }
    } else {
        Modifier.padding(playerInfoBorderWidth)
    }

    val playerInfoBackgroundColor = if (isPlayerAtTurn) {
        Color.Black
    } else {
        Color.Black.copy(alpha = 0.5f)
    }

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,
    ) {
        if (player != null) {
            Box(
                modifier = Modifier
                    .alpha(playerTableSeatAlpha)
                    .width(playerTableSeatWidth)
                    .height(playerTableSeatAvatarSize),
                contentAlignment = Alignment.Center,
            ) {
                Box(
                    modifier = Modifier
                        .fillMaxSize(),
                ) {
                    if (player.avatarUrl != null) {
                        KamelImage(
                            modifier = Modifier
                                .size(playerTableSeatAvatarSize)
                                .clip(CircleShape)
                                .align(Alignment.Center),
                            resource = { asyncPainterResource(player.avatarUrl) },
                            contentDescription = "Avatar",
                            contentScale = ContentScale.Crop,
                            onLoading = { progress ->
                                CircularProgressIndicator(
                                    progress = { progress },
                                )
                            },
                            onFailure = { _ ->
                                Image(
                                    painter = painterResource(Res.drawable.avatar_profile),
                                    contentDescription = "Avatar",
                                    modifier = Modifier
                                        .size(playerTableSeatAvatarSize)
                                        .clip(CircleShape)
                                        .align(Alignment.Center),
                                )
                            },
                        )
                    } else {
                        Image(
                            painter = if (!player.bot) {
                                painterResource(Res.drawable.avatar_profile)
                            } else {
                                painterResource(Res.drawable.avatar_robot)
                            },
                            contentDescription = "Avatar",
                            modifier = Modifier
                                .size(playerTableSeatAvatarSize)
                                .clip(CircleShape)
                                .align(Alignment.Center),
                        )
                    }
                }
                Box(
                    modifier = Modifier
                        .fillMaxSize(),
                ) {
                    if (player == actualPlayer) {
                        Row(
                            modifier = Modifier
                                .fillMaxSize(),
                            verticalAlignment = Alignment.Bottom,
                            horizontalArrangement = Arrangement.Center,
                        ) {
                            player.holeCards.forEachIndexed { index, card ->
                                Box(
                                    contentAlignment = Alignment.Center,
                                ) {
                                    if (revealCardsTrigger) {
                                        Icon(
                                            modifier = Modifier
                                                .align(Alignment.TopCenter)
                                                .size(playerTableSeatCardSize)
                                                .offset(y = -(playerTableSeatCardSize + PokerAppTheme.dimensions.grid_0_25))
                                                .pointerInput(card) {
                                                    detectTapGestures(
                                                        onTap = {
                                                            onRevealCard(index)
                                                        },
                                                    )
                                                },
                                            imageVector = if (revealCardsSelection.contains(index)) {
                                                Icons.Filled.Visibility
                                            } else {
                                                Icons.Filled.VisibilityOff
                                            },
                                            contentDescription = "Visibility",
                                            tint = Color.LightGray,
                                        )
                                    }
                                    Card(
                                        dealtCard = card,
                                        cardLayout = CardLayout.SingleSidedSimple,
                                        cardStyle = cardStyle,
                                        cardBackStyle = cardBackStyle,
                                        cardWidth = playerTableSeatCardSize,
                                        winningCard = if (!playingHand) {
                                            (!player.folded && (winningHandDetails.cards.isEmpty() || winningHandDetails.cards.singleOrNull { it == card } != null)) || revealCards
                                        } else {
                                            !player.folded
                                        },
                                    )
                                }
                            }
                        }
                    } else {
                        if (!player.folded || (player.folded && player.showdown)) {
                            if (player.showdown) {
                                Row(
                                    modifier = Modifier
                                        .fillMaxSize(),
                                    verticalAlignment = Alignment.Bottom,
                                    horizontalArrangement = Arrangement.Center,
                                ) {
                                    player.holeCards.forEach { card ->
                                        FlipCard(
                                            dealtCard = card,
                                            cardLayout = CardLayout.SingleSidedSimple,
                                            cardStyle = cardStyle,
                                            cardBackStyle = cardBackStyle,
                                            cardWidth = playerTableSeatCardSize,
                                            isFlipped = false,
                                            flipRotation = flipRotation,
                                            winningCard = if (!playingHand) {
                                                winningHandDetails.cards.isEmpty() || winningHandDetails.cards.singleOrNull { it == card } != null
                                            } else {
                                                true
                                            },
                                        )
                                    }
                                }
                            } else {
                                Row(
                                    modifier = Modifier
                                        .align(Alignment.BottomCenter),
                                ) {
                                    repeat(player.holeCards.size) {
                                        Card(
                                            dealtCard = FaceDown,
                                            cardLayout = CardLayout.SingleSidedSimple,
                                            cardBackStyle = cardBackStyle,
                                            cardWidth = playerTableSeatCardSize / 2f,
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
            Box(
                modifier = playerInfoModifier
                    .alpha(playerTableSeatAlpha)
                    .width(playerTableSeatWidth)
                    .height(playerTableSeatPlayerInfoHeight),
                contentAlignment = Alignment.BottomCenter,
            ) {
                Box(
                    modifier = Modifier
                        .width(playerTableSeatWidth)
                        .height(playerTableSeatPlayerInfoHeight)
                        .clip(RoundedCornerShape(playerInfoCornerRadius))
                        .background(playerInfoBackgroundColor)
                        .align(Alignment.Center),
                    contentAlignment = Alignment.Center,
                ) {
                    Column(
                        verticalArrangement = Arrangement.Center,
                        horizontalAlignment = Alignment.CenterHorizontally,
                    ) {
                        Box(
                            modifier = Modifier
                                .width(playerTableSeatWidth * 0.9f)
                                .height(playerTableSeatPlayerInfoHeight * 0.5f),
                            contentAlignment = Alignment.Center,
                        ) {
                            Text(
                                text = player.screenName ?: stringResource(Res.string.loading_screen_name),
                                style = playerTableSeatPlayerInfoTextStyle,
                                maxLines = 1,
                                overflow = TextOverflow.Ellipsis,
                                color = Color.White,
                            )
                        }
                        HorizontalDivider(
                            thickness = 1.dp,
                            color = when (tableFelt) {
                                TableFelt.Green -> tableFeltGreenColor2
                                TableFelt.Red -> tableFeltRedColor2
                                TableFelt.Blue -> tableFeltBlueColor2
                            },
                        )
                        Box(
                            modifier = Modifier
                                .width(playerTableSeatWidth * 0.9f)
                                .height(playerTableSeatPlayerInfoHeight * 0.5f),
                            contentAlignment = Alignment.Center,
                        ) {
                            if (!player.away || player.eliminated) {
                                Text(
                                    text = playerRemainingStack.value.toDouble().format(digits = fractionDigits, appLanguage = userLanguage),
                                    style = playerTableSeatPlayerInfoTextStyle,
                                    maxLines = 1,
                                    overflow = TextOverflow.Ellipsis,
                                    color = colorStack,
                                )
                            } else {
                                Text(
                                    text = stringResource(Res.string.poker_devices_sitting_out),
                                    style = playerTableSeatPlayerInfoTextStyle,
                                    color = Color.Gray,
                                )
                            }
                        }
                    }
                    player.position?.let { position ->
                        PositionButton(
                            modifier = Modifier
                                .size(playerTableSeatPositionButtonSize)
                                .align(Alignment.BottomEnd),
                            position = position,
                        )
                    }
                }
            }
            Box(
                modifier = Modifier
                    .width(playerTableSeatWidth)
                    .height(playerTableSeatPlayerInfoHeight * 0.5f),
                contentAlignment = Alignment.Center,
            ) {
                PlayerAction(
                    modifier = Modifier
                        .height(playerTableSeatPlayerInfoHeight * 0.5f),
                    bettingStack = player.bettingStack,
                    action = player.action,
                    actionSize = playerTableSeatWidth,
                    actionTextStyle = playerTableSeatActionTextStyle,
                    actionIconSize = playerTableSeatActionIconSize,
                    actionCornerRadius = PokerAppTheme.dimensions.grid_1_5,
                    roundEnded = roundEnded,
                    isWinningPlayer = player.seat == winningHandDetails.seat,
                    winningHandDetails = winningHandDetails,
                    playingHand = playingHand,
                    fractionDigits = fractionDigits,
                )
            }
        } else {
            TableOpenSeat(
                seat = seat,
                avatarSize = playerTableSeatWidth * 0.8f,
            )
        }
    }
}
