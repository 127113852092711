package studio.lostjoker.smartdealer.ui.poker.host_game.components

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Circle
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.Slider
import androidx.compose.material3.SliderDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.getUserLanguage
import studio.lostjoker.smartdealer.helpers.format
import studio.lostjoker.smartdealer.helpers.isInteger
import studio.lostjoker.smartdealer.ui.components.ToggleButton
import studio.lostjoker.smartdealer.ui.components.ToggleButtonOption
import studio.lostjoker.smartdealer.ui.components.ToggleSelection
import studio.lostjoker.smartdealer.ui.components.TwoSplitRow
import studio.lostjoker.smartdealer.ui.poker.common.helpers.ringGameBlindStructure
import studio.lostjoker.smartdealer.ui.poker.enum.RingGameStake
import studio.lostjoker.smartdealer.ui.poker.host_game.RingGameSettings
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun RingGameBlindSelector(
    ringGameSettings: RingGameSettings,
    onChangeRingGameStake: (stake: RingGameStake) -> Unit,
    onChangeRingGameBlinds: (sliderPosition: Float) -> Unit,
) {
    val userLanguage = getUserLanguage()

    val blindSettings = ringGameSettings.blindSettings

    val options = arrayOf(
        ToggleButtonOption(
            value = RingGameStake.Micro,
            text = stringResource(Res.string.poker_ring_game_micro),
            imageVector = null,
        ),
        ToggleButtonOption(
            value = RingGameStake.Low,
            text = stringResource(Res.string.poker_ring_game_low),
            imageVector = null,
        ),
        ToggleButtonOption(
            value = RingGameStake.Medium,
            text = stringResource(Res.string.poker_ring_game_medium),
            imageVector = null,
        ),
        ToggleButtonOption(
            value = RingGameStake.High,
            text = stringResource(Res.string.poker_ring_game_high),
            imageVector = null,
        ),
    )

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        TwoSplitRow(
            firstColumnContent = {
                Text(
                    text = stringResource(Res.string.poker_settings_blinds),
                    style = PokerAppTheme.typography.titleMedium,
                )
            },
            secondColumnContent = {
                val digits = if (isInteger(blindSettings.smallBlind) && isInteger(blindSettings.bigBlind)) 0 else 2
                Text(
                    modifier = Modifier,
                    text = "${blindSettings.smallBlind.format(digits, userLanguage)} / ${blindSettings.bigBlind.format(digits, userLanguage)}",
                    style = PokerAppTheme.typography.labelLarge,
                )
            },
        )

        Spacer(modifier = Modifier.height(PokerAppTheme.dimensions.grid_0_25))

        ToggleButton(
            options = options,
            values = arrayOf(options.first { it.value == ringGameSettings.stake }),
            type = ToggleSelection.Single,
            modifier = Modifier
                .padding(4.dp)
                .height(36.dp)
                .fillMaxWidth(),
            onClick = {
                onChangeRingGameStake(it.first().value)
            },
        )

        Slider(
            colors = SliderDefaults.colors().copy(
                activeTickColor = Color.Transparent,
                inactiveTickColor = Color.Transparent,
                inactiveTrackColor = PokerAppTheme.colors.surfaceVariant,
            ),
            value = ringGameSettings.blindSettingsSliderPosition,
            steps = ringGameBlindStructure[ringGameSettings.stake]!!.size.minus(2),
            valueRange = 1f..(ringGameBlindStructure[ringGameSettings.stake]!!.size.toFloat()),
            onValueChange = { sliderPosition ->
                onChangeRingGameBlinds(sliderPosition)
            },
            thumb = {
                Icon(
                    modifier = Modifier.size(PokerAppTheme.dimensions.grid_4),
                    imageVector = Icons.Filled.Circle,
                    contentDescription = null,
                    tint = PokerAppTheme.colors.primary,
                )
            },
        )
    }
}
