import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.window.CanvasBasedWindow
import kotlinx.browser.window
import org.jetbrains.skiko.wasm.onWasmReady
import studio.lostjoker.smartdealer.Offering

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    onWasmReady {
        val apiBaseUrl: String
        val gameplayBaseUrl: String

        when (window.location.hostname) {
            "gameplay.smartdealer.poker" -> {
                apiBaseUrl = "https://api.smartdealer.poker"
                gameplayBaseUrl = "wss://gameplay.smartdealer.poker"
            }
            "smartdealer.tplinkdns.com" -> {
                apiBaseUrl = "https://smartdealer.tplinkdns.com"
                gameplayBaseUrl = "wss://smartdealer.tplinkdns.com"
            }
            else -> {
                apiBaseUrl = "http://localhost:3000"
                gameplayBaseUrl = "ws://localhost:3000"
            }
        }

        CanvasBasedWindow("SD Poker") {
            Column(modifier = Modifier.fillMaxSize()) {
                MainView(Offering.Poker, gameplayBaseUrl, apiBaseUrl)
            }
        }
    }
}
