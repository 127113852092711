package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltBlue
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltGreen
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltRed
import studio.lostjoker.smartdealer.ui.poker.enum.TableFelt
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun TableFelt(
    tableFelt: TableFelt,
    changeTableFelt: (tableFelt: TableFelt) -> Unit = {},
) {

    var selected by remember { mutableStateOf(tableFelt) }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight(0.4f)
            .wrapContentHeight()
            .padding(vertical = 8.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_1, Alignment.CenterVertically),
    ) {
        TableFelt.entries.forEach { tableFelt ->
            Card(
                modifier = Modifier
                    .width(200.dp)
                    .height(50.dp),
                onClick = {
                    changeTableFelt(tableFelt)
                    selected = tableFelt
                },
                shape = MaterialTheme.shapes.small,
                elevation = CardDefaults.cardElevation(defaultElevation = 4.dp),
                colors = CardDefaults.cardColors(
                    containerColor = if (selected == tableFelt) PokerAppTheme.colors.surfaceBright else PokerAppTheme.colors.surfaceVariant,
                    contentColor = if (selected == tableFelt) PokerAppTheme.colors.onSurface else PokerAppTheme.colors.onSurfaceVariant,
                ),
            ) {
                Row(
                    modifier = Modifier.fillMaxSize(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween,
                ) {
                    Text(
                        modifier = Modifier
                            .fillMaxWidth(0.3f)
                            .padding(5.dp),
                        text = stringResource(tableFelt.resource),
                        style = MaterialTheme.typography.labelSmall,
                    )
                    Box(
                        modifier = Modifier
                            .fillMaxWidth(0.7f)
                            .fillMaxHeight(0.8f)
                            .padding(end = 5.dp)
                            .clip(MaterialTheme.shapes.small)
                            .border(width = 2.dp, color = Color.White, shape = MaterialTheme.shapes.small)
                            .background(when(tableFelt) {
                                TableFelt.Green -> tableFeltGreen
                                TableFelt.Red -> tableFeltRed
                                TableFelt.Blue -> tableFeltBlue
                            }),
                    )
                }
            }
        }
    }
}
