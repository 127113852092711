package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import org.jetbrains.compose.resources.pluralStringResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_devices_blind_level_up_duration_hands
import smartdealer.appshared.generated.resources.poker_settings_late_registration_period_ends
import smartdealer.appshared.generated.resources.poker_settings_rebuy_period_ends
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevelHandCountRange
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun LateRegistrationEndPeriodReminder(
    lastBlindLevelBeforeRebuyEnds: Boolean = false,
    blindLevelHandCountRange: BlindLevelHandCountRange? = null,
    handCount: Int = 0,
) {
    // TODO: Implement time-based blind level up

    val handsLeft = if (blindLevelHandCountRange != null) {
        (blindLevelHandCountRange.endExclusive - blindLevelHandCountRange.start) - handCount
    } else {
        null
    }

    val reminderMessage = if (lastBlindLevelBeforeRebuyEnds) {
        stringResource(Res.string.poker_settings_rebuy_period_ends)
    } else {
        stringResource(Res.string.poker_settings_late_registration_period_ends)
    }

    Column(
        modifier = Modifier
            .background(Color.Black.copy(alpha = 0.8f))
            .fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,
    ) {
        handsLeft?.let {
            Text(
                text = reminderMessage + " " + pluralStringResource(Res.plurals.poker_devices_blind_level_up_duration_hands, handsLeft, handsLeft),
                color = Color.White,
                style = PokerAppTheme.typography.titleLarge,
            )
        }
    }
}
