package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.layout.Layout
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Constraints
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorButtonContainer
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorButtonContent
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.enum.Device
import studio.lostjoker.smartdealer.ui.poker.enum.DeviceMode
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme
import kotlin.math.PI
import kotlin.math.cos
import kotlin.math.roundToInt
import kotlin.math.sin

@Composable
fun TableSeating(
    device: Device,
    deviceMode: DeviceMode,
    tableSize: Int,
    players: List<Player>,
    gameCode: String,
    titleTextStyle: TextStyle = TextStyle(),
    gameCodeTextStyle: TextStyle = TextStyle(),
    startTournament: () -> Unit = {},
    changePlayerSeat: (Int) -> Unit = {},
    onShareGameCode: (String) -> Unit = {},
) {
    var openStartGameDialog by remember(gameCode) { mutableStateOf(false) }
    Column {
        Box(
            modifier = Modifier.fillMaxWidth(),
        ) {
            Column(
                modifier = Modifier
                    .align(Alignment.Center),
            ) {
                GameCode(
                    gameCode = gameCode,
                    titleTextStyle = titleTextStyle,
                    gameCodeTextStyle = gameCodeTextStyle,
                ) {
                    onShareGameCode(it)
                }
            }
        }
        BoxWithConstraints(
            modifier = Modifier
                .fillMaxSize()
                .weight(1f),
        ) {
            TableSeatingLayout(
                contentSize = 90.dp,
            ) {
                for (seat in 0..<tableSize) {
                    PlayerSeatTableSeating(
                        seat = seat,
                        player = players.singleOrNull { it.seat == seat },
                    ) {
                        changePlayerSeat(it)
                    }
                }
            }
        }
        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Bottom,
        ) {
            if (device == Device.Player) {
                Text(
                    text = stringResource(Res.string.poker_devices_change_seats),
                    style = PokerAppTheme.typography.labelMedium,
                    fontWeight = FontWeight.Bold,
                    color = Color.Yellow,
                )
            }
            Button(
                modifier = Modifier
                    .padding(horizontal = 16.dp, vertical = 8.dp)
                    .fillMaxWidth(.8f),
                enabled = deviceMode == DeviceMode.Host,
                onClick = { openStartGameDialog = true },
                colors = ButtonDefaults.buttonColors(
                    containerColor = colorButtonContainer,
                    contentColor = colorButtonContent,
                ),
            ) {
                Text(
                    text = stringResource(Res.string.poker_devices_start_game),
                    style = PokerAppTheme.typography.titleMedium,
                )
            }
        }
        if (openStartGameDialog) {
            AlertDialog(
                onDismissRequest = {
                    openStartGameDialog = false
                },
                title = {
                    Text(
                        text = stringResource(Res.string.poker_dialog_start_game_title),
                        style = PokerAppTheme.typography.titleMedium,
                    )
                },
                text = {
                    Text(
                        text = if (players.size < 2) {
                            stringResource(Res.string.poker_dialog_start_game_text_minimum_players)
                        } else {
                            stringResource(Res.string.poker_dialog_start_game_text)
                        },
                        style = PokerAppTheme.typography.labelLarge,
                    )
                },
                confirmButton = {
                    TextButton(
                        onClick = {
                            openStartGameDialog = false
                            if (players.size >= 2) {
                                startTournament()
                            }
                        },
                    ) {
                        Text(
                            text = if (players.size < 2) {
                                stringResource(Res.string.poker_dialog_ok)
                            } else {
                                stringResource(Res.string.poker_dialog_confirm)
                            },
                            style = PokerAppTheme.typography.labelLarge,
                        )
                    }
                },
                dismissButton = if (players.size >= 2) {
                    {
                        TextButton(
                            onClick = {
                                openStartGameDialog = false
                            },
                        ) {
                            Text(
                                text = stringResource(Res.string.poker_dialog_cancel),
                                style = PokerAppTheme.typography.labelLarge,
                            )
                        }
                    }
                } else {
                    null
                },
            )
        }
    }
}

@Composable
fun TableSeatingLayout(
    modifier: Modifier = Modifier,
    contentSize: Dp,
    content: @Composable () -> Unit,
) {
    Box(
        modifier = Modifier
            .padding(PokerAppTheme.dimensions.grid_1)
            .fillMaxSize()
            .clip(CircleShape)
            .border(
                width = PokerAppTheme.dimensions.grid_0_5,
                color = Color.LightGray,
                shape = CircleShape,
            ),
    ) {
        Image(
            modifier = Modifier
                .fillMaxSize(0.6f)
                .align(Alignment.Center),
            painter = painterResource(Res.drawable.logo_long_text_horizontal_white),
            contentDescription = null,
            contentScale = ContentScale.Fit,
            alpha = 0.7f,
        )
        Layout(
            modifier = modifier,
            content = content,
        ) { measurables, constraints ->
            val itemDimension = contentSize.toPx()
            val itemConstraints = Constraints.fixed(
                width = itemDimension.toInt(),
                height = itemDimension.toInt(),
            )
            val placeables = measurables.map { measurable -> measurable.measure(itemConstraints) }
            layout(
                width = constraints.maxWidth,
                height = constraints.maxHeight,
            ) {
                val availableHorizontalSpace = constraints.maxWidth - itemDimension
                val horizontalOffset = availableHorizontalSpace / 2.0
                val verticalOffset = (constraints.maxHeight - itemDimension).toInt() / 2
                val angleStep = - 2.0 * PI / placeables.size
                placeables.forEachIndexed { index, placeable ->
                    val itemAngle = angleStep * index.toDouble()
                    val offset = getCoordinates(
                        width = availableHorizontalSpace / 2.0,
                        height = (constraints.maxHeight.toDouble() / 2.0 - itemDimension),
                        angle = itemAngle,
                    )
                    placeable.placeRelative(
                        x = (horizontalOffset + offset.x).roundToInt(),
                        y = offset.y.roundToInt() + verticalOffset,
                    )
                }
            }
        }
    }
}

private fun getCoordinates(width: Double, height: Double, angle: Double): Offset {
    val x = width * sin(angle)
    val y = height * cos(angle)
    return Offset(
        x = x.toFloat(),
        y = y.toFloat(),
    )
}
