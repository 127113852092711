package studio.lostjoker.smartdealer.gameplay.protocol.converter

import studio.lostjoker.smartdealer.domain.Player

// converters for Player
fun Player.Bot.toProtocol() = protocol.Player.Bot(
	botId = botId,
)
fun protocol.Player.Bot.toDomain() = Player.Bot(
	botId = botId,
)

fun Player.Human.toProtocol() = protocol.Player.Human(
	userId = userId,
)
fun protocol.Player.Human.toDomain() = Player.Human(
	userId = userId,
)

fun Player.toProtocol(): protocol.Player = when (this) {
	is Player.Bot -> toProtocol()
	is Player.Human -> toProtocol()
}
fun protocol.Player.toDomain(): Player = when (this) {
	is protocol.Player.Bot -> toDomain()
	is protocol.Player.Human -> toDomain()
}

