package studio.lostjoker.smartdealer.firebase

import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseApp

actual val Firebase.analytics: FirebaseAnalytics
    get() = JsFirebaseAnalytics()

actual fun Firebase.analytics(app: FirebaseApp): FirebaseAnalytics = JsFirebaseAnalytics()

class JsFirebaseAnalytics: FirebaseAnalytics {
    override fun logEvent(eventName: String, parameters: List<FirebaseParamsBuilder.Parameter>) {

    }
}
