package studio.lostjoker.smartdealer.game_management.web.contract

import com.benasher44.uuid.Uuid
import kotlinx.serialization.Serializable
import studio.lostjoker.smartdealer.game_management.web.contract.serializers.UuidSerializer

@Serializable
data class GameCreationResponse(
    val gameCode: String,
    @Serializable(with = UuidSerializer::class)
    val gameId: Uuid,
)

@Serializable
data class GameExistsResponse(
    val gameCode: String,
    @Serializable(with = UuidSerializer::class)
    val gameId: Uuid,
)

@Serializable
data class GameManagementApiError(
    val errorCode: GameManagementApiErrorCode? = null,
)

@Serializable
data class PlayerAccountResponse(
    val playerId: String,
    val screenName: String,
    val profileImageUrl: String?,
)
