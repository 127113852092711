package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.tween
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.KeyboardArrowRight
import androidx.compose.material.icons.filled.KeyboardArrowDown
import androidx.compose.material.icons.filled.KeyboardArrowUp
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.unit.Dp
import kotlinx.coroutines.delay
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_player_hand_action_checked
import smartdealer.appshared.generated.resources.poker_player_hand_action_folded
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAction
import studio.lostjoker.smartdealer.domain.toFixed2
import studio.lostjoker.smartdealer.getUserLanguage
import studio.lostjoker.smartdealer.helpers.format
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorBet
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorCall
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorCheck
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorFold
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorRaise
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.WinningHandDetails

@Composable
fun PlayerAction(
    modifier: Modifier = Modifier,
    bettingStack: Fixed2,
    action: PlayerHandAction? = null,
    actionSize: Dp,
    actionTextStyle: TextStyle = TextStyle(),
    actionIconSize: Dp,
    actionCornerRadius: Dp,
    roundEnded: Boolean = false,
    isWinningPlayer: Boolean = false,
    winningHandDetails: WinningHandDetails = WinningHandDetails(),
    playingHand: Boolean = false,
    fractionDigits: Int = 0,
) {
    val userLanguage = getUserLanguage()
    val betAmount by remember(action) { mutableStateOf(Animatable(bettingStack.asFloat())) }
    var betCountdownAnimationFinished by remember(action) { mutableStateOf(false) }
    var showWinningHandRank by remember(isWinningPlayer) { mutableStateOf(false) }

    LaunchedEffect(key1 = roundEnded, key2 = isWinningPlayer, key3 = playingHand) {
        if (playingHand) {
            if (roundEnded) {
                delay(250)
                betAmount.animateTo(
                    targetValue = 0f,
                    animationSpec = tween(durationMillis = 750),
                )
                if (!betAmount.isRunning) {
                    betCountdownAnimationFinished = true
                }
            }
            if (roundEnded && isWinningPlayer) {
                delay(1000)
                showWinningHandRank = true
            }
        }
    }

    if (!playingHand && (showWinningHandRank || isWinningPlayer)) {
        winningHandDetails.rank?.let { rank ->
            Text(
                text = rank,
                style = actionTextStyle,
                color = Color.White,
            )
        }
    }

    AnimatedVisibility(
        visible = action != null && !betCountdownAnimationFinished,
        enter = fadeIn(animationSpec = tween(durationMillis = 500)),
        exit = fadeOut(animationSpec = tween(durationMillis = 500)),
    ) {
        action?.let {
            val color = when (action) {
                is PlayerHandAction.Fold -> colorFold
                is PlayerHandAction.Bet -> colorBet
                is PlayerHandAction.Raise -> colorRaise
                is PlayerHandAction.Call -> colorCall
                is PlayerHandAction.Check -> colorCheck
                is PlayerHandAction.PostBigBlind -> colorCall
                is PlayerHandAction.PostSmallBlind -> colorCall
            }
            val icon = when (action) {
                is PlayerHandAction.Fold -> Icons.Default.KeyboardArrowDown
                is PlayerHandAction.Bet -> Icons.Default.KeyboardArrowUp
                is PlayerHandAction.Raise -> Icons.Default.KeyboardArrowUp
                is PlayerHandAction.Call -> Icons.AutoMirrored.Default.KeyboardArrowRight
                is PlayerHandAction.Check -> Icons.AutoMirrored.Default.KeyboardArrowRight
                is PlayerHandAction.PostBigBlind -> Icons.AutoMirrored.Default.KeyboardArrowRight
                is PlayerHandAction.PostSmallBlind -> Icons.AutoMirrored.Default.KeyboardArrowRight
            }
            Box(
                modifier = modifier
                    .width(actionSize)
                    .height(actionSize * 0.3f)
                    .drawBehind {
                        drawRoundRect(
                            color = color,
                            cornerRadius = CornerRadius(actionCornerRadius.toPx()),
                        )
                    },
                contentAlignment = Alignment.Center,
            ) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    Text(
                        text = if (betAmount.value.toFixed2() > 0.toFixed2()) {
                            betAmount.value.toDouble().format(digits = fractionDigits, appLanguage = userLanguage)
                        } else {
                            when (action) {
                                PlayerHandAction.Check -> stringResource(Res.string.poker_player_hand_action_checked)
                                PlayerHandAction.Fold -> stringResource(Res.string.poker_player_hand_action_folded)
                                else -> ""
                            }
                        },
                        style = actionTextStyle,
                        color = Color.White,
                    )
                    Icon(
                        modifier = Modifier
                            .size(actionIconSize),
                        imageVector = icon,
                        contentDescription = "Arrow",
                        tint = Color.White,
                    )
                }
            }
        }
    }
}
