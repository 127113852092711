package studio.lostjoker.smartdealer.ui.poker.host_game.components

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Circle
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.RangeSlider
import androidx.compose.material3.SliderDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.getUserLanguage
import studio.lostjoker.smartdealer.helpers.format
import studio.lostjoker.smartdealer.helpers.isInteger
import studio.lostjoker.smartdealer.ui.components.TwoSplitRow
import studio.lostjoker.smartdealer.ui.poker.host_game.RingGameSettings
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun RingGameBuyInRangeSelector(
    ringGameSettings: RingGameSettings,
    onChangeRingGameBuyInRange: (sliderPosition: ClosedFloatingPointRange<Float>) -> Unit,
) {
    val userLanguage = getUserLanguage()

    val buyInRange = ringGameSettings.buyInRange

    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        TwoSplitRow(
            firstColumnContent = {
                Text(
                    text = stringResource(Res.string.poker_settings_buy_in),
                    style = PokerAppTheme.typography.titleMedium,
                )
            },
            secondColumnContent = {
                val digits = if (isInteger(buyInRange.minBuyIn) && isInteger(buyInRange.maxBuyIn)) 0 else 2
                Text(
                    modifier = Modifier,
                    text = "${buyInRange.minBuyIn.format(digits, userLanguage)} / ${buyInRange.maxBuyIn.format(digits, userLanguage)}",
                    style = PokerAppTheme.typography.labelLarge,
                )
            },
        )

        RangeSlider(
            colors = SliderDefaults.colors().copy(
                activeTickColor = Color.Transparent,
                inactiveTickColor = Color.Transparent,
                inactiveTrackColor = PokerAppTheme.colors.surfaceVariant,
            ),
            value = ringGameSettings.buyInRangeSliderPosition,
            steps = 30,
            valueRange = 1f ..30f ,
            onValueChange = { sliderPosition ->
                onChangeRingGameBuyInRange(sliderPosition)
            },
            startThumb = {
                Icon(
                    modifier = Modifier.size(PokerAppTheme.dimensions.grid_4),
                    imageVector = Icons.Filled.Circle,
                    contentDescription = null,
                    tint = PokerAppTheme.colors.primary,
                )
            },
            endThumb = {
                Icon(
                    modifier = Modifier.size(PokerAppTheme.dimensions.grid_4),
                    imageVector = Icons.Filled.Circle,
                    contentDescription = null,
                    tint = PokerAppTheme.colors.primary,
                )
            },
        )
    }
}
