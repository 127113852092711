package studio.lostjoker.smartdealer.sharesheet

import androidx.compose.runtime.Composable

interface ShareSheetInterface {
    fun shareText(content: String, title: String? = null)
    fun shareImage(path: String, content: String? = null, title: String? = null)
}


@Composable
expect fun shareSheet() : ShareSheetInterface
