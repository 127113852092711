package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.material3.Text
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import kotlinx.coroutines.delay
import kotlinx.datetime.Clock.*
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.ui.poker.devices.player.PokerViewState
import kotlin.time.Duration

@Composable
fun Clock(
    gameTime: PokerViewState.GameTime,
    titleTextStyle: TextStyle = TextStyle(),
    timeTextStyle: TextStyle = TextStyle(),
) {
    val offset = gameTime.offset
    val pauseInstant = gameTime.pauseInstant
    var currentGameDuration by remember { mutableStateOf(Duration.ZERO) }

    LaunchedEffect(key1 = offset, key2 = pauseInstant) {
        if (pauseInstant != null) {
            val pauseDuration = pauseInstant - offset
            if (pauseDuration > currentGameDuration) {
                currentGameDuration = pauseDuration
            }
        } else {
            while (true) {
                currentGameDuration = System.now() - offset
                delay(1000) // pause for one second
            }
        }
    }

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,
    ) {
        Text(
            text = stringResource(Res.string.poker_devices_clock),
            style = titleTextStyle,
            color = Color.Black,
        )
        Text(
            text = currentGameDuration.toFormattedString(),
            style = timeTextStyle,
            color = Color.White,
        )
    }
}

private fun Duration.toFormattedString() = toComponents { hours, minutes, seconds, _ ->
    "${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}"
}
