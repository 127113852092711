package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.Layout
import androidx.compose.ui.unit.Constraints
import androidx.compose.ui.unit.Dp
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme
import kotlin.math.PI
import kotlin.math.cos
import kotlin.math.roundToInt
import kotlin.math.sin

@Composable
fun TableOnlineLayout(
    modifier: Modifier = Modifier,
    contentSize: Dp,
    content: @Composable () -> Unit,
) {
    Box(
        modifier = modifier
            .padding(PokerAppTheme.dimensions.grid_1)
            .fillMaxSize()
            .clip(CircleShape)
            .border(
                width = PokerAppTheme.dimensions.grid_0_5,
                color = Color.LightGray,
                shape = CircleShape,
            ),
    ) {
        Layout(
            modifier = modifier,
            content = content,
        ) { measurables, constraints ->
            val itemDimension = contentSize.toPx()
            val itemConstraints = Constraints.fixed(
                width = itemDimension.toInt(),
                height = itemDimension.toInt(),
            )
            val placeables = measurables.map { measurable -> measurable.measure(itemConstraints) }
            layout(
                width = constraints.maxWidth,
                height = constraints.maxHeight,
            ) {
                val availableHorizontalSpace = constraints.maxWidth - itemDimension
                val horizontalOffset = availableHorizontalSpace / 2.0
                val verticalOffset = (constraints.maxHeight - itemDimension).toInt() / 2
                val angleStep = - 2.0 * PI / placeables.size
                placeables.forEachIndexed { index, placeable ->
                    val itemAngle = angleStep * index.toDouble()
                    val offset = getCoordinates(
                        width = availableHorizontalSpace / 2.0,
                        height = (constraints.maxHeight.toDouble() / 2.0 - itemDimension),
                        angle = itemAngle,
                    )
                    placeable.placeRelative(
                        x = (horizontalOffset + offset.x).roundToInt(),
                        y = offset.y.roundToInt() + verticalOffset,
                    )
                }
            }
        }
    }
}

private fun getCoordinates(width: Double, height: Double, angle: Double): Offset {
    val x = width * sin(angle)
    val y = height * cos(angle)
    return Offset(
        x = x.toFloat(),
        y = y.toFloat(),
    )
}
