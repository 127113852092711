package studio.lostjoker.smartdealer.domain.poker.model

import studio.lostjoker.smartdealer.domain.Card
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.Rank
import studio.lostjoker.smartdealer.domain.toFixed2
import kotlin.time.Duration

data class BlindLevel(
    val ante: Fixed2,
    val smallBlind: Fixed2,
    val bigBlind: Fixed2,
) {
    constructor(ante: Int, smallBlind: Int, bigBlind: Int) : this(ante.toFixed2(), smallBlind.toFixed2(), bigBlind.toFixed2())
    companion object {
        val Empty = BlindLevel(0, 0, 0)
    }
}

sealed interface BlindLevelDuration {
    val blindLevel: BlindLevel
}

data class BlindLevelTimeSlotDuration(
    override val blindLevel: BlindLevel,
    val timeSlot: Duration,
) : BlindLevelDuration

data class BlindLevelHandCountDuration(
    override val blindLevel: BlindLevel,
    val handCount: Int,
) : BlindLevelDuration

data class BlindLevelHandCountRange(
    val start: Int,
    val endExclusive: Int,
)

enum class PokerVariant {
    Texas,
    Omaha,
}

enum class HighLowMode {
    High,
    HighLow,
}

enum class BettingLimit {
    NoLimit,
    PotLimit,
    FixedLimit,
}

sealed interface BlindProgression {
    sealed interface MultiLevelProgression<C: BlindLevelDuration>: BlindProgression {
        val levels: List<C>
    }
    data class Static(val blindLevel: BlindLevel) : BlindProgression
    data class HandCount(override val levels: List<BlindLevelHandCountDuration>) : BlindProgression, MultiLevelProgression<BlindLevelHandCountDuration>
    data class TimeSlot(override val levels: List<BlindLevelTimeSlotDuration>) : BlindProgression, MultiLevelProgression<BlindLevelTimeSlotDuration>
}

enum class HighLow {
    High,
    Low,
}

data class PokerSettings(
    val variant: PokerVariant,
    val highLowMode: HighLowMode,
    val bettingLimit: BettingLimit,
    val tableSize: Int,
    val deckStart: Rank,
    val actionTimeout: Int,
    val blindProgression: BlindProgression,
) {
    companion object {
        internal val Empty = PokerSettings(
            variant = PokerVariant.Texas,
            highLowMode = HighLowMode.High,
            bettingLimit = BettingLimit.NoLimit,
            tableSize = 0,
            deckStart = Rank.A,
            actionTimeout = 0,
            blindProgression = BlindProgression.Static(BlindLevel(0.toFixed2(), 0.toFixed2(), 0.toFixed2())),
        )
    }
}

// TODO: Review parameters before persisting data
data class Positions(
    val dealerSeat: Int,
    val smallBlindSeat: Int?, // null in case of a dead small blind
    val bigBlindSeat: Int,
    val utgSeat: Int? = null,
    val utg1Seat: Int? = null,
    val mp1Seat: Int? = null,
    val mp2Seat: Int? = null,
    val mp3Seat: Int? = null,
    val hijackSeat: Int? = null,
    val cutoffSeat: Int? = null,
)

sealed interface PlayerHandAction {
    sealed interface SpendingAction : PlayerHandAction {
        val amount: Fixed2
    }

    sealed interface NonBlindAction : PlayerHandAction

    data class PostSmallBlind(override val amount: Fixed2) : SpendingAction
    data class PostBigBlind(override val amount: Fixed2) : SpendingAction
    data class Call(override val amount: Fixed2) : SpendingAction, NonBlindAction
    data object Check : NonBlindAction
    data object Fold : NonBlindAction
    data class Bet(override val amount: Fixed2) : SpendingAction, NonBlindAction
    data class Raise(override val amount: Fixed2) : SpendingAction, NonBlindAction
}

sealed interface PlayerHandAvailableAction {
    data class PostSmallBlind(val amount: Fixed2) : PlayerHandAvailableAction
    data class PostBigBlind(val amount: Fixed2) : PlayerHandAvailableAction
    data class Call(val amount: Fixed2) : PlayerHandAvailableAction
    data object Check : PlayerHandAvailableAction
    data object Fold : PlayerHandAvailableAction
    data class Bet(val min: Fixed2, val max: Fixed2) : PlayerHandAvailableAction
    data class Raise(val min: Fixed2, val max: Fixed2) : PlayerHandAvailableAction
}


data class SeatSnapshot(
    val seat: Int,
    val stack: Fixed2,
    val folded: Boolean,
) {
    constructor(seat: Int, stack: Float, folded: Boolean) : this (seat, stack.toFixed2(), folded)
}

data class SeatRoundResult(
    val seat: Int,
    val folded: Boolean,
    val betAmount: Fixed2, // amount bet in the round, excluding any uncalled bets
    val uncalledBetAmount: Fixed2 = 0.toFixed2(),
) {
    internal constructor(seat: Int, folded: Boolean, betAmount: Float, uncalledBetAmount: Float = 0f) :
            this(seat, folded, betAmount.toFixed2(), uncalledBetAmount.toFixed2())
}

data class SeatHandResult(
    val seat: Int,
    val folded: Boolean,
    val betAmount: Fixed2, // total amount bet in all rounds, excluding any uncalled bets
    val winAmount: Fixed2,
) {
    internal constructor(seat: Int, folded: Boolean, betAmount: Float, winAmount: Float) :
            this(seat, folded, betAmount.toFixed2(), winAmount.toFixed2())
}

data class PotShare(
    // key
    val seat: Int,
    val pot: Int,
    val run: Int,
    val highLow: HighLow,
    // data
    val hand: List<Card.Ranked>,
    val amount: Fixed2,
) {
    internal constructor(seat: Int, pot: Int, run: Int, highLow: HighLow, hand: List<Card.Ranked>, amount: Float) :
            this(seat, pot, run, highLow, hand, amount.toFixed2())
}

// currently the same as the domain, can be removed
data class PotSharePlayerProjection(
    // key
    val seat: Int,
    val pot: Int,
    val run: Int,
    val highLow: HighLow,
    // data
    val hand: List<Card.Ranked>?, // null if player did not have to reveal cards during showdown
    val amount: Fixed2,
)

data class Pot(
    val amount: Fixed2,
    val seats: Set<Int>,
) {
    internal constructor(amount: Float, seats: Set<Int>) :
            this(amount.toFixed2(), seats)
}

internal infix fun List<Pot>.receives(contribution: Pot): List<Pot> = this
    .partition { it.seats == contribution.seats }
    .let {
        it.second + if (it.first.isNotEmpty()) {
            val currentPot = it.first.single()
            Pot(currentPot.amount + contribution.amount, currentPot.seats)
        } else {
            contribution
        }
    }
    .sortedByDescending { it.seats.size }

internal fun Pot.split(shares: Int, minPrecision: Fixed2 = Fixed2.minPrecision): List<Fixed2> {
    val roundAmount = (amount / shares).roundTo(minPrecision)
    val remainder = amount - roundAmount * shares
    val playersWithRemainder = remainder / minPrecision
    return buildList {
        repeat(shares) {
            add(roundAmount + if (it < playersWithRemainder) minPrecision else 0.toFixed2())
        }
    }
}

data class BotSettings(
    val allowBots: Boolean = false,
    val numberOfBots: Int = 0,
)
