package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Share
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.unit.Dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.helpers.Platform
import studio.lostjoker.smartdealer.helpers.getPlatform
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun GameCode(
    gameCode: String,
    titleTextStyle: TextStyle = TextStyle(),
    gameCodeTextStyle: TextStyle = TextStyle(),
    iconSize: Dp = PokerAppTheme.dimensions.grid_4,
    onShareGameCode: (String) -> Unit = {},
) {
    val platform = getPlatform()
    val shareCodeMessage = stringResource(Res.string.sharesheet_share_game_code, gameCode)

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,
    ) {
        Text(
            text = stringResource(Res.string.poker_devices_game_code),
            style = titleTextStyle,
            color = Color.Black,
        )
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_0_5, Alignment.CenterHorizontally),
        ) {
            Text(
                text = gameCode,
                style = gameCodeTextStyle,
                color = Color.White,
            )
            if (platform != Platform.WEB) {
                Icon(
                    modifier = Modifier
                        .size(iconSize)
                        .pointerInput(Unit) {
                            detectTapGestures(
                                onPress = {
                                    onShareGameCode(shareCodeMessage)
                                },
                            )
                        },
                    imageVector = Icons.Default.Share,
                    contentDescription = "Share",
                    tint = Color.LightGray,
                )
            }
        }
    }
}
