package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.AnimationVector1D
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.animate
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawWithCache
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Rect
import androidx.compose.ui.geometry.RoundRect
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.PathMeasure
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import io.kamel.image.KamelImage
import io.kamel.image.asyncPainterResource
import kotlinx.coroutines.delay
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.AppLanguage
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView.*
import studio.lostjoker.smartdealer.domain.poker.model.PokerVariant
import studio.lostjoker.smartdealer.getUserLanguage
import studio.lostjoker.smartdealer.helpers.format
import studio.lostjoker.smartdealer.ui.components.Card
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorStack
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltBlueColor2
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltGreenColor2
import studio.lostjoker.smartdealer.ui.poker.common.helpers.tableFeltRedColor2
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.PlayerAction
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.FlipCard
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.TableOpenSeat
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.PositionButton
import studio.lostjoker.smartdealer.ui.poker.devices.common.components.animatedBorder
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.WinningHandDetails
import studio.lostjoker.smartdealer.ui.poker.devices.player.PokerViewState
import studio.lostjoker.smartdealer.ui.poker.enum.CardBackStyle
import studio.lostjoker.smartdealer.ui.poker.enum.CardLayout
import studio.lostjoker.smartdealer.ui.poker.enum.CardStyle
import studio.lostjoker.smartdealer.ui.poker.enum.Device
import studio.lostjoker.smartdealer.ui.poker.enum.TableFelt
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

@Composable
fun PlayerTableSeat(
    device: Device,
    player: Player? = null,
    seat: Int = -1,
    playerAtTurnSeat: Int = -1,
    cardStyle: CardStyle,
    cardBackStyle: CardBackStyle,
    playingHand: Boolean = false,
    winningHandDetails: WinningHandDetails = WinningHandDetails(),
    roundEnded: Boolean = false,
    tableSize: Int = 10,
    maxPlayerTableSeatWidth: Dp = 100.dp,
    tableFelt: TableFelt = TableFelt.Green,
    pokerVariant: PokerVariant = PokerVariant.Texas,
    actionTime: PokerViewState.ActionTime = PokerViewState.ActionTime(Instant.DISTANT_PAST, Duration.ZERO, null),
    fractionDigits: Int = 0,
) {
    val userLanguage = getUserLanguage()

    var flipRotation by remember { mutableStateOf(0f) }

    LaunchedEffect(key1 = player?.showdown) {
        if (player?.showdown == true) {
            animate(initialValue = 0f, targetValue = 180f, animationSpec = tween(durationMillis = 1000)) { value: Float, _: Float ->
                flipRotation = value
            }
        }
    }

    // Omaha only works with this layout
    val tableSizeFactor = if (tableSize > 6 || pokerVariant == PokerVariant.Omaha) 1f else 1.2f

    val playerTableSeatWidth: Dp
    val playerTableSeatAvatarSize: Dp
    val playerTableSeatCardSize: Dp
    val playerTableSeatPlayerInfoHeight: Dp
    val playerTableSeatPositionButtonSize: Dp
    val playerTableSeatActionIconSize: Dp
    val playerTableSeatPlayerInfoTextStyle: TextStyle
    val playerTableSeatActionTextStyle: TextStyle

    when (device) {
        Device.Player -> {
            if (PokerAppTheme.isPhone()) {
                playerTableSeatWidth = minOf(PokerAppTheme.dimensions.playerTableSeatPlayerDeviceWidth * tableSizeFactor, maxPlayerTableSeatWidth)
                playerTableSeatAvatarSize = PokerAppTheme.dimensions.playerTableSeatPlayerDeviceAvatarSize * tableSizeFactor
                playerTableSeatCardSize = PokerAppTheme.dimensions.playerTableSeatPlayerDeviceCardSize * tableSizeFactor
                playerTableSeatPlayerInfoHeight = PokerAppTheme.dimensions.playerTableSeatPlayerDevicePlayerInfoHeight * tableSizeFactor
                playerTableSeatPositionButtonSize = PokerAppTheme.dimensions.playerTableSeatPlayerDevicePositionButtonSize * tableSizeFactor
                playerTableSeatActionIconSize = PokerAppTheme.dimensions.grid_1_5 * tableSizeFactor
                playerTableSeatPlayerInfoTextStyle = PokerAppTheme.typography.labelSmall
                playerTableSeatActionTextStyle = PokerAppTheme.typography.labelSmall
            } else {
                playerTableSeatWidth = minOf(PokerAppTheme.dimensions.playerTableSeatPlayerDeviceWidth * tableSizeFactor, maxPlayerTableSeatWidth) * 1.5f
                playerTableSeatAvatarSize = PokerAppTheme.dimensions.playerTableSeatPlayerDeviceAvatarSize * tableSizeFactor * 1.5f
                playerTableSeatCardSize = PokerAppTheme.dimensions.playerTableSeatPlayerDeviceCardSize * tableSizeFactor * 1.5f
                playerTableSeatPlayerInfoHeight = PokerAppTheme.dimensions.playerTableSeatPlayerDevicePlayerInfoHeight * tableSizeFactor * 1.5f
                playerTableSeatPositionButtonSize = PokerAppTheme.dimensions.playerTableSeatPlayerDevicePositionButtonSize * tableSizeFactor * 1.5f
                playerTableSeatActionIconSize = PokerAppTheme.dimensions.grid_1_5 * tableSizeFactor * 1.5f
                playerTableSeatPlayerInfoTextStyle = PokerAppTheme.typography.titleMedium
                playerTableSeatActionTextStyle = PokerAppTheme.typography.titleMedium
            }
        }

        Device.Table -> {
            playerTableSeatWidth = minOf(PokerAppTheme.dimensions.playerTableSeatTableDeviceWidth * tableSizeFactor, maxPlayerTableSeatWidth)
            playerTableSeatAvatarSize = PokerAppTheme.dimensions.playerTableSeatTableDeviceAvatarSize * tableSizeFactor
            playerTableSeatCardSize = minOf(PokerAppTheme.dimensions.playerTableSeatTableDeviceCardSize * tableSizeFactor, maxPlayerTableSeatWidth * 0.25f)
            playerTableSeatPlayerInfoHeight = PokerAppTheme.dimensions.playerTableSeatTableDevicePlayerInfoHeight * if (tableSizeFactor == 1f) tableSizeFactor else 1.9f
            playerTableSeatPositionButtonSize = PokerAppTheme.dimensions.playerTableSeatTableDevicePositionButtonSize * tableSizeFactor
            playerTableSeatActionIconSize = PokerAppTheme.dimensions.grid_2_5 * tableSizeFactor
            playerTableSeatPlayerInfoTextStyle = if (tableSizeFactor == 1f) PokerAppTheme.typography.titleMedium else PokerAppTheme.typography.displaySmall
            playerTableSeatActionTextStyle = if (tableSizeFactor == 1f) PokerAppTheme.typography.titleMedium else PokerAppTheme.typography.displaySmall
        }
    }

    val playerTableSeatAlpha = if (player?.eliminated == true && player.holeCards.isEmpty()) 0.5f else 1f

    val playerRemainingStack by remember { mutableStateOf(Animatable(0f)) }

    LaunchedEffect(playingHand, player?.remainingStack) {
        if (player != null) {
            if (playingHand) {
                playerRemainingStack.snapTo(player.remainingStack.asFloat())
            } else {
                if (!playerRemainingStack.isRunning && playerRemainingStack.value != player.remainingStack.asFloat()) {
                    delay(1000)
                    playerRemainingStack.animateTo(
                        targetValue = player.remainingStack.asFloat(),
                        animationSpec = tween(durationMillis = 750),
                    )
                }
            }
        }
    }

    val offset = actionTime.offset
    val pauseInstant = actionTime.pauseInstant

    var currentTimeoutDuration by remember { mutableStateOf(Duration.ZERO) }
    val currentProgress by remember { mutableStateOf(Animatable(1f)) }
    val pathWithProgress by remember { mutableStateOf(Path()) }
    val pathMeasure by remember { mutableStateOf(PathMeasure()) }

    val isPlayerAtTurn = player?.seat == playerAtTurnSeat

    LaunchedEffect(key1 = isPlayerAtTurn) {
        if (isPlayerAtTurn) {
            if (pauseInstant != null) {
                val pauseDuration = pauseInstant - offset
                if (pauseDuration > currentTimeoutDuration) {
                    currentTimeoutDuration = pauseDuration
                }
            } else {
                while (true) {
                    try {
                        currentTimeoutDuration = System.now() - offset
                        currentProgress.animateTo(
                            targetValue = ((actionTime.timeout - currentTimeoutDuration) / actionTime.timeout).toFloat(),
                            animationSpec = tween(durationMillis = 1000, easing = LinearEasing),
                        )
                    } catch (e: Exception) {
                        break
                    }
                }
            }
        }
    }

    val playerInfoCornerRadius = PokerAppTheme.dimensions.grid_0_5
    val playerInfoBorderWidth = PokerAppTheme.dimensions.grid_0_25

    val playerInfoModifier = if (isPlayerAtTurn) {
        if (actionTime.timeout == Int.MAX_VALUE.seconds) {
            Modifier.animatedBorder(
                borderColors = listOf(Color.Transparent, Color.Yellow),
                backgroundColor = Color.Transparent,
                shape = RoundedCornerShape(playerInfoCornerRadius),
                borderWidth = playerInfoBorderWidth,
                animationDurationInMillis = 3000,
            )
        } else {
            Modifier
                .padding(playerInfoBorderWidth)
                .drawWithCache {
                    val path = Path()
                    path.addRoundRect(
                        RoundRect(
                            rect = Rect(offset = Offset.Zero, size = size),
                            cornerRadius = CornerRadius(playerInfoCornerRadius.toPx(), playerInfoCornerRadius.toPx()),
                        ),
                    )

                    pathWithProgress.reset()

                    pathMeasure.setPath(
                        path = path,
                        forceClosed = false,
                    )

                    pathMeasure.getSegment(
                        startDistance = 0f,
                        stopDistance = pathMeasure.length * currentProgress.value,
                        destination = pathWithProgress,
                        startWithMoveTo = true,
                    )

                    onDrawWithContent {
                        drawContent()
                        drawPath(
                            path = path,
                            style = Stroke(playerInfoBorderWidth.toPx()),
                            color = Color.LightGray,
                        )
                        drawPath(
                            path = pathWithProgress,
                            style = Stroke(playerInfoBorderWidth.toPx()),
                            color = Color.Yellow,
                        )
                    }
                }
        }
    } else {
        Modifier.padding(playerInfoBorderWidth)
    }

    val playerInfoBackgroundColor = if (isPlayerAtTurn) {
        Color.Black
    } else {
        Color.Black.copy(alpha = 0.5f)
    }

    if (tableSize > 6 || pokerVariant == PokerVariant.Omaha) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
        ) {
            if (player != null) {
                Box(
                    modifier = Modifier
                        .alpha(playerTableSeatAlpha)
                        .width(playerTableSeatWidth)
                        .height(playerTableSeatAvatarSize),
                    contentAlignment = Alignment.Center,
                ) {
                    PlayerAvatarVerticalLayout(
                        player = player,
                        avatarSize = playerTableSeatAvatarSize,
                    )
                    PlayerCardsVerticalLayout(
                        player = player,
                        cardStyle = cardStyle,
                        cardBackStyle = cardBackStyle,
                        cardSize = playerTableSeatCardSize,
                        flipRotation = flipRotation,
                        playingHand = playingHand,
                        winningCards = winningHandDetails.cards,
                    )
                }
                Box(
                    modifier = playerInfoModifier
                        .alpha(playerTableSeatAlpha)
                        .width(playerTableSeatWidth)
                        .height(playerTableSeatPlayerInfoHeight),
                    contentAlignment = Alignment.BottomCenter,
                ) {
                    PlayerInfoVerticalLayout(
                        modifier = Modifier.align(Alignment.Center),
                        seatWidth = playerTableSeatWidth,
                        seatHeight = playerTableSeatPlayerInfoHeight,
                        cornerRadius = playerInfoCornerRadius,
                        backgroundColor = playerInfoBackgroundColor,
                        player = player,
                        textStyle = playerTableSeatPlayerInfoTextStyle,
                        tableFelt = tableFelt,
                        remainingStack = playerRemainingStack,
                        fractionDigits = fractionDigits,
                        userLanguage = userLanguage,
                        buttonSize = playerTableSeatPositionButtonSize
                    )
                }
                Box(
                    modifier = Modifier
                        .width(playerTableSeatWidth)
                        .height(playerTableSeatPlayerInfoHeight * 0.5f),
                    contentAlignment = Alignment.Center,
                ) {
                    PlayerAction(
                        modifier = Modifier
                            .height(playerTableSeatPlayerInfoHeight * 0.5f),
                        bettingStack = player.bettingStack,
                        action = player.action,
                        actionSize = playerTableSeatWidth,
                        actionTextStyle = playerTableSeatActionTextStyle,
                        actionIconSize = playerTableSeatActionIconSize,
                        actionCornerRadius = PokerAppTheme.dimensions.grid_1_5,
                        roundEnded = roundEnded,
                        isWinningPlayer = player.seat == winningHandDetails.seat,
                        winningHandDetails = winningHandDetails,
                        playingHand = playingHand,
                        fractionDigits = fractionDigits,
                    )
                }
            } else {
                TableOpenSeat(
                    seat = seat,
                    avatarSize = playerTableSeatWidth * 0.8f,
                )
            }
        }
    } else {
        val maxWidth = maxOf(playerTableSeatCardSize * 2f, playerTableSeatPlayerInfoHeight) + playerTableSeatWidth
        Column(
            modifier = Modifier
                .width(maxWidth),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
        ) {
            if (player != null) {
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center,
                ) {
                    Box(
                        modifier = Modifier
                            .alpha(playerTableSeatAlpha)
                            .width(maxOf(playerTableSeatCardSize * 2f, playerTableSeatPlayerInfoHeight))
                            .height(playerTableSeatPlayerInfoHeight),
                        contentAlignment = Alignment.Center,
                    ) {
                        PlayerAvatarHorizontalLayout(
                            player = player,
                            avatarSize = playerTableSeatPlayerInfoHeight,
                        )
                        Row(
                            modifier = Modifier.fillMaxSize(),
                            verticalAlignment = Alignment.Bottom,
                            horizontalArrangement = Arrangement.Center,
                        ) {
                            PlayerCardsHorizontalLayout(
                                player = player,
                                cardStyle = cardStyle,
                                cardBackStyle = cardBackStyle,
                                cardSize = playerTableSeatCardSize,
                                cardWidth = playerTableSeatPlayerInfoHeight,
                                flipRotation = flipRotation,
                                playingHand = playingHand,
                                winningCards = winningHandDetails.cards,
                            )
                        }
                    }
                    Box(
                        modifier = playerInfoModifier
                            .alpha(playerTableSeatAlpha)
                            .width(playerTableSeatWidth)
                            .height(playerTableSeatPlayerInfoHeight),
                        contentAlignment = Alignment.BottomCenter,
                    ) {
                        PlayerInfoHorizontalLayout(
                            modifier = Modifier.align(Alignment.Center),
                            seatWidth = playerTableSeatWidth,
                            seatHeight = playerTableSeatPlayerInfoHeight,
                            cornerRadius = playerInfoCornerRadius,
                            backgroundColor = playerInfoBackgroundColor,
                            player = player,
                            textStyle = playerTableSeatPlayerInfoTextStyle,
                            tableFelt = tableFelt,
                            remainingStack = playerRemainingStack,
                            fractionDigits = fractionDigits,
                            userLanguage = userLanguage
                        )
                    }
                }
                Row(
                    modifier = Modifier
                        .width(maxWidth)
                        .height(playerTableSeatPlayerInfoHeight * 0.5f),
                ) {
                    Box(
                        modifier = Modifier
                            .width(maxWidth - playerTableSeatWidth)
                            .height(playerTableSeatPlayerInfoHeight * 0.5f),
                        contentAlignment = Alignment.Center,
                    ) {
                        player.position?.let { position ->
                            PositionButton(
                                modifier = Modifier
                                    .width(maxWidth - playerTableSeatWidth)
                                    .height(playerTableSeatPlayerInfoHeight * 0.5f),
                                position = position,
                            )
                        }
                    }
                    Box(
                        modifier = Modifier
                            .width(playerTableSeatWidth)
                            .height(playerTableSeatPlayerInfoHeight * 0.5f),
                        contentAlignment = Alignment.Center,
                    ) {
                        PlayerAction(
                            modifier = Modifier
                                .width(playerTableSeatWidth)
                                .height(playerTableSeatPlayerInfoHeight * 0.5f),
                            bettingStack = player.bettingStack,
                            action = player.action,
                            actionSize = playerTableSeatWidth,
                            actionTextStyle = playerTableSeatActionTextStyle,
                            actionIconSize = playerTableSeatActionIconSize,
                            actionCornerRadius = PokerAppTheme.dimensions.grid_1_5,
                            roundEnded = roundEnded,
                            isWinningPlayer = player.seat == winningHandDetails.seat,
                            winningHandDetails = winningHandDetails,
                            playingHand = playingHand,
                            fractionDigits = fractionDigits,
                        )
                    }
                }
            } else {
                TableOpenSeat(
                    seat = seat,
                    avatarSize = playerTableSeatWidth * 0.8f,
                )
            }
        }
    }
}

@Composable
private fun PlayerInfoHorizontalLayout(
    modifier: Modifier,
    seatWidth: Dp,
    seatHeight: Dp,
    cornerRadius: Dp,
    backgroundColor: Color,
    player: Player,
    textStyle: TextStyle,
    tableFelt: TableFelt,
    remainingStack: Animatable<Float, AnimationVector1D>,
    fractionDigits: Int,
    userLanguage: AppLanguage,
) {
    Box(
        modifier = modifier
            .width(seatWidth)
            .height(seatHeight)
            .clip(RoundedCornerShape(cornerRadius))
            .background(backgroundColor),
        contentAlignment = Alignment.Center,
    ) {
        Column(
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Box(
                modifier = Modifier
                    .width(seatWidth * 0.9f)
                    .height(seatHeight * 0.5f),
                contentAlignment = Alignment.Center,
            ) {
                Text(
                    text = player.screenName ?: stringResource(Res.string.loading_screen_name),
                    style = textStyle,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                    color = Color.White,
                )
            }
            HorizontalDivider(
                thickness = 1.dp,
                color = when (tableFelt) {
                    TableFelt.Green -> tableFeltGreenColor2
                    TableFelt.Red -> tableFeltRedColor2
                    TableFelt.Blue -> tableFeltBlueColor2
                },
            )
            Box(
                modifier = Modifier
                    .width(seatWidth * 0.9f)
                    .height(seatHeight * 0.5f),
                contentAlignment = Alignment.Center,
            ) {
                if (!player.away || player.eliminated) {
                    Text(
                        text = remainingStack.value.toDouble().format(digits = fractionDigits, appLanguage = userLanguage),
                        style = textStyle,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                        color = colorStack,
                    )
                } else {
                    Text(
                        text = stringResource(Res.string.poker_devices_sitting_out),
                        style = textStyle,
                        color = Color.Gray,
                    )
                }
            }
        }
    }
}

@Composable
private fun PlayerCardsHorizontalLayout(
    player: Player,
    cardStyle: CardStyle,
    cardBackStyle: CardBackStyle,
    cardSize: Dp,
    cardWidth: Dp,
    flipRotation: Float,
    playingHand: Boolean,
    winningCards: List<DealtRankedCardPlayerView>,
) {
    if (!player.folded || (player.folded && player.showdown)) {
        if (player.showdown) {
            player.holeCards.forEach { card ->
                FlipCard(
                    dealtCard = card,
                    cardLayout = CardLayout.SingleSidedSimple,
                    cardStyle = cardStyle,
                    cardBackStyle = cardBackStyle,
                    cardWidth = maxOf(cardSize, cardWidth / 2f),
                    isFlipped = false,
                    flipRotation = flipRotation,
                    winningCard = if (!playingHand) {
                        winningCards.isEmpty() || winningCards.singleOrNull { it == card } != null
                    } else {
                        true
                    },
                )
            }
        } else {
            repeat(player.holeCards.size) {
                Card(
                    dealtCard = FaceDown,
                    cardLayout = CardLayout.SingleSidedSimple,
                    cardBackStyle = cardBackStyle,
                    cardWidth = maxOf(cardSize, cardWidth / 2f) / 2f,
                )
            }
        }
    }
}

@Composable
private fun PlayerAvatarHorizontalLayout(
    player: Player,
    avatarSize: Dp,
) {
    Box(modifier = Modifier.fillMaxSize()) {
        if (player.avatarUrl != null) {
            KamelImage(
                modifier = Modifier
                    .size(avatarSize)
                    .clip(CircleShape)
                    .align(Alignment.Center),
                resource = { asyncPainterResource(player.avatarUrl) },
                contentDescription = "Avatar",
                contentScale = ContentScale.Crop,
                onLoading = { progress ->
                    CircularProgressIndicator(
                        progress = { progress },
                    )
                },
                onFailure = { _ ->
                    Image(
                        painter = if (!player.bot) {
                            painterResource(Res.drawable.avatar_profile)
                        } else {
                            painterResource(Res.drawable.avatar_robot)
                        },
                        contentDescription = "Avatar",
                        modifier = Modifier
                            .size(avatarSize)
                            .clip(CircleShape)
                            .align(Alignment.Center),
                    )
                },
            )
        } else {
            Image(
                painter = if (!player.bot) {
                    painterResource(Res.drawable.avatar_profile)
                } else {
                    painterResource(Res.drawable.avatar_robot)
                },
                contentDescription = "Avatar",
                modifier = Modifier
                    .size(avatarSize)
                    .clip(CircleShape)
                    .align(Alignment.Center),
            )
        }
    }
}

@Composable
private fun PlayerInfoVerticalLayout(
    modifier: Modifier,
    seatWidth: Dp,
    seatHeight: Dp,
    cornerRadius: Dp,
    backgroundColor: Color,
    player: Player,
    textStyle: TextStyle,
    tableFelt: TableFelt,
    remainingStack: Animatable<Float, AnimationVector1D>,
    fractionDigits: Int,
    userLanguage: AppLanguage,
    buttonSize: Dp,
) {
    Box(
        modifier = modifier
            .width(seatWidth)
            .height(seatHeight)
            .clip(RoundedCornerShape(cornerRadius))
            .background(backgroundColor),
        contentAlignment = Alignment.Center,
    ) {
        Column(
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Box(
                modifier = Modifier
                    .width(seatWidth * 0.9f)
                    .height(seatHeight * 0.5f),
                contentAlignment = Alignment.Center,
            ) {
                Text(
                    text = player.screenName ?: stringResource(Res.string.loading_screen_name),
                    style = textStyle,
                    maxLines = 1,
                    overflow = TextOverflow.Ellipsis,
                    color = Color.White,
                )
            }
            HorizontalDivider(
                thickness = 1.dp,
                color = when (tableFelt) {
                    TableFelt.Green -> tableFeltGreenColor2
                    TableFelt.Red -> tableFeltRedColor2
                    TableFelt.Blue -> tableFeltBlueColor2
                },
            )
            Box(
                modifier = Modifier
                    .width(seatWidth * 0.9f)
                    .height(seatHeight * 0.5f),
                contentAlignment = Alignment.Center,
            ) {
                if (!player.away || player.eliminated) {
                    Text(
                        text = remainingStack.value.toDouble().format(digits = fractionDigits, appLanguage = userLanguage),
                        style = textStyle,
                        maxLines = 1,
                        overflow = TextOverflow.Ellipsis,
                        color = colorStack,
                    )
                } else {
                    Text(
                        text = stringResource(Res.string.poker_devices_sitting_out),
                        style = textStyle,
                        color = Color.Gray,
                    )
                }
            }
        }
        player.position?.let { position ->
            PositionButton(
                modifier = Modifier
                    .size(buttonSize)
                    .align(Alignment.BottomEnd),
                position = position,
            )
        }
    }
}

@Composable
private fun PlayerCardsVerticalLayout(
    player: Player,
    cardStyle: CardStyle,
    cardBackStyle: CardBackStyle,
    cardSize: Dp,
    flipRotation: Float,
    playingHand: Boolean,
    winningCards: List<DealtRankedCardPlayerView>,
) {
    Box(modifier = Modifier.fillMaxSize()) {
        if (!player.folded || (player.folded && player.showdown)) {
            if (player.showdown) {
                Row(
                    modifier = Modifier
                        .fillMaxSize(),
                    verticalAlignment = Alignment.Bottom,
                    horizontalArrangement = Arrangement.Center,
                ) {
                    player.holeCards.forEach { card ->
                        FlipCard(
                            dealtCard = card,
                            cardLayout = CardLayout.SingleSidedSimple,
                            cardStyle = cardStyle,
                            cardBackStyle = cardBackStyle,
                            cardWidth = cardSize,
                            isFlipped = false,
                            flipRotation = flipRotation,
                            winningCard = if (!playingHand) {
                                winningCards.isEmpty() || winningCards.singleOrNull { it == card } != null
                            } else {
                                true
                            },
                        )
                    }
                }
            } else {
                Row(
                    modifier = Modifier
                        .align(Alignment.BottomCenter),
                ) {
                    repeat(player.holeCards.size) {
                        Card(
                            dealtCard = FaceDown,
                            cardLayout = CardLayout.SingleSidedSimple,
                            cardBackStyle = cardBackStyle,
                            cardWidth = cardSize / 2f,
                        )
                    }
                }
            }
        }
    }
}

@Composable
private fun PlayerAvatarVerticalLayout(
    player: Player,
    avatarSize: Dp,
) {
    Box(modifier = Modifier.fillMaxSize()) {
        if (player.avatarUrl != null) {
            KamelImage(
                modifier = Modifier
                    .size(avatarSize)
                    .clip(CircleShape)
                    .align(Alignment.Center),
                resource = { asyncPainterResource(player.avatarUrl) },
                contentDescription = "Avatar",
                contentScale = ContentScale.Crop,
                onLoading = { progress ->
                    CircularProgressIndicator(
                        progress = { progress },
                    )
                },
                onFailure = { _ ->
                    Image(
                        painter = painterResource(Res.drawable.avatar_profile),
                        contentDescription = "Avatar",
                        modifier = Modifier
                            .size(avatarSize)
                            .clip(CircleShape)
                            .align(Alignment.Center),
                    )
                },
            )
        } else {
            Image(
                painter = if (!player.bot) {
                    painterResource(Res.drawable.avatar_profile)
                } else {
                    painterResource(Res.drawable.avatar_robot)
                },
                contentDescription = "Avatar",
                modifier = Modifier
                    .size(avatarSize)
                    .clip(CircleShape)
                    .align(Alignment.Center),
            )
        }
    }
}
