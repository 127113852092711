package studio.lostjoker.smartdealer.ui.poker.enum

import org.jetbrains.compose.resources.StringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_game_settings_card_style_four_color_bg
import smartdealer.appshared.generated.resources.poker_game_settings_card_style_four_color_suit
import smartdealer.appshared.generated.resources.poker_game_settings_card_style_original

enum class CardStyle(val resource: StringResource) {
    Original(Res.string.poker_game_settings_card_style_original),
    FourColoredSuit(Res.string.poker_game_settings_card_style_four_color_suit),
    FourColoredBackground(Res.string.poker_game_settings_card_style_four_color_bg),
}
