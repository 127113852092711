package studio.lostjoker.smartdealer.ui.components.card_layout

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.BlendMode
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.unit.Dp
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun CardDoubleSidedSimple(
    modifier: Modifier,
    cardFaceValueImage: DrawableResource,
    cardBackgroundColor: Color,
    cardWidth: Dp,
    cardHeightMultiplier: Float,
    suitImage: DrawableResource,
    suitColor: Color,
    winningCard: Boolean,
    folded: Boolean,
) {
    BoxWithConstraints(
        modifier = modifier
            .width(cardWidth)
            .height(cardWidth * cardHeightMultiplier)
            .clip(RoundedCornerShape(size = cardWidth * 0.1f))
            .border(
                border = BorderStroke(cardWidth * 0.015f, Color.LightGray),
                shape = RoundedCornerShape(cardWidth * 0.1f),
            )
            .background(cardBackgroundColor),
    ) {
        val maxWidth = maxWidth
        val maxHeight = maxHeight
        repeat(2) { index ->
            Row(
                modifier = Modifier
                    .rotate(degrees = if (index == 0) 0f else 180f)
                    .width(maxWidth)
                    .height(maxHeight * 0.5f)
                    .align(alignment = if (index == 0) Alignment.TopStart else Alignment.BottomStart),
            ) {
                Image(
                    painter = painterResource(cardFaceValueImage),
                    contentDescription = "Card Face Value",
                    modifier = Modifier
                        .width(maxWidth * 0.5f)
                        .height(maxHeight * 0.5f),
                    colorFilter = ColorFilter.tint(color = suitColor, blendMode = BlendMode.SrcIn),
                )
                Image(
                    painter = painterResource(suitImage),
                    contentDescription = "Suit",
                    modifier = Modifier
                        .width(maxWidth * 0.5f)
                        .height(maxHeight * 0.5f),
                    colorFilter = ColorFilter.tint(color = suitColor, blendMode = BlendMode.SrcIn),
                )
            }
        }
        if (!winningCard || folded) {
            Column(
                modifier = Modifier
                    .width(maxWidth)
                    .height(maxHeight)
                    .background(Color.DarkGray.copy(alpha = 0.5f)),
            ) { /* DO NOTHING */ }
        }
    }
}
