package studio.lostjoker.smartdealer.ui.components

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp

@Composable
fun <T> SelectionPill(
    option: ToggleButtonOption<T>,
    selected: Boolean,
    onClick: (option: ToggleButtonOption<T>) -> Unit = {},
    modifier: Modifier,
) {
    Button(
        onClick = { onClick(option) },
        colors = ButtonDefaults.buttonColors(
            containerColor = if (selected) MaterialTheme.colorScheme.secondary else MaterialTheme.colorScheme.surfaceVariant,
            contentColor = if (selected) MaterialTheme.colorScheme.onSecondary else MaterialTheme.colorScheme.onSurfaceVariant,
        ),
        shape = RoundedCornerShape(0),
        elevation = ButtonDefaults.buttonElevation(0.dp, 0.dp),
        contentPadding = PaddingValues(0.dp),
        modifier = modifier.fillMaxHeight(),
    ) {
        Row(
            modifier = Modifier.padding(0.dp).fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Text(
                text = option.text,
                textAlign = TextAlign.Center,
                modifier = Modifier.padding(0.dp).fillMaxWidth(),
                style = MaterialTheme.typography.labelLarge,
            )
            if (option.imageVector != null) {
                Icon(
                    imageVector = option.imageVector,
                    contentDescription = null,
                    modifier = Modifier.padding(4.dp, 2.dp, 2.dp, 2.dp),
                )
            }
        }
    }
}

enum class ToggleSelection {
    None,
    Single,
    Multiple,
}

data class ToggleButtonOption<T>(
    val value: T,
    val text: String,
    val imageVector: ImageVector?,
)

@Composable
fun <T> ToggleButton(
    options: Array<ToggleButtonOption<T>>,
    modifier: Modifier = Modifier,
    type: ToggleSelection = ToggleSelection.Single,
    values: Array<ToggleButtonOption<T>>,
    shape: RoundedCornerShape = RoundedCornerShape(25),
    onClick: (selectedOptions: Array<ToggleButtonOption<T>>) -> Unit = {},
) {
    val state = remember { mutableStateMapOf(*values.map { it.text to it }.toTypedArray()) }

    OutlinedButton(
        onClick = { },
        border = BorderStroke(2.dp, MaterialTheme.colorScheme.secondary),
        shape = shape,
        contentPadding = PaddingValues(0.dp, 0.dp),
        modifier = modifier,
    ) {
        if (options.isEmpty()) {
            return@OutlinedButton
        }
        val onItemClick: (option: ToggleButtonOption<T>) -> Unit = { option ->
            if (type == ToggleSelection.Single) {
                options.forEach {
                    val key = it.text
                    if (key == option.text) {
                        state[key] = option
                    } else {
                        state.remove(key)
                    }
                }
            } else {
                val key = option.text
                if (!state.contains(key)) {
                    state[key] = option
                } else {
                    state.remove(key)
                }
            }
            onClick(state.values.toTypedArray())
        }
        if (options.size == 1) {
            val option = options.first()
            SelectionPill(
                option = option,
                selected = state.contains(option.text),
                onClick = onItemClick,
                modifier = Modifier.weight(1f),
            )
            return@OutlinedButton
        }
        val first = options.first()
        val last = options.last()
        val middle = options.slice(1..options.size - 2)

        SelectionPill(
            option = first,
            selected = state.contains(first.text),
            onClick = onItemClick,
            modifier = Modifier.weight(1f),
        )

        VerticalDivider(MaterialTheme.colorScheme.secondary)

        middle.map { option ->
            SelectionPill(
                option = option,
                selected = state.contains(option.text),
                onClick = onItemClick,
                modifier = Modifier.weight(1f),
            )
            VerticalDivider(MaterialTheme.colorScheme.secondary)
        }
        SelectionPill(
            option = last,
            selected = state.contains(last.text),
            onClick = onItemClick,
            modifier = Modifier.weight(1f),
        )
    }
}
