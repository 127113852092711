package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.PauseCircle
import androidx.compose.material.icons.filled.PlayCircle
import androidx.compose.material.icons.filled.Settings
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.playing_cards
import studio.lostjoker.smartdealer.ui.poker.enum.Device
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun FloatingActionButtons(
    device: Device = Device.Player,
    onClickPauseOrResume: () -> Unit = {},
    onClickHandSummary: () -> Unit = {},
    onClickSettings: () -> Unit = {},
    gamePaused: Boolean = false,
    host: Boolean = false,
) {
    Column(
        modifier = Modifier.padding(bottom = 10.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_1),
    ) {
        if (host) {
            Icon(
                modifier = Modifier
                    .size(PokerAppTheme.dimensions.grid_4)
                    .pointerInput(gamePaused) {
                        detectTapGestures(
                            onPress = {
                                onClickPauseOrResume()
                            },
                        )
                    },
                imageVector = if (gamePaused) {
                    Icons.Default.PlayCircle
                } else {
                    Icons.Default.PauseCircle
                },
                contentDescription = "Play/Pause",
                tint = Color.LightGray,
            )
        }
        if (device == Device.Player) {
            Icon(
                modifier = Modifier
                    .size(PokerAppTheme.dimensions.grid_4)
                    .pointerInput(Unit) {
                        detectTapGestures(
                            onPress = {
                                onClickHandSummary()
                            },
                        )
                    },
                painter = painterResource(Res.drawable.playing_cards),
                contentDescription = "Hand Summary",
                tint = Color.LightGray,
            )
        }
        Icon(
            modifier = Modifier
                .size(PokerAppTheme.dimensions.grid_4)
                .pointerInput(Unit) {
                    detectTapGestures(
                        onPress = {
                            onClickSettings()
                        },
                    )
                },
            imageVector = Icons.Default.Settings,
            contentDescription = "Settings",
            tint = Color.LightGray,
        )
    }
}
