package studio.lostjoker.smartdealer.ui.poker.enum

import org.jetbrains.compose.resources.StringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_game_settings_card_back_style
import smartdealer.appshared.generated.resources.poker_game_settings_card_style
import smartdealer.appshared.generated.resources.poker_game_settings_table_felt
import smartdealer.appshared.generated.resources.poker_game_settings_table_layout

enum class TableDeviceSettings(val title: StringResource) {
    TableLayout(Res.string.poker_game_settings_table_layout),
    TableFelt(Res.string.poker_game_settings_table_felt),
    CardStyle(Res.string.poker_game_settings_card_style),
    CardBackStyle(Res.string.poker_game_settings_card_back_style),
}
