package studio.lostjoker.smartdealer.ui.components

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier

@Composable
internal fun TwoSplitRow(
    rowModifier: Modifier = Modifier.fillMaxWidth().wrapContentHeight(),
    firstColumnWeight: Float = 1f,
    secondColumnWeight: Float = 1f,
    rowVerticalAlignment: Alignment.Vertical = Alignment.CenterVertically,
    firsColumnHorizontalAlignment: Alignment.Horizontal = Alignment.Start,
    secondColumnHorizontalAlignment: Alignment.Horizontal = Alignment.End,
    firstColumnContent: @Composable () -> Unit,
    secondColumnContent: @Composable () -> Unit,
) {
    Row(
        modifier = rowModifier,
        verticalAlignment = rowVerticalAlignment,
    ) {
        Column(
            modifier = Modifier.weight(firstColumnWeight),
            horizontalAlignment = firsColumnHorizontalAlignment,
        ) {
            firstColumnContent()
        }

        Column(
            modifier = Modifier.weight(secondColumnWeight),
            horizontalAlignment = secondColumnHorizontalAlignment,
        ) {
            secondColumnContent()
        }
    }
}
