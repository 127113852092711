package studio.lostjoker.smartdealer.ui.poker.host_game.components

import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Remove
import androidx.compose.material3.FilledIconButton
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButtonDefaults
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.ui.components.TwoSplitRow
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun TableSizeSelector(
    value: Int,
    minPlayers: Int,
    maxPlayers: Int,
    onChangeTableSize: (newValue: Int) -> Unit,
) {

    var size by remember { mutableStateOf(value) }

    TwoSplitRow(
        firstColumnContent = {
            Text(
                text = stringResource(Res.string.poker_settings_table_size),
                style = PokerAppTheme.typography.titleMedium,
            )
        },
        secondColumnContent = {
            Row(
                modifier = Modifier.wrapContentHeight(),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                FilledIconButton(
                    modifier = Modifier.size(PokerAppTheme.dimensions.grid_2),
                    colors = IconButtonDefaults.filledIconButtonColors(containerColor = PokerAppTheme.colors.primary),
                    onClick = {
                        if (size > minPlayers) {
                            size -= 1
                            onChangeTableSize(size)
                        }
                    },
                ) {
                    Icon(Icons.Filled.Remove, "Minus")
                }
                BasicTextField(
                    modifier = Modifier.width(100.dp),
                    value = "",
                    onValueChange = { },
                    decorationBox = {
                        Text(
                            text = if (size == 2) "Heads-up" else "$size-Max",
                            style = PokerAppTheme.typography.labelLarge,
                            textAlign = TextAlign.Center,
                        )
                    },
                    enabled = false,
                )
                FilledIconButton(
                    modifier = Modifier.size(PokerAppTheme.dimensions.grid_2),
                    colors = IconButtonDefaults.filledIconButtonColors(containerColor = PokerAppTheme.colors.primary),
                    onClick = {
                        if (size < maxPlayers) {
                            size += 1
                            onChangeTableSize(size)
                        }
                    },
                ) {
                    Icon(Icons.Filled.Add, "Plus")
                }
            }
        }
    )
}
