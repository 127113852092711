package studio.lostjoker.smartdealer.ui.poker.enum

import org.jetbrains.compose.resources.StringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_game_settings_card_back_style_blue
import smartdealer.appshared.generated.resources.poker_game_settings_card_back_style_green
import smartdealer.appshared.generated.resources.poker_game_settings_card_back_style_orange
import smartdealer.appshared.generated.resources.poker_game_settings_card_back_style_red

enum class CardBackStyle(val resource: StringResource) {
    Red(Res.string.poker_game_settings_card_back_style_red),
    Green(Res.string.poker_game_settings_card_back_style_green),
    Blue(Res.string.poker_game_settings_card_back_style_blue),
    Orange(Res.string.poker_game_settings_card_back_style_orange),
}
