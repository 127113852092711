package protocol

import kotlinx.serialization.Serializable

@Serializable
enum class Suit {
    Diamonds,
    Hearts,
    Spades,
    Clubs,
}

@Serializable
enum class Rank {
    Two,
    Three,
    Four,
    Five,
    Six,
    Seven,
    Eight,
    Nine,
    Ten,
    J,
    Q,
    K,
    A,
}

@Serializable
sealed interface Card {
    val color: Int

    @Serializable
    data class Joker(override val color: Int) : Card

    @Serializable
    data class Ranked(val rank: Rank, val suit: Suit, override val color: Int) : Card
}

@Serializable
sealed interface DealtRankedCardPlayerView {
    @Serializable
    data object FaceDown : DealtRankedCardPlayerView

    @Serializable
    data class FaceUp(
        val card: Card.Ranked,
    ) : DealtRankedCardPlayerView
}
