package service

import com.benasher44.uuid.uuid4
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.contentType
import studio.lostjoker.smartdealer.game_management.web.contract.GameCreationRequest
import studio.lostjoker.smartdealer.game_management.web.contract.GameCreationResponse
import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiError
import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiErrorCode
import studio.lostjoker.smartdealer.game_management.web.contract.GameType

class GameManagementApiService(
    apiBaseUrl: String,
) : BaseApiService() {
    private val apiBaseUrl = "$apiBaseUrl/api/game-management/v1"

    suspend fun createGame(gameType: GameType): GameCreationResponse {
        val uuid = uuid4()
        val response = client.put("$apiBaseUrl/games/${uuid}") {
            contentType(ContentType.Application.Json)
            setBody(GameCreationRequest(gameType))
        }

        if (response.status != HttpStatusCode.OK) {
            throw response.body<GameManagementApiError>().errorCode?.let { GameManagementApiServiceException(it) } ?: GameManagementApiServiceException(
                GameManagementApiErrorCode.Unknown,
            )
        }
        return response.body()
    }

    suspend fun findGameByCode(code: String): GameCreationResponse? {
        val response = client.get("$apiBaseUrl/games/join-code/${code}")

        return when (response.status) {
            HttpStatusCode.OK -> response.body()
            HttpStatusCode.NotFound -> throw GameManagementApiServiceException(GameManagementApiErrorCode.GameNotFound)
            else -> throw response.body<GameManagementApiError>().errorCode?.let {
                GameManagementApiServiceException(it)
            } ?: GameManagementApiServiceException(
                GameManagementApiErrorCode.Unknown,
            )
        }
    }
}
