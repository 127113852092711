package service

import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.HttpStatusCode
import io.ktor.http.contentType
import io.ktor.util.date.GMTDate
import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiError
import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiErrorCode
import studio.lostjoker.smartdealer.game_management.web.contract.PlayerAccountRequest
import studio.lostjoker.smartdealer.game_management.web.contract.PlayerAccountResponse

class PlayerAccountService(
    apiBaseUrl: String,
) : BaseApiService() {
    private val playerAccountApiBaseUrl = "$apiBaseUrl/api/account/v1"

    suspend fun getPlayerAccount(playerId: String): PlayerAccountResponse? {
        val response = client.get("${playerAccountApiBaseUrl}/profile/${playerId}")

        if (response.status != HttpStatusCode.OK) {
            if (response.status == HttpStatusCode.NotFound) return null
            throw response.body<GameManagementApiError>().errorCode?.let { GameManagementApiServiceException(it) } ?: GameManagementApiServiceException(
                GameManagementApiErrorCode.Unknown,
            )
        }
        return response.body()
    }

    fun getPlayerAvatarUrl(playerId: String): String {
        val date = GMTDate().timestamp
        return "${playerAccountApiBaseUrl}/profile/${playerId}/avatar?t=${date}"
    }

    suspend fun updatePlayerAccount(playerAccount: PlayerAccountRequest): PlayerAccountResponse {
        val response = client.put("${playerAccountApiBaseUrl}/profile/${playerAccount.playerId}") {
            contentType(ContentType.Application.Json)
            setBody(playerAccount)
        }

        if (response.status != HttpStatusCode.OK) {
            throw response.body<GameManagementApiError>().errorCode?.let { GameManagementApiServiceException(it) } ?: GameManagementApiServiceException(
                GameManagementApiErrorCode.Unknown,
            )
        }

        return response.body()
    }
}
