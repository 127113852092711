package service

import io.ktor.client.HttpClient
import io.ktor.client.plugins.HttpRequestRetry
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.serialization.kotlinx.json.json

abstract class BaseApiService {
    protected val client = HttpClient {
        install(ContentNegotiation) {
            json()
        }
        install(HttpRequestRetry) {
            retryOnException(maxRetries = 5, retryOnTimeout = true)
            retryOnServerErrors(maxRetries = 5)
            exponentialDelay()
        }
    }
}
