@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package smartdealer.appshared.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val api_error_code_not_provided: StringResource by 
      lazy { init_api_error_code_not_provided() }

  public val api_error_game_not_found: StringResource by 
      lazy { init_api_error_game_not_found() }

  public val api_error_id_not_provided: StringResource by 
      lazy { init_api_error_id_not_provided() }

  public val api_error_player_account_not_found: StringResource by 
      lazy { init_api_error_player_account_not_found() }

  public val api_error_unknown: StringResource by 
      lazy { init_api_error_unknown() }

  public val api_hint_game_not_found: StringResource by 
      lazy { init_api_hint_game_not_found() }

  public val app_update_successful: StringResource by 
      lazy { init_app_update_successful() }

  public val auth_cancel: StringResource by 
      lazy { init_auth_cancel() }

  public val auth_error_auth: StringResource by 
      lazy { init_auth_error_auth() }

  public val auth_error_link: StringResource by 
      lazy { init_auth_error_link() }

  public val auth_error_network: StringResource by 
      lazy { init_auth_error_network() }

  public val auth_error_not_logged: StringResource by 
      lazy { init_auth_error_not_logged() }

  public val auth_error_sign_in_required: StringResource by 
      lazy { init_auth_error_sign_in_required() }

  public val auth_error_unknown: StringResource by 
      lazy { init_auth_error_unknown() }

  public val banner_home: StringResource by 
      lazy { init_banner_home() }

  public val beta_tester_welcome_one_line: StringResource by 
      lazy { init_beta_tester_welcome_one_line() }

  public val beta_tester_welcome_two_lines: StringResource by 
      lazy { init_beta_tester_welcome_two_lines() }

  public val bottom_bar_home: StringResource by 
      lazy { init_bottom_bar_home() }

  public val bottom_bar_profile: StringResource by 
      lazy { init_bottom_bar_profile() }

  public val bottom_bar_settings: StringResource by 
      lazy { init_bottom_bar_settings() }

  public val bottom_bar_store: StringResource by 
      lazy { init_bottom_bar_store() }

  public val device_type_option_player: StringResource by 
      lazy { init_device_type_option_player() }

  public val device_type_option_player_help: StringResource by 
      lazy { init_device_type_option_player_help() }

  public val device_type_option_table: StringResource by 
      lazy { init_device_type_option_table() }

  public val device_type_option_table_help: StringResource by 
      lazy { init_device_type_option_table_help() }

  public val device_type_title: StringResource by 
      lazy { init_device_type_title() }

  public val file_upload_error: StringResource by 
      lazy { init_file_upload_error() }

  public val game_code_game_input_label: StringResource by 
      lazy { init_game_code_game_input_label() }

  public val game_code_host_game: StringResource by 
      lazy { init_game_code_host_game() }

  public val game_code_join_game: StringResource by 
      lazy { init_game_code_join_game() }

  public val game_code_share_message: StringResource by 
      lazy { init_game_code_share_message() }

  public val game_code_title: StringResource by 
      lazy { init_game_code_title() }

  public val game_mode_host_game: StringResource by 
      lazy { init_game_mode_host_game() }

  public val game_mode_join_game: StringResource by 
      lazy { init_game_mode_join_game() }

  public val game_variant_title: StringResource by 
      lazy { init_game_variant_title() }

  public val gameplay_tutorial_check_description: StringResource by 
      lazy { init_gameplay_tutorial_check_description() }

  public val gameplay_tutorial_check_title: StringResource by 
      lazy { init_gameplay_tutorial_check_title() }

  public val gameplay_tutorial_finish_button: StringResource by 
      lazy { init_gameplay_tutorial_finish_button() }

  public val gameplay_tutorial_fold_description: StringResource by 
      lazy { init_gameplay_tutorial_fold_description() }

  public val gameplay_tutorial_fold_title: StringResource by 
      lazy { init_gameplay_tutorial_fold_title() }

  public val gameplay_tutorial_help_description: StringResource by 
      lazy { init_gameplay_tutorial_help_description() }

  public val gameplay_tutorial_help_hand_rankings_image_url: StringResource by 
      lazy { init_gameplay_tutorial_help_hand_rankings_image_url() }

  public val gameplay_tutorial_help_poker_rules_image_url: StringResource by 
      lazy { init_gameplay_tutorial_help_poker_rules_image_url() }

  public val gameplay_tutorial_help_title: StringResource by 
      lazy { init_gameplay_tutorial_help_title() }

  public val gameplay_tutorial_next_button: StringResource by 
      lazy { init_gameplay_tutorial_next_button() }

  public val gameplay_tutorial_peek_description: StringResource by 
      lazy { init_gameplay_tutorial_peek_description() }

  public val gameplay_tutorial_peek_title: StringResource by 
      lazy { init_gameplay_tutorial_peek_title() }

  public val gameplay_tutorial_settings_card_back_style_image_url: StringResource by 
      lazy { init_gameplay_tutorial_settings_card_back_style_image_url() }

  public val gameplay_tutorial_settings_card_style_image_url: StringResource by 
      lazy { init_gameplay_tutorial_settings_card_style_image_url() }

  public val gameplay_tutorial_settings_description: StringResource by 
      lazy { init_gameplay_tutorial_settings_description() }

  public val gameplay_tutorial_settings_table_felt_image_url: StringResource by 
      lazy { init_gameplay_tutorial_settings_table_felt_image_url() }

  public val gameplay_tutorial_settings_table_layout_image_url: StringResource by 
      lazy { init_gameplay_tutorial_settings_table_layout_image_url() }

  public val gameplay_tutorial_settings_title: StringResource by 
      lazy { init_gameplay_tutorial_settings_title() }

  public val gameplay_tutorial_skip_button: StringResource by 
      lazy { init_gameplay_tutorial_skip_button() }

  public val google_login_id: StringResource by 
      lazy { init_google_login_id() }

  public val host_game_bo3_explanation: StringResource by 
      lazy { init_host_game_bo3_explanation() }

  public val host_game_create_game_btn: StringResource by 
      lazy { init_host_game_create_game_btn() }

  public val host_game_full_match: StringResource by 
      lazy { init_host_game_full_match() }

  public val host_game_single_match: StringResource by 
      lazy { init_host_game_single_match() }

  public val host_game_single_match_explanation: StringResource by 
      lazy { init_host_game_single_match_explanation() }

  public val host_game_text_divider: StringResource by 
      lazy { init_host_game_text_divider() }

  public val host_game_title: StringResource by 
      lazy { init_host_game_title() }

  public val join_game_btn: StringResource by 
      lazy { init_join_game_btn() }

  public val join_game_game_code: StringResource by 
      lazy { init_join_game_game_code() }

  public val join_game_no_code_link: StringResource by 
      lazy { init_join_game_no_code_link() }

  public val join_game_seat_selection: StringResource by 
      lazy { init_join_game_seat_selection() }

  public val join_game_title: StringResource by 
      lazy { init_join_game_title() }

  public val language_english: StringResource by 
      lazy { init_language_english() }

  public val language_portuguese: StringResource by 
      lazy { init_language_portuguese() }

  public val loading_screen_name: StringResource by 
      lazy { init_loading_screen_name() }

  public val permission_local_notification: StringResource by 
      lazy { init_permission_local_notification() }

  public val permission_media_access: StringResource by 
      lazy { init_permission_media_access() }

  public val player_device_connecting_message: StringResource by 
      lazy { init_player_device_connecting_message() }

  public val player_device_explanation: StringResource by 
      lazy { init_player_device_explanation() }

  public val player_device_play_card: StringResource by 
      lazy { init_player_device_play_card() }

  public val player_device_start_game: StringResource by 
      lazy { init_player_device_start_game() }

  public val player_device_your_turn: StringResource by 
      lazy { init_player_device_your_turn() }

  public val poker_action_timeout_none: StringResource by 
      lazy { init_poker_action_timeout_none() }

  public val poker_action_timeout_seconds: StringResource by 
      lazy { init_poker_action_timeout_seconds() }

  public val poker_blind_speed_hyper: StringResource by 
      lazy { init_poker_blind_speed_hyper() }

  public val poker_blind_speed_regular: StringResource by 
      lazy { init_poker_blind_speed_regular() }

  public val poker_blind_speed_slow: StringResource by 
      lazy { init_poker_blind_speed_slow() }

  public val poker_blind_speed_turbo: StringResource by 
      lazy { init_poker_blind_speed_turbo() }

  public val poker_blinds_table_ante: StringResource by 
      lazy { init_poker_blinds_table_ante() }

  public val poker_blinds_table_blinds: StringResource by 
      lazy { init_poker_blinds_table_blinds() }

  public val poker_blinds_table_level_up_hands: StringResource by 
      lazy { init_poker_blinds_table_level_up_hands() }

  public val poker_blinds_table_level_up_time: StringResource by 
      lazy { init_poker_blinds_table_level_up_time() }

  public val poker_blinds_table_lvl: StringResource by 
      lazy { init_poker_blinds_table_lvl() }

  public val poker_blinds_table_title: StringResource by 
      lazy { init_poker_blinds_table_title() }

  public val poker_devices_blind_level: StringResource by 
      lazy { init_poker_devices_blind_level() }

  public val poker_devices_blind_level_up: StringResource by 
      lazy { init_poker_devices_blind_level_up() }

  public val poker_devices_blind_level_up_hand: StringResource by 
      lazy { init_poker_devices_blind_level_up_hand() }

  public val poker_devices_blind_level_up_time: StringResource by 
      lazy { init_poker_devices_blind_level_up_time() }

  public val poker_devices_blind_level_up_time_not_available: StringResource by 
      lazy { init_poker_devices_blind_level_up_time_not_available() }

  public val poker_devices_blinds: StringResource by 
      lazy { init_poker_devices_blinds() }

  public val poker_devices_change_seats: StringResource by 
      lazy { init_poker_devices_change_seats() }

  public val poker_devices_clock: StringResource by 
      lazy { init_poker_devices_clock() }

  public val poker_devices_current_blind: StringResource by 
      lazy { init_poker_devices_current_blind() }

  public val poker_devices_deal_cards: StringResource by 
      lazy { init_poker_devices_deal_cards() }

  public val poker_devices_deal_cards_countdown: StringResource by 
      lazy { init_poker_devices_deal_cards_countdown() }

  public val poker_devices_game_code: StringResource by 
      lazy { init_poker_devices_game_code() }

  public val poker_devices_game_paused: StringResource by 
      lazy { init_poker_devices_game_paused() }

  public val poker_devices_help: StringResource by 
      lazy { init_poker_devices_help() }

  public val poker_devices_next_blind: StringResource by 
      lazy { init_poker_devices_next_blind() }

  public val poker_devices_overview: StringResource by 
      lazy { init_poker_devices_overview() }

  public val poker_devices_payments: StringResource by 
      lazy { init_poker_devices_payments() }

  public val poker_devices_payments_pays: StringResource by 
      lazy { init_poker_devices_payments_pays() }

  public val poker_devices_payments_transfers: StringResource by 
      lazy { init_poker_devices_payments_transfers() }

  public val poker_devices_payments_transfers_not_available: StringResource by 
      lazy { init_poker_devices_payments_transfers_not_available() }

  public val poker_devices_payments_winners: StringResource by 
      lazy { init_poker_devices_payments_winners() }

  public val poker_devices_payments_winners_not_available: StringResource by 
      lazy { init_poker_devices_payments_winners_not_available() }

  public val poker_devices_payments_wins: StringResource by 
      lazy { init_poker_devices_payments_wins() }

  public val poker_devices_pots: StringResource by 
      lazy { init_poker_devices_pots() }

  public val poker_devices_rebuy: StringResource by 
      lazy { init_poker_devices_rebuy() }

  public val poker_devices_replay: StringResource by 
      lazy { init_poker_devices_replay() }

  public val poker_devices_screen_landscape: StringResource by 
      lazy { init_poker_devices_screen_landscape() }

  public val poker_devices_screen_not_supported: StringResource by 
      lazy { init_poker_devices_screen_not_supported() }

  public val poker_devices_screen_portrait: StringResource by 
      lazy { init_poker_devices_screen_portrait() }

  public val poker_devices_screen_table_view_not_supported: StringResource by 
      lazy { init_poker_devices_screen_table_view_not_supported() }

  public val poker_devices_settings: StringResource by 
      lazy { init_poker_devices_settings() }

  public val poker_devices_spectator: StringResource by 
      lazy { init_poker_devices_spectator() }

  public val poker_devices_start_game: StringResource by 
      lazy { init_poker_devices_start_game() }

  public val poker_dialog_cancel: StringResource by 
      lazy { init_poker_dialog_cancel() }

  public val poker_dialog_confirm: StringResource by 
      lazy { init_poker_dialog_confirm() }

  public val poker_dialog_leave_game_text: StringResource by 
      lazy { init_poker_dialog_leave_game_text() }

  public val poker_dialog_leave_game_title: StringResource by 
      lazy { init_poker_dialog_leave_game_title() }

  public val poker_dialog_ok: StringResource by 
      lazy { init_poker_dialog_ok() }

  public val poker_dialog_rebuy_text: StringResource by 
      lazy { init_poker_dialog_rebuy_text() }

  public val poker_dialog_rebuy_title: StringResource by 
      lazy { init_poker_dialog_rebuy_title() }

  public val poker_dialog_start_game_text: StringResource by 
      lazy { init_poker_dialog_start_game_text() }

  public val poker_dialog_start_game_text_minimum_players: StringResource by 
      lazy { init_poker_dialog_start_game_text_minimum_players() }

  public val poker_dialog_start_game_title: StringResource by 
      lazy { init_poker_dialog_start_game_title() }

  public val poker_dialog_terminate_game_disabled: StringResource by 
      lazy { init_poker_dialog_terminate_game_disabled() }

  public val poker_dialog_terminate_game_text: StringResource by 
      lazy { init_poker_dialog_terminate_game_text() }

  public val poker_dialog_terminate_game_title: StringResource by 
      lazy { init_poker_dialog_terminate_game_title() }

  public val poker_dialog_update: StringResource by 
      lazy { init_poker_dialog_update() }

  public val poker_dialog_update_game_settings_text: StringResource by 
      lazy { init_poker_dialog_update_game_settings_text() }

  public val poker_dialog_update_game_settings_title: StringResource by 
      lazy { init_poker_dialog_update_game_settings_title() }

  public val poker_game_help_hand_rankings: StringResource by 
      lazy { init_poker_game_help_hand_rankings() }

  public val poker_game_help_rules_gameplay: StringResource by 
      lazy { init_poker_game_help_rules_gameplay() }

  public val poker_game_help_rules_gameplay_text_1: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_1() }

  public val poker_game_help_rules_gameplay_text_10: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_10() }

  public val poker_game_help_rules_gameplay_text_11: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_11() }

  public val poker_game_help_rules_gameplay_text_12: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_12() }

  public val poker_game_help_rules_gameplay_text_2: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_2() }

  public val poker_game_help_rules_gameplay_text_3: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_3() }

  public val poker_game_help_rules_gameplay_text_4: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_4() }

  public val poker_game_help_rules_gameplay_text_5: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_5() }

  public val poker_game_help_rules_gameplay_text_6: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_6() }

  public val poker_game_help_rules_gameplay_text_7: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_7() }

  public val poker_game_help_rules_gameplay_text_8: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_8() }

  public val poker_game_help_rules_gameplay_text_9: StringResource by 
      lazy { init_poker_game_help_rules_gameplay_text_9() }

  public val poker_game_log_action_bet: StringResource by 
      lazy { init_poker_game_log_action_bet() }

  public val poker_game_log_action_call: StringResource by 
      lazy { init_poker_game_log_action_call() }

  public val poker_game_log_action_check: StringResource by 
      lazy { init_poker_game_log_action_check() }

  public val poker_game_log_action_fold: StringResource by 
      lazy { init_poker_game_log_action_fold() }

  public val poker_game_log_action_muck: StringResource by 
      lazy { init_poker_game_log_action_muck() }

  public val poker_game_log_action_post_bb: StringResource by 
      lazy { init_poker_game_log_action_post_bb() }

  public val poker_game_log_action_post_sb: StringResource by 
      lazy { init_poker_game_log_action_post_sb() }

  public val poker_game_log_action_raise: StringResource by 
      lazy { init_poker_game_log_action_raise() }

  public val poker_game_log_action_show: StringResource by 
      lazy { init_poker_game_log_action_show() }

  public val poker_game_log_action_win: StringResource by 
      lazy { init_poker_game_log_action_win() }

  public val poker_game_navigation_drawer_gameplay_tutorial: StringResource by 
      lazy { init_poker_game_navigation_drawer_gameplay_tutorial() }

  public val poker_game_navigation_drawer_help: StringResource by 
      lazy { init_poker_game_navigation_drawer_help() }

  public val poker_game_navigation_drawer_leave: StringResource by 
      lazy { init_poker_game_navigation_drawer_leave() }

  public val poker_game_navigation_drawer_terminate: StringResource by 
      lazy { init_poker_game_navigation_drawer_terminate() }

  public val poker_game_navigation_drawer_top_up: StringResource by 
      lazy { init_poker_game_navigation_drawer_top_up() }

  public val poker_game_navigation_drawer_update_settings: StringResource by 
      lazy { init_poker_game_navigation_drawer_update_settings() }

  public val poker_game_replay_hand_summary: StringResource by 
      lazy { init_poker_game_replay_hand_summary() }

  public val poker_game_replay_hand_summary_blinds: StringResource by 
      lazy { init_poker_game_replay_hand_summary_blinds() }

  public val poker_game_replay_hand_summary_fold: StringResource by 
      lazy { init_poker_game_replay_hand_summary_fold() }

  public val poker_game_replay_hand_summary_hand_id: StringResource by 
      lazy { init_poker_game_replay_hand_summary_hand_id() }

  public val poker_game_replay_hand_summary_not_available: StringResource by 
      lazy { init_poker_game_replay_hand_summary_not_available() }

  public val poker_game_settings_card_back_style: StringResource by 
      lazy { init_poker_game_settings_card_back_style() }

  public val poker_game_settings_card_back_style_blue: StringResource by 
      lazy { init_poker_game_settings_card_back_style_blue() }

  public val poker_game_settings_card_back_style_green: StringResource by 
      lazy { init_poker_game_settings_card_back_style_green() }

  public val poker_game_settings_card_back_style_orange: StringResource by 
      lazy { init_poker_game_settings_card_back_style_orange() }

  public val poker_game_settings_card_back_style_red: StringResource by 
      lazy { init_poker_game_settings_card_back_style_red() }

  public val poker_game_settings_card_style: StringResource by 
      lazy { init_poker_game_settings_card_style() }

  public val poker_game_settings_card_style_four_color_bg: StringResource by 
      lazy { init_poker_game_settings_card_style_four_color_bg() }

  public val poker_game_settings_card_style_four_color_suit: StringResource by 
      lazy { init_poker_game_settings_card_style_four_color_suit() }

  public val poker_game_settings_card_style_original: StringResource by 
      lazy { init_poker_game_settings_card_style_original() }

  public val poker_game_settings_table_felt: StringResource by 
      lazy { init_poker_game_settings_table_felt() }

  public val poker_game_settings_table_felt_blue: StringResource by 
      lazy { init_poker_game_settings_table_felt_blue() }

  public val poker_game_settings_table_felt_green: StringResource by 
      lazy { init_poker_game_settings_table_felt_green() }

  public val poker_game_settings_table_felt_red: StringResource by 
      lazy { init_poker_game_settings_table_felt_red() }

  public val poker_game_settings_table_layout: StringResource by 
      lazy { init_poker_game_settings_table_layout() }

  public val poker_game_settings_table_layout_live: StringResource by 
      lazy { init_poker_game_settings_table_layout_live() }

  public val poker_game_settings_table_layout_none: StringResource by 
      lazy { init_poker_game_settings_table_layout_none() }

  public val poker_game_settings_table_layout_online: StringResource by 
      lazy { init_poker_game_settings_table_layout_online() }

  public val poker_game_settings_table_layout_vertical_display: StringResource by 
      lazy { init_poker_game_settings_table_layout_vertical_display() }

  public val poker_game_settings_table_layout_vertical_display_description: StringResource by 
      lazy { init_poker_game_settings_table_layout_vertical_display_description() }

  public val poker_hand_rankings: StringResource by 
      lazy { init_poker_hand_rankings() }

  public val poker_hand_rankings_eight_low: StringResource by 
      lazy { init_poker_hand_rankings_eight_low() }

  public val poker_hand_rankings_five_low: StringResource by 
      lazy { init_poker_hand_rankings_five_low() }

  public val poker_hand_rankings_flush: StringResource by 
      lazy { init_poker_hand_rankings_flush() }

  public val poker_hand_rankings_four_of_a_kind: StringResource by 
      lazy { init_poker_hand_rankings_four_of_a_kind() }

  public val poker_hand_rankings_full_house: StringResource by 
      lazy { init_poker_hand_rankings_full_house() }

  public val poker_hand_rankings_high_card: StringResource by 
      lazy { init_poker_hand_rankings_high_card() }

  public val poker_hand_rankings_high_hand: StringResource by 
      lazy { init_poker_hand_rankings_high_hand() }

  public val poker_hand_rankings_low_hand: StringResource by 
      lazy { init_poker_hand_rankings_low_hand() }

  public val poker_hand_rankings_pair: StringResource by 
      lazy { init_poker_hand_rankings_pair() }

  public val poker_hand_rankings_royal_flush: StringResource by 
      lazy { init_poker_hand_rankings_royal_flush() }

  public val poker_hand_rankings_seven_low: StringResource by 
      lazy { init_poker_hand_rankings_seven_low() }

  public val poker_hand_rankings_short_deck_flush: StringResource by 
      lazy { init_poker_hand_rankings_short_deck_flush() }

  public val poker_hand_rankings_short_deck_four_of_a_kind: StringResource by 
      lazy { init_poker_hand_rankings_short_deck_four_of_a_kind() }

  public val poker_hand_rankings_short_deck_full_house: StringResource by 
      lazy { init_poker_hand_rankings_short_deck_full_house() }

  public val poker_hand_rankings_short_deck_high_card: StringResource by 
      lazy { init_poker_hand_rankings_short_deck_high_card() }

  public val poker_hand_rankings_short_deck_pair: StringResource by 
      lazy { init_poker_hand_rankings_short_deck_pair() }

  public val poker_hand_rankings_short_deck_royal_flush: StringResource by 
      lazy { init_poker_hand_rankings_short_deck_royal_flush() }

  public val poker_hand_rankings_short_deck_straight: StringResource by 
      lazy { init_poker_hand_rankings_short_deck_straight() }

  public val poker_hand_rankings_short_deck_straight_flush: StringResource by 
      lazy { init_poker_hand_rankings_short_deck_straight_flush() }

  public val poker_hand_rankings_short_deck_three_of_a_kind: StringResource by 
      lazy { init_poker_hand_rankings_short_deck_three_of_a_kind() }

  public val poker_hand_rankings_short_deck_two_pair: StringResource by 
      lazy { init_poker_hand_rankings_short_deck_two_pair() }

  public val poker_hand_rankings_six_low: StringResource by 
      lazy { init_poker_hand_rankings_six_low() }

  public val poker_hand_rankings_straight: StringResource by 
      lazy { init_poker_hand_rankings_straight() }

  public val poker_hand_rankings_straight_flush: StringResource by 
      lazy { init_poker_hand_rankings_straight_flush() }

  public val poker_hand_rankings_three_of_a_kind: StringResource by 
      lazy { init_poker_hand_rankings_three_of_a_kind() }

  public val poker_hand_rankings_two_pair: StringResource by 
      lazy { init_poker_hand_rankings_two_pair() }

  public val poker_high_hand_rank: StringResource by 
      lazy { init_poker_high_hand_rank() }

  public val poker_high_hand_rank_flush: StringResource by 
      lazy { init_poker_high_hand_rank_flush() }

  public val poker_high_hand_rank_four_of_a_kind: StringResource by 
      lazy { init_poker_high_hand_rank_four_of_a_kind() }

  public val poker_high_hand_rank_full_house: StringResource by 
      lazy { init_poker_high_hand_rank_full_house() }

  public val poker_high_hand_rank_high_card: StringResource by 
      lazy { init_poker_high_hand_rank_high_card() }

  public val poker_high_hand_rank_pair: StringResource by 
      lazy { init_poker_high_hand_rank_pair() }

  public val poker_high_hand_rank_royal_flush: StringResource by 
      lazy { init_poker_high_hand_rank_royal_flush() }

  public val poker_high_hand_rank_straight: StringResource by 
      lazy { init_poker_high_hand_rank_straight() }

  public val poker_high_hand_rank_straight_flush: StringResource by 
      lazy { init_poker_high_hand_rank_straight_flush() }

  public val poker_high_hand_rank_three_of_a_kind: StringResource by 
      lazy { init_poker_high_hand_rank_three_of_a_kind() }

  public val poker_high_hand_rank_two_pair: StringResource by 
      lazy { init_poker_high_hand_rank_two_pair() }

  public val poker_high_hand_rank_unranked: StringResource by 
      lazy { init_poker_high_hand_rank_unranked() }

  public val poker_low_hand_rank: StringResource by 
      lazy { init_poker_low_hand_rank() }

  public val poker_low_hand_rank_eight_low: StringResource by 
      lazy { init_poker_low_hand_rank_eight_low() }

  public val poker_low_hand_rank_five_low: StringResource by 
      lazy { init_poker_low_hand_rank_five_low() }

  public val poker_low_hand_rank_seven_low: StringResource by 
      lazy { init_poker_low_hand_rank_seven_low() }

  public val poker_low_hand_rank_six_low: StringResource by 
      lazy { init_poker_low_hand_rank_six_low() }

  public val poker_low_hand_rank_unranked: StringResource by 
      lazy { init_poker_low_hand_rank_unranked() }

  public val poker_payout_structure_aggressive: StringResource by 
      lazy { init_poker_payout_structure_aggressive() }

  public val poker_payout_structure_conservative: StringResource by 
      lazy { init_poker_payout_structure_conservative() }

  public val poker_payout_structure_moderate: StringResource by 
      lazy { init_poker_payout_structure_moderate() }

  public val poker_payout_table_title: StringResource by 
      lazy { init_poker_payout_table_title() }

  public val poker_player_hand_action_bet: StringResource by 
      lazy { init_poker_player_hand_action_bet() }

  public val poker_player_hand_action_call: StringResource by 
      lazy { init_poker_player_hand_action_call() }

  public val poker_player_hand_action_check: StringResource by 
      lazy { init_poker_player_hand_action_check() }

  public val poker_player_hand_action_checked: StringResource by 
      lazy { init_poker_player_hand_action_checked() }

  public val poker_player_hand_action_fold: StringResource by 
      lazy { init_poker_player_hand_action_fold() }

  public val poker_player_hand_action_folded: StringResource by 
      lazy { init_poker_player_hand_action_folded() }

  public val poker_player_hand_action_muck: StringResource by 
      lazy { init_poker_player_hand_action_muck() }

  public val poker_player_hand_action_raise: StringResource by 
      lazy { init_poker_player_hand_action_raise() }

  public val poker_player_hand_action_show: StringResource by 
      lazy { init_poker_player_hand_action_show() }

  public val poker_player_hand_action_your_turn: StringResource by 
      lazy { init_poker_player_hand_action_your_turn() }

  public val poker_player_notification_collected: StringResource by 
      lazy { init_poker_player_notification_collected() }

  public val poker_player_notification_from: StringResource by 
      lazy { init_poker_player_notification_from() }

  public val poker_player_notification_winner: StringResource by 
      lazy { init_poker_player_notification_winner() }

  public val poker_player_notification_wins: StringResource by 
      lazy { init_poker_player_notification_wins() }

  public val poker_player_notification_with: StringResource by 
      lazy { init_poker_player_notification_with() }

  public val poker_ring_game_high: StringResource by 
      lazy { init_poker_ring_game_high() }

  public val poker_ring_game_low: StringResource by 
      lazy { init_poker_ring_game_low() }

  public val poker_ring_game_medium: StringResource by 
      lazy { init_poker_ring_game_medium() }

  public val poker_ring_game_micro: StringResource by 
      lazy { init_poker_ring_game_micro() }

  public val poker_ring_overview_session_summary: StringResource by 
      lazy { init_poker_ring_overview_session_summary() }

  public val poker_ring_overview_session_summary_buy_in: StringResource by 
      lazy { init_poker_ring_overview_session_summary_buy_in() }

  public val poker_ring_overview_session_summary_net_profit: StringResource by 
      lazy { init_poker_ring_overview_session_summary_net_profit() }

  public val poker_ring_overview_session_summary_ranking: StringResource by 
      lazy { init_poker_ring_overview_session_summary_ranking() }

  public val poker_ring_overview_session_summary_screen_name: StringResource by 
      lazy { init_poker_ring_overview_session_summary_screen_name() }

  public val poker_ring_overview_session_summary_stack: StringResource by 
      lazy { init_poker_ring_overview_session_summary_stack() }

  public val poker_settings_action_timeout: StringResource by 
      lazy { init_poker_settings_action_timeout() }

  public val poker_settings_auto_deal_hands: StringResource by 
      lazy { init_poker_settings_auto_deal_hands() }

  public val poker_settings_blind_level_up: StringResource by 
      lazy { init_poker_settings_blind_level_up() }

  public val poker_settings_blind_level_up_hand: StringResource by 
      lazy { init_poker_settings_blind_level_up_hand() }

  public val poker_settings_blind_level_up_time: StringResource by 
      lazy { init_poker_settings_blind_level_up_time() }

  public val poker_settings_blind_structure: StringResource by 
      lazy { init_poker_settings_blind_structure() }

  public val poker_settings_blinds: StringResource by 
      lazy { init_poker_settings_blinds() }

  public val poker_settings_bots: StringResource by 
      lazy { init_poker_settings_bots() }

  public val poker_settings_bots_number: StringResource by 
      lazy { init_poker_settings_bots_number() }

  public val poker_settings_buy_in: StringResource by 
      lazy { init_poker_settings_buy_in() }

  public val poker_settings_late_registration: StringResource by 
      lazy { init_poker_settings_late_registration() }

  public val poker_settings_late_registration_off: StringResource by 
      lazy { init_poker_settings_late_registration_off() }

  public val poker_settings_late_registration_period_ends: StringResource by 
      lazy { init_poker_settings_late_registration_period_ends() }

  public val poker_settings_payout_structure: StringResource by 
      lazy { init_poker_settings_payout_structure() }

  public val poker_settings_payout_structure_ranking: StringResource by 
      lazy { init_poker_settings_payout_structure_ranking() }

  public val poker_settings_rebuy: StringResource by 
      lazy { init_poker_settings_rebuy() }

  public val poker_settings_rebuy_cannot_be_activated: StringResource by 
      lazy { init_poker_settings_rebuy_cannot_be_activated() }

  public val poker_settings_rebuy_cannot_be_deactivated: StringResource by 
      lazy { init_poker_settings_rebuy_cannot_be_deactivated() }

  public val poker_settings_rebuy_duration: StringResource by 
      lazy { init_poker_settings_rebuy_duration() }

  public val poker_settings_rebuy_limit: StringResource by 
      lazy { init_poker_settings_rebuy_limit() }

  public val poker_settings_rebuy_limit_unlimited: StringResource by 
      lazy { init_poker_settings_rebuy_limit_unlimited() }

  public val poker_settings_rebuy_period_ends: StringResource by 
      lazy { init_poker_settings_rebuy_period_ends() }

  public val poker_settings_ring: StringResource by 
      lazy { init_poker_settings_ring() }

  public val poker_settings_ring_game: StringResource by 
      lazy { init_poker_settings_ring_game() }

  public val poker_settings_sit_and_go: StringResource by 
      lazy { init_poker_settings_sit_and_go() }

  public val poker_settings_sng: StringResource by 
      lazy { init_poker_settings_sng() }

  public val poker_settings_starting_stack: StringResource by 
      lazy { init_poker_settings_starting_stack() }

  public val poker_settings_table_size: StringResource by 
      lazy { init_poker_settings_table_size() }

  public val poker_settings_update: StringResource by 
      lazy { init_poker_settings_update() }

  public val poker_tournament_overview_blinds: StringResource by 
      lazy { init_poker_tournament_overview_blinds() }

  public val poker_tournament_overview_blinds_ante: StringResource by 
      lazy { init_poker_tournament_overview_blinds_ante() }

  public val poker_tournament_overview_blinds_blinds: StringResource by 
      lazy { init_poker_tournament_overview_blinds_blinds() }

  public val poker_tournament_overview_blinds_duration_hands: StringResource by 
      lazy { init_poker_tournament_overview_blinds_duration_hands() }

  public val poker_tournament_overview_blinds_duration_time: StringResource by 
      lazy { init_poker_tournament_overview_blinds_duration_time() }

  public val poker_tournament_overview_blinds_level: StringResource by 
      lazy { init_poker_tournament_overview_blinds_level() }

  public val poker_tournament_overview_prizes: StringResource by 
      lazy { init_poker_tournament_overview_prizes() }

  public val poker_tournament_overview_prizes_prize_pool_percentage: StringResource by 
      lazy { init_poker_tournament_overview_prizes_prize_pool_percentage() }

  public val poker_tournament_overview_prizes_prizes: StringResource by 
      lazy { init_poker_tournament_overview_prizes_prizes() }

  public val poker_tournament_overview_prizes_ranking: StringResource by 
      lazy { init_poker_tournament_overview_prizes_ranking() }

  public val poker_tournament_overview_ranking: StringResource by 
      lazy { init_poker_tournament_overview_ranking() }

  public val poker_tournament_overview_ranking_ranking: StringResource by 
      lazy { init_poker_tournament_overview_ranking_ranking() }

  public val poker_tournament_overview_ranking_rebuy: StringResource by 
      lazy { init_poker_tournament_overview_ranking_rebuy() }

  public val poker_tournament_overview_ranking_screen_name: StringResource by 
      lazy { init_poker_tournament_overview_ranking_screen_name() }

  public val poker_tournament_overview_ranking_stack: StringResource by 
      lazy { init_poker_tournament_overview_ranking_stack() }

  public val poker_tournament_overview_stats: StringResource by 
      lazy { init_poker_tournament_overview_stats() }

  public val poker_tournament_overview_stats_avg_stack: StringResource by 
      lazy { init_poker_tournament_overview_stats_avg_stack() }

  public val poker_tournament_overview_stats_largest_stack: StringResource by 
      lazy { init_poker_tournament_overview_stats_largest_stack() }

  public val poker_tournament_overview_stats_prize_pool: StringResource by 
      lazy { init_poker_tournament_overview_stats_prize_pool() }

  public val poker_tournament_overview_stats_rebuys: StringResource by 
      lazy { init_poker_tournament_overview_stats_rebuys() }

  public val poker_tournament_overview_stats_smallest_stack: StringResource by 
      lazy { init_poker_tournament_overview_stats_smallest_stack() }

  public val poker_tournament_overview_stats_total_buy_ins: StringResource by 
      lazy { init_poker_tournament_overview_stats_total_buy_ins() }

  public val poker_variants_omaha: StringResource by 
      lazy { init_poker_variants_omaha() }

  public val poker_variants_texas: StringResource by 
      lazy { init_poker_variants_texas() }

  public val poker_watch_live: StringResource by 
      lazy { init_poker_watch_live() }

  public val profile_contact_us: StringResource by 
      lazy { init_profile_contact_us() }

  public val profile_delete_account: StringResource by 
      lazy { init_profile_delete_account() }

  public val profile_delete_account_cancel: StringResource by 
      lazy { init_profile_delete_account_cancel() }

  public val profile_delete_account_confirmation: StringResource by 
      lazy { init_profile_delete_account_confirmation() }

  public val profile_delete_account_confirmation_content: StringResource by 
      lazy { init_profile_delete_account_confirmation_content() }

  public val profile_delete_account_confirmation_privacy_policy: StringResource by 
      lazy { init_profile_delete_account_confirmation_privacy_policy() }

  public val profile_delete_account_confirmation_title: StringResource by 
      lazy { init_profile_delete_account_confirmation_title() }

  public val profile_invite_friends: StringResource by 
      lazy { init_profile_invite_friends() }

  public val profile_sign_out: StringResource by 
      lazy { init_profile_sign_out() }

  public val profile_text_divider: StringResource by 
      lazy { init_profile_text_divider() }

  public val rename_dialog_screen_name: StringResource by 
      lazy { init_rename_dialog_screen_name() }

  public val rename_dialog_screen_name_hint: StringResource by 
      lazy { init_rename_dialog_screen_name_hint() }

  public val rename_dialog_screen_name_min_char: StringResource by 
      lazy { init_rename_dialog_screen_name_min_char() }

  public val rename_dialog_screen_name_welcome: StringResource by 
      lazy { init_rename_dialog_screen_name_welcome() }

  public val ring_summary_buy_in: StringResource by 
      lazy { init_ring_summary_buy_in() }

  public val ring_summary_cashed_out: StringResource by 
      lazy { init_ring_summary_cashed_out() }

  public val ring_summary_leave_game: StringResource by 
      lazy { init_ring_summary_leave_game() }

  public val ring_summary_net_profit: StringResource by 
      lazy { init_ring_summary_net_profit() }

  public val ring_summary_payments: StringResource by 
      lazy { init_ring_summary_payments() }

  public val ring_summary_player: StringResource by 
      lazy { init_ring_summary_player() }

  public val ring_summary_rank: StringResource by 
      lazy { init_ring_summary_rank() }

  public val ring_summary_ranking: StringResource by 
      lazy { init_ring_summary_ranking() }

  public val ring_summary_share_now: StringResource by 
      lazy { init_ring_summary_share_now() }

  public val scoreboard_cards: StringResource by 
      lazy { init_scoreboard_cards() }

  public val scoreboard_game_continues: StringResource by 
      lazy { init_scoreboard_game_continues() }

  public val scoreboard_hand: StringResource by 
      lazy { init_scoreboard_hand() }

  public val scoreboard_lost_count: StringResource by 
      lazy { init_scoreboard_lost_count() }

  public val scoreboard_lost_message: StringResource by 
      lazy { init_scoreboard_lost_message() }

  public val scoreboard_points: StringResource by 
      lazy { init_scoreboard_points() }

  public val scoreboard_them: StringResource by 
      lazy { init_scoreboard_them() }

  public val scoreboard_them_points: StringResource by 
      lazy { init_scoreboard_them_points() }

  public val scoreboard_total: StringResource by 
      lazy { init_scoreboard_total() }

  public val scoreboard_us: StringResource by 
      lazy { init_scoreboard_us() }

  public val scoreboard_us_points: StringResource by 
      lazy { init_scoreboard_us_points() }

  public val scoreboard_won_count: StringResource by 
      lazy { init_scoreboard_won_count() }

  public val scoreboard_won_message: StringResource by 
      lazy { init_scoreboard_won_message() }

  public val screen_title_host_game: StringResource by 
      lazy { init_screen_title_host_game() }

  public val screen_title_join_game: StringResource by 
      lazy { init_screen_title_join_game() }

  public val screen_title_language_preference: StringResource by 
      lazy { init_screen_title_language_preference() }

  public val screen_title_mode_selection: StringResource by 
      lazy { init_screen_title_mode_selection() }

  public val settings_choose_language: StringResource by 
      lazy { init_settings_choose_language() }

  public val settings_dark_mode: StringResource by 
      lazy { init_settings_dark_mode() }

  public val settings_general_title: StringResource by 
      lazy { init_settings_general_title() }

  public val settings_language: StringResource by 
      lazy { init_settings_language() }

  public val settings_permission_allowed: StringResource by 
      lazy { init_settings_permission_allowed() }

  public val settings_permission_not_allowed: StringResource by 
      lazy { init_settings_permission_not_allowed() }

  public val settings_permissions_title: StringResource by 
      lazy { init_settings_permissions_title() }

  public val sharesheet_invite_friends: StringResource by 
      lazy { init_sharesheet_invite_friends() }

  public val sharesheet_share_game_code: StringResource by 
      lazy { init_sharesheet_share_game_code() }

  public val sign_in_btn_login_apple: StringResource by 
      lazy { init_sign_in_btn_login_apple() }

  public val sign_in_btn_login_fb: StringResource by 
      lazy { init_sign_in_btn_login_fb() }

  public val sign_in_btn_login_google: StringResource by 
      lazy { init_sign_in_btn_login_google() }

  public val sign_in_btn_login_guest: StringResource by 
      lazy { init_sign_in_btn_login_guest() }

  public val sign_in_login_or: StringResource by 
      lazy { init_sign_in_login_or() }

  public val table_device_explanation: StringResource by 
      lazy { init_table_device_explanation() }

  public val table_device_start_game: StringResource by 
      lazy { init_table_device_start_game() }

  public val table_seating_dialog_bot_confirm_button: StringResource by 
      lazy { init_table_seating_dialog_bot_confirm_button() }

  public val table_seating_dialog_bot_dismiss_button: StringResource by 
      lazy { init_table_seating_dialog_bot_dismiss_button() }

  public val table_seating_dialog_bot_headline: StringResource by 
      lazy { init_table_seating_dialog_bot_headline() }

  public val table_seating_dialog_bot_supporting_text: StringResource by 
      lazy { init_table_seating_dialog_bot_supporting_text() }

  public val table_seating_dialog_player_confirm_button: StringResource by 
      lazy { init_table_seating_dialog_player_confirm_button() }

  public val table_seating_dialog_player_headline: StringResource by 
      lazy { init_table_seating_dialog_player_headline() }

  public val table_seating_dialog_player_supporting_text: StringResource by 
      lazy { init_table_seating_dialog_player_supporting_text() }

  public val table_seating_open_seat: StringResource by 
      lazy { init_table_seating_open_seat() }

  public val table_seating_seat: StringResource by 
      lazy { init_table_seating_seat() }

  public val table_seating_waiting_players_to_join: StringResource by 
      lazy { init_table_seating_waiting_players_to_join() }

  public val table_seating_waiting_players_to_take_seat: StringResource by 
      lazy { init_table_seating_waiting_players_to_take_seat() }

  public val table_seating_waiting_start: StringResource by 
      lazy { init_table_seating_waiting_start() }

  public val top_up_dialog_chips: StringResource by 
      lazy { init_top_up_dialog_chips() }

  public val top_up_dialog_disabled: StringResource by 
      lazy { init_top_up_dialog_disabled() }

  public val top_up_dialog_text: StringResource by 
      lazy { init_top_up_dialog_text() }

  public val top_up_dialog_title: StringResource by 
      lazy { init_top_up_dialog_title() }

  public val tournament_summary_leave_game: StringResource by 
      lazy { init_tournament_summary_leave_game() }

  public val tournament_summary_payments: StringResource by 
      lazy { init_tournament_summary_payments() }

  public val tournament_summary_player: StringResource by 
      lazy { init_tournament_summary_player() }

  public val tournament_summary_prize: StringResource by 
      lazy { init_tournament_summary_prize() }

  public val tournament_summary_rank: StringResource by 
      lazy { init_tournament_summary_rank() }

  public val tournament_summary_ranking: StringResource by 
      lazy { init_tournament_summary_ranking() }

  public val tournament_summary_rebuy: StringResource by 
      lazy { init_tournament_summary_rebuy() }

  public val tournament_summary_share_now: StringResource by 
      lazy { init_tournament_summary_share_now() }

  public val web_watch_live: StringResource by 
      lazy { init_web_watch_live() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("api_error_code_not_provided", CommonMainString0.api_error_code_not_provided)
  map.put("api_error_game_not_found", CommonMainString0.api_error_game_not_found)
  map.put("api_error_id_not_provided", CommonMainString0.api_error_id_not_provided)
  map.put("api_error_player_account_not_found",
      CommonMainString0.api_error_player_account_not_found)
  map.put("api_error_unknown", CommonMainString0.api_error_unknown)
  map.put("api_hint_game_not_found", CommonMainString0.api_hint_game_not_found)
  map.put("app_update_successful", CommonMainString0.app_update_successful)
  map.put("auth_cancel", CommonMainString0.auth_cancel)
  map.put("auth_error_auth", CommonMainString0.auth_error_auth)
  map.put("auth_error_link", CommonMainString0.auth_error_link)
  map.put("auth_error_network", CommonMainString0.auth_error_network)
  map.put("auth_error_not_logged", CommonMainString0.auth_error_not_logged)
  map.put("auth_error_sign_in_required", CommonMainString0.auth_error_sign_in_required)
  map.put("auth_error_unknown", CommonMainString0.auth_error_unknown)
  map.put("banner_home", CommonMainString0.banner_home)
  map.put("beta_tester_welcome_one_line", CommonMainString0.beta_tester_welcome_one_line)
  map.put("beta_tester_welcome_two_lines", CommonMainString0.beta_tester_welcome_two_lines)
  map.put("bottom_bar_home", CommonMainString0.bottom_bar_home)
  map.put("bottom_bar_profile", CommonMainString0.bottom_bar_profile)
  map.put("bottom_bar_settings", CommonMainString0.bottom_bar_settings)
  map.put("bottom_bar_store", CommonMainString0.bottom_bar_store)
  map.put("device_type_option_player", CommonMainString0.device_type_option_player)
  map.put("device_type_option_player_help", CommonMainString0.device_type_option_player_help)
  map.put("device_type_option_table", CommonMainString0.device_type_option_table)
  map.put("device_type_option_table_help", CommonMainString0.device_type_option_table_help)
  map.put("device_type_title", CommonMainString0.device_type_title)
  map.put("file_upload_error", CommonMainString0.file_upload_error)
  map.put("game_code_game_input_label", CommonMainString0.game_code_game_input_label)
  map.put("game_code_host_game", CommonMainString0.game_code_host_game)
  map.put("game_code_join_game", CommonMainString0.game_code_join_game)
  map.put("game_code_share_message", CommonMainString0.game_code_share_message)
  map.put("game_code_title", CommonMainString0.game_code_title)
  map.put("game_mode_host_game", CommonMainString0.game_mode_host_game)
  map.put("game_mode_join_game", CommonMainString0.game_mode_join_game)
  map.put("game_variant_title", CommonMainString0.game_variant_title)
  map.put("gameplay_tutorial_check_description",
      CommonMainString0.gameplay_tutorial_check_description)
  map.put("gameplay_tutorial_check_title", CommonMainString0.gameplay_tutorial_check_title)
  map.put("gameplay_tutorial_finish_button", CommonMainString0.gameplay_tutorial_finish_button)
  map.put("gameplay_tutorial_fold_description",
      CommonMainString0.gameplay_tutorial_fold_description)
  map.put("gameplay_tutorial_fold_title", CommonMainString0.gameplay_tutorial_fold_title)
  map.put("gameplay_tutorial_help_description",
      CommonMainString0.gameplay_tutorial_help_description)
  map.put("gameplay_tutorial_help_hand_rankings_image_url",
      CommonMainString0.gameplay_tutorial_help_hand_rankings_image_url)
  map.put("gameplay_tutorial_help_poker_rules_image_url",
      CommonMainString0.gameplay_tutorial_help_poker_rules_image_url)
  map.put("gameplay_tutorial_help_title", CommonMainString0.gameplay_tutorial_help_title)
  map.put("gameplay_tutorial_next_button", CommonMainString0.gameplay_tutorial_next_button)
  map.put("gameplay_tutorial_peek_description",
      CommonMainString0.gameplay_tutorial_peek_description)
  map.put("gameplay_tutorial_peek_title", CommonMainString0.gameplay_tutorial_peek_title)
  map.put("gameplay_tutorial_settings_card_back_style_image_url",
      CommonMainString0.gameplay_tutorial_settings_card_back_style_image_url)
  map.put("gameplay_tutorial_settings_card_style_image_url",
      CommonMainString0.gameplay_tutorial_settings_card_style_image_url)
  map.put("gameplay_tutorial_settings_description",
      CommonMainString0.gameplay_tutorial_settings_description)
  map.put("gameplay_tutorial_settings_table_felt_image_url",
      CommonMainString0.gameplay_tutorial_settings_table_felt_image_url)
  map.put("gameplay_tutorial_settings_table_layout_image_url",
      CommonMainString0.gameplay_tutorial_settings_table_layout_image_url)
  map.put("gameplay_tutorial_settings_title", CommonMainString0.gameplay_tutorial_settings_title)
  map.put("gameplay_tutorial_skip_button", CommonMainString0.gameplay_tutorial_skip_button)
  map.put("google_login_id", CommonMainString0.google_login_id)
  map.put("host_game_bo3_explanation", CommonMainString0.host_game_bo3_explanation)
  map.put("host_game_create_game_btn", CommonMainString0.host_game_create_game_btn)
  map.put("host_game_full_match", CommonMainString0.host_game_full_match)
  map.put("host_game_single_match", CommonMainString0.host_game_single_match)
  map.put("host_game_single_match_explanation",
      CommonMainString0.host_game_single_match_explanation)
  map.put("host_game_text_divider", CommonMainString0.host_game_text_divider)
  map.put("host_game_title", CommonMainString0.host_game_title)
  map.put("join_game_btn", CommonMainString0.join_game_btn)
  map.put("join_game_game_code", CommonMainString0.join_game_game_code)
  map.put("join_game_no_code_link", CommonMainString0.join_game_no_code_link)
  map.put("join_game_seat_selection", CommonMainString0.join_game_seat_selection)
  map.put("join_game_title", CommonMainString0.join_game_title)
  map.put("language_english", CommonMainString0.language_english)
  map.put("language_portuguese", CommonMainString0.language_portuguese)
  map.put("loading_screen_name", CommonMainString0.loading_screen_name)
  map.put("permission_local_notification", CommonMainString0.permission_local_notification)
  map.put("permission_media_access", CommonMainString0.permission_media_access)
  map.put("player_device_connecting_message", CommonMainString0.player_device_connecting_message)
  map.put("player_device_explanation", CommonMainString0.player_device_explanation)
  map.put("player_device_play_card", CommonMainString0.player_device_play_card)
  map.put("player_device_start_game", CommonMainString0.player_device_start_game)
  map.put("player_device_your_turn", CommonMainString0.player_device_your_turn)
  map.put("poker_action_timeout_none", CommonMainString0.poker_action_timeout_none)
  map.put("poker_action_timeout_seconds", CommonMainString0.poker_action_timeout_seconds)
  map.put("poker_blind_speed_hyper", CommonMainString0.poker_blind_speed_hyper)
  map.put("poker_blind_speed_regular", CommonMainString0.poker_blind_speed_regular)
  map.put("poker_blind_speed_slow", CommonMainString0.poker_blind_speed_slow)
  map.put("poker_blind_speed_turbo", CommonMainString0.poker_blind_speed_turbo)
  map.put("poker_blinds_table_ante", CommonMainString0.poker_blinds_table_ante)
  map.put("poker_blinds_table_blinds", CommonMainString0.poker_blinds_table_blinds)
  map.put("poker_blinds_table_level_up_hands", CommonMainString0.poker_blinds_table_level_up_hands)
  map.put("poker_blinds_table_level_up_time", CommonMainString0.poker_blinds_table_level_up_time)
  map.put("poker_blinds_table_lvl", CommonMainString0.poker_blinds_table_lvl)
  map.put("poker_blinds_table_title", CommonMainString0.poker_blinds_table_title)
  map.put("poker_devices_blind_level", CommonMainString0.poker_devices_blind_level)
  map.put("poker_devices_blind_level_up", CommonMainString0.poker_devices_blind_level_up)
  map.put("poker_devices_blind_level_up_hand", CommonMainString0.poker_devices_blind_level_up_hand)
  map.put("poker_devices_blind_level_up_time", CommonMainString0.poker_devices_blind_level_up_time)
  map.put("poker_devices_blind_level_up_time_not_available",
      CommonMainString0.poker_devices_blind_level_up_time_not_available)
  map.put("poker_devices_blinds", CommonMainString0.poker_devices_blinds)
  map.put("poker_devices_change_seats", CommonMainString0.poker_devices_change_seats)
  map.put("poker_devices_clock", CommonMainString0.poker_devices_clock)
  map.put("poker_devices_current_blind", CommonMainString0.poker_devices_current_blind)
  map.put("poker_devices_deal_cards", CommonMainString0.poker_devices_deal_cards)
  map.put("poker_devices_deal_cards_countdown",
      CommonMainString0.poker_devices_deal_cards_countdown)
  map.put("poker_devices_game_code", CommonMainString0.poker_devices_game_code)
  map.put("poker_devices_game_paused", CommonMainString0.poker_devices_game_paused)
  map.put("poker_devices_help", CommonMainString0.poker_devices_help)
  map.put("poker_devices_next_blind", CommonMainString0.poker_devices_next_blind)
  map.put("poker_devices_overview", CommonMainString0.poker_devices_overview)
  map.put("poker_devices_payments", CommonMainString0.poker_devices_payments)
  map.put("poker_devices_payments_pays", CommonMainString0.poker_devices_payments_pays)
  map.put("poker_devices_payments_transfers", CommonMainString0.poker_devices_payments_transfers)
  map.put("poker_devices_payments_transfers_not_available",
      CommonMainString0.poker_devices_payments_transfers_not_available)
  map.put("poker_devices_payments_winners", CommonMainString0.poker_devices_payments_winners)
  map.put("poker_devices_payments_winners_not_available",
      CommonMainString0.poker_devices_payments_winners_not_available)
  map.put("poker_devices_payments_wins", CommonMainString0.poker_devices_payments_wins)
  map.put("poker_devices_pots", CommonMainString0.poker_devices_pots)
  map.put("poker_devices_rebuy", CommonMainString0.poker_devices_rebuy)
  map.put("poker_devices_replay", CommonMainString0.poker_devices_replay)
  map.put("poker_devices_screen_landscape", CommonMainString0.poker_devices_screen_landscape)
  map.put("poker_devices_screen_not_supported",
      CommonMainString0.poker_devices_screen_not_supported)
  map.put("poker_devices_screen_portrait", CommonMainString0.poker_devices_screen_portrait)
  map.put("poker_devices_screen_table_view_not_supported",
      CommonMainString0.poker_devices_screen_table_view_not_supported)
  map.put("poker_devices_settings", CommonMainString0.poker_devices_settings)
  map.put("poker_devices_spectator", CommonMainString0.poker_devices_spectator)
  map.put("poker_devices_start_game", CommonMainString0.poker_devices_start_game)
  map.put("poker_dialog_cancel", CommonMainString0.poker_dialog_cancel)
  map.put("poker_dialog_confirm", CommonMainString0.poker_dialog_confirm)
  map.put("poker_dialog_leave_game_text", CommonMainString0.poker_dialog_leave_game_text)
  map.put("poker_dialog_leave_game_title", CommonMainString0.poker_dialog_leave_game_title)
  map.put("poker_dialog_ok", CommonMainString0.poker_dialog_ok)
  map.put("poker_dialog_rebuy_text", CommonMainString0.poker_dialog_rebuy_text)
  map.put("poker_dialog_rebuy_title", CommonMainString0.poker_dialog_rebuy_title)
  map.put("poker_dialog_start_game_text", CommonMainString0.poker_dialog_start_game_text)
  map.put("poker_dialog_start_game_text_minimum_players",
      CommonMainString0.poker_dialog_start_game_text_minimum_players)
  map.put("poker_dialog_start_game_title", CommonMainString0.poker_dialog_start_game_title)
  map.put("poker_dialog_terminate_game_disabled",
      CommonMainString0.poker_dialog_terminate_game_disabled)
  map.put("poker_dialog_terminate_game_text", CommonMainString0.poker_dialog_terminate_game_text)
  map.put("poker_dialog_terminate_game_title", CommonMainString0.poker_dialog_terminate_game_title)
  map.put("poker_dialog_update", CommonMainString0.poker_dialog_update)
  map.put("poker_dialog_update_game_settings_text",
      CommonMainString0.poker_dialog_update_game_settings_text)
  map.put("poker_dialog_update_game_settings_title",
      CommonMainString0.poker_dialog_update_game_settings_title)
  map.put("poker_game_help_hand_rankings", CommonMainString0.poker_game_help_hand_rankings)
  map.put("poker_game_help_rules_gameplay", CommonMainString0.poker_game_help_rules_gameplay)
  map.put("poker_game_help_rules_gameplay_text_1",
      CommonMainString0.poker_game_help_rules_gameplay_text_1)
  map.put("poker_game_help_rules_gameplay_text_10",
      CommonMainString0.poker_game_help_rules_gameplay_text_10)
  map.put("poker_game_help_rules_gameplay_text_11",
      CommonMainString0.poker_game_help_rules_gameplay_text_11)
  map.put("poker_game_help_rules_gameplay_text_12",
      CommonMainString0.poker_game_help_rules_gameplay_text_12)
  map.put("poker_game_help_rules_gameplay_text_2",
      CommonMainString0.poker_game_help_rules_gameplay_text_2)
  map.put("poker_game_help_rules_gameplay_text_3",
      CommonMainString0.poker_game_help_rules_gameplay_text_3)
  map.put("poker_game_help_rules_gameplay_text_4",
      CommonMainString0.poker_game_help_rules_gameplay_text_4)
  map.put("poker_game_help_rules_gameplay_text_5",
      CommonMainString0.poker_game_help_rules_gameplay_text_5)
  map.put("poker_game_help_rules_gameplay_text_6",
      CommonMainString0.poker_game_help_rules_gameplay_text_6)
  map.put("poker_game_help_rules_gameplay_text_7",
      CommonMainString0.poker_game_help_rules_gameplay_text_7)
  map.put("poker_game_help_rules_gameplay_text_8",
      CommonMainString0.poker_game_help_rules_gameplay_text_8)
  map.put("poker_game_help_rules_gameplay_text_9",
      CommonMainString0.poker_game_help_rules_gameplay_text_9)
  map.put("poker_game_log_action_bet", CommonMainString0.poker_game_log_action_bet)
  map.put("poker_game_log_action_call", CommonMainString0.poker_game_log_action_call)
  map.put("poker_game_log_action_check", CommonMainString0.poker_game_log_action_check)
  map.put("poker_game_log_action_fold", CommonMainString0.poker_game_log_action_fold)
  map.put("poker_game_log_action_muck", CommonMainString0.poker_game_log_action_muck)
  map.put("poker_game_log_action_post_bb", CommonMainString0.poker_game_log_action_post_bb)
  map.put("poker_game_log_action_post_sb", CommonMainString0.poker_game_log_action_post_sb)
  map.put("poker_game_log_action_raise", CommonMainString0.poker_game_log_action_raise)
  map.put("poker_game_log_action_show", CommonMainString0.poker_game_log_action_show)
  map.put("poker_game_log_action_win", CommonMainString0.poker_game_log_action_win)
  map.put("poker_game_navigation_drawer_gameplay_tutorial",
      CommonMainString0.poker_game_navigation_drawer_gameplay_tutorial)
  map.put("poker_game_navigation_drawer_help", CommonMainString0.poker_game_navigation_drawer_help)
  map.put("poker_game_navigation_drawer_leave",
      CommonMainString0.poker_game_navigation_drawer_leave)
  map.put("poker_game_navigation_drawer_terminate",
      CommonMainString0.poker_game_navigation_drawer_terminate)
  map.put("poker_game_navigation_drawer_top_up",
      CommonMainString0.poker_game_navigation_drawer_top_up)
  map.put("poker_game_navigation_drawer_update_settings",
      CommonMainString0.poker_game_navigation_drawer_update_settings)
  map.put("poker_game_replay_hand_summary", CommonMainString0.poker_game_replay_hand_summary)
  map.put("poker_game_replay_hand_summary_blinds",
      CommonMainString0.poker_game_replay_hand_summary_blinds)
  map.put("poker_game_replay_hand_summary_fold",
      CommonMainString0.poker_game_replay_hand_summary_fold)
  map.put("poker_game_replay_hand_summary_hand_id",
      CommonMainString0.poker_game_replay_hand_summary_hand_id)
  map.put("poker_game_replay_hand_summary_not_available",
      CommonMainString0.poker_game_replay_hand_summary_not_available)
  map.put("poker_game_settings_card_back_style",
      CommonMainString0.poker_game_settings_card_back_style)
  map.put("poker_game_settings_card_back_style_blue",
      CommonMainString0.poker_game_settings_card_back_style_blue)
  map.put("poker_game_settings_card_back_style_green",
      CommonMainString0.poker_game_settings_card_back_style_green)
  map.put("poker_game_settings_card_back_style_orange",
      CommonMainString0.poker_game_settings_card_back_style_orange)
  map.put("poker_game_settings_card_back_style_red",
      CommonMainString0.poker_game_settings_card_back_style_red)
  map.put("poker_game_settings_card_style", CommonMainString0.poker_game_settings_card_style)
  map.put("poker_game_settings_card_style_four_color_bg",
      CommonMainString0.poker_game_settings_card_style_four_color_bg)
  map.put("poker_game_settings_card_style_four_color_suit",
      CommonMainString0.poker_game_settings_card_style_four_color_suit)
  map.put("poker_game_settings_card_style_original",
      CommonMainString0.poker_game_settings_card_style_original)
  map.put("poker_game_settings_table_felt", CommonMainString0.poker_game_settings_table_felt)
  map.put("poker_game_settings_table_felt_blue",
      CommonMainString0.poker_game_settings_table_felt_blue)
  map.put("poker_game_settings_table_felt_green",
      CommonMainString0.poker_game_settings_table_felt_green)
  map.put("poker_game_settings_table_felt_red",
      CommonMainString0.poker_game_settings_table_felt_red)
  map.put("poker_game_settings_table_layout", CommonMainString0.poker_game_settings_table_layout)
  map.put("poker_game_settings_table_layout_live",
      CommonMainString0.poker_game_settings_table_layout_live)
  map.put("poker_game_settings_table_layout_none",
      CommonMainString0.poker_game_settings_table_layout_none)
  map.put("poker_game_settings_table_layout_online",
      CommonMainString0.poker_game_settings_table_layout_online)
  map.put("poker_game_settings_table_layout_vertical_display",
      CommonMainString0.poker_game_settings_table_layout_vertical_display)
  map.put("poker_game_settings_table_layout_vertical_display_description",
      CommonMainString0.poker_game_settings_table_layout_vertical_display_description)
  map.put("poker_hand_rankings", CommonMainString0.poker_hand_rankings)
  map.put("poker_hand_rankings_eight_low", CommonMainString0.poker_hand_rankings_eight_low)
  map.put("poker_hand_rankings_five_low", CommonMainString0.poker_hand_rankings_five_low)
  map.put("poker_hand_rankings_flush", CommonMainString0.poker_hand_rankings_flush)
  map.put("poker_hand_rankings_four_of_a_kind",
      CommonMainString0.poker_hand_rankings_four_of_a_kind)
  map.put("poker_hand_rankings_full_house", CommonMainString0.poker_hand_rankings_full_house)
  map.put("poker_hand_rankings_high_card", CommonMainString0.poker_hand_rankings_high_card)
  map.put("poker_hand_rankings_high_hand", CommonMainString0.poker_hand_rankings_high_hand)
  map.put("poker_hand_rankings_low_hand", CommonMainString0.poker_hand_rankings_low_hand)
  map.put("poker_hand_rankings_pair", CommonMainString0.poker_hand_rankings_pair)
  map.put("poker_hand_rankings_royal_flush", CommonMainString0.poker_hand_rankings_royal_flush)
  map.put("poker_hand_rankings_seven_low", CommonMainString0.poker_hand_rankings_seven_low)
  map.put("poker_hand_rankings_short_deck_flush",
      CommonMainString0.poker_hand_rankings_short_deck_flush)
  map.put("poker_hand_rankings_short_deck_four_of_a_kind",
      CommonMainString0.poker_hand_rankings_short_deck_four_of_a_kind)
  map.put("poker_hand_rankings_short_deck_full_house",
      CommonMainString0.poker_hand_rankings_short_deck_full_house)
  map.put("poker_hand_rankings_short_deck_high_card",
      CommonMainString0.poker_hand_rankings_short_deck_high_card)
  map.put("poker_hand_rankings_short_deck_pair",
      CommonMainString0.poker_hand_rankings_short_deck_pair)
  map.put("poker_hand_rankings_short_deck_royal_flush",
      CommonMainString0.poker_hand_rankings_short_deck_royal_flush)
  map.put("poker_hand_rankings_short_deck_straight",
      CommonMainString0.poker_hand_rankings_short_deck_straight)
  map.put("poker_hand_rankings_short_deck_straight_flush",
      CommonMainString0.poker_hand_rankings_short_deck_straight_flush)
  map.put("poker_hand_rankings_short_deck_three_of_a_kind",
      CommonMainString0.poker_hand_rankings_short_deck_three_of_a_kind)
  map.put("poker_hand_rankings_short_deck_two_pair",
      CommonMainString0.poker_hand_rankings_short_deck_two_pair)
  map.put("poker_hand_rankings_six_low", CommonMainString0.poker_hand_rankings_six_low)
  map.put("poker_hand_rankings_straight", CommonMainString0.poker_hand_rankings_straight)
  map.put("poker_hand_rankings_straight_flush",
      CommonMainString0.poker_hand_rankings_straight_flush)
  map.put("poker_hand_rankings_three_of_a_kind",
      CommonMainString0.poker_hand_rankings_three_of_a_kind)
  map.put("poker_hand_rankings_two_pair", CommonMainString0.poker_hand_rankings_two_pair)
  map.put("poker_high_hand_rank", CommonMainString0.poker_high_hand_rank)
  map.put("poker_high_hand_rank_flush", CommonMainString0.poker_high_hand_rank_flush)
  map.put("poker_high_hand_rank_four_of_a_kind",
      CommonMainString0.poker_high_hand_rank_four_of_a_kind)
  map.put("poker_high_hand_rank_full_house", CommonMainString0.poker_high_hand_rank_full_house)
  map.put("poker_high_hand_rank_high_card", CommonMainString0.poker_high_hand_rank_high_card)
  map.put("poker_high_hand_rank_pair", CommonMainString0.poker_high_hand_rank_pair)
  map.put("poker_high_hand_rank_royal_flush", CommonMainString0.poker_high_hand_rank_royal_flush)
  map.put("poker_high_hand_rank_straight", CommonMainString0.poker_high_hand_rank_straight)
  map.put("poker_high_hand_rank_straight_flush",
      CommonMainString0.poker_high_hand_rank_straight_flush)
  map.put("poker_high_hand_rank_three_of_a_kind",
      CommonMainString0.poker_high_hand_rank_three_of_a_kind)
  map.put("poker_high_hand_rank_two_pair", CommonMainString0.poker_high_hand_rank_two_pair)
  map.put("poker_high_hand_rank_unranked", CommonMainString0.poker_high_hand_rank_unranked)
  map.put("poker_low_hand_rank", CommonMainString0.poker_low_hand_rank)
  map.put("poker_low_hand_rank_eight_low", CommonMainString0.poker_low_hand_rank_eight_low)
  map.put("poker_low_hand_rank_five_low", CommonMainString0.poker_low_hand_rank_five_low)
  map.put("poker_low_hand_rank_seven_low", CommonMainString0.poker_low_hand_rank_seven_low)
  map.put("poker_low_hand_rank_six_low", CommonMainString0.poker_low_hand_rank_six_low)
  map.put("poker_low_hand_rank_unranked", CommonMainString0.poker_low_hand_rank_unranked)
  map.put("poker_payout_structure_aggressive", CommonMainString0.poker_payout_structure_aggressive)
  map.put("poker_payout_structure_conservative",
      CommonMainString0.poker_payout_structure_conservative)
  map.put("poker_payout_structure_moderate", CommonMainString0.poker_payout_structure_moderate)
  map.put("poker_payout_table_title", CommonMainString0.poker_payout_table_title)
  map.put("poker_player_hand_action_bet", CommonMainString0.poker_player_hand_action_bet)
  map.put("poker_player_hand_action_call", CommonMainString0.poker_player_hand_action_call)
  map.put("poker_player_hand_action_check", CommonMainString0.poker_player_hand_action_check)
  map.put("poker_player_hand_action_checked", CommonMainString0.poker_player_hand_action_checked)
  map.put("poker_player_hand_action_fold", CommonMainString0.poker_player_hand_action_fold)
  map.put("poker_player_hand_action_folded", CommonMainString0.poker_player_hand_action_folded)
  map.put("poker_player_hand_action_muck", CommonMainString0.poker_player_hand_action_muck)
  map.put("poker_player_hand_action_raise", CommonMainString0.poker_player_hand_action_raise)
  map.put("poker_player_hand_action_show", CommonMainString0.poker_player_hand_action_show)
  map.put("poker_player_hand_action_your_turn",
      CommonMainString0.poker_player_hand_action_your_turn)
  map.put("poker_player_notification_collected",
      CommonMainString0.poker_player_notification_collected)
  map.put("poker_player_notification_from", CommonMainString0.poker_player_notification_from)
  map.put("poker_player_notification_winner", CommonMainString0.poker_player_notification_winner)
  map.put("poker_player_notification_wins", CommonMainString0.poker_player_notification_wins)
  map.put("poker_player_notification_with", CommonMainString0.poker_player_notification_with)
  map.put("poker_ring_game_high", CommonMainString0.poker_ring_game_high)
  map.put("poker_ring_game_low", CommonMainString0.poker_ring_game_low)
  map.put("poker_ring_game_medium", CommonMainString0.poker_ring_game_medium)
  map.put("poker_ring_game_micro", CommonMainString0.poker_ring_game_micro)
  map.put("poker_ring_overview_session_summary",
      CommonMainString0.poker_ring_overview_session_summary)
  map.put("poker_ring_overview_session_summary_buy_in",
      CommonMainString0.poker_ring_overview_session_summary_buy_in)
  map.put("poker_ring_overview_session_summary_net_profit",
      CommonMainString0.poker_ring_overview_session_summary_net_profit)
  map.put("poker_ring_overview_session_summary_ranking",
      CommonMainString0.poker_ring_overview_session_summary_ranking)
  map.put("poker_ring_overview_session_summary_screen_name",
      CommonMainString0.poker_ring_overview_session_summary_screen_name)
  map.put("poker_ring_overview_session_summary_stack",
      CommonMainString0.poker_ring_overview_session_summary_stack)
  map.put("poker_settings_action_timeout", CommonMainString0.poker_settings_action_timeout)
  map.put("poker_settings_auto_deal_hands", CommonMainString0.poker_settings_auto_deal_hands)
  map.put("poker_settings_blind_level_up", CommonMainString0.poker_settings_blind_level_up)
  map.put("poker_settings_blind_level_up_hand",
      CommonMainString0.poker_settings_blind_level_up_hand)
  map.put("poker_settings_blind_level_up_time",
      CommonMainString0.poker_settings_blind_level_up_time)
  map.put("poker_settings_blind_structure", CommonMainString0.poker_settings_blind_structure)
  map.put("poker_settings_blinds", CommonMainString0.poker_settings_blinds)
  map.put("poker_settings_bots", CommonMainString0.poker_settings_bots)
  map.put("poker_settings_bots_number", CommonMainString0.poker_settings_bots_number)
  map.put("poker_settings_buy_in", CommonMainString0.poker_settings_buy_in)
  map.put("poker_settings_late_registration", CommonMainString0.poker_settings_late_registration)
  map.put("poker_settings_late_registration_off",
      CommonMainString0.poker_settings_late_registration_off)
  map.put("poker_settings_late_registration_period_ends",
      CommonMainString0.poker_settings_late_registration_period_ends)
  map.put("poker_settings_payout_structure", CommonMainString0.poker_settings_payout_structure)
  map.put("poker_settings_payout_structure_ranking",
      CommonMainString0.poker_settings_payout_structure_ranking)
  map.put("poker_settings_rebuy", CommonMainString0.poker_settings_rebuy)
  map.put("poker_settings_rebuy_cannot_be_activated",
      CommonMainString0.poker_settings_rebuy_cannot_be_activated)
  map.put("poker_settings_rebuy_cannot_be_deactivated",
      CommonMainString0.poker_settings_rebuy_cannot_be_deactivated)
  map.put("poker_settings_rebuy_duration", CommonMainString0.poker_settings_rebuy_duration)
  map.put("poker_settings_rebuy_limit", CommonMainString0.poker_settings_rebuy_limit)
  map.put("poker_settings_rebuy_limit_unlimited",
      CommonMainString0.poker_settings_rebuy_limit_unlimited)
  map.put("poker_settings_rebuy_period_ends", CommonMainString0.poker_settings_rebuy_period_ends)
  map.put("poker_settings_ring", CommonMainString0.poker_settings_ring)
  map.put("poker_settings_ring_game", CommonMainString0.poker_settings_ring_game)
  map.put("poker_settings_sit_and_go", CommonMainString0.poker_settings_sit_and_go)
  map.put("poker_settings_sng", CommonMainString0.poker_settings_sng)
  map.put("poker_settings_starting_stack", CommonMainString0.poker_settings_starting_stack)
  map.put("poker_settings_table_size", CommonMainString0.poker_settings_table_size)
  map.put("poker_settings_update", CommonMainString0.poker_settings_update)
  map.put("poker_tournament_overview_blinds", CommonMainString0.poker_tournament_overview_blinds)
  map.put("poker_tournament_overview_blinds_ante",
      CommonMainString0.poker_tournament_overview_blinds_ante)
  map.put("poker_tournament_overview_blinds_blinds",
      CommonMainString0.poker_tournament_overview_blinds_blinds)
  map.put("poker_tournament_overview_blinds_duration_hands",
      CommonMainString0.poker_tournament_overview_blinds_duration_hands)
  map.put("poker_tournament_overview_blinds_duration_time",
      CommonMainString0.poker_tournament_overview_blinds_duration_time)
  map.put("poker_tournament_overview_blinds_level",
      CommonMainString0.poker_tournament_overview_blinds_level)
  map.put("poker_tournament_overview_prizes", CommonMainString0.poker_tournament_overview_prizes)
  map.put("poker_tournament_overview_prizes_prize_pool_percentage",
      CommonMainString0.poker_tournament_overview_prizes_prize_pool_percentage)
  map.put("poker_tournament_overview_prizes_prizes",
      CommonMainString0.poker_tournament_overview_prizes_prizes)
  map.put("poker_tournament_overview_prizes_ranking",
      CommonMainString0.poker_tournament_overview_prizes_ranking)
  map.put("poker_tournament_overview_ranking", CommonMainString0.poker_tournament_overview_ranking)
  map.put("poker_tournament_overview_ranking_ranking",
      CommonMainString0.poker_tournament_overview_ranking_ranking)
  map.put("poker_tournament_overview_ranking_rebuy",
      CommonMainString0.poker_tournament_overview_ranking_rebuy)
  map.put("poker_tournament_overview_ranking_screen_name",
      CommonMainString0.poker_tournament_overview_ranking_screen_name)
  map.put("poker_tournament_overview_ranking_stack",
      CommonMainString0.poker_tournament_overview_ranking_stack)
  map.put("poker_tournament_overview_stats", CommonMainString0.poker_tournament_overview_stats)
  map.put("poker_tournament_overview_stats_avg_stack",
      CommonMainString0.poker_tournament_overview_stats_avg_stack)
  map.put("poker_tournament_overview_stats_largest_stack",
      CommonMainString0.poker_tournament_overview_stats_largest_stack)
  map.put("poker_tournament_overview_stats_prize_pool",
      CommonMainString0.poker_tournament_overview_stats_prize_pool)
  map.put("poker_tournament_overview_stats_rebuys",
      CommonMainString0.poker_tournament_overview_stats_rebuys)
  map.put("poker_tournament_overview_stats_smallest_stack",
      CommonMainString0.poker_tournament_overview_stats_smallest_stack)
  map.put("poker_tournament_overview_stats_total_buy_ins",
      CommonMainString0.poker_tournament_overview_stats_total_buy_ins)
  map.put("poker_variants_omaha", CommonMainString0.poker_variants_omaha)
  map.put("poker_variants_texas", CommonMainString0.poker_variants_texas)
  map.put("poker_watch_live", CommonMainString0.poker_watch_live)
  map.put("profile_contact_us", CommonMainString0.profile_contact_us)
  map.put("profile_delete_account", CommonMainString0.profile_delete_account)
  map.put("profile_delete_account_cancel", CommonMainString0.profile_delete_account_cancel)
  map.put("profile_delete_account_confirmation",
      CommonMainString0.profile_delete_account_confirmation)
  map.put("profile_delete_account_confirmation_content",
      CommonMainString0.profile_delete_account_confirmation_content)
  map.put("profile_delete_account_confirmation_privacy_policy",
      CommonMainString0.profile_delete_account_confirmation_privacy_policy)
  map.put("profile_delete_account_confirmation_title",
      CommonMainString0.profile_delete_account_confirmation_title)
  map.put("profile_invite_friends", CommonMainString0.profile_invite_friends)
  map.put("profile_sign_out", CommonMainString0.profile_sign_out)
  map.put("profile_text_divider", CommonMainString0.profile_text_divider)
  map.put("rename_dialog_screen_name", CommonMainString0.rename_dialog_screen_name)
  map.put("rename_dialog_screen_name_hint", CommonMainString0.rename_dialog_screen_name_hint)
  map.put("rename_dialog_screen_name_min_char",
      CommonMainString0.rename_dialog_screen_name_min_char)
  map.put("rename_dialog_screen_name_welcome", CommonMainString0.rename_dialog_screen_name_welcome)
  map.put("ring_summary_buy_in", CommonMainString0.ring_summary_buy_in)
  map.put("ring_summary_cashed_out", CommonMainString0.ring_summary_cashed_out)
  map.put("ring_summary_leave_game", CommonMainString0.ring_summary_leave_game)
  map.put("ring_summary_net_profit", CommonMainString0.ring_summary_net_profit)
  map.put("ring_summary_payments", CommonMainString0.ring_summary_payments)
  map.put("ring_summary_player", CommonMainString0.ring_summary_player)
  map.put("ring_summary_rank", CommonMainString0.ring_summary_rank)
  map.put("ring_summary_ranking", CommonMainString0.ring_summary_ranking)
  map.put("ring_summary_share_now", CommonMainString0.ring_summary_share_now)
  map.put("scoreboard_cards", CommonMainString0.scoreboard_cards)
  map.put("scoreboard_game_continues", CommonMainString0.scoreboard_game_continues)
  map.put("scoreboard_hand", CommonMainString0.scoreboard_hand)
  map.put("scoreboard_lost_count", CommonMainString0.scoreboard_lost_count)
  map.put("scoreboard_lost_message", CommonMainString0.scoreboard_lost_message)
  map.put("scoreboard_points", CommonMainString0.scoreboard_points)
  map.put("scoreboard_them", CommonMainString0.scoreboard_them)
  map.put("scoreboard_them_points", CommonMainString0.scoreboard_them_points)
  map.put("scoreboard_total", CommonMainString0.scoreboard_total)
  map.put("scoreboard_us", CommonMainString0.scoreboard_us)
  map.put("scoreboard_us_points", CommonMainString0.scoreboard_us_points)
  map.put("scoreboard_won_count", CommonMainString0.scoreboard_won_count)
  map.put("scoreboard_won_message", CommonMainString0.scoreboard_won_message)
  map.put("screen_title_host_game", CommonMainString0.screen_title_host_game)
  map.put("screen_title_join_game", CommonMainString0.screen_title_join_game)
  map.put("screen_title_language_preference", CommonMainString0.screen_title_language_preference)
  map.put("screen_title_mode_selection", CommonMainString0.screen_title_mode_selection)
  map.put("settings_choose_language", CommonMainString0.settings_choose_language)
  map.put("settings_dark_mode", CommonMainString0.settings_dark_mode)
  map.put("settings_general_title", CommonMainString0.settings_general_title)
  map.put("settings_language", CommonMainString0.settings_language)
  map.put("settings_permission_allowed", CommonMainString0.settings_permission_allowed)
  map.put("settings_permission_not_allowed", CommonMainString0.settings_permission_not_allowed)
  map.put("settings_permissions_title", CommonMainString0.settings_permissions_title)
  map.put("sharesheet_invite_friends", CommonMainString0.sharesheet_invite_friends)
  map.put("sharesheet_share_game_code", CommonMainString0.sharesheet_share_game_code)
  map.put("sign_in_btn_login_apple", CommonMainString0.sign_in_btn_login_apple)
  map.put("sign_in_btn_login_fb", CommonMainString0.sign_in_btn_login_fb)
  map.put("sign_in_btn_login_google", CommonMainString0.sign_in_btn_login_google)
  map.put("sign_in_btn_login_guest", CommonMainString0.sign_in_btn_login_guest)
  map.put("sign_in_login_or", CommonMainString0.sign_in_login_or)
  map.put("table_device_explanation", CommonMainString0.table_device_explanation)
  map.put("table_device_start_game", CommonMainString0.table_device_start_game)
  map.put("table_seating_dialog_bot_confirm_button",
      CommonMainString0.table_seating_dialog_bot_confirm_button)
  map.put("table_seating_dialog_bot_dismiss_button",
      CommonMainString0.table_seating_dialog_bot_dismiss_button)
  map.put("table_seating_dialog_bot_headline", CommonMainString0.table_seating_dialog_bot_headline)
  map.put("table_seating_dialog_bot_supporting_text",
      CommonMainString0.table_seating_dialog_bot_supporting_text)
  map.put("table_seating_dialog_player_confirm_button",
      CommonMainString0.table_seating_dialog_player_confirm_button)
  map.put("table_seating_dialog_player_headline",
      CommonMainString0.table_seating_dialog_player_headline)
  map.put("table_seating_dialog_player_supporting_text",
      CommonMainString0.table_seating_dialog_player_supporting_text)
  map.put("table_seating_open_seat", CommonMainString0.table_seating_open_seat)
  map.put("table_seating_seat", CommonMainString0.table_seating_seat)
  map.put("table_seating_waiting_players_to_join",
      CommonMainString0.table_seating_waiting_players_to_join)
  map.put("table_seating_waiting_players_to_take_seat",
      CommonMainString0.table_seating_waiting_players_to_take_seat)
  map.put("table_seating_waiting_start", CommonMainString0.table_seating_waiting_start)
  map.put("top_up_dialog_chips", CommonMainString0.top_up_dialog_chips)
  map.put("top_up_dialog_disabled", CommonMainString0.top_up_dialog_disabled)
  map.put("top_up_dialog_text", CommonMainString0.top_up_dialog_text)
  map.put("top_up_dialog_title", CommonMainString0.top_up_dialog_title)
  map.put("tournament_summary_leave_game", CommonMainString0.tournament_summary_leave_game)
  map.put("tournament_summary_payments", CommonMainString0.tournament_summary_payments)
  map.put("tournament_summary_player", CommonMainString0.tournament_summary_player)
  map.put("tournament_summary_prize", CommonMainString0.tournament_summary_prize)
  map.put("tournament_summary_rank", CommonMainString0.tournament_summary_rank)
  map.put("tournament_summary_ranking", CommonMainString0.tournament_summary_ranking)
  map.put("tournament_summary_rebuy", CommonMainString0.tournament_summary_rebuy)
  map.put("tournament_summary_share_now", CommonMainString0.tournament_summary_share_now)
  map.put("web_watch_live", CommonMainString0.web_watch_live)
}

internal val Res.string.api_error_code_not_provided: StringResource
  get() = CommonMainString0.api_error_code_not_provided

private fun init_api_error_code_not_provided(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:api_error_code_not_provided", "api_error_code_not_provided",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    375, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 363,
    51),
    )
)

internal val Res.string.api_error_game_not_found: StringResource
  get() = CommonMainString0.api_error_game_not_found

private fun init_api_error_game_not_found(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:api_error_game_not_found", "api_error_game_not_found",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    447, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 415,
    72),
    )
)

internal val Res.string.api_error_id_not_provided: StringResource
  get() = CommonMainString0.api_error_id_not_provided

private fun init_api_error_id_not_provided(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:api_error_id_not_provided", "api_error_id_not_provided",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    540, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 488,
    53),
    )
)

internal val Res.string.api_error_player_account_not_found: StringResource
  get() = CommonMainString0.api_error_player_account_not_found

private fun init_api_error_player_account_not_found(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:api_error_player_account_not_found", "api_error_player_account_not_found",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    602, 98),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 542,
    70),
    )
)

internal val Res.string.api_error_unknown: StringResource
  get() = CommonMainString0.api_error_unknown

private fun init_api_error_unknown(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:api_error_unknown", "api_error_unknown",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    701, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 613,
    49),
    )
)

internal val Res.string.api_hint_game_not_found: StringResource
  get() = CommonMainString0.api_hint_game_not_found

private fun init_api_hint_game_not_found(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:api_hint_game_not_found", "api_hint_game_not_found",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    747, 87),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 663,
    83),
    )
)

internal val Res.string.app_update_successful: StringResource
  get() = CommonMainString0.app_update_successful

private fun init_app_update_successful(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:app_update_successful", "app_update_successful",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    835, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 747,
    97),
    )
)

internal val Res.string.auth_cancel: StringResource
  get() = CommonMainString0.auth_cancel

private fun init_auth_cancel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:auth_cancel", "auth_cancel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    937, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 845,
    67),
    )
)

internal val Res.string.auth_error_auth: StringResource
  get() = CommonMainString0.auth_error_auth

private fun init_auth_error_auth(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:auth_error_auth", "auth_error_auth",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1005, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 913,
    63),
    )
)

internal val Res.string.auth_error_link: StringResource
  get() = CommonMainString0.auth_error_link

private fun init_auth_error_link(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:auth_error_link", "auth_error_link",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1073, 119),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr", 977,
    111),
    )
)

internal val Res.string.auth_error_network: StringResource
  get() = CommonMainString0.auth_error_network

private fun init_auth_error_network(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:auth_error_network", "auth_error_network",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1193, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1089, 58),
    )
)

internal val Res.string.auth_error_not_logged: StringResource
  get() = CommonMainString0.auth_error_not_logged

private fun init_auth_error_not_logged(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:auth_error_not_logged", "auth_error_not_logged",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1252, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1148, 49),
    )
)

internal val Res.string.auth_error_sign_in_required: StringResource
  get() = CommonMainString0.auth_error_sign_in_required

private fun init_auth_error_sign_in_required(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:auth_error_sign_in_required", "auth_error_sign_in_required",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1298, 115),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1198, 115),
    )
)

internal val Res.string.auth_error_unknown: StringResource
  get() = CommonMainString0.auth_error_unknown

private fun init_auth_error_unknown(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:auth_error_unknown", "auth_error_unknown",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1414, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1314, 54),
    )
)

internal val Res.string.banner_home: StringResource
  get() = CommonMainString0.banner_home

private fun init_banner_home(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:banner_home", "banner_home",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1477, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1369, 71),
    )
)

internal val Res.string.beta_tester_welcome_one_line: StringResource
  get() = CommonMainString0.beta_tester_welcome_one_line

private fun init_beta_tester_welcome_one_line(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:beta_tester_welcome_one_line", "beta_tester_welcome_one_line",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1549, 180),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1441, 168),
    )
)

internal val Res.string.beta_tester_welcome_two_lines: StringResource
  get() = CommonMainString0.beta_tester_welcome_two_lines

private fun init_beta_tester_welcome_two_lines(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:beta_tester_welcome_two_lines", "beta_tester_welcome_two_lines",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1730, 185),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1610, 169),
    )
)

internal val Res.string.bottom_bar_home: StringResource
  get() = CommonMainString0.bottom_bar_home

private fun init_bottom_bar_home(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:bottom_bar_home", "bottom_bar_home",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1916, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1780, 31),
    )
)

internal val Res.string.bottom_bar_profile: StringResource
  get() = CommonMainString0.bottom_bar_profile

private fun init_bottom_bar_profile(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:bottom_bar_profile", "bottom_bar_profile",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1952, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1812, 38),
    )
)

internal val Res.string.bottom_bar_settings: StringResource
  get() = CommonMainString0.bottom_bar_settings

private fun init_bottom_bar_settings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:bottom_bar_settings", "bottom_bar_settings",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    1987, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1851, 39),
    )
)

internal val Res.string.bottom_bar_store: StringResource
  get() = CommonMainString0.bottom_bar_store

private fun init_bottom_bar_store(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:bottom_bar_store", "bottom_bar_store",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    2035, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1891, 32),
    )
)

internal val Res.string.device_type_option_player: StringResource
  get() = CommonMainString0.device_type_option_player

private fun init_device_type_option_player(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:device_type_option_player", "device_type_option_player",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    2411, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    2243, 41),
    )
)

internal val Res.string.device_type_option_player_help: StringResource
  get() = CommonMainString0.device_type_option_player_help

private fun init_device_type_option_player_help(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:device_type_option_player_help", "device_type_option_player_help",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    2068, 342),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    1924, 318),
    )
)

internal val Res.string.device_type_option_table: StringResource
  get() = CommonMainString0.device_type_option_table

private fun init_device_type_option_table(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:device_type_option_table", "device_type_option_table",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    2859, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    2639, 40),
    )
)

internal val Res.string.device_type_option_table_help: StringResource
  get() = CommonMainString0.device_type_option_table_help

private fun init_device_type_option_table_help(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:device_type_option_table_help", "device_type_option_table_help",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    2457, 401),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    2285, 353),
    )
)

internal val Res.string.device_type_title: StringResource
  get() = CommonMainString0.device_type_title

private fun init_device_type_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:device_type_title", "device_type_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    2900, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    2680, 33),
    )
)

internal val Res.string.file_upload_error: StringResource
  get() = CommonMainString0.file_upload_error

private fun init_file_upload_error(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:file_upload_error", "file_upload_error",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    2942, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    2714, 85),
    )
)

internal val Res.string.game_code_game_input_label: StringResource
  get() = CommonMainString0.game_code_game_input_label

private fun init_game_code_game_input_label(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_code_game_input_label", "game_code_game_input_label",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3020, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    2800, 46),
    )
)

internal val Res.string.game_code_host_game: StringResource
  get() = CommonMainString0.game_code_host_game

private fun init_game_code_host_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_code_host_game", "game_code_host_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3075, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    2847, 35),
    )
)

internal val Res.string.game_code_join_game: StringResource
  get() = CommonMainString0.game_code_join_game

private fun init_game_code_join_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_code_join_game", "game_code_join_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3111, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    2883, 35),
    )
)

internal val Res.string.game_code_share_message: StringResource
  get() = CommonMainString0.game_code_share_message

private fun init_game_code_share_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_code_share_message", "game_code_share_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3147, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    2919, 83),
    )
)

internal val Res.string.game_code_title: StringResource
  get() = CommonMainString0.game_code_title

private fun init_game_code_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:game_code_title", "game_code_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3247, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3003, 35),
    )
)

internal val Res.string.game_mode_host_game: StringResource
  get() = CommonMainString0.game_mode_host_game

private fun init_game_mode_host_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_mode_host_game", "game_mode_host_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3291, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3039, 39),
    )
)

internal val Res.string.game_mode_join_game: StringResource
  get() = CommonMainString0.game_mode_join_game

private fun init_game_mode_join_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_mode_join_game", "game_mode_join_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3339, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3079, 39),
    )
)

internal val Res.string.game_variant_title: StringResource
  get() = CommonMainString0.game_variant_title

private fun init_game_variant_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:game_variant_title", "game_variant_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3387, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3119, 42),
    )
)

internal val Res.string.gameplay_tutorial_check_description: StringResource
  get() = CommonMainString0.gameplay_tutorial_check_description

private fun init_gameplay_tutorial_check_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_check_description", "gameplay_tutorial_check_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3438, 131),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3162, 111),
    )
)

internal val Res.string.gameplay_tutorial_check_title: StringResource
  get() = CommonMainString0.gameplay_tutorial_check_title

private fun init_gameplay_tutorial_check_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_check_title", "gameplay_tutorial_check_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3570, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3274, 45),
    )
)

internal val Res.string.gameplay_tutorial_finish_button: StringResource
  get() = CommonMainString0.gameplay_tutorial_finish_button

private fun init_gameplay_tutorial_finish_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_finish_button", "gameplay_tutorial_finish_button",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3616, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3320, 47),
    )
)

internal val Res.string.gameplay_tutorial_fold_description: StringResource
  get() = CommonMainString0.gameplay_tutorial_fold_description

private fun init_gameplay_tutorial_fold_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_fold_description", "gameplay_tutorial_fold_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3668, 106),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3368, 90),
    )
)

internal val Res.string.gameplay_tutorial_fold_title: StringResource
  get() = CommonMainString0.gameplay_tutorial_fold_title

private fun init_gameplay_tutorial_fold_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_fold_title", "gameplay_tutorial_fold_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3775, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3459, 44),
    )
)

internal val Res.string.gameplay_tutorial_help_description: StringResource
  get() = CommonMainString0.gameplay_tutorial_help_description

private fun init_gameplay_tutorial_help_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_help_description", "gameplay_tutorial_help_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3824, 126),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3504, 90),
    )
)

internal val Res.string.gameplay_tutorial_help_hand_rankings_image_url: StringResource
  get() = CommonMainString0.gameplay_tutorial_help_hand_rankings_image_url

private fun init_gameplay_tutorial_help_hand_rankings_image_url(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_help_hand_rankings_image_url",
    "gameplay_tutorial_help_hand_rankings_image_url",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    3951, 126),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3595, 126),
    )
)

internal val Res.string.gameplay_tutorial_help_poker_rules_image_url: StringResource
  get() = CommonMainString0.gameplay_tutorial_help_poker_rules_image_url

private fun init_gameplay_tutorial_help_poker_rules_image_url(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_help_poker_rules_image_url",
    "gameplay_tutorial_help_poker_rules_image_url",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    4078, 120),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3722, 120),
    )
)

internal val Res.string.gameplay_tutorial_help_title: StringResource
  get() = CommonMainString0.gameplay_tutorial_help_title

private fun init_gameplay_tutorial_help_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_help_title", "gameplay_tutorial_help_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    4199, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3843, 44),
    )
)

internal val Res.string.gameplay_tutorial_next_button: StringResource
  get() = CommonMainString0.gameplay_tutorial_next_button

private fun init_gameplay_tutorial_next_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_next_button", "gameplay_tutorial_next_button",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    4248, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3888, 45),
    )
)

internal val Res.string.gameplay_tutorial_peek_description: StringResource
  get() = CommonMainString0.gameplay_tutorial_peek_description

private fun init_gameplay_tutorial_peek_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_peek_description", "gameplay_tutorial_peek_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    4298, 130),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    3934, 94),
    )
)

internal val Res.string.gameplay_tutorial_peek_title: StringResource
  get() = CommonMainString0.gameplay_tutorial_peek_title

private fun init_gameplay_tutorial_peek_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_peek_title", "gameplay_tutorial_peek_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    4429, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    4029, 44),
    )
)

internal val Res.string.gameplay_tutorial_settings_card_back_style_image_url: StringResource
  get() = CommonMainString0.gameplay_tutorial_settings_card_back_style_image_url

private fun init_gameplay_tutorial_settings_card_back_style_image_url(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_settings_card_back_style_image_url",
    "gameplay_tutorial_settings_card_back_style_image_url",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    4474, 140),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    4074, 140),
    )
)

internal val Res.string.gameplay_tutorial_settings_card_style_image_url: StringResource
  get() = CommonMainString0.gameplay_tutorial_settings_card_style_image_url

private fun init_gameplay_tutorial_settings_card_style_image_url(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_settings_card_style_image_url",
    "gameplay_tutorial_settings_card_style_image_url",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    4615, 127),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    4215, 127),
    )
)

internal val Res.string.gameplay_tutorial_settings_description: StringResource
  get() = CommonMainString0.gameplay_tutorial_settings_description

private fun init_gameplay_tutorial_settings_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_settings_description", "gameplay_tutorial_settings_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    4743, 130),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    4343, 110),
    )
)

internal val Res.string.gameplay_tutorial_settings_table_felt_image_url: StringResource
  get() = CommonMainString0.gameplay_tutorial_settings_table_felt_image_url

private fun init_gameplay_tutorial_settings_table_felt_image_url(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_settings_table_felt_image_url",
    "gameplay_tutorial_settings_table_felt_image_url",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    4874, 127),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    4454, 127),
    )
)

internal val Res.string.gameplay_tutorial_settings_table_layout_image_url: StringResource
  get() = CommonMainString0.gameplay_tutorial_settings_table_layout_image_url

private fun init_gameplay_tutorial_settings_table_layout_image_url(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_settings_table_layout_image_url",
    "gameplay_tutorial_settings_table_layout_image_url",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5002, 133),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    4582, 133),
    )
)

internal val Res.string.gameplay_tutorial_settings_title: StringResource
  get() = CommonMainString0.gameplay_tutorial_settings_title

private fun init_gameplay_tutorial_settings_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_settings_title", "gameplay_tutorial_settings_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5136, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    4716, 52),
    )
)

internal val Res.string.gameplay_tutorial_skip_button: StringResource
  get() = CommonMainString0.gameplay_tutorial_skip_button

private fun init_gameplay_tutorial_skip_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:gameplay_tutorial_skip_button", "gameplay_tutorial_skip_button",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5197, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    4769, 57),
    )
)

internal val Res.string.google_login_id: StringResource
  get() = CommonMainString0.google_login_id

private fun init_google_login_id(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:google_login_id", "google_login_id",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5255, 119),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    4827, 119),
    )
)

internal val Res.string.host_game_bo3_explanation: StringResource
  get() = CommonMainString0.host_game_bo3_explanation

private fun init_host_game_bo3_explanation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:host_game_bo3_explanation", "host_game_bo3_explanation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5375, 101),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    4947, 89),
    )
)

internal val Res.string.host_game_create_game_btn: StringResource
  get() = CommonMainString0.host_game_create_game_btn

private fun init_host_game_create_game_btn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:host_game_create_game_btn", "host_game_create_game_btn",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5477, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5037, 45),
    )
)

internal val Res.string.host_game_full_match: StringResource
  get() = CommonMainString0.host_game_full_match

private fun init_host_game_full_match(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:host_game_full_match", "host_game_full_match",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5523, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5083, 48),
    )
)

internal val Res.string.host_game_single_match: StringResource
  get() = CommonMainString0.host_game_single_match

private fun init_host_game_single_match(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:host_game_single_match", "host_game_single_match",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5683, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5235, 46),
    )
)

internal val Res.string.host_game_single_match_explanation: StringResource
  get() = CommonMainString0.host_game_single_match_explanation

private fun init_host_game_single_match_explanation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:host_game_single_match_explanation", "host_game_single_match_explanation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5572, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5132, 102),
    )
)

internal val Res.string.host_game_text_divider: StringResource
  get() = CommonMainString0.host_game_text_divider

private fun init_host_game_text_divider(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:host_game_text_divider", "host_game_text_divider",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5734, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5282, 42),
    )
)

internal val Res.string.host_game_title: StringResource
  get() = CommonMainString0.host_game_title

private fun init_host_game_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:host_game_title", "host_game_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5781, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5325, 35),
    )
)

internal val Res.string.join_game_btn: StringResource
  get() = CommonMainString0.join_game_btn

private fun init_join_game_btn(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_game_btn", "join_game_btn",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5825, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5361, 29),
    )
)

internal val Res.string.join_game_game_code: StringResource
  get() = CommonMainString0.join_game_game_code

private fun init_join_game_game_code(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_game_game_code", "join_game_game_code",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5855, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5391, 39),
    )
)

internal val Res.string.join_game_no_code_link: StringResource
  get() = CommonMainString0.join_game_no_code_link

private fun init_join_game_no_code_link(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_game_no_code_link", "join_game_no_code_link",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5903, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5431, 82),
    )
)

internal val Res.string.join_game_seat_selection: StringResource
  get() = CommonMainString0.join_game_seat_selection

private fun init_join_game_seat_selection(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:join_game_seat_selection", "join_game_seat_selection",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    5998, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5514, 56),
    )
)

internal val Res.string.join_game_title: StringResource
  get() = CommonMainString0.join_game_title

private fun init_join_game_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_game_title", "join_game_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6063, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5571, 35),
    )
)

internal val Res.string.language_english: StringResource
  get() = CommonMainString0.language_english

private fun init_language_english(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:language_english", "language_english",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6107, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5607, 40),
    )
)

internal val Res.string.language_portuguese: StringResource
  get() = CommonMainString0.language_portuguese

private fun init_language_portuguese(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:language_portuguese", "language_portuguese",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6148, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5648, 47),
    )
)

internal val Res.string.loading_screen_name: StringResource
  get() = CommonMainString0.loading_screen_name

private fun init_loading_screen_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:loading_screen_name", "loading_screen_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6196, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5696, 43),
    )
)

internal val Res.string.permission_local_notification: StringResource
  get() = CommonMainString0.permission_local_notification

private fun init_permission_local_notification(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:permission_local_notification", "permission_local_notification",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6244, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5740, 57),
    )
)

internal val Res.string.permission_media_access: StringResource
  get() = CommonMainString0.permission_media_access

private fun init_permission_media_access(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:permission_media_access", "permission_media_access",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6302, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5798, 47),
    )
)

internal val Res.string.player_device_connecting_message: StringResource
  get() = CommonMainString0.player_device_connecting_message

private fun init_player_device_connecting_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:player_device_connecting_message", "player_device_connecting_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6354, 76),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5846, 76),
    )
)

internal val Res.string.player_device_explanation: StringResource
  get() = CommonMainString0.player_device_explanation

private fun init_player_device_explanation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:player_device_explanation", "player_device_explanation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6431, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5923, 53),
    )
)

internal val Res.string.player_device_play_card: StringResource
  get() = CommonMainString0.player_device_play_card

private fun init_player_device_play_card(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:player_device_play_card", "player_device_play_card",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6485, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    5977, 43),
    )
)

internal val Res.string.player_device_start_game: StringResource
  get() = CommonMainString0.player_device_start_game

private fun init_player_device_start_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:player_device_start_game", "player_device_start_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6533, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6021, 48),
    )
)

internal val Res.string.player_device_your_turn: StringResource
  get() = CommonMainString0.player_device_your_turn

private fun init_player_device_your_turn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:player_device_your_turn", "player_device_your_turn",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6586, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6070, 43),
    )
)

internal val Res.string.poker_action_timeout_none: StringResource
  get() = CommonMainString0.poker_action_timeout_none

private fun init_poker_action_timeout_none(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_action_timeout_none", "poker_action_timeout_none",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6630, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6114, 37),
    )
)

internal val Res.string.poker_action_timeout_seconds: StringResource
  get() = CommonMainString0.poker_action_timeout_seconds

private fun init_poker_action_timeout_seconds(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_action_timeout_seconds", "poker_action_timeout_seconds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6668, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6152, 44),
    )
)

internal val Res.string.poker_blind_speed_hyper: StringResource
  get() = CommonMainString0.poker_blind_speed_hyper

private fun init_poker_blind_speed_hyper(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_blind_speed_hyper", "poker_blind_speed_hyper",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6713, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6197, 39),
    )
)

internal val Res.string.poker_blind_speed_regular: StringResource
  get() = CommonMainString0.poker_blind_speed_regular

private fun init_poker_blind_speed_regular(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_blind_speed_regular", "poker_blind_speed_regular",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6753, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6237, 45),
    )
)

internal val Res.string.poker_blind_speed_slow: StringResource
  get() = CommonMainString0.poker_blind_speed_slow

private fun init_poker_blind_speed_slow(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_blind_speed_slow", "poker_blind_speed_slow",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6795, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6283, 38),
    )
)

internal val Res.string.poker_blind_speed_turbo: StringResource
  get() = CommonMainString0.poker_blind_speed_turbo

private fun init_poker_blind_speed_turbo(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_blind_speed_turbo", "poker_blind_speed_turbo",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6834, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6322, 39),
    )
)

internal val Res.string.poker_blinds_table_ante: StringResource
  get() = CommonMainString0.poker_blinds_table_ante

private fun init_poker_blinds_table_ante(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_blinds_table_ante", "poker_blinds_table_ante",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6874, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6362, 39),
    )
)

internal val Res.string.poker_blinds_table_blinds: StringResource
  get() = CommonMainString0.poker_blinds_table_blinds

private fun init_poker_blinds_table_blinds(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_blinds_table_blinds", "poker_blinds_table_blinds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6914, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6402, 41),
    )
)

internal val Res.string.poker_blinds_table_level_up_hands: StringResource
  get() = CommonMainString0.poker_blinds_table_level_up_hands

private fun init_poker_blinds_table_level_up_hands(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_blinds_table_level_up_hands", "poker_blinds_table_level_up_hands",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    6956, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6444, 49),
    )
)

internal val Res.string.poker_blinds_table_level_up_time: StringResource
  get() = CommonMainString0.poker_blinds_table_level_up_time

private fun init_poker_blinds_table_level_up_time(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_blinds_table_level_up_time", "poker_blinds_table_level_up_time",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7006, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6494, 52),
    )
)

internal val Res.string.poker_blinds_table_lvl: StringResource
  get() = CommonMainString0.poker_blinds_table_lvl

private fun init_poker_blinds_table_lvl(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_blinds_table_lvl", "poker_blinds_table_lvl",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7059, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6547, 38),
    )
)

internal val Res.string.poker_blinds_table_title: StringResource
  get() = CommonMainString0.poker_blinds_table_title

private fun init_poker_blinds_table_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_blinds_table_title", "poker_blinds_table_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7098, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6586, 56),
    )
)

internal val Res.string.poker_devices_blind_level: StringResource
  get() = CommonMainString0.poker_devices_blind_level

private fun init_poker_devices_blind_level(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_blind_level", "poker_devices_blind_level",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7468, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6920, 49),
    )
)

internal val Res.string.poker_devices_blind_level_up: StringResource
  get() = CommonMainString0.poker_devices_blind_level_up

private fun init_poker_devices_blind_level_up(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_blind_level_up", "poker_devices_blind_level_up",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7407, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6863, 56),
    )
)

internal val Res.string.poker_devices_blind_level_up_hand: StringResource
  get() = CommonMainString0.poker_devices_blind_level_up_hand

private fun init_poker_devices_blind_level_up_hand(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_blind_level_up_hand", "poker_devices_blind_level_up_hand",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7159, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6643, 49),
    )
)

internal val Res.string.poker_devices_blind_level_up_time: StringResource
  get() = CommonMainString0.poker_devices_blind_level_up_time

private fun init_poker_devices_blind_level_up_time(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_blind_level_up_time", "poker_devices_blind_level_up_time",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7357, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6813, 49),
    )
)

internal val Res.string.poker_devices_blind_level_up_time_not_available: StringResource
  get() = CommonMainString0.poker_devices_blind_level_up_time_not_available

private fun init_poker_devices_blind_level_up_time_not_available(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_blind_level_up_time_not_available",
    "poker_devices_blind_level_up_time_not_available",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7209, 147),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6693, 119),
    )
)

internal val Res.string.poker_devices_blinds: StringResource
  get() = CommonMainString0.poker_devices_blinds

private fun init_poker_devices_blinds(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_blinds", "poker_devices_blinds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7522, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    6970, 36),
    )
)

internal val Res.string.poker_devices_change_seats: StringResource
  get() = CommonMainString0.poker_devices_change_seats

private fun init_poker_devices_change_seats(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_change_seats", "poker_devices_change_seats",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7559, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7007, 66),
    )
)

internal val Res.string.poker_devices_clock: StringResource
  get() = CommonMainString0.poker_devices_clock

private fun init_poker_devices_clock(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_clock", "poker_devices_clock",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7638, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7074, 39),
    )
)

internal val Res.string.poker_devices_current_blind: StringResource
  get() = CommonMainString0.poker_devices_current_blind

private fun init_poker_devices_current_blind(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_current_blind", "poker_devices_current_blind",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7686, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7114, 55),
    )
)

internal val Res.string.poker_devices_deal_cards: StringResource
  get() = CommonMainString0.poker_devices_deal_cards

private fun init_poker_devices_deal_cards(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_deal_cards", "poker_devices_deal_cards",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7837, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7249, 40),
    )
)

internal val Res.string.poker_devices_deal_cards_countdown: StringResource
  get() = CommonMainString0.poker_devices_deal_cards_countdown

private fun init_poker_devices_deal_cards_countdown(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_deal_cards_countdown", "poker_devices_deal_cards_countdown",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7738, 98),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7170, 78),
    )
)

internal val Res.string.poker_devices_game_code: StringResource
  get() = CommonMainString0.poker_devices_game_code

private fun init_poker_devices_game_code(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_game_code", "poker_devices_game_code",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7886, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7290, 43),
    )
)

internal val Res.string.poker_devices_game_paused: StringResource
  get() = CommonMainString0.poker_devices_game_paused

private fun init_poker_devices_game_paused(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_game_paused", "poker_devices_game_paused",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    7938, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7334, 53),
    )
)

internal val Res.string.poker_devices_help: StringResource
  get() = CommonMainString0.poker_devices_help

private fun init_poker_devices_help(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_help", "poker_devices_help",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8000, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7388, 34),
    )
)

internal val Res.string.poker_devices_next_blind: StringResource
  get() = CommonMainString0.poker_devices_next_blind

private fun init_poker_devices_next_blind(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_next_blind", "poker_devices_next_blind",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8039, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7423, 48),
    )
)

internal val Res.string.poker_devices_overview: StringResource
  get() = CommonMainString0.poker_devices_overview

private fun init_poker_devices_overview(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_overview", "poker_devices_overview",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8092, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7472, 42),
    )
)

internal val Res.string.poker_devices_payments: StringResource
  get() = CommonMainString0.poker_devices_payments

private fun init_poker_devices_payments(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_payments", "poker_devices_payments",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8523, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7863, 42),
    )
)

internal val Res.string.poker_devices_payments_pays: StringResource
  get() = CommonMainString0.poker_devices_payments_pays

private fun init_poker_devices_payments_pays(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_payments_pays", "poker_devices_payments_pays",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8139, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7515, 43),
    )
)

internal val Res.string.poker_devices_payments_transfers: StringResource
  get() = CommonMainString0.poker_devices_payments_transfers

private fun init_poker_devices_payments_transfers(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_payments_transfers", "poker_devices_payments_transfers",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8290, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7646, 52),
    )
)

internal val Res.string.poker_devices_payments_transfers_not_available: StringResource
  get() = CommonMainString0.poker_devices_payments_transfers_not_available

private fun init_poker_devices_payments_transfers_not_available(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_payments_transfers_not_available",
    "poker_devices_payments_transfers_not_available",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8183, 106),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7559, 86),
    )
)

internal val Res.string.poker_devices_payments_winners: StringResource
  get() = CommonMainString0.poker_devices_payments_winners

private fun init_poker_devices_payments_winners(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_payments_winners", "poker_devices_payments_winners",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8424, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7768, 50),
    )
)

internal val Res.string.poker_devices_payments_winners_not_available: StringResource
  get() = CommonMainString0.poker_devices_payments_winners_not_available

private fun init_poker_devices_payments_winners_not_available(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_payments_winners_not_available",
    "poker_devices_payments_winners_not_available",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8351, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7699, 68),
    )
)

internal val Res.string.poker_devices_payments_wins: StringResource
  get() = CommonMainString0.poker_devices_payments_wins

private fun init_poker_devices_payments_wins(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_payments_wins", "poker_devices_payments_wins",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8479, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7819, 43),
    )
)

internal val Res.string.poker_devices_pots: StringResource
  get() = CommonMainString0.poker_devices_pots

private fun init_poker_devices_pots(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_pots", "poker_devices_pots",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8570, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7906, 34),
    )
)

internal val Res.string.poker_devices_rebuy: StringResource
  get() = CommonMainString0.poker_devices_rebuy

private fun init_poker_devices_rebuy(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_rebuy", "poker_devices_rebuy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8605, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7941, 35),
    )
)

internal val Res.string.poker_devices_replay: StringResource
  get() = CommonMainString0.poker_devices_replay

private fun init_poker_devices_replay(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_replay", "poker_devices_replay",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8645, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    7977, 36),
    )
)

internal val Res.string.poker_devices_screen_landscape: StringResource
  get() = CommonMainString0.poker_devices_screen_landscape

private fun init_poker_devices_screen_landscape(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_screen_landscape", "poker_devices_screen_landscape",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8682, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8014, 50),
    )
)

internal val Res.string.poker_devices_screen_not_supported: StringResource
  get() = CommonMainString0.poker_devices_screen_not_supported

private fun init_poker_devices_screen_not_supported(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_screen_not_supported", "poker_devices_screen_not_supported",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8733, 146),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8065, 146),
    )
)

internal val Res.string.poker_devices_screen_portrait: StringResource
  get() = CommonMainString0.poker_devices_screen_portrait

private fun init_poker_devices_screen_portrait(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_screen_portrait", "poker_devices_screen_portrait",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8880, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8212, 49),
    )
)

internal val Res.string.poker_devices_screen_table_view_not_supported: StringResource
  get() = CommonMainString0.poker_devices_screen_table_view_not_supported

private fun init_poker_devices_screen_table_view_not_supported(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_screen_table_view_not_supported",
    "poker_devices_screen_table_view_not_supported",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    8930, 141),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8262, 113),
    )
)

internal val Res.string.poker_devices_settings: StringResource
  get() = CommonMainString0.poker_devices_settings

private fun init_poker_devices_settings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_settings", "poker_devices_settings",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9072, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8376, 42),
    )
)

internal val Res.string.poker_devices_spectator: StringResource
  get() = CommonMainString0.poker_devices_spectator

private fun init_poker_devices_spectator(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_spectator", "poker_devices_spectator",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9123, 159),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8419, 139),
    )
)

internal val Res.string.poker_devices_start_game: StringResource
  get() = CommonMainString0.poker_devices_start_game

private fun init_poker_devices_start_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_devices_start_game", "poker_devices_start_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9283, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8559, 40),
    )
)

internal val Res.string.poker_dialog_cancel: StringResource
  get() = CommonMainString0.poker_dialog_cancel

private fun init_poker_dialog_cancel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_cancel", "poker_dialog_cancel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9328, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8600, 35),
    )
)

internal val Res.string.poker_dialog_confirm: StringResource
  get() = CommonMainString0.poker_dialog_confirm

private fun init_poker_dialog_confirm(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_confirm", "poker_dialog_confirm",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9368, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8636, 40),
    )
)

internal val Res.string.poker_dialog_leave_game_text: StringResource
  get() = CommonMainString0.poker_dialog_leave_game_text

private fun init_poker_dialog_leave_game_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_leave_game_text", "poker_dialog_leave_game_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9409, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8677, 84),
    )
)

internal val Res.string.poker_dialog_leave_game_title: StringResource
  get() = CommonMainString0.poker_dialog_leave_game_title

private fun init_poker_dialog_leave_game_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_leave_game_title", "poker_dialog_leave_game_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9498, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8762, 53),
    )
)

internal val Res.string.poker_dialog_ok: StringResource
  get() = CommonMainString0.poker_dialog_ok

private fun init_poker_dialog_ok(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_ok", "poker_dialog_ok",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9552, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8816, 27),
    )
)

internal val Res.string.poker_dialog_rebuy_text: StringResource
  get() = CommonMainString0.poker_dialog_rebuy_text

private fun init_poker_dialog_rebuy_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_rebuy_text", "poker_dialog_rebuy_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9580, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8844, 75),
    )
)

internal val Res.string.poker_dialog_rebuy_title: StringResource
  get() = CommonMainString0.poker_dialog_rebuy_title

private fun init_poker_dialog_rebuy_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_rebuy_title", "poker_dialog_rebuy_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9660, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8920, 40),
    )
)

internal val Res.string.poker_dialog_start_game_text: StringResource
  get() = CommonMainString0.poker_dialog_start_game_text

private fun init_poker_dialog_start_game_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_start_game_text", "poker_dialog_start_game_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9930, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9178, 84),
    )
)

internal val Res.string.poker_dialog_start_game_text_minimum_players: StringResource
  get() = CommonMainString0.poker_dialog_start_game_text_minimum_players

private fun init_poker_dialog_start_game_text_minimum_players(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_start_game_text_minimum_players",
    "poker_dialog_start_game_text_minimum_players",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    9701, 228),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    8961, 216),
    )
)

internal val Res.string.poker_dialog_start_game_title: StringResource
  get() = CommonMainString0.poker_dialog_start_game_title

private fun init_poker_dialog_start_game_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_start_game_title", "poker_dialog_start_game_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10023, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9263, 53),
    )
)

internal val Res.string.poker_dialog_terminate_game_disabled: StringResource
  get() = CommonMainString0.poker_dialog_terminate_game_disabled

private fun init_poker_dialog_terminate_game_disabled(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_terminate_game_disabled", "poker_dialog_terminate_game_disabled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10081, 188),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9317, 184),
    )
)

internal val Res.string.poker_dialog_terminate_game_text: StringResource
  get() = CommonMainString0.poker_dialog_terminate_game_text

private fun init_poker_dialog_terminate_game_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_terminate_game_text", "poker_dialog_terminate_game_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10270, 96),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9502, 96),
    )
)

internal val Res.string.poker_dialog_terminate_game_title: StringResource
  get() = CommonMainString0.poker_dialog_terminate_game_title

private fun init_poker_dialog_terminate_game_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_terminate_game_title", "poker_dialog_terminate_game_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10367, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9599, 61),
    )
)

internal val Res.string.poker_dialog_update: StringResource
  get() = CommonMainString0.poker_dialog_update

private fun init_poker_dialog_update(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_update", "poker_dialog_update",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10624, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9820, 35),
    )
)

internal val Res.string.poker_dialog_update_game_settings_text: StringResource
  get() = CommonMainString0.poker_dialog_update_game_settings_text

private fun init_poker_dialog_update_game_settings_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_update_game_settings_text", "poker_dialog_update_game_settings_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10429, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9661, 90),
    )
)

internal val Res.string.poker_dialog_update_game_settings_title: StringResource
  get() = CommonMainString0.poker_dialog_update_game_settings_title

private fun init_poker_dialog_update_game_settings_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_dialog_update_game_settings_title", "poker_dialog_update_game_settings_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10540, 83),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9752, 67),
    )
)

internal val Res.string.poker_game_help_hand_rankings: StringResource
  get() = CommonMainString0.poker_game_help_hand_rankings

private fun init_poker_game_help_hand_rankings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_hand_rankings", "poker_game_help_hand_rankings",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10664, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9856, 57),
    )
)

internal val Res.string.poker_game_help_rules_gameplay: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay

private fun init_poker_game_help_rules_gameplay(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay", "poker_game_help_rules_gameplay",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    19889, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18121, 62),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_1: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_1

private fun init_poker_game_help_rules_gameplay_text_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_1", "poker_game_help_rules_gameplay_text_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    12887, 1109),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    11947, 953),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_10: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_10

private fun init_poker_game_help_rules_gameplay_text_10(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_10", "poker_game_help_rules_gameplay_text_10",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10738, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9914, 82),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_11: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_11

private fun init_poker_game_help_rules_gameplay_text_11(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_11", "poker_game_help_rules_gameplay_text_11",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    10817, 2014),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    9997, 1894),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_12: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_12

private fun init_poker_game_help_rules_gameplay_text_12(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_12", "poker_game_help_rules_gameplay_text_12",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    12832, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    11892, 54),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_2: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_2

private fun init_poker_game_help_rules_gameplay_text_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_2", "poker_game_help_rules_gameplay_text_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    13997, 81),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    12901, 65),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_3: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_3

private fun init_poker_game_help_rules_gameplay_text_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_3", "poker_game_help_rules_gameplay_text_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    14079, 1093),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    12967, 881),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_4: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_4

private fun init_poker_game_help_rules_gameplay_text_4(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_4", "poker_game_help_rules_gameplay_text_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    15173, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    13849, 69),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_5: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_5

private fun init_poker_game_help_rules_gameplay_text_5(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_5", "poker_game_help_rules_gameplay_text_5",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    15239, 1773),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    13919, 1609),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_6: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_6

private fun init_poker_game_help_rules_gameplay_text_6(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_6", "poker_game_help_rules_gameplay_text_6",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    17013, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    15529, 65),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_7: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_7

private fun init_poker_game_help_rules_gameplay_text_7(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_7", "poker_game_help_rules_gameplay_text_7",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    17087, 917),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    15595, 877),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_8: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_8

private fun init_poker_game_help_rules_gameplay_text_8(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_8", "poker_game_help_rules_gameplay_text_8",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    18005, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    16473, 69),
    )
)

internal val Res.string.poker_game_help_rules_gameplay_text_9: StringResource
  get() = CommonMainString0.poker_game_help_rules_gameplay_text_9

private fun init_poker_game_help_rules_gameplay_text_9(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_help_rules_gameplay_text_9", "poker_game_help_rules_gameplay_text_9",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    18079, 1809),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    16543, 1577),
    )
)

internal val Res.string.poker_game_log_action_bet: StringResource
  get() = CommonMainString0.poker_game_log_action_bet

private fun init_poker_game_log_action_bet(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_log_action_bet", "poker_game_log_action_bet",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    19956, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18184, 41),
    )
)

internal val Res.string.poker_game_log_action_call: StringResource
  get() = CommonMainString0.poker_game_log_action_call

private fun init_poker_game_log_action_call(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_log_action_call", "poker_game_log_action_call",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20002, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18226, 42),
    )
)

internal val Res.string.poker_game_log_action_check: StringResource
  get() = CommonMainString0.poker_game_log_action_check

private fun init_poker_game_log_action_check(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_log_action_check", "poker_game_log_action_check",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20049, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18269, 43),
    )
)

internal val Res.string.poker_game_log_action_fold: StringResource
  get() = CommonMainString0.poker_game_log_action_fold

private fun init_poker_game_log_action_fold(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_log_action_fold", "poker_game_log_action_fold",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20093, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18313, 42),
    )
)

internal val Res.string.poker_game_log_action_muck: StringResource
  get() = CommonMainString0.poker_game_log_action_muck

private fun init_poker_game_log_action_muck(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_log_action_muck", "poker_game_log_action_muck",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20140, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18356, 42),
    )
)

internal val Res.string.poker_game_log_action_post_bb: StringResource
  get() = CommonMainString0.poker_game_log_action_post_bb

private fun init_poker_game_log_action_post_bb(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_log_action_post_bb", "poker_game_log_action_post_bb",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20187, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18399, 49),
    )
)

internal val Res.string.poker_game_log_action_post_sb: StringResource
  get() = CommonMainString0.poker_game_log_action_post_sb

private fun init_poker_game_log_action_post_sb(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_log_action_post_sb", "poker_game_log_action_post_sb",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20241, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18449, 49),
    )
)

internal val Res.string.poker_game_log_action_raise: StringResource
  get() = CommonMainString0.poker_game_log_action_raise

private fun init_poker_game_log_action_raise(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_log_action_raise", "poker_game_log_action_raise",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20295, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18499, 47),
    )
)

internal val Res.string.poker_game_log_action_show: StringResource
  get() = CommonMainString0.poker_game_log_action_show

private fun init_poker_game_log_action_show(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_log_action_show", "poker_game_log_action_show",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20351, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18547, 42),
    )
)

internal val Res.string.poker_game_log_action_win: StringResource
  get() = CommonMainString0.poker_game_log_action_win

private fun init_poker_game_log_action_win(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_log_action_win", "poker_game_log_action_win",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20398, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18590, 41),
    )
)

internal val Res.string.poker_game_navigation_drawer_gameplay_tutorial: StringResource
  get() = CommonMainString0.poker_game_navigation_drawer_gameplay_tutorial

private fun init_poker_game_navigation_drawer_gameplay_tutorial(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_navigation_drawer_gameplay_tutorial",
    "poker_game_navigation_drawer_gameplay_tutorial",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20440, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18632, 78),
    )
)

internal val Res.string.poker_game_navigation_drawer_help: StringResource
  get() = CommonMainString0.poker_game_navigation_drawer_help

private fun init_poker_game_navigation_drawer_help(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_navigation_drawer_help", "poker_game_navigation_drawer_help",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20527, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18711, 57),
    )
)

internal val Res.string.poker_game_navigation_drawer_leave: StringResource
  get() = CommonMainString0.poker_game_navigation_drawer_leave

private fun init_poker_game_navigation_drawer_leave(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_navigation_drawer_leave", "poker_game_navigation_drawer_leave",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20593, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18769, 58),
    )
)

internal val Res.string.poker_game_navigation_drawer_terminate: StringResource
  get() = CommonMainString0.poker_game_navigation_drawer_terminate

private fun init_poker_game_navigation_drawer_terminate(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_navigation_drawer_terminate", "poker_game_navigation_drawer_terminate",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20652, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18828, 66),
    )
)

internal val Res.string.poker_game_navigation_drawer_top_up: StringResource
  get() = CommonMainString0.poker_game_navigation_drawer_top_up

private fun init_poker_game_navigation_drawer_top_up(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_navigation_drawer_top_up", "poker_game_navigation_drawer_top_up",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20719, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18895, 51),
    )
)

internal val Res.string.poker_game_navigation_drawer_update_settings: StringResource
  get() = CommonMainString0.poker_game_navigation_drawer_update_settings

private fun init_poker_game_navigation_drawer_update_settings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_navigation_drawer_update_settings",
    "poker_game_navigation_drawer_update_settings",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20779, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    18947, 72),
    )
)

internal val Res.string.poker_game_replay_hand_summary: StringResource
  get() = CommonMainString0.poker_game_replay_hand_summary

private fun init_poker_game_replay_hand_summary(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_replay_hand_summary", "poker_game_replay_hand_summary",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21134, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19270, 54),
    )
)

internal val Res.string.poker_game_replay_hand_summary_blinds: StringResource
  get() = CommonMainString0.poker_game_replay_hand_summary_blinds

private fun init_poker_game_replay_hand_summary_blinds(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_replay_hand_summary_blinds", "poker_game_replay_hand_summary_blinds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20868, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19020, 57),
    )
)

internal val Res.string.poker_game_replay_hand_summary_fold: StringResource
  get() = CommonMainString0.poker_game_replay_hand_summary_fold

private fun init_poker_game_replay_hand_summary_fold(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_replay_hand_summary_fold", "poker_game_replay_hand_summary_fold",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20926, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19078, 51),
    )
)

internal val Res.string.poker_game_replay_hand_summary_hand_id: StringResource
  get() = CommonMainString0.poker_game_replay_hand_summary_hand_id

private fun init_poker_game_replay_hand_summary_hand_id(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_replay_hand_summary_hand_id", "poker_game_replay_hand_summary_hand_id",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    20982, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19130, 58),
    )
)

internal val Res.string.poker_game_replay_hand_summary_not_available: StringResource
  get() = CommonMainString0.poker_game_replay_hand_summary_not_available

private fun init_poker_game_replay_hand_summary_not_available(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_replay_hand_summary_not_available",
    "poker_game_replay_hand_summary_not_available",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21045, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19189, 80),
    )
)

internal val Res.string.poker_game_settings_card_back_style: StringResource
  get() = CommonMainString0.poker_game_settings_card_back_style

private fun init_poker_game_settings_card_back_style(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_card_back_style", "poker_game_settings_card_back_style",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21431, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19551, 63),
    )
)

internal val Res.string.poker_game_settings_card_back_style_blue: StringResource
  get() = CommonMainString0.poker_game_settings_card_back_style_blue

private fun init_poker_game_settings_card_back_style_blue(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_card_back_style_blue", "poker_game_settings_card_back_style_blue",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21193, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19325, 56),
    )
)

internal val Res.string.poker_game_settings_card_back_style_green: StringResource
  get() = CommonMainString0.poker_game_settings_card_back_style_green

private fun init_poker_game_settings_card_back_style_green(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_card_back_style_green", "poker_game_settings_card_back_style_green",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21250, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19382, 57),
    )
)

internal val Res.string.poker_game_settings_card_back_style_orange: StringResource
  get() = CommonMainString0.poker_game_settings_card_back_style_orange

private fun init_poker_game_settings_card_back_style_orange(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_card_back_style_orange", "poker_game_settings_card_back_style_orange",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21308, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19440, 58),
    )
)

internal val Res.string.poker_game_settings_card_back_style_red: StringResource
  get() = CommonMainString0.poker_game_settings_card_back_style_red

private fun init_poker_game_settings_card_back_style_red(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_card_back_style_red", "poker_game_settings_card_back_style_red",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21371, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19499, 51),
    )
)

internal val Res.string.poker_game_settings_card_style: StringResource
  get() = CommonMainString0.poker_game_settings_card_style

private fun init_poker_game_settings_card_style(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_card_style", "poker_game_settings_card_style",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21723, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19815, 54),
    )
)

internal val Res.string.poker_game_settings_card_style_four_color_bg: StringResource
  get() = CommonMainString0.poker_game_settings_card_style_four_color_bg

private fun init_poker_game_settings_card_style_four_color_bg(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_card_style_four_color_bg",
    "poker_game_settings_card_style_four_color_bg",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21507, 76),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19615, 68),
    )
)

internal val Res.string.poker_game_settings_card_style_four_color_suit: StringResource
  get() = CommonMainString0.poker_game_settings_card_style_four_color_suit

private fun init_poker_game_settings_card_style_four_color_suit(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_card_style_four_color_suit",
    "poker_game_settings_card_style_four_color_suit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21584, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19684, 70),
    )
)

internal val Res.string.poker_game_settings_card_style_original: StringResource
  get() = CommonMainString0.poker_game_settings_card_style_original

private fun init_poker_game_settings_card_style_original(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_card_style_original", "poker_game_settings_card_style_original",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21663, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19755, 59),
    )
)

internal val Res.string.poker_game_settings_table_felt: StringResource
  get() = CommonMainString0.poker_game_settings_table_felt

private fun init_poker_game_settings_table_felt(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_table_felt", "poker_game_settings_table_felt",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21942, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20022, 54),
    )
)

internal val Res.string.poker_game_settings_table_felt_blue: StringResource
  get() = CommonMainString0.poker_game_settings_table_felt_blue

private fun init_poker_game_settings_table_felt_blue(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_table_felt_blue", "poker_game_settings_table_felt_blue",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21782, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19870, 51),
    )
)

internal val Res.string.poker_game_settings_table_felt_green: StringResource
  get() = CommonMainString0.poker_game_settings_table_felt_green

private fun init_poker_game_settings_table_felt_green(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_table_felt_green", "poker_game_settings_table_felt_green",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21834, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19922, 52),
    )
)

internal val Res.string.poker_game_settings_table_felt_red: StringResource
  get() = CommonMainString0.poker_game_settings_table_felt_red

private fun init_poker_game_settings_table_felt_red(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_table_felt_red", "poker_game_settings_table_felt_red",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    21887, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    19975, 46),
    )
)

internal val Res.string.poker_game_settings_table_layout: StringResource
  get() = CommonMainString0.poker_game_settings_table_layout

private fun init_poker_game_settings_table_layout(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_table_layout", "poker_game_settings_table_layout",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22413, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20481, 56),
    )
)

internal val Res.string.poker_game_settings_table_layout_live: StringResource
  get() = CommonMainString0.poker_game_settings_table_layout_live

private fun init_poker_game_settings_table_layout_live(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_table_layout_live", "poker_game_settings_table_layout_live",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22001, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20077, 53),
    )
)

internal val Res.string.poker_game_settings_table_layout_none: StringResource
  get() = CommonMainString0.poker_game_settings_table_layout_none

private fun init_poker_game_settings_table_layout_none(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_table_layout_none", "poker_game_settings_table_layout_none",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22055, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20131, 53),
    )
)

internal val Res.string.poker_game_settings_table_layout_online: StringResource
  get() = CommonMainString0.poker_game_settings_table_layout_online

private fun init_poker_game_settings_table_layout_online(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_table_layout_online", "poker_game_settings_table_layout_online",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22109, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20185, 55),
    )
)

internal val Res.string.poker_game_settings_table_layout_vertical_display: StringResource
  get() = CommonMainString0.poker_game_settings_table_layout_vertical_display

private fun init_poker_game_settings_table_layout_vertical_display(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_table_layout_vertical_display",
    "poker_game_settings_table_layout_vertical_display",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22327, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20399, 81),
    )
)

internal val Res.string.poker_game_settings_table_layout_vertical_display_description:
    StringResource
  get() = CommonMainString0.poker_game_settings_table_layout_vertical_display_description

private fun init_poker_game_settings_table_layout_vertical_display_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_game_settings_table_layout_vertical_display_description",
    "poker_game_settings_table_layout_vertical_display_description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22165, 161),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20241, 157),
    )
)

internal val Res.string.poker_hand_rankings: StringResource
  get() = CommonMainString0.poker_hand_rankings

private fun init_poker_hand_rankings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings", "poker_hand_rankings",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24080, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22112, 47),
    )
)

internal val Res.string.poker_hand_rankings_eight_low: StringResource
  get() = CommonMainString0.poker_hand_rankings_eight_low

private fun init_poker_hand_rankings_eight_low(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_eight_low", "poker_hand_rankings_eight_low",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22474, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20538, 53),
    )
)

internal val Res.string.poker_hand_rankings_five_low: StringResource
  get() = CommonMainString0.poker_hand_rankings_five_low

private fun init_poker_hand_rankings_five_low(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_five_low", "poker_hand_rankings_five_low",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22532, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20592, 52),
    )
)

internal val Res.string.poker_hand_rankings_flush: StringResource
  get() = CommonMainString0.poker_hand_rankings_flush

private fun init_poker_hand_rankings_flush(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_flush", "poker_hand_rankings_flush",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22589, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20645, 45),
    )
)

internal val Res.string.poker_hand_rankings_four_of_a_kind: StringResource
  get() = CommonMainString0.poker_hand_rankings_four_of_a_kind

private fun init_poker_hand_rankings_four_of_a_kind(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_four_of_a_kind", "poker_hand_rankings_four_of_a_kind",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22635, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20691, 66),
    )
)

internal val Res.string.poker_hand_rankings_full_house: StringResource
  get() = CommonMainString0.poker_hand_rankings_full_house

private fun init_poker_hand_rankings_full_house(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_full_house", "poker_hand_rankings_full_house",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22690, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20758, 58),
    )
)

internal val Res.string.poker_hand_rankings_high_card: StringResource
  get() = CommonMainString0.poker_hand_rankings_high_card

private fun init_poker_hand_rankings_high_card(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_high_card", "poker_hand_rankings_high_card",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22749, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20817, 57),
    )
)

internal val Res.string.poker_hand_rankings_high_hand: StringResource
  get() = CommonMainString0.poker_hand_rankings_high_hand

private fun init_poker_hand_rankings_high_hand(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_high_hand", "poker_hand_rankings_high_hand",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22807, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20875, 49),
    )
)

internal val Res.string.poker_hand_rankings_low_hand: StringResource
  get() = CommonMainString0.poker_hand_rankings_low_hand

private fun init_poker_hand_rankings_low_hand(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_low_hand", "poker_hand_rankings_low_hand",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22865, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20925, 48),
    )
)

internal val Res.string.poker_hand_rankings_pair: StringResource
  get() = CommonMainString0.poker_hand_rankings_pair

private fun init_poker_hand_rankings_pair(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_pair", "poker_hand_rankings_pair",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22922, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    20974, 44),
    )
)

internal val Res.string.poker_hand_rankings_royal_flush: StringResource
  get() = CommonMainString0.poker_hand_rankings_royal_flush

private fun init_poker_hand_rankings_royal_flush(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_royal_flush", "poker_hand_rankings_royal_flush",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    22963, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21019, 59),
    )
)

internal val Res.string.poker_hand_rankings_seven_low: StringResource
  get() = CommonMainString0.poker_hand_rankings_seven_low

private fun init_poker_hand_rankings_seven_low(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_seven_low", "poker_hand_rankings_seven_low",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23027, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21079, 53),
    )
)

internal val Res.string.poker_hand_rankings_short_deck_flush: StringResource
  get() = CommonMainString0.poker_hand_rankings_short_deck_flush

private fun init_poker_hand_rankings_short_deck_flush(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_short_deck_flush", "poker_hand_rankings_short_deck_flush",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23085, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21133, 56),
    )
)

internal val Res.string.poker_hand_rankings_short_deck_four_of_a_kind: StringResource
  get() = CommonMainString0.poker_hand_rankings_short_deck_four_of_a_kind

private fun init_poker_hand_rankings_short_deck_four_of_a_kind(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_short_deck_four_of_a_kind",
    "poker_hand_rankings_short_deck_four_of_a_kind",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23142, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21190, 77),
    )
)

internal val Res.string.poker_hand_rankings_short_deck_full_house: StringResource
  get() = CommonMainString0.poker_hand_rankings_short_deck_full_house

private fun init_poker_hand_rankings_short_deck_full_house(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_short_deck_full_house", "poker_hand_rankings_short_deck_full_house",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23208, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21268, 69),
    )
)

internal val Res.string.poker_hand_rankings_short_deck_high_card: StringResource
  get() = CommonMainString0.poker_hand_rankings_short_deck_high_card

private fun init_poker_hand_rankings_short_deck_high_card(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_short_deck_high_card", "poker_hand_rankings_short_deck_high_card",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23278, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21338, 68),
    )
)

internal val Res.string.poker_hand_rankings_short_deck_pair: StringResource
  get() = CommonMainString0.poker_hand_rankings_short_deck_pair

private fun init_poker_hand_rankings_short_deck_pair(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_short_deck_pair", "poker_hand_rankings_short_deck_pair",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23347, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21407, 55),
    )
)

internal val Res.string.poker_hand_rankings_short_deck_royal_flush: StringResource
  get() = CommonMainString0.poker_hand_rankings_short_deck_royal_flush

private fun init_poker_hand_rankings_short_deck_royal_flush(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_short_deck_royal_flush", "poker_hand_rankings_short_deck_royal_flush",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23399, 74),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21463, 70),
    )
)

internal val Res.string.poker_hand_rankings_short_deck_straight: StringResource
  get() = CommonMainString0.poker_hand_rankings_short_deck_straight

private fun init_poker_hand_rankings_short_deck_straight(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_short_deck_straight", "poker_hand_rankings_short_deck_straight",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23568, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21612, 63),
    )
)

internal val Res.string.poker_hand_rankings_short_deck_straight_flush: StringResource
  get() = CommonMainString0.poker_hand_rankings_short_deck_straight_flush

private fun init_poker_hand_rankings_short_deck_straight_flush(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_short_deck_straight_flush",
    "poker_hand_rankings_short_deck_straight_flush",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23474, 93),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21534, 77),
    )
)

internal val Res.string.poker_hand_rankings_short_deck_three_of_a_kind: StringResource
  get() = CommonMainString0.poker_hand_rankings_short_deck_three_of_a_kind

private fun init_poker_hand_rankings_short_deck_three_of_a_kind(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_short_deck_three_of_a_kind",
    "poker_hand_rankings_short_deck_three_of_a_kind",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23636, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21676, 78),
    )
)

internal val Res.string.poker_hand_rankings_short_deck_two_pair: StringResource
  get() = CommonMainString0.poker_hand_rankings_short_deck_two_pair

private fun init_poker_hand_rankings_short_deck_two_pair(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_short_deck_two_pair", "poker_hand_rankings_short_deck_two_pair",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23703, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21755, 63),
    )
)

internal val Res.string.poker_hand_rankings_six_low: StringResource
  get() = CommonMainString0.poker_hand_rankings_six_low

private fun init_poker_hand_rankings_six_low(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_six_low", "poker_hand_rankings_six_low",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23771, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21819, 51),
    )
)

internal val Res.string.poker_hand_rankings_straight: StringResource
  get() = CommonMainString0.poker_hand_rankings_straight

private fun init_poker_hand_rankings_straight(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_straight", "poker_hand_rankings_straight",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23910, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21938, 52),
    )
)

internal val Res.string.poker_hand_rankings_straight_flush: StringResource
  get() = CommonMainString0.poker_hand_rankings_straight_flush

private fun init_poker_hand_rankings_straight_flush(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_straight_flush", "poker_hand_rankings_straight_flush",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23827, 82),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21871, 66),
    )
)

internal val Res.string.poker_hand_rankings_three_of_a_kind: StringResource
  get() = CommonMainString0.poker_hand_rankings_three_of_a_kind

private fun init_poker_hand_rankings_three_of_a_kind(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_three_of_a_kind", "poker_hand_rankings_three_of_a_kind",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    23967, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    21991, 67),
    )
)

internal val Res.string.poker_hand_rankings_two_pair: StringResource
  get() = CommonMainString0.poker_hand_rankings_two_pair

private fun init_poker_hand_rankings_two_pair(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_hand_rankings_two_pair", "poker_hand_rankings_two_pair",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24023, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22059, 52),
    )
)

internal val Res.string.poker_high_hand_rank: StringResource
  get() = CommonMainString0.poker_high_hand_rank

private fun init_poker_high_hand_rank(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank", "poker_high_hand_rank",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24756, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22752, 40),
    )
)

internal val Res.string.poker_high_hand_rank_flush: StringResource
  get() = CommonMainString0.poker_high_hand_rank_flush

private fun init_poker_high_hand_rank_flush(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_flush", "poker_high_hand_rank_flush",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24144, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22160, 42),
    )
)

internal val Res.string.poker_high_hand_rank_four_of_a_kind: StringResource
  get() = CommonMainString0.poker_high_hand_rank_four_of_a_kind

private fun init_poker_high_hand_rank_four_of_a_kind(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_four_of_a_kind", "poker_high_hand_rank_four_of_a_kind",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24187, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22203, 63),
    )
)

internal val Res.string.poker_high_hand_rank_full_house: StringResource
  get() = CommonMainString0.poker_high_hand_rank_full_house

private fun init_poker_high_hand_rank_full_house(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_full_house", "poker_high_hand_rank_full_house",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24239, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22267, 55),
    )
)

internal val Res.string.poker_high_hand_rank_high_card: StringResource
  get() = CommonMainString0.poker_high_hand_rank_high_card

private fun init_poker_high_hand_rank_high_card(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_high_card", "poker_high_hand_rank_high_card",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24295, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22323, 50),
    )
)

internal val Res.string.poker_high_hand_rank_pair: StringResource
  get() = CommonMainString0.poker_high_hand_rank_pair

private fun init_poker_high_hand_rank_pair(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_pair", "poker_high_hand_rank_pair",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24350, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22374, 41),
    )
)

internal val Res.string.poker_high_hand_rank_royal_flush: StringResource
  get() = CommonMainString0.poker_high_hand_rank_royal_flush

private fun init_poker_high_hand_rank_royal_flush(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_royal_flush", "poker_high_hand_rank_royal_flush",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24388, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22416, 56),
    )
)

internal val Res.string.poker_high_hand_rank_straight: StringResource
  get() = CommonMainString0.poker_high_hand_rank_straight

private fun init_poker_high_hand_rank_straight(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_straight", "poker_high_hand_rank_straight",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24529, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22537, 49),
    )
)

internal val Res.string.poker_high_hand_rank_straight_flush: StringResource
  get() = CommonMainString0.poker_high_hand_rank_straight_flush

private fun init_poker_high_hand_rank_straight_flush(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_straight_flush", "poker_high_hand_rank_straight_flush",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24449, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22473, 63),
    )
)

internal val Res.string.poker_high_hand_rank_three_of_a_kind: StringResource
  get() = CommonMainString0.poker_high_hand_rank_three_of_a_kind

private fun init_poker_high_hand_rank_three_of_a_kind(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_three_of_a_kind", "poker_high_hand_rank_three_of_a_kind",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24583, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22587, 64),
    )
)

internal val Res.string.poker_high_hand_rank_two_pair: StringResource
  get() = CommonMainString0.poker_high_hand_rank_two_pair

private fun init_poker_high_hand_rank_two_pair(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_two_pair", "poker_high_hand_rank_two_pair",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24636, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22652, 49),
    )
)

internal val Res.string.poker_high_hand_rank_unranked: StringResource
  get() = CommonMainString0.poker_high_hand_rank_unranked

private fun init_poker_high_hand_rank_unranked(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_high_hand_rank_unranked", "poker_high_hand_rank_unranked",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24690, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22702, 49),
    )
)

internal val Res.string.poker_low_hand_rank: StringResource
  get() = CommonMainString0.poker_low_hand_rank

private fun init_poker_low_hand_rank(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_low_hand_rank", "poker_low_hand_rank",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25087, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23039, 39),
    )
)

internal val Res.string.poker_low_hand_rank_eight_low: StringResource
  get() = CommonMainString0.poker_low_hand_rank_eight_low

private fun init_poker_low_hand_rank_eight_low(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_low_hand_rank_eight_low", "poker_low_hand_rank_eight_low",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24809, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22793, 49),
    )
)

internal val Res.string.poker_low_hand_rank_five_low: StringResource
  get() = CommonMainString0.poker_low_hand_rank_five_low

private fun init_poker_low_hand_rank_five_low(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_low_hand_rank_five_low", "poker_low_hand_rank_five_low",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24863, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22843, 48),
    )
)

internal val Res.string.poker_low_hand_rank_seven_low: StringResource
  get() = CommonMainString0.poker_low_hand_rank_seven_low

private fun init_poker_low_hand_rank_seven_low(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_low_hand_rank_seven_low", "poker_low_hand_rank_seven_low",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24916, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22892, 49),
    )
)

internal val Res.string.poker_low_hand_rank_six_low: StringResource
  get() = CommonMainString0.poker_low_hand_rank_six_low

private fun init_poker_low_hand_rank_six_low(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_low_hand_rank_six_low", "poker_low_hand_rank_six_low",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    24970, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22942, 47),
    )
)

internal val Res.string.poker_low_hand_rank_unranked: StringResource
  get() = CommonMainString0.poker_low_hand_rank_unranked

private fun init_poker_low_hand_rank_unranked(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_low_hand_rank_unranked", "poker_low_hand_rank_unranked",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25022, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    22990, 48),
    )
)

internal val Res.string.poker_payout_structure_aggressive: StringResource
  get() = CommonMainString0.poker_payout_structure_aggressive

private fun init_poker_payout_structure_aggressive(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_payout_structure_aggressive", "poker_payout_structure_aggressive",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25139, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23079, 57),
    )
)

internal val Res.string.poker_payout_structure_conservative: StringResource
  get() = CommonMainString0.poker_payout_structure_conservative

private fun init_poker_payout_structure_conservative(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_payout_structure_conservative", "poker_payout_structure_conservative",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25193, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23137, 59),
    )
)

internal val Res.string.poker_payout_structure_moderate: StringResource
  get() = CommonMainString0.poker_payout_structure_moderate

private fun init_poker_payout_structure_moderate(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_payout_structure_moderate", "poker_payout_structure_moderate",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25253, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23197, 51),
    )
)

internal val Res.string.poker_payout_table_title: StringResource
  get() = CommonMainString0.poker_payout_table_title

private fun init_poker_payout_table_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_payout_table_title", "poker_payout_table_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25305, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23249, 56),
    )
)

internal val Res.string.poker_player_hand_action_bet: StringResource
  get() = CommonMainString0.poker_player_hand_action_bet

private fun init_poker_player_hand_action_bet(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_hand_action_bet", "poker_player_hand_action_bet",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25370, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23306, 40),
    )
)

internal val Res.string.poker_player_hand_action_call: StringResource
  get() = CommonMainString0.poker_player_hand_action_call

private fun init_poker_player_hand_action_call(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_hand_action_call", "poker_player_hand_action_call",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25419, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23347, 45),
    )
)

internal val Res.string.poker_player_hand_action_check: StringResource
  get() = CommonMainString0.poker_player_hand_action_check

private fun init_poker_player_hand_action_check(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_hand_action_check", "poker_player_hand_action_check",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25518, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23446, 46),
    )
)

internal val Res.string.poker_player_hand_action_checked: StringResource
  get() = CommonMainString0.poker_player_hand_action_checked

private fun init_poker_player_hand_action_checked(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_hand_action_checked", "poker_player_hand_action_checked",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25469, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23393, 52),
    )
)

internal val Res.string.poker_player_hand_action_fold: StringResource
  get() = CommonMainString0.poker_player_hand_action_fold

private fun init_poker_player_hand_action_fold(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_hand_action_fold", "poker_player_hand_action_fold",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25617, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23541, 45),
    )
)

internal val Res.string.poker_player_hand_action_folded: StringResource
  get() = CommonMainString0.poker_player_hand_action_folded

private fun init_poker_player_hand_action_folded(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_hand_action_folded", "poker_player_hand_action_folded",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25565, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23493, 47),
    )
)

internal val Res.string.poker_player_hand_action_muck: StringResource
  get() = CommonMainString0.poker_player_hand_action_muck

private fun init_poker_player_hand_action_muck(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_hand_action_muck", "poker_player_hand_action_muck",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25667, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23587, 45),
    )
)

internal val Res.string.poker_player_hand_action_raise: StringResource
  get() = CommonMainString0.poker_player_hand_action_raise

private fun init_poker_player_hand_action_raise(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_hand_action_raise", "poker_player_hand_action_raise",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25717, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23633, 50),
    )
)

internal val Res.string.poker_player_hand_action_show: StringResource
  get() = CommonMainString0.poker_player_hand_action_show

private fun init_poker_player_hand_action_show(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_hand_action_show", "poker_player_hand_action_show",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25776, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23684, 45),
    )
)

internal val Res.string.poker_player_hand_action_your_turn: StringResource
  get() = CommonMainString0.poker_player_hand_action_your_turn

private fun init_poker_player_hand_action_your_turn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_hand_action_your_turn", "poker_player_hand_action_your_turn",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25826, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23730, 54),
    )
)

internal val Res.string.poker_player_notification_collected: StringResource
  get() = CommonMainString0.poker_player_notification_collected

private fun init_poker_player_notification_collected(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_notification_collected", "poker_player_notification_collected",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25881, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23785, 55),
    )
)

internal val Res.string.poker_player_notification_from: StringResource
  get() = CommonMainString0.poker_player_notification_from

private fun init_poker_player_notification_from(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_notification_from", "poker_player_notification_from",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25937, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23841, 46),
    )
)

internal val Res.string.poker_player_notification_winner: StringResource
  get() = CommonMainString0.poker_player_notification_winner

private fun init_poker_player_notification_winner(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_notification_winner", "poker_player_notification_winner",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    25980, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23888, 52),
    )
)

internal val Res.string.poker_player_notification_wins: StringResource
  get() = CommonMainString0.poker_player_notification_wins

private fun init_poker_player_notification_wins(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_notification_wins", "poker_player_notification_wins",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26033, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23941, 46),
    )
)

internal val Res.string.poker_player_notification_with: StringResource
  get() = CommonMainString0.poker_player_notification_with

private fun init_poker_player_notification_with(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_player_notification_with", "poker_player_notification_with",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26084, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    23988, 46),
    )
)

internal val Res.string.poker_ring_game_high: StringResource
  get() = CommonMainString0.poker_ring_game_high

private fun init_poker_ring_game_high(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_ring_game_high", "poker_ring_game_high",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26127, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24035, 36),
    )
)

internal val Res.string.poker_ring_game_low: StringResource
  get() = CommonMainString0.poker_ring_game_low

private fun init_poker_ring_game_low(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_ring_game_low", "poker_ring_game_low",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26164, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24072, 31),
    )
)

internal val Res.string.poker_ring_game_medium: StringResource
  get() = CommonMainString0.poker_ring_game_medium

private fun init_poker_ring_game_medium(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_ring_game_medium", "poker_ring_game_medium",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26200, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24104, 34),
    )
)

internal val Res.string.poker_ring_game_micro: StringResource
  get() = CommonMainString0.poker_ring_game_micro

private fun init_poker_ring_game_micro(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_ring_game_micro", "poker_ring_game_micro",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26239, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24139, 37),
    )
)

internal val Res.string.poker_ring_overview_session_summary: StringResource
  get() = CommonMainString0.poker_ring_overview_session_summary

private fun init_poker_ring_overview_session_summary(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_ring_overview_session_summary", "poker_ring_overview_session_summary",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26609, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24501, 63),
    )
)

internal val Res.string.poker_ring_overview_session_summary_buy_in: StringResource
  get() = CommonMainString0.poker_ring_overview_session_summary_buy_in

private fun init_poker_ring_overview_session_summary_buy_in(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_ring_overview_session_summary_buy_in", "poker_ring_overview_session_summary_buy_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26277, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24177, 58),
    )
)

internal val Res.string.poker_ring_overview_session_summary_net_profit: StringResource
  get() = CommonMainString0.poker_ring_overview_session_summary_net_profit

private fun init_poker_ring_overview_session_summary_net_profit(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_ring_overview_session_summary_net_profit",
    "poker_ring_overview_session_summary_net_profit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26336, 74),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24236, 70),
    )
)

internal val Res.string.poker_ring_overview_session_summary_ranking: StringResource
  get() = CommonMainString0.poker_ring_overview_session_summary_ranking

private fun init_poker_ring_overview_session_summary_ranking(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_ring_overview_session_summary_ranking",
    "poker_ring_overview_session_summary_ranking",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26411, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24307, 63),
    )
)

internal val Res.string.poker_ring_overview_session_summary_screen_name: StringResource
  get() = CommonMainString0.poker_ring_overview_session_summary_screen_name

private fun init_poker_ring_overview_session_summary_screen_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_ring_overview_session_summary_screen_name",
    "poker_ring_overview_session_summary_screen_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26483, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24371, 71),
    )
)

internal val Res.string.poker_ring_overview_session_summary_stack: StringResource
  get() = CommonMainString0.poker_ring_overview_session_summary_stack

private fun init_poker_ring_overview_session_summary_stack(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_ring_overview_session_summary_stack", "poker_ring_overview_session_summary_stack",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26551, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24443, 57),
    )
)

internal val Res.string.poker_settings_action_timeout: StringResource
  get() = CommonMainString0.poker_settings_action_timeout

private fun init_poker_settings_action_timeout(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_action_timeout", "poker_settings_action_timeout",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26677, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24565, 57),
    )
)

internal val Res.string.poker_settings_auto_deal_hands: StringResource
  get() = CommonMainString0.poker_settings_auto_deal_hands

private fun init_poker_settings_auto_deal_hands(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_auto_deal_hands", "poker_settings_auto_deal_hands",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26747, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24623, 70),
    )
)

internal val Res.string.poker_settings_blind_level_up: StringResource
  get() = CommonMainString0.poker_settings_blind_level_up

private fun init_poker_settings_blind_level_up(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_blind_level_up", "poker_settings_blind_level_up",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26948, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24808, 57),
    )
)

internal val Res.string.poker_settings_blind_level_up_hand: StringResource
  get() = CommonMainString0.poker_settings_blind_level_up_hand

private fun init_poker_settings_blind_level_up_hand(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_blind_level_up_hand", "poker_settings_blind_level_up_hand",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26834, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24694, 58),
    )
)

internal val Res.string.poker_settings_blind_level_up_time: StringResource
  get() = CommonMainString0.poker_settings_blind_level_up_time

private fun init_poker_settings_blind_level_up_time(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_blind_level_up_time", "poker_settings_blind_level_up_time",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    26893, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24753, 54),
    )
)

internal val Res.string.poker_settings_blind_structure: StringResource
  get() = CommonMainString0.poker_settings_blind_structure

private fun init_poker_settings_blind_structure(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_blind_structure", "poker_settings_blind_structure",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27010, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24866, 58),
    )
)

internal val Res.string.poker_settings_blinds: StringResource
  get() = CommonMainString0.poker_settings_blinds

private fun init_poker_settings_blinds(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_blinds", "poker_settings_blinds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27077, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24925, 37),
    )
)

internal val Res.string.poker_settings_bots: StringResource
  get() = CommonMainString0.poker_settings_bots

private fun init_poker_settings_bots(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_bots", "poker_settings_bots",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27166, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25014, 35),
    )
)

internal val Res.string.poker_settings_bots_number: StringResource
  get() = CommonMainString0.poker_settings_bots_number

private fun init_poker_settings_bots_number(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_bots_number", "poker_settings_bots_number",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27115, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    24963, 50),
    )
)

internal val Res.string.poker_settings_buy_in: StringResource
  get() = CommonMainString0.poker_settings_buy_in

private fun init_poker_settings_buy_in(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_buy_in", "poker_settings_buy_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27202, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25050, 37),
    )
)

internal val Res.string.poker_settings_late_registration: StringResource
  get() = CommonMainString0.poker_settings_late_registration

private fun init_poker_settings_late_registration(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_late_registration", "poker_settings_late_registration",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27390, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25226, 64),
    )
)

internal val Res.string.poker_settings_late_registration_off: StringResource
  get() = CommonMainString0.poker_settings_late_registration_off

private fun init_poker_settings_late_registration_off(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_late_registration_off", "poker_settings_late_registration_off",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27240, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25088, 48),
    )
)

internal val Res.string.poker_settings_late_registration_period_ends: StringResource
  get() = CommonMainString0.poker_settings_late_registration_period_ends

private fun init_poker_settings_late_registration_period_ends(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_late_registration_period_ends",
    "poker_settings_late_registration_period_ends",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27297, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25137, 88),
    )
)

internal val Res.string.poker_settings_payout_structure: StringResource
  get() = CommonMainString0.poker_settings_payout_structure

private fun init_poker_settings_payout_structure(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_payout_structure", "poker_settings_payout_structure",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27519, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25351, 63),
    )
)

internal val Res.string.poker_settings_payout_structure_ranking: StringResource
  get() = CommonMainString0.poker_settings_payout_structure_ranking

private fun init_poker_settings_payout_structure_ranking(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_payout_structure_ranking", "poker_settings_payout_structure_ranking",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27451, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25291, 59),
    )
)

internal val Res.string.poker_settings_rebuy: StringResource
  get() = CommonMainString0.poker_settings_rebuy

private fun init_poker_settings_rebuy(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_rebuy", "poker_settings_rebuy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28202, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25978, 36),
    )
)

internal val Res.string.poker_settings_rebuy_cannot_be_activated: StringResource
  get() = CommonMainString0.poker_settings_rebuy_cannot_be_activated

private fun init_poker_settings_rebuy_cannot_be_activated(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_rebuy_cannot_be_activated", "poker_settings_rebuy_cannot_be_activated",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27591, 200),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25415, 180),
    )
)

internal val Res.string.poker_settings_rebuy_cannot_be_deactivated: StringResource
  get() = CommonMainString0.poker_settings_rebuy_cannot_be_deactivated

private fun init_poker_settings_rebuy_cannot_be_deactivated(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_rebuy_cannot_be_deactivated", "poker_settings_rebuy_cannot_be_deactivated",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27792, 166),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25596, 158),
    )
)

internal val Res.string.poker_settings_rebuy_duration: StringResource
  get() = CommonMainString0.poker_settings_rebuy_duration

private fun init_poker_settings_rebuy_duration(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_rebuy_duration", "poker_settings_rebuy_duration",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    27959, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25755, 49),
    )
)

internal val Res.string.poker_settings_rebuy_limit: StringResource
  get() = CommonMainString0.poker_settings_rebuy_limit

private fun init_poker_settings_rebuy_limit(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_rebuy_limit", "poker_settings_rebuy_limit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28070, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25862, 46),
    )
)

internal val Res.string.poker_settings_rebuy_limit_unlimited: StringResource
  get() = CommonMainString0.poker_settings_rebuy_limit_unlimited

private fun init_poker_settings_rebuy_limit_unlimited(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_rebuy_limit_unlimited", "poker_settings_rebuy_limit_unlimited",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28009, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25805, 56),
    )
)

internal val Res.string.poker_settings_rebuy_period_ends: StringResource
  get() = CommonMainString0.poker_settings_rebuy_period_ends

private fun init_poker_settings_rebuy_period_ends(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_rebuy_period_ends", "poker_settings_rebuy_period_ends",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28117, 84),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    25909, 68),
    )
)

internal val Res.string.poker_settings_ring: StringResource
  get() = CommonMainString0.poker_settings_ring

private fun init_poker_settings_ring(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_ring", "poker_settings_ring",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28288, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26060, 51),
    )
)

internal val Res.string.poker_settings_ring_game: StringResource
  get() = CommonMainString0.poker_settings_ring_game

private fun init_poker_settings_ring_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_ring_game", "poker_settings_ring_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28243, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26015, 44),
    )
)

internal val Res.string.poker_settings_sit_and_go: StringResource
  get() = CommonMainString0.poker_settings_sit_and_go

private fun init_poker_settings_sit_and_go(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_sit_and_go", "poker_settings_sit_and_go",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28356, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26112, 45),
    )
)

internal val Res.string.poker_settings_sng: StringResource
  get() = CommonMainString0.poker_settings_sng

private fun init_poker_settings_sng(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_sng", "poker_settings_sng",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28402, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26158, 50),
    )
)

internal val Res.string.poker_settings_starting_stack: StringResource
  get() = CommonMainString0.poker_settings_starting_stack

private fun init_poker_settings_starting_stack(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_starting_stack", "poker_settings_starting_stack",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28465, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26209, 57),
    )
)

internal val Res.string.poker_settings_table_size: StringResource
  get() = CommonMainString0.poker_settings_table_size

private fun init_poker_settings_table_size(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_table_size", "poker_settings_table_size",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28523, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26267, 49),
    )
)

internal val Res.string.poker_settings_update: StringResource
  get() = CommonMainString0.poker_settings_update

private fun init_poker_settings_update(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_settings_update", "poker_settings_update",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28577, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26317, 37),
    )
)

internal val Res.string.poker_tournament_overview_blinds: StringResource
  get() = CommonMainString0.poker_tournament_overview_blinds

private fun init_poker_tournament_overview_blinds(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_blinds", "poker_tournament_overview_blinds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28911, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26647, 48),
    )
)

internal val Res.string.poker_tournament_overview_blinds_ante: StringResource
  get() = CommonMainString0.poker_tournament_overview_blinds_ante

private fun init_poker_tournament_overview_blinds_ante(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_blinds_ante", "poker_tournament_overview_blinds_ante",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28619, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26355, 53),
    )
)

internal val Res.string.poker_tournament_overview_blinds_blinds: StringResource
  get() = CommonMainString0.poker_tournament_overview_blinds_blinds

private fun init_poker_tournament_overview_blinds_blinds(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_blinds_blinds", "poker_tournament_overview_blinds_blinds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28673, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26409, 55),
    )
)

internal val Res.string.poker_tournament_overview_blinds_duration_hands: StringResource
  get() = CommonMainString0.poker_tournament_overview_blinds_duration_hands

private fun init_poker_tournament_overview_blinds_duration_hands(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_blinds_duration_hands",
    "poker_tournament_overview_blinds_duration_hands",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28729, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26465, 63),
    )
)

internal val Res.string.poker_tournament_overview_blinds_duration_time: StringResource
  get() = CommonMainString0.poker_tournament_overview_blinds_duration_time

private fun init_poker_tournament_overview_blinds_duration_time(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_blinds_duration_time",
    "poker_tournament_overview_blinds_duration_time",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28793, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26529, 62),
    )
)

internal val Res.string.poker_tournament_overview_blinds_level: StringResource
  get() = CommonMainString0.poker_tournament_overview_blinds_level

private fun init_poker_tournament_overview_blinds_level(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_blinds_level", "poker_tournament_overview_blinds_level",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28856, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26592, 54),
    )
)

internal val Res.string.poker_tournament_overview_prizes: StringResource
  get() = CommonMainString0.poker_tournament_overview_prizes

private fun init_poker_tournament_overview_prizes(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_prizes", "poker_tournament_overview_prizes",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29172, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26896, 48),
    )
)

internal val Res.string.poker_tournament_overview_prizes_prize_pool_percentage: StringResource
  get() = CommonMainString0.poker_tournament_overview_prizes_prize_pool_percentage

private fun init_poker_tournament_overview_prizes_prize_pool_percentage(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_prizes_prize_pool_percentage",
    "poker_tournament_overview_prizes_prize_pool_percentage",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    28960, 82),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26696, 82),
    )
)

internal val Res.string.poker_tournament_overview_prizes_prizes: StringResource
  get() = CommonMainString0.poker_tournament_overview_prizes_prizes

private fun init_poker_tournament_overview_prizes_prizes(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_prizes_prizes", "poker_tournament_overview_prizes_prizes",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29043, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26779, 55),
    )
)

internal val Res.string.poker_tournament_overview_prizes_ranking: StringResource
  get() = CommonMainString0.poker_tournament_overview_prizes_ranking

private fun init_poker_tournament_overview_prizes_ranking(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_prizes_ranking", "poker_tournament_overview_prizes_ranking",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29103, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26835, 60),
    )
)

internal val Res.string.poker_tournament_overview_ranking: StringResource
  get() = CommonMainString0.poker_tournament_overview_ranking

private fun init_poker_tournament_overview_ranking(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_ranking", "poker_tournament_overview_ranking",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29481, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27189, 53),
    )
)

internal val Res.string.poker_tournament_overview_ranking_ranking: StringResource
  get() = CommonMainString0.poker_tournament_overview_ranking_ranking

private fun init_poker_tournament_overview_ranking_ranking(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_ranking_ranking", "poker_tournament_overview_ranking_ranking",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29229, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    26945, 61),
    )
)

internal val Res.string.poker_tournament_overview_ranking_rebuy: StringResource
  get() = CommonMainString0.poker_tournament_overview_ranking_rebuy

private fun init_poker_tournament_overview_ranking_rebuy(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_ranking_rebuy", "poker_tournament_overview_ranking_rebuy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29299, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27007, 55),
    )
)

internal val Res.string.poker_tournament_overview_ranking_screen_name: StringResource
  get() = CommonMainString0.poker_tournament_overview_ranking_screen_name

private fun init_poker_tournament_overview_ranking_screen_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_ranking_screen_name",
    "poker_tournament_overview_ranking_screen_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29359, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27063, 69),
    )
)

internal val Res.string.poker_tournament_overview_ranking_stack: StringResource
  get() = CommonMainString0.poker_tournament_overview_ranking_stack

private fun init_poker_tournament_overview_ranking_stack(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_ranking_stack", "poker_tournament_overview_ranking_stack",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29425, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27133, 55),
    )
)

internal val Res.string.poker_tournament_overview_stats: StringResource
  get() = CommonMainString0.poker_tournament_overview_stats

private fun init_poker_tournament_overview_stats(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_stats", "poker_tournament_overview_stats",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29974, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27654, 47),
    )
)

internal val Res.string.poker_tournament_overview_stats_avg_stack: StringResource
  get() = CommonMainString0.poker_tournament_overview_stats_avg_stack

private fun init_poker_tournament_overview_stats_avg_stack(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_stats_avg_stack", "poker_tournament_overview_stats_avg_stack",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29543, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27243, 65),
    )
)

internal val Res.string.poker_tournament_overview_stats_largest_stack: StringResource
  get() = CommonMainString0.poker_tournament_overview_stats_largest_stack

private fun init_poker_tournament_overview_stats_largest_stack(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_stats_largest_stack",
    "poker_tournament_overview_stats_largest_stack",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29617, 81),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27309, 73),
    )
)

internal val Res.string.poker_tournament_overview_stats_prize_pool: StringResource
  get() = CommonMainString0.poker_tournament_overview_stats_prize_pool

private fun init_poker_tournament_overview_stats_prize_pool(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_stats_prize_pool", "poker_tournament_overview_stats_prize_pool",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29699, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27383, 66),
    )
)

internal val Res.string.poker_tournament_overview_stats_rebuys: StringResource
  get() = CommonMainString0.poker_tournament_overview_stats_rebuys

private fun init_poker_tournament_overview_stats_rebuys(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_stats_rebuys", "poker_tournament_overview_stats_rebuys",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29766, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27450, 54),
    )
)

internal val Res.string.poker_tournament_overview_stats_smallest_stack: StringResource
  get() = CommonMainString0.poker_tournament_overview_stats_smallest_stack

private fun init_poker_tournament_overview_stats_smallest_stack(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_stats_smallest_stack",
    "poker_tournament_overview_stats_smallest_stack",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29825, 82),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27505, 74),
    )
)

internal val Res.string.poker_tournament_overview_stats_total_buy_ins: StringResource
  get() = CommonMainString0.poker_tournament_overview_stats_total_buy_ins

private fun init_poker_tournament_overview_stats_total_buy_ins(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_tournament_overview_stats_total_buy_ins",
    "poker_tournament_overview_stats_total_buy_ins",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    29908, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27580, 73),
    )
)

internal val Res.string.poker_variants_omaha: StringResource
  get() = CommonMainString0.poker_variants_omaha

private fun init_poker_variants_omaha(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_variants_omaha", "poker_variants_omaha",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30034, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27702, 48),
    )
)

internal val Res.string.poker_variants_texas: StringResource
  get() = CommonMainString0.poker_variants_texas

private fun init_poker_variants_texas(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_variants_texas", "poker_variants_texas",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30083, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27751, 48),
    )
)

internal val Res.string.poker_watch_live: StringResource
  get() = CommonMainString0.poker_watch_live

private fun init_poker_watch_live(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:poker_watch_live", "poker_watch_live",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27800, 40),
    )
)

internal val Res.string.profile_contact_us: StringResource
  get() = CommonMainString0.profile_contact_us

private fun init_profile_contact_us(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_contact_us", "profile_contact_us",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30132, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27841, 42),
    )
)

internal val Res.string.profile_delete_account: StringResource
  get() = CommonMainString0.profile_delete_account

private fun init_profile_delete_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_delete_account", "profile_delete_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30630, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28319, 50),
    )
)

internal val Res.string.profile_delete_account_cancel: StringResource
  get() = CommonMainString0.profile_delete_account_cancel

private fun init_profile_delete_account_cancel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_delete_account_cancel", "profile_delete_account_cancel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30183, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27884, 45),
    )
)

internal val Res.string.profile_delete_account_confirmation: StringResource
  get() = CommonMainString0.profile_delete_account_confirmation

private fun init_profile_delete_account_confirmation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_delete_account_confirmation", "profile_delete_account_confirmation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30554, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28243, 75),
    )
)

internal val Res.string.profile_delete_account_confirmation_content: StringResource
  get() = CommonMainString0.profile_delete_account_confirmation_content

private fun init_profile_delete_account_confirmation_content(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_delete_account_confirmation_content",
    "profile_delete_account_confirmation_content",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30233, 159),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    27930, 163),
    )
)

internal val Res.string.profile_delete_account_confirmation_privacy_policy: StringResource
  get() = CommonMainString0.profile_delete_account_confirmation_privacy_policy

private fun init_profile_delete_account_confirmation_privacy_policy(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_delete_account_confirmation_privacy_policy",
    "profile_delete_account_confirmation_privacy_policy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30393, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28094, 78),
    )
)

internal val Res.string.profile_delete_account_confirmation_title: StringResource
  get() = CommonMainString0.profile_delete_account_confirmation_title

private fun init_profile_delete_account_confirmation_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_delete_account_confirmation_title", "profile_delete_account_confirmation_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30484, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28173, 69),
    )
)

internal val Res.string.profile_invite_friends: StringResource
  get() = CommonMainString0.profile_invite_friends

private fun init_profile_invite_friends(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_invite_friends", "profile_invite_friends",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30681, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28370, 50),
    )
)

internal val Res.string.profile_sign_out: StringResource
  get() = CommonMainString0.profile_sign_out

private fun init_profile_sign_out(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_sign_out", "profile_sign_out",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30732, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28421, 36),
    )
)

internal val Res.string.profile_text_divider: StringResource
  get() = CommonMainString0.profile_text_divider

private fun init_profile_text_divider(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_text_divider", "profile_text_divider",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30765, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28458, 44),
    )
)

internal val Res.string.rename_dialog_screen_name: StringResource
  get() = CommonMainString0.rename_dialog_screen_name

private fun init_rename_dialog_screen_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:rename_dialog_screen_name", "rename_dialog_screen_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31034, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28723, 57),
    )
)

internal val Res.string.rename_dialog_screen_name_hint: StringResource
  get() = CommonMainString0.rename_dialog_screen_name_hint

private fun init_rename_dialog_screen_name_hint(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:rename_dialog_screen_name_hint", "rename_dialog_screen_name_hint",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30814, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28503, 70),
    )
)

internal val Res.string.rename_dialog_screen_name_min_char: StringResource
  get() = CommonMainString0.rename_dialog_screen_name_min_char

private fun init_rename_dialog_screen_name_min_char(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:rename_dialog_screen_name_min_char", "rename_dialog_screen_name_min_char",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30881, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28574, 66),
    )
)

internal val Res.string.rename_dialog_screen_name_welcome: StringResource
  get() = CommonMainString0.rename_dialog_screen_name_welcome

private fun init_rename_dialog_screen_name_welcome(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:rename_dialog_screen_name_welcome", "rename_dialog_screen_name_welcome",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    30948, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28641, 81),
    )
)

internal val Res.string.ring_summary_buy_in: StringResource
  get() = CommonMainString0.ring_summary_buy_in

private fun init_ring_summary_buy_in(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ring_summary_buy_in", "ring_summary_buy_in",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31088, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28781, 35),
    )
)

internal val Res.string.ring_summary_cashed_out: StringResource
  get() = CommonMainString0.ring_summary_cashed_out

private fun init_ring_summary_cashed_out(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ring_summary_cashed_out", "ring_summary_cashed_out",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31124, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28817, 47),
    )
)

internal val Res.string.ring_summary_leave_game: StringResource
  get() = CommonMainString0.ring_summary_leave_game

private fun init_ring_summary_leave_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ring_summary_leave_game", "ring_summary_leave_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31164, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28865, 39),
    )
)

internal val Res.string.ring_summary_net_profit: StringResource
  get() = CommonMainString0.ring_summary_net_profit

private fun init_ring_summary_net_profit(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ring_summary_net_profit", "ring_summary_net_profit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31204, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28905, 47),
    )
)

internal val Res.string.ring_summary_payments: StringResource
  get() = CommonMainString0.ring_summary_payments

private fun init_ring_summary_payments(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ring_summary_payments", "ring_summary_payments",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31256, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28953, 41),
    )
)

internal val Res.string.ring_summary_player: StringResource
  get() = CommonMainString0.ring_summary_player

private fun init_ring_summary_player(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ring_summary_player", "ring_summary_player",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31302, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    28995, 35),
    )
)

internal val Res.string.ring_summary_rank: StringResource
  get() = CommonMainString0.ring_summary_rank

private fun init_ring_summary_rank(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ring_summary_rank", "ring_summary_rank",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31391, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29072, 33),
    )
)

internal val Res.string.ring_summary_ranking: StringResource
  get() = CommonMainString0.ring_summary_ranking

private fun init_ring_summary_ranking(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ring_summary_ranking", "ring_summary_ranking",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31342, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29031, 40),
    )
)

internal val Res.string.ring_summary_share_now: StringResource
  get() = CommonMainString0.ring_summary_share_now

private fun init_ring_summary_share_now(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ring_summary_share_now", "ring_summary_share_now",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31437, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29106, 38),
    )
)

internal val Res.string.scoreboard_cards: StringResource
  get() = CommonMainString0.scoreboard_cards

private fun init_scoreboard_cards(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_cards", "scoreboard_cards",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31484, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29145, 32),
    )
)

internal val Res.string.scoreboard_game_continues: StringResource
  get() = CommonMainString0.scoreboard_game_continues

private fun init_scoreboard_game_continues(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_game_continues", "scoreboard_game_continues",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31517, 73),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29178, 77),
    )
)

internal val Res.string.scoreboard_hand: StringResource
  get() = CommonMainString0.scoreboard_hand

private fun init_scoreboard_hand(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_hand", "scoreboard_hand",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31591, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29256, 35),
    )
)

internal val Res.string.scoreboard_lost_count: StringResource
  get() = CommonMainString0.scoreboard_lost_count

private fun init_scoreboard_lost_count(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_lost_count", "scoreboard_lost_count",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31627, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29292, 45),
    )
)

internal val Res.string.scoreboard_lost_message: StringResource
  get() = CommonMainString0.scoreboard_lost_message

private fun init_scoreboard_lost_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_lost_message", "scoreboard_lost_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31677, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29338, 63),
    )
)

internal val Res.string.scoreboard_points: StringResource
  get() = CommonMainString0.scoreboard_points

private fun init_scoreboard_points(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_points", "scoreboard_points",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31745, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29402, 33),
    )
)

internal val Res.string.scoreboard_them: StringResource
  get() = CommonMainString0.scoreboard_them

private fun init_scoreboard_them(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_them", "scoreboard_them",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31826, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29483, 31),
    )
)

internal val Res.string.scoreboard_them_points: StringResource
  get() = CommonMainString0.scoreboard_them_points

private fun init_scoreboard_them_points(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_them_points", "scoreboard_them_points",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31779, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29436, 46),
    )
)

internal val Res.string.scoreboard_total: StringResource
  get() = CommonMainString0.scoreboard_total

private fun init_scoreboard_total(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_total", "scoreboard_total",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31858, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29515, 32),
    )
)

internal val Res.string.scoreboard_us: StringResource
  get() = CommonMainString0.scoreboard_us

private fun init_scoreboard_us(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_us", "scoreboard_us",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31936, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29589, 25),
    )
)

internal val Res.string.scoreboard_us_points: StringResource
  get() = CommonMainString0.scoreboard_us_points

private fun init_scoreboard_us_points(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_us_points", "scoreboard_us_points",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31891, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29548, 40),
    )
)

internal val Res.string.scoreboard_won_count: StringResource
  get() = CommonMainString0.scoreboard_won_count

private fun init_scoreboard_won_count(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_won_count", "scoreboard_won_count",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    31966, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29615, 44),
    )
)

internal val Res.string.scoreboard_won_message: StringResource
  get() = CommonMainString0.scoreboard_won_message

private fun init_scoreboard_won_message(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:scoreboard_won_message", "scoreboard_won_message",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32015, 74),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29660, 74),
    )
)

internal val Res.string.screen_title_host_game: StringResource
  get() = CommonMainString0.screen_title_host_game

private fun init_screen_title_host_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:screen_title_host_game", "screen_title_host_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32090, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29735, 42),
    )
)

internal val Res.string.screen_title_join_game: StringResource
  get() = CommonMainString0.screen_title_join_game

private fun init_screen_title_join_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:screen_title_join_game", "screen_title_join_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32141, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29778, 42),
    )
)

internal val Res.string.screen_title_language_preference: StringResource
  get() = CommonMainString0.screen_title_language_preference

private fun init_screen_title_language_preference(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:screen_title_language_preference", "screen_title_language_preference",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32192, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29821, 52),
    )
)

internal val Res.string.screen_title_mode_selection: StringResource
  get() = CommonMainString0.screen_title_mode_selection

private fun init_screen_title_mode_selection(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:screen_title_mode_selection", "screen_title_mode_selection",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32241, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29874, 55),
    )
)

internal val Res.string.settings_choose_language: StringResource
  get() = CommonMainString0.settings_choose_language

private fun init_settings_choose_language(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_choose_language", "settings_choose_language",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32301, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29930, 56),
    )
)

internal val Res.string.settings_dark_mode: StringResource
  get() = CommonMainString0.settings_dark_mode

private fun init_settings_dark_mode(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_dark_mode", "settings_dark_mode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32358, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    29987, 38),
    )
)

internal val Res.string.settings_general_title: StringResource
  get() = CommonMainString0.settings_general_title

private fun init_settings_general_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_general_title", "settings_general_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32401, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30026, 42),
    )
)

internal val Res.string.settings_language: StringResource
  get() = CommonMainString0.settings_language

private fun init_settings_language(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_language", "settings_language",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32440, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30069, 37),
    )
)

internal val Res.string.settings_permission_allowed: StringResource
  get() = CommonMainString0.settings_permission_allowed

private fun init_settings_permission_allowed(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_permission_allowed", "settings_permission_allowed",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32474, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30107, 47),
    )
)

internal val Res.string.settings_permission_not_allowed: StringResource
  get() = CommonMainString0.settings_permission_not_allowed

private fun init_settings_permission_not_allowed(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_permission_not_allowed", "settings_permission_not_allowed",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32522, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30155, 55),
    )
)

internal val Res.string.settings_permissions_title: StringResource
  get() = CommonMainString0.settings_permissions_title

private fun init_settings_permissions_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:settings_permissions_title", "settings_permissions_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32582, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30211, 50),
    )
)

internal val Res.string.sharesheet_invite_friends: StringResource
  get() = CommonMainString0.sharesheet_invite_friends

private fun init_sharesheet_invite_friends(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sharesheet_invite_friends", "sharesheet_invite_friends",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32633, 157),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30262, 149),
    )
)

internal val Res.string.sharesheet_share_game_code: StringResource
  get() = CommonMainString0.sharesheet_share_game_code

private fun init_sharesheet_share_game_code(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sharesheet_share_game_code", "sharesheet_share_game_code",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32791, 138),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30412, 130),
    )
)

internal val Res.string.sign_in_btn_login_apple: StringResource
  get() = CommonMainString0.sign_in_btn_login_apple

private fun init_sign_in_btn_login_apple(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sign_in_btn_login_apple", "sign_in_btn_login_apple",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32930, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30543, 55),
    )
)

internal val Res.string.sign_in_btn_login_fb: StringResource
  get() = CommonMainString0.sign_in_btn_login_fb

private fun init_sign_in_btn_login_fb(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sign_in_btn_login_fb", "sign_in_btn_login_fb",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    32986, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30599, 56),
    )
)

internal val Res.string.sign_in_btn_login_google: StringResource
  get() = CommonMainString0.sign_in_btn_login_google

private fun init_sign_in_btn_login_google(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sign_in_btn_login_google", "sign_in_btn_login_google",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33043, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30656, 60),
    )
)

internal val Res.string.sign_in_btn_login_guest: StringResource
  get() = CommonMainString0.sign_in_btn_login_guest

private fun init_sign_in_btn_login_guest(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sign_in_btn_login_guest", "sign_in_btn_login_guest",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33100, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30717, 51),
    )
)

internal val Res.string.sign_in_login_or: StringResource
  get() = CommonMainString0.sign_in_login_or

private fun init_sign_in_login_or(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:sign_in_login_or", "sign_in_login_or",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33160, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30769, 28),
    )
)

internal val Res.string.table_device_explanation: StringResource
  get() = CommonMainString0.table_device_explanation

private fun init_table_device_explanation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_device_explanation", "table_device_explanation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33189, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30798, 52),
    )
)

internal val Res.string.table_device_start_game: StringResource
  get() = CommonMainString0.table_device_start_game

private fun init_table_device_start_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_device_start_game", "table_device_start_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33242, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30851, 47),
    )
)

internal val Res.string.table_seating_dialog_bot_confirm_button: StringResource
  get() = CommonMainString0.table_seating_dialog_bot_confirm_button

private fun init_table_seating_dialog_bot_confirm_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_dialog_bot_confirm_button", "table_seating_dialog_bot_confirm_button",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33294, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30899, 67),
    )
)

internal val Res.string.table_seating_dialog_bot_dismiss_button: StringResource
  get() = CommonMainString0.table_seating_dialog_bot_dismiss_button

private fun init_table_seating_dialog_bot_dismiss_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_dialog_bot_dismiss_button", "table_seating_dialog_bot_dismiss_button",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33366, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    30967, 59),
    )
)

internal val Res.string.table_seating_dialog_bot_headline: StringResource
  get() = CommonMainString0.table_seating_dialog_bot_headline

private fun init_table_seating_dialog_bot_headline(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_dialog_bot_headline", "table_seating_dialog_bot_headline",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33430, 117),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31027, 109),
    )
)

internal val Res.string.table_seating_dialog_bot_supporting_text: StringResource
  get() = CommonMainString0.table_seating_dialog_bot_supporting_text

private fun init_table_seating_dialog_bot_supporting_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_dialog_bot_supporting_text", "table_seating_dialog_bot_supporting_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33548, 148),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31137, 144),
    )
)

internal val Res.string.table_seating_dialog_player_confirm_button: StringResource
  get() = CommonMainString0.table_seating_dialog_player_confirm_button

private fun init_table_seating_dialog_player_confirm_button(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_dialog_player_confirm_button", "table_seating_dialog_player_confirm_button",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33697, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31282, 58),
    )
)

internal val Res.string.table_seating_dialog_player_headline: StringResource
  get() = CommonMainString0.table_seating_dialog_player_headline

private fun init_table_seating_dialog_player_headline(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_dialog_player_headline", "table_seating_dialog_player_headline",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33756, 84),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31341, 68),
    )
)

internal val Res.string.table_seating_dialog_player_supporting_text: StringResource
  get() = CommonMainString0.table_seating_dialog_player_supporting_text

private fun init_table_seating_dialog_player_supporting_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_dialog_player_supporting_text",
    "table_seating_dialog_player_supporting_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33841, 111),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31410, 99),
    )
)

internal val Res.string.table_seating_open_seat: StringResource
  get() = CommonMainString0.table_seating_open_seat

private fun init_table_seating_open_seat(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_open_seat", "table_seating_open_seat",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33953, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31510, 39),
    )
)

internal val Res.string.table_seating_seat: StringResource
  get() = CommonMainString0.table_seating_seat

private fun init_table_seating_seat(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_seat", "table_seating_seat",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    33993, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31550, 34),
    )
)

internal val Res.string.table_seating_waiting_players_to_join: StringResource
  get() = CommonMainString0.table_seating_waiting_players_to_join

private fun init_table_seating_waiting_players_to_join(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_waiting_players_to_join", "table_seating_waiting_players_to_join",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34032, 89),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31585, 81),
    )
)

internal val Res.string.table_seating_waiting_players_to_take_seat: StringResource
  get() = CommonMainString0.table_seating_waiting_players_to_take_seat

private fun init_table_seating_waiting_players_to_take_seat(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_waiting_players_to_take_seat", "table_seating_waiting_players_to_take_seat",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34122, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31667, 98),
    )
)

internal val Res.string.table_seating_waiting_start: StringResource
  get() = CommonMainString0.table_seating_waiting_start

private fun init_table_seating_waiting_start(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:table_seating_waiting_start", "table_seating_waiting_start",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34233, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31766, 71),
    )
)

internal val Res.string.top_up_dialog_chips: StringResource
  get() = CommonMainString0.top_up_dialog_chips

private fun init_top_up_dialog_chips(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:top_up_dialog_chips", "top_up_dialog_chips",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34305, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31838, 35),
    )
)

internal val Res.string.top_up_dialog_disabled: StringResource
  get() = CommonMainString0.top_up_dialog_disabled

private fun init_top_up_dialog_disabled(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:top_up_dialog_disabled", "top_up_dialog_disabled",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34341, 190),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    31874, 182),
    )
)

internal val Res.string.top_up_dialog_text: StringResource
  get() = CommonMainString0.top_up_dialog_text

private fun init_top_up_dialog_text(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:top_up_dialog_text", "top_up_dialog_text",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34532, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32057, 58),
    )
)

internal val Res.string.top_up_dialog_title: StringResource
  get() = CommonMainString0.top_up_dialog_title

private fun init_top_up_dialog_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:top_up_dialog_title", "top_up_dialog_title",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34599, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32116, 35),
    )
)

internal val Res.string.tournament_summary_leave_game: StringResource
  get() = CommonMainString0.tournament_summary_leave_game

private fun init_tournament_summary_leave_game(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tournament_summary_leave_game", "tournament_summary_leave_game",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34643, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32152, 45),
    )
)

internal val Res.string.tournament_summary_payments: StringResource
  get() = CommonMainString0.tournament_summary_payments

private fun init_tournament_summary_payments(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tournament_summary_payments", "tournament_summary_payments",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34689, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32198, 47),
    )
)

internal val Res.string.tournament_summary_player: StringResource
  get() = CommonMainString0.tournament_summary_player

private fun init_tournament_summary_player(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tournament_summary_player", "tournament_summary_player",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34741, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32246, 41),
    )
)

internal val Res.string.tournament_summary_prize: StringResource
  get() = CommonMainString0.tournament_summary_prize

private fun init_tournament_summary_prize(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tournament_summary_prize", "tournament_summary_prize",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34787, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32288, 40),
    )
)

internal val Res.string.tournament_summary_rank: StringResource
  get() = CommonMainString0.tournament_summary_rank

private fun init_tournament_summary_rank(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tournament_summary_rank", "tournament_summary_rank",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34887, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32376, 39),
    )
)

internal val Res.string.tournament_summary_ranking: StringResource
  get() = CommonMainString0.tournament_summary_ranking

private fun init_tournament_summary_ranking(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tournament_summary_ranking", "tournament_summary_ranking",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34832, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32329, 46),
    )
)

internal val Res.string.tournament_summary_rebuy: StringResource
  get() = CommonMainString0.tournament_summary_rebuy

private fun init_tournament_summary_rebuy(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tournament_summary_rebuy", "tournament_summary_rebuy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34939, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32416, 40),
    )
)

internal val Res.string.tournament_summary_share_now: StringResource
  get() = CommonMainString0.tournament_summary_share_now

private fun init_tournament_summary_share_now(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tournament_summary_share_now", "tournament_summary_share_now",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("pt"),
    ),
    "composeResources/smartdealer.appshared.generated.resources/values-pt/strings.commonMain.cvr",
    34984, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32457, 44),
    )
)

internal val Res.string.web_watch_live: StringResource
  get() = CommonMainString0.web_watch_live

private fun init_web_watch_live(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:web_watch_live", "web_watch_live",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/values/strings.commonMain.cvr",
    32502, 38),
    )
)
