@file:AutoConverters(
    [
        AutoConverter(domainClass = TournamentSnapshot::class, protocolClass = protocol.poker.TournamentSnapshot::class),
        AutoConverter(domainClass = HandSnapshot::class, protocolClass = protocol.poker.HandSnapshot::class),
        AutoConverter(domainClass = HandSnapshotSeat::class, protocolClass = protocol.poker.HandSnapshotSeat::class),
        AutoConverter(domainClass = PlayerSnapshotStack::class, protocolClass = protocol.poker.PlayerSnapshotStack::class),
        AutoConverter(domainClass = RoundSnapshot::class, protocolClass = protocol.poker.RoundSnapshot::class),
        AutoConverter(domainClass = RoundSnapshotSeat::class, protocolClass = protocol.poker.RoundSnapshotSeat::class),
        AutoConverter(domainClass = RingSnapshot::class, protocolClass = protocol.poker.RingSnapshot::class),
    ],
)

package studio.lostjoker.smartdealer.gameplay.protocol.converter

import studio.lostjoker.smartdealer.domain.poker.game.projection.HandSnapshot
import studio.lostjoker.smartdealer.domain.poker.game.projection.HandSnapshotSeat
import studio.lostjoker.smartdealer.domain.poker.game.projection.RoundSnapshotSeat
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerSnapshotStack
import studio.lostjoker.smartdealer.domain.poker.game.projection.PokerClientSnapshot
import studio.lostjoker.smartdealer.domain.poker.game.projection.RingSnapshot
import studio.lostjoker.smartdealer.domain.poker.game.projection.RoundSnapshot
import studio.lostjoker.smartdealer.domain.poker.game.projection.TableSnapshot
import studio.lostjoker.smartdealer.domain.poker.game.projection.TournamentSnapshot
import studio.lostjoker.smartdealer.contract.annotation.AutoConverter
import studio.lostjoker.smartdealer.contract.annotation.AutoConverters

fun PokerClientSnapshot.toProtocol() = protocol.poker.PokerClientSnapshot(
    currentTournament = currentTournament?.toProtocol(),
    currentRing = currentRing?.toProtocol(),
    currentTable = currentTable?.toProtocol(),
    currentHand = currentHand?.toProtocol(),
    currentRound = currentRound?.toProtocol(),
)
fun protocol.poker.PokerClientSnapshot.toDomain() = PokerClientSnapshot(
    currentTournament = currentTournament?.toDomain(),
    currentRing = currentRing?.toDomain(),
    currentTable = currentTable?.toDomain(),
    currentHand = currentHand?.toDomain(),
    currentRound = currentRound?.toDomain(),
)

fun TableSnapshot.toProtocol() = protocol.poker.TableSnapshot(
    seats = seats.mapIndexed { index, seat -> seat.toProtocol(index) }.filterNotNull(),
    tableSize = seats.size,
    playerSeat = playerSeat,
    tableId = tableId,
    stacks = stacks.map { it.toProtocol() },
    pokerSettings = pokerSettings.toProtocol(),
)

fun protocol.poker.TableSnapshot.toDomain() = TableSnapshot(
    seats = (0..tableSize).map { seat ->
        seats.singleOrNull { it.seat == seat }.toDomain()
    },
    playerSeat = playerSeat,
    tableId = tableId,
    stacks = stacks.map { it.toDomain() },
    pokerSettings = pokerSettings.toDomain(),
)
