package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import io.kamel.image.KamelImage
import io.kamel.image.asyncPainterResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun PlayerSeatTableSeating(
    seat: Int,
    player: Player? = null,
    changePlayerSeat: (Int) -> Unit = {},
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .pointerInput(seat) {
                detectTapGestures(
                    onTap = {
                        changePlayerSeat(seat)
                    },
                )
            },
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_0_25, Alignment.CenterVertically),
    ) {
        if (player != null) {
            Text(
                text = stringResource(Res.string.table_seating_seat) + " ${player.seat + 1}",
                style = PokerAppTheme.typography.labelSmall,
                color = Color.Black,
            )
            if (player.avatarUrl != null) {
                KamelImage(
                    resource = asyncPainterResource(player.avatarUrl),
                    contentDescription = "Avatar",
                    contentScale = ContentScale.Crop,
                    modifier = Modifier
                        .size(PokerAppTheme.dimensions.tableSeatingAvatarSize)
                        .clip(CircleShape),
                    onLoading = { progress ->
                        CircularProgressIndicator(
                            progress = { progress },
                        )
                    },
                    onFailure = { _ ->
                        Image(
                            painter = painterResource(Res.drawable.avatar_profile),
                            contentDescription = "Avatar",
                            modifier = Modifier
                                .size(PokerAppTheme.dimensions.tableSeatingAvatarSize)
                                .clip(CircleShape),
                        )
                    },
                )
            } else {
                Image(
                    painter = if (!player.bot) {
                        painterResource(Res.drawable.avatar_profile)
                    } else {
                        painterResource(Res.drawable.avatar_robot)
                    },
                    contentDescription = "Avatar",
                    modifier = Modifier
                        .size(PokerAppTheme.dimensions.tableSeatingAvatarSize)
                        .clip(CircleShape),
                )
            }
        } else {
            TableOpenSeat(
                seat = seat,
                avatarSize = PokerAppTheme.dimensions.tableSeatingAvatarSize,
            )
        }
        Text(
            modifier = Modifier
                .width(PokerAppTheme.dimensions.tableSeatingScreenNameSize),
            text = if (player?.screenName != null) player.screenName else "",
            style = PokerAppTheme.typography.labelSmall,
            maxLines = 1,
            overflow = TextOverflow.Ellipsis,
            color = Color.White,
            textAlign = TextAlign.Center,
        )
    }
}
