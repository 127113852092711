package studio.lostjoker.smartdealer.ui.poker.enum

import org.jetbrains.compose.resources.StringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_game_settings_table_layout_live
import smartdealer.appshared.generated.resources.poker_game_settings_table_layout_none
import smartdealer.appshared.generated.resources.poker_game_settings_table_layout_online

enum class TableLayout(val resource: StringResource) {
    Live(Res.string.poker_game_settings_table_layout_live),
    Online(Res.string.poker_game_settings_table_layout_online),
    Cards(Res.string.poker_game_settings_table_layout_none),
}
