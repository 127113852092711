package studio.lostjoker.smartdealer.ui.poker.host_game.components

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.ui.components.ToggleButton
import studio.lostjoker.smartdealer.ui.components.ToggleButtonOption
import studio.lostjoker.smartdealer.ui.components.ToggleSelection
import studio.lostjoker.smartdealer.ui.components.TwoSplitRow
import studio.lostjoker.smartdealer.ui.poker.enum.ActionTimeout
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun ActionTimeoutSelector(
    actionTimeout: ActionTimeout,
    onChangeActionTimeout: (actionTimeout: ActionTimeout) -> Unit,
) {

    Column(
        modifier = Modifier.fillMaxWidth(),
    ) {
        TwoSplitRow(
            firstColumnContent = {
                Text(
                    text = stringResource(Res.string.poker_settings_action_timeout),
                    style = PokerAppTheme.typography.titleMedium,
                )
            },
            secondColumnContent = {

            },
        )

        Spacer(modifier = Modifier.height(PokerAppTheme.dimensions.grid_0_25))

        ActionTimeToggleButton(
            defaultValue = actionTimeout,
            modifier = Modifier
                .padding(4.dp)
                .height(36.dp)
                .fillMaxWidth(),
        ) {
            onChangeActionTimeout(it)
        }
    }
}

@Composable
internal fun ActionTimeToggleButton(modifier: Modifier, defaultValue: ActionTimeout = ActionTimeout.Minute, onChange: (actionTimeout: ActionTimeout) -> Unit) {

    val value by remember { mutableStateOf(defaultValue) }

    val options = ActionTimeout.entries.map { actionTimeout ->
        when (actionTimeout) {
            ActionTimeout.None -> ToggleButtonOption(
                value = ActionTimeout.None,
                text = stringResource(Res.string.poker_action_timeout_none),
                imageVector = null,
            )

            else -> ToggleButtonOption(
                value = actionTimeout,
                text = stringResource(Res.string.poker_action_timeout_seconds, actionTimeout.seconds),
                imageVector = null,
            )
        }
    }

    ToggleButton(
        options = options.toTypedArray(),
        values = arrayOf(options.first { it.value == value }),
        type = ToggleSelection.Single,
        modifier = modifier,
        onClick = {
            onChange(it.first().value)
        },
    )
}
