package studio.lostjoker.smartdealer.ui.common.dialog

import androidx.compose.material3.AlertDialog
import androidx.compose.material3.Text
import androidx.compose.material3.TextButton
import androidx.compose.runtime.Composable
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
internal fun LeaveGameConfirmationDialog(
    closeDialog: () -> Unit,
    leaveGame: () -> Unit,
) {
    AlertDialog(
        onDismissRequest = {
            closeDialog()
        },
        title = {
            Text(
                text = stringResource(Res.string.poker_dialog_leave_game_title),
                style = PokerAppTheme.typography.titleMedium,
            )
        },
        text = {
            Text(
                text = stringResource(Res.string.poker_dialog_leave_game_text),
                style = PokerAppTheme.typography.labelLarge,
            )
        },
        confirmButton = {
            TextButton(
                onClick = {
                    closeDialog()
                    leaveGame()
                },
            ) {
                Text(
                    text = stringResource(Res.string.poker_dialog_confirm),
                    style = PokerAppTheme.typography.labelLarge,
                )
            }
        },
        dismissButton = {
            TextButton(
                onClick = {
                    closeDialog()
                },
            ) {
                Text(
                    text = stringResource(Res.string.poker_dialog_cancel),
                    style = PokerAppTheme.typography.labelLarge,
                )
            }
        },
    )
}
