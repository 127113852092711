package studio.lostjoker.smartdealer.sharesheet

import androidx.compose.runtime.Composable

//todo: Implemente sharesheet JS
class JsShareSheet : ShareSheetInterface {
    override fun shareText(content: String, title: String?) {

    }

    override fun shareImage(path: String, content: String?, title: String?) {
    }
}

@Composable
actual fun shareSheet() : ShareSheetInterface {
    return JsShareSheet()
}
