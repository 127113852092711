package studio.lostjoker.smartdealer.helpers

enum class Platform {
    ANDROID,
    IOS,
    WEB
}

fun Platform.isMobile() : Boolean {
    return this == Platform.ANDROID || this == Platform.IOS
}

expect fun getPlatform(): Platform
