package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_devices_payments
import smartdealer.appshared.generated.resources.poker_devices_payments_pays
import smartdealer.appshared.generated.resources.poker_devices_payments_transfers
import smartdealer.appshared.generated.resources.poker_devices_payments_transfers_not_available
import smartdealer.appshared.generated.resources.poker_devices_payments_winners
import smartdealer.appshared.generated.resources.poker_devices_payments_winners_not_available
import smartdealer.appshared.generated.resources.poker_devices_payments_wins
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.toFixed2
import studio.lostjoker.smartdealer.getUserLanguage
import studio.lostjoker.smartdealer.helpers.format
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Payment
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Transaction
import studio.lostjoker.smartdealer.ui.poker.enum.GameFormat
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun Payments(
    gameFormat: GameFormat,
    players: List<Player>,
    actualPlayer: Player? = null,
    payments: List<Payment> = emptyList(),
    fractionDigits: Int = 0,
) {
    val userLanguage = getUserLanguage()

    Column(
        modifier = Modifier
            .fillMaxSize()
            .verticalScroll(rememberScrollState()),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        Text(
            modifier = Modifier.padding(vertical = 8.dp),
            text = stringResource(Res.string.poker_devices_payments),
            style = PokerAppTheme.typography.titleLarge,
            fontWeight = FontWeight.Bold,
            color = PokerAppTheme.colors.onBackground,
        )

        Spacer(modifier = Modifier.height(4.dp))

        Text(
            modifier = Modifier.fillMaxWidth().padding(start = 10.dp),
            textAlign = TextAlign.Center,
            text = stringResource(Res.string.poker_devices_payments_winners),
            color = PokerAppTheme.colors.onBackground,
        )

        Spacer(modifier = Modifier.height(PokerAppTheme.dimensions.grid_0_25))

        Column(
            modifier = Modifier
                .padding(top = 4.dp)
                .fillMaxWidth(.9f)
                .background(
                    color = PokerAppTheme.colors.surfaceBright,
                    shape = RoundedCornerShape(10.dp),
                ),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            val filteredAndSortedPlayers = players
                .filter { player ->
                    when (gameFormat) {
                        GameFormat.SitAndGo -> player.prize > 0.toFixed2()
                        GameFormat.RingGame -> player.netProfit > 0.toFixed2()
                    }
                }
                .sortedByDescending { player ->
                    when (gameFormat) {
                        GameFormat.SitAndGo -> player.prize
                        GameFormat.RingGame -> player.netProfit
                    }
                }

            filteredAndSortedPlayers.forEach { player ->
                    Text(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(2.dp),
                        text = "${player.screenName} " + stringResource(Res.string.poker_devices_payments_wins) + " ${
                            when (gameFormat) {
                                GameFormat.SitAndGo -> player.prize
                                GameFormat.RingGame -> player.netProfit
                            }.asDouble().format(digits = fractionDigits, appLanguage = userLanguage)
                        }",
                        textAlign = TextAlign.Center,
                        style = PokerAppTheme.typography.labelLarge,
                        color = if (player.screenName == actualPlayer?.screenName) {
                            PokerAppTheme.colors.primary
                        } else {
                            PokerAppTheme.colors.onSurfaceVariant
                        },
                    )
                }

            if (filteredAndSortedPlayers.isEmpty()) {
                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(2.dp),
                    text = stringResource(Res.string.poker_devices_payments_winners_not_available),
                    textAlign = TextAlign.Center,
                    style = PokerAppTheme.typography.titleSmall,
                    color = PokerAppTheme.colors.onSurfaceVariant,
                )
            }
        }
        Spacer(modifier = Modifier.height(PokerAppTheme.dimensions.grid_0_25))

        Text(
            modifier = Modifier.fillMaxWidth().padding(start = 10.dp),
            textAlign = TextAlign.Center,
            text = stringResource(Res.string.poker_devices_payments_transfers),
            color = PokerAppTheme.colors.onBackground,
        )

        Spacer(modifier = Modifier.height(PokerAppTheme.dimensions.grid_0_25))

        Column(
            modifier = Modifier
                .padding(top = 4.dp)
                .fillMaxWidth(.9f)
                .background(
                    color = PokerAppTheme.colors.surfaceBright,
                    shape = RoundedCornerShape(10.dp),
                ),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            payments.forEach { payment ->
                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(2.dp),
                    text = "${payment.debtorScreenName} " + stringResource(Res.string.poker_devices_payments_pays) + " ${payment.creditorScreenName} ${
                        payment.amount.format(digits = fractionDigits, appLanguage = userLanguage)
                    }",
                    textAlign = TextAlign.Center,
                    style = PokerAppTheme.typography.labelLarge,
                    color = if (payment.debtorScreenName == actualPlayer?.screenName) {
                        PokerAppTheme.colors.primary
                    } else {
                        PokerAppTheme.colors.onSurfaceVariant
                    },
                )
            }
            if (payments.isEmpty()) {
                Text(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(2.dp),
                    text = stringResource(Res.string.poker_devices_payments_transfers_not_available),
                    textAlign = TextAlign.Center,
                    style = PokerAppTheme.typography.titleSmall,
                    color = PokerAppTheme.colors.onSurfaceVariant,
                )
            }
        }
    }
}

fun generatePayments(gameFormat: GameFormat, players: List<Player>, buyIn: Fixed2): List<Payment> {
    val payments: MutableList<Payment> = mutableListOf()

    val transactions = players.map { player ->
        Transaction(
            playerId = player.playerId!!,
            screenName = player.screenName ?: "Unknown",
            amount = when (gameFormat) {
                GameFormat.SitAndGo -> (player.prize - buyIn * (1 + player.rebuy)).asDouble()
                GameFormat.RingGame -> (player.netProfit).asDouble()
            },
        )
    }

    val debtors = transactions.filter { it.amount < 0 }.sortedBy { it.amount }.toMutableList()
    val creditors = transactions.filter { it.amount > 0 }.sortedByDescending { it.amount }.toMutableList()

    while (debtors.isNotEmpty() && creditors.isNotEmpty()) {
        val debtor = debtors[0]
        val creditor = creditors[0]

        val amount = minOf(-debtor.amount, creditor.amount)

        payments += Payment(
            debtorPlayerId =  debtor.playerId,
            debtorScreenName = debtor.screenName,
            creditorPlayerId = creditor.playerId,
            creditorScreenName = creditor.screenName,
            amount = amount,
        )

        debtor.amount += amount
        creditor.amount -= amount

        if (debtor.amount >= 0) debtors.removeAt(0)
        if (creditor.amount <= 0) creditors.removeAt(0)
    }

    return payments
}
