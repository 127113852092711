package studio.lostjoker.smartdealer.helpers

import studio.lostjoker.smartdealer.domain.Card
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView.*
import studio.lostjoker.smartdealer.domain.Rank
import studio.lostjoker.smartdealer.domain.Suit

internal fun String.toCard(color: Int = 0): FaceUp {
    val rank = Rank.bySingleChar[this[0]]
        ?: throw IllegalStateException("wtf is this rank?")
    val suit = Suit.bySingleChar[this[1]]
        ?: throw IllegalStateException("wtf is this suit?")
    return FaceUp(card = Card.Ranked(rank, suit, color))
}

internal fun Collection<String>.mapToCardList(color: Int = 0): List<FaceUp> = this.map { it.toCard(color) }
