package studio.lostjoker.smartdealer.ui.components.card_layout

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.BlendMode
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.unit.Dp
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun CardSingleSidedSquare(
    modifier: Modifier,
    cardFaceValueImage: DrawableResource,
    cardBackgroundColor: Color,
    cardSize: Dp,
    suitImage: DrawableResource,
    suitColor: Color,
    winningCard: Boolean,
    folded: Boolean
) {
    BoxWithConstraints(
        modifier = modifier
            .size(cardSize)
            .border(border = BorderStroke(cardSize * 0.015f, Color.LightGray))
            .background(cardBackgroundColor),
    ) {
        Row {
            Image(
                painter = painterResource(cardFaceValueImage),
                contentDescription = "Card Face Value",
                modifier = Modifier
                    .width(cardSize * 0.6f)
                    .height(cardSize),
                colorFilter = ColorFilter.tint(color = suitColor, blendMode = BlendMode.SrcIn),
            )
            Image(
                painter = painterResource(suitImage),
                contentDescription = "Suit",
                modifier = Modifier
                    .width(cardSize * 0.4f)
                    .height(cardSize),
                colorFilter = ColorFilter.tint(color = suitColor, blendMode = BlendMode.SrcIn),
            )
        }
        if (!winningCard || folded) {
            Column(
                modifier = Modifier
                    .size(cardSize)
                    .background(Color.DarkGray.copy(alpha = 0.5f)),
            ) { /* DO NOTHING */ }
        }
    }
}
