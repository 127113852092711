package studio.lostjoker.smartdealer.preferences

import androidx.compose.runtime.Composable

interface PreferenceDelegate {
    fun init(storageName: String)
    fun putInt(key: String, value: Int)
    fun putBoolean(key: String, value: Boolean)
    fun putString(key: String, value: String)
    fun putLong(key: String, value: Long)
    fun putFloat(key: String, value: Float)
    fun getInt(key: String, defaultValue: Int = 0): Int
    fun getBoolean(key: String, defaultValue: Boolean = false): Boolean
    fun getString(key: String, defaultValue: String? = null): String?
    fun getLong(key: String, defaultValue: Long = 0): Long
    fun getFloat(key: String, defaultValue: Float = Float.NaN): Float
}

@Composable
expect fun rememberUserPreferences(storageName: String) : PreferenceDelegate
