package studio.lostjoker.smartdealer.ui.poker.devices.table.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.graphics.Color
import org.jetbrains.compose.resources.pluralStringResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevel
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevelHandCountRange
import studio.lostjoker.smartdealer.getUserLanguage
import studio.lostjoker.smartdealer.ui.poker.enum.GameFormat
import studio.lostjoker.smartdealer.ui.poker.devices.common.model.getRingGameFormat
import studio.lostjoker.smartdealer.ui.poker.devices.common.model.getTournamentFormat
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun BlindLevel(
    gameFormat: GameFormat,
    currentBlindLevel: BlindLevel = BlindLevel.Empty,
    nextBlindLevel: BlindLevel? = null,
    blindLevelHandCountRange: BlindLevelHandCountRange? = null,
    handCount: Int = 0,
) {
    val userLanguage = getUserLanguage()

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_0_25, Alignment.CenterVertically),
    ) {
        if (gameFormat == GameFormat.SitAndGo) {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
            ) {
                Text(
                    text = stringResource(Res.string.poker_devices_blind_level_up),
                    style = PokerAppTheme.typography.titleMedium,
                    color = Color.Black,
                )
                Text(
                    text = if (blindLevelHandCountRange != null) {
                        val handsLeft = (blindLevelHandCountRange.endExclusive - blindLevelHandCountRange.start) - handCount
                        pluralStringResource(Res.plurals.poker_settings_blind_level_up_hands_left, handsLeft, handsLeft)
                    } else {
                        "Time" // TODO: Implement time-based blind level up
                    },
                    style = PokerAppTheme.typography.titleLarge,
                    color = Color.White,
                )
            }
        }
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
        ) {
            Text(
                text = if (gameFormat == GameFormat.SitAndGo) {
                    stringResource(Res.string.poker_devices_current_blind)
                } else {
                    stringResource(Res.string.poker_devices_blinds)
                },
                style = PokerAppTheme.typography.titleMedium,
                color = Color.Black,
            )
            Text(
                text = when (gameFormat) {
                    GameFormat.RingGame -> currentBlindLevel.getRingGameFormat(appLanguage = userLanguage)
                    GameFormat.SitAndGo -> currentBlindLevel.getTournamentFormat(appLanguage = userLanguage)
                },
                style = PokerAppTheme.typography.titleLarge,
                color = Color.White,
            )
        }
        nextBlindLevel?.let {
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center,
            ) {
                Text(
                    text = stringResource(Res.string.poker_devices_next_blind),
                    style = PokerAppTheme.typography.titleMedium,
                    color = Color.Black,
                )
                Text(
                    text = when (gameFormat) {
                        GameFormat.RingGame -> it.getRingGameFormat(appLanguage = userLanguage)
                        GameFormat.SitAndGo -> it.getTournamentFormat(appLanguage = userLanguage)
                    },
                    style = PokerAppTheme.typography.titleLarge,
                    color = Color.White,
                )
            }
        }
    }
}
