@file:UseSerializers(
    UuidStringSerializer::class,
)

package protocol.poker

import com.benasher44.uuid.Uuid
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import protocol.UuidStringSerializer


@Serializable
sealed interface PokerTableCommand

@Serializable
sealed interface PokerPlayerCommand

@Serializable
data class CreateTournamentCommand(
    override val hostPlayerId: String,
    val tournamentSettings: TournamentSettings,
    val pokerSettings: PokerSettings,
    val botSettings: BotSettings,
) : PokerPlayerCommand, PokerTableCommand, CreatePokerGameCommand

@Serializable
data class UpdateTournamentSettingsCommand(
    val tournamentSettings: TournamentSettings,
    val pokerSettings: PokerSettings,
) : PokerPlayerCommand, PokerTableCommand

@Serializable
data class RegisterPlayerCommand(
    val playerId: String,
    val sessionId: Uuid,
) : PokerPlayerCommand

@Serializable
data class ChangePlayerSeatCommand(
    val playerId: String,
    val seat: Int,
): PokerPlayerCommand

@Serializable
data object StartGameCommand : PokerPlayerCommand, PokerTableCommand

@Serializable
data object PauseGameCommand : PokerPlayerCommand, PokerTableCommand

@Serializable
data object ResumeGameCommand : PokerPlayerCommand, PokerTableCommand

@Serializable
class DealHandCommand(
    val tableId: Uuid,
) : PokerPlayerCommand, PokerTableCommand

@Serializable
data class PlayCommand(
    val tableId: Uuid,
    val requestId: Uuid,
    val action: PlayerHandAction,
): PokerPlayerCommand

@Serializable
data class RevealPlayerCardsCommand(
    val tableId: Uuid,
    val seat: Int,
    val cardIndexes: List<Int>,
): PokerPlayerCommand

@Serializable
data class RebuyCommand(
    val playerId: String,
): PokerPlayerCommand

@Serializable
data class CreateRingGameCommand(
    override val hostPlayerId: String,
    val ringGameSettings: RingGameSettings,
    val pokerSettings: PokerSettings,
    val botSettings: BotSettings,
) : PokerPlayerCommand, PokerTableCommand, CreatePokerGameCommand

@Serializable
data class BuyInCommand(
    val playerId: String,
    val sessionId: Uuid,
) : PokerPlayerCommand

@Serializable
data class TopUpCommand(
    val playerId: String,
    val amount: Fixed2,
): PokerPlayerCommand

@Serializable
data class UpdateRingGameSettingsCommand(
    val ringGameSettings: RingGameSettings,
    val pokerSettings: PokerSettings,
) : PokerPlayerCommand, PokerTableCommand

@Serializable
data object TerminateRingCommand : PokerPlayerCommand, PokerTableCommand
