package studio.lostjoker.smartdealer.helpers

import studio.lostjoker.smartdealer.AppLanguage
import Intl

actual object DecimalFormat {
    actual fun format(double: Double, digits: Int?, appLanguage: AppLanguage?): String {
        val locale = appLanguage?.languageCode ?: "en-US"

        val options = object : Intl.NumberFormatOptions {
            override var minimumFractionDigits: Int? = digits ?: 0
            override var maximumFractionDigits: Int? = digits ?: 2
        }

        val numberFormatter = Intl.NumberFormat(locale, options)

        return numberFormatter.format(double)
    }
}
