package studio.lostjoker.smartdealer.domain.poker

import studio.lostjoker.smartdealer.domain.Card
import studio.lostjoker.smartdealer.domain.Rank

enum class LowHandRank {
    Unranked,
    EightLow,
    SevenLow,
    SixLow,
    FiveLow,
}

enum class HighHandRank {
    Unranked,
    HighCard,
    Pair,
    TwoPair,
    ThreeOfAKind,
    Straight,
    Flush,
    FullHouse,
    FourOfAKind,
    StraightFlush,
}

data class RankedHand<R>(
    val cards: List<Card.Ranked>,
    val rank: R,
)

fun Collection<Card.Ranked>.rankedHighHand(deckStart: Rank): RankedHand<HighHandRank> {
    if (isEmpty()) return RankedHand(emptyList(), HighHandRank.Unranked)
    val ranks = this.groupBy { it.rank }
    val suits = this.groupBy { it.suit }

    val twoOfAKind = ranks.filter { g -> g.value.size == 2 }
    val threeOfAKind = ranks.filter { g -> g.value.size == 3 }
    val fourOfAKind = ranks.filter { g -> g.value.size == 4 }

    // four of a kind
    if (fourOfAKind.size == 1) {
        val fourCards = fourOfAKind.entries.single().value
        val kicker = this - fourCards.toSet()
        return RankedHand(fourCards + kicker, HighHandRank.FourOfAKind)
    }

    // flush or straight flush
    if (suits.size == 1 && ranks.size == 5) {
        val cards = suits.entries.single().value.sortedByDescending { it.rank }
        val rank = if (cards.isStraight(deckStart)) HighHandRank.StraightFlush else HighHandRank.Flush
        return RankedHand(cards.ensureAceBeforeLowerRank(deckStart), rank)
    }

    // full house
    if (threeOfAKind.size == 1 && twoOfAKind.size == 1) {
        val threeCards = threeOfAKind.entries.single().value
        val pairCards = twoOfAKind.entries.single().value
        return RankedHand(threeCards + pairCards, HighHandRank.FullHouse)
    }

    // straight
    if (ranks.size == 5) {
        val cards = this.sortedByDescending { it.rank }
        if (cards.isStraight(deckStart)) {
            return RankedHand(cards.ensureAceBeforeLowerRank(deckStart), HighHandRank.Straight)
        }
    }

    // three of a kind
    if (threeOfAKind.size == 1) {
        val threeCards = threeOfAKind.entries.single().value
        val otherCards = this - threeCards.toSet()
        return RankedHand(threeCards + otherCards.sortedByDescending { it.rank }, HighHandRank.ThreeOfAKind)
    }

    // two pair
    if (twoOfAKind.size == 2) {
        val pair1Cards = twoOfAKind.entries.first().value
        val pair2Cards = twoOfAKind.entries.last().value
        val kicker = this - pair1Cards.toSet() - pair2Cards.toSet()
        return if (pair1Cards[0].rank > pair2Cards[0].rank) {
            RankedHand(pair1Cards + pair2Cards + kicker, HighHandRank.TwoPair)
        } else {
            RankedHand(pair2Cards + pair1Cards + kicker, HighHandRank.TwoPair)
        }
    }

    // pair
    if (twoOfAKind.size == 1) {
        val pairCards = twoOfAKind.entries.single().value
        val otherCards = this - pairCards.toSet()
        return RankedHand(pairCards + otherCards.sortedByDescending { it.rank }, HighHandRank.Pair)
    }

    return RankedHand(this.sortedByDescending { it.rank }, HighHandRank.HighCard)
}

private fun List<Card.Ranked>.isStraight(deckStart: Rank): Boolean {
    return this.windowed(2).all { it[0].rank.ordinal - 1 == it[1].rank.ordinal } ||
            this[0].rank == Rank.A && this[4].rank == deckStart && this.drop(1).isStraight(deckStart)
}

private fun List<Card.Ranked>.ensureAceBeforeLowerRank(deckStart: Rank): List<Card.Ranked> {
    return if (this[0].rank == Rank.A && this[4].rank == deckStart) this.drop(1) + this[0] else this
}

fun Collection<Card.Ranked>.rankedLowHand(deckStart: Rank): RankedHand<LowHandRank> {
    if (isEmpty()) return RankedHand(emptyList(), LowHandRank.Unranked)

    // cards ranked higher than eight
    if (this.count { it.rank > Rank.Eight && it.rank != Rank.A } > 0) return RankedHand(emptyList(), LowHandRank.Unranked)

    // paired cards
    if (this.distinctBy { it.rank }.count() < 5) return RankedHand(emptyList(), LowHandRank.Unranked)

    val cards = this.sortedByDescending { it.rank }.ensureAceAlwaysBeforeLowerRank()

    return when (cards[0].rank) {
        Rank.Eight -> RankedHand(cards, LowHandRank.EightLow)
        Rank.Seven -> RankedHand(cards, LowHandRank.SevenLow)
        Rank.Six -> RankedHand(cards, LowHandRank.SixLow)
        else -> RankedHand(cards, LowHandRank.FiveLow)
    }
}

private fun List<Card.Ranked>.ensureAceAlwaysBeforeLowerRank(): List<Card.Ranked> {
    return if (this[0].rank == Rank.A) this.drop(1) + this[0] else this
}
