package service

import io.ktor.client.utils.*
import io.ktor.utils.io.errors.*
import io.ktor.websocket.*
import kotlinx.coroutines.channels.ClosedReceiveChannelException
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

abstract class AbstractSessionService<E, C, S>(
    socketUrl: String,
) : AbstractSocketSessionHolder(socketUrl), SessionService<E, C, S> {
    protected abstract suspend fun receiveSnapshot(): S
    protected abstract suspend fun receiveEvent(): E
    protected abstract suspend fun sendCommand(command: C)

    override suspend fun connect(): S {
        try {
            open()
            return receiveSnapshot()
        } catch (e: Exception) {
            translateException(e)
        }
    }

    override val events: Flow<E> = flow {
        while (true) {
            val event = try {
                receiveEvent()
            } catch (e: ClosedReceiveChannelException) {
                break
            } catch (e: Exception) {
                translateException(e)
            }
            emit(event)
        }
    }

    override suspend fun send(command: C) {
        try {
            sendCommand(command)
        } catch (e: Exception) {
            translateException(e)
        }
    }

    private fun translateException(e: Exception): Nothing {
        when (val cause = e.unwrapCancellationException()) {
            // I don't think the IO exception should be wrapped in a cancellation exception, we need to investigate this further
            is IOException -> throw RetryableSessionException(cause)
            else -> throw e
        }
    }
}
