package protocol.poker

import kotlinx.serialization.Serializable
import protocol.Card
import protocol.Rank
import kotlin.time.Duration

typealias Fixed2 = Long

@Serializable
data class BlindLevel(
    val ante: Long,
    val smallBlind: Long,
    val bigBlind: Long,
)

@Serializable
data class BlindLevelTimeSlotDuration(
    val blindLevel: BlindLevel,
    val timeSlot: Duration,
)

@Serializable
data class BlindLevelHandCountDuration(
    val blindLevel: BlindLevel,
    val handCount: Int,
)

@Serializable
data class BlindLevelHandCountRange(
    val start: Int,
    val endExclusive: Int,
)

@Serializable
enum class PokerVariant {
    Texas,
    Omaha,
}

@Serializable
enum class HighLowMode {
    High,
    HighLow,
}

@Serializable
enum class BettingLimit {
    NoLimit,
    PotLimit,
    FixedLimit,
}

@Serializable
sealed interface BlindProgression {
    @Serializable
    data class Static(val blindLevel: BlindLevel) : BlindProgression

    @Serializable
    data class HandCount(val levels: List<BlindLevelHandCountDuration>) : BlindProgression

    @Serializable
    data class TimeSlot(val levels: List<BlindLevelTimeSlotDuration>) : BlindProgression
}


@Serializable
data class PokerSettings(
    val variant: PokerVariant,
    val highLowMode: HighLowMode,
    val bettingLimit: BettingLimit,
    val tableSize: Int,
    val deckStart: Rank,
    val actionTimeout: Int,
    val blindProgression: BlindProgression,
)

@Serializable
enum class LowHandRank {
    Unranked,
    EightLow,
    SevenLow,
    SixLow,
    FiveLow,
}

@Serializable
enum class HighHandRank {
    Unranked,
    HighCard,
    Pair,
    TwoPair,
    ThreeOfAKind,
    Straight,
    Flush,
    FullHouse,
    FourOfAKind,
    StraightFlush,
}

@Serializable
data class RankedHighHand(
    val cards: List<Card.Ranked>,
    val rank: HighHandRank,
)

@Serializable
data class RankedLowHand(
    val cards: List<Card.Ranked>,
    val rank: LowHandRank,
)


@Serializable
data class Positions(
    val dealerSeat: Int,
    val smallBlindSeat: Int?,
    val bigBlindSeat: Int,
    val utgSeat: Int?,
    val utg1Seat: Int?,
    val mp1Seat: Int?,
    val mp2Seat: Int?,
    val mp3Seat: Int?,
    val hijackSeat: Int?,
    val cutoffSeat: Int?,
)

@Serializable
data class SeatSnapshot(
    val seat: Int,
    val stack: Fixed2,
    val folded: Boolean,
)

@Serializable
sealed interface PlayerHandAction {

    @Serializable
    data class PostSmallBlind(val amount: Fixed2) : PlayerHandAction

    @Serializable
    data class PostBigBlind(val amount: Fixed2) : PlayerHandAction

    @Serializable
    data class Call(val amount: Fixed2) : PlayerHandAction

    @Serializable
    data object Check : PlayerHandAction

    @Serializable
    data object Fold : PlayerHandAction

    @Serializable
    data class Bet(val amount: Fixed2) : PlayerHandAction

    @Serializable
    data class Raise(val amount: Fixed2) : PlayerHandAction
}


@Serializable
sealed interface PlayerHandAvailableAction {
    @Serializable
    data class PostSmallBlind(val amount: Fixed2) : PlayerHandAvailableAction

    @Serializable
    data class PostBigBlind(val amount: Fixed2) : PlayerHandAvailableAction

    @Serializable
    data class Call(val amount: Fixed2) : PlayerHandAvailableAction

    @Serializable
    data object Check : PlayerHandAvailableAction

    @Serializable
    data object Fold : PlayerHandAvailableAction

    @Serializable
    data class Bet(val min: Fixed2, val max: Fixed2) : PlayerHandAvailableAction

    @Serializable
    data class Raise(val min: Fixed2, val max: Fixed2) : PlayerHandAvailableAction

}
@Serializable
data class Pot(
    val amount: Fixed2,
    val seats: List<Int>,
)

@Serializable
data class SeatRoundResult(
    val seat: Int,
    val folded: Boolean,
    val betAmount: Fixed2,
    val uncalledBetAmount: Fixed2,
)

@Serializable
data class SeatHandResult(
    val seat: Int,
    val folded: Boolean,
    val betAmount: Fixed2,
    val winAmount: Fixed2,
)

@Serializable
enum class HighLow {
    High,
    Low,
}

@Serializable
data class PotSharePlayerProjection(
    val seat: Int,
    val pot: Int,
    val run: Int,
    val highLow: HighLow,
    val hand: List<Card.Ranked>?,
    val amount: Fixed2,
)

@Serializable
data class BotSettings(
    val allowBots: Boolean,
    val numberOfBots: Int,
)
