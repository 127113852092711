package studio.lostjoker.smartdealer.ui.poker.devices.player.components

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Switch
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_game_settings_table_layout_vertical_display
import smartdealer.appshared.generated.resources.poker_game_settings_table_layout_vertical_display_description
import studio.lostjoker.smartdealer.ui.poker.enum.TableLayout
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun TableLayout(
    tableLayout: TableLayout,
    changeTableLayout: (tableLayout: TableLayout) -> Unit = {},
) {
    var selected by remember { mutableStateOf(tableLayout) }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight(0.4f)
            .wrapContentHeight()
            .padding(vertical = 8.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_1, Alignment.CenterVertically),
    ) {
        TableLayout.entries.forEach { tableLayout ->
            Card(
                modifier = Modifier
                    .width(200.dp)
                    .height(50.dp),
                onClick = {
                    changeTableLayout(tableLayout)
                    selected = tableLayout
                },
                shape = MaterialTheme.shapes.small,
                elevation = CardDefaults.cardElevation(defaultElevation = 4.dp),
                colors = CardDefaults.cardColors(
                    containerColor = if (selected == tableLayout) PokerAppTheme.colors.surfaceBright else PokerAppTheme.colors.surfaceVariant,
                    contentColor = if (selected == tableLayout) PokerAppTheme.colors.onSurface else PokerAppTheme.colors.onSurfaceVariant,
                ),
            ) {
                Row(
                    modifier = Modifier.fillMaxSize(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween,
                ) {
                    Text(
                        modifier = Modifier
                            .fillMaxWidth(0.3f)
                            .padding(5.dp),
                        text = stringResource(tableLayout.resource),
                        style = PokerAppTheme.typography.labelSmall,
                    )
                    Box(
                        modifier = Modifier
                            .fillMaxWidth(0.7f)
                            .fillMaxHeight(0.8f)
                            .padding(end = 5.dp)
                            .clip(MaterialTheme.shapes.small)
                            .background(Color.Transparent),
                    ) {
                        // TODO: Include an image for each table layout option
                    }
                }
            }
        }
    }
}

@Composable
fun TableLayout(
    verticalDisplay: Boolean,
    changeVerticalDisplay: (verticalDisplay: Boolean) -> Unit = {},
) {
    var selected by remember { mutableStateOf(verticalDisplay) }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight(0.4f)
            .wrapContentHeight()
            .padding(vertical = 8.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_1, Alignment.CenterVertically),
    ) {
        Card(
            modifier = Modifier
                .width(500.dp)
                .height(100.dp),
            shape = MaterialTheme.shapes.small,
            elevation = CardDefaults.cardElevation(defaultElevation = 4.dp),
            colors = CardDefaults.cardColors(
                containerColor = if (selected) PokerAppTheme.colors.surfaceBright else PokerAppTheme.colors.surfaceVariant,
                contentColor = if (selected) PokerAppTheme.colors.onSurface else PokerAppTheme.colors.onSurfaceVariant,
            ),
        ) {
            Row(
                modifier = Modifier.fillMaxSize(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween,
            ) {
                Column(
                    modifier = Modifier
                        .padding(5.dp),
                ) {
                    Row (
                        modifier = Modifier
                            .fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        Text(
                            text = stringResource(Res.string.poker_game_settings_table_layout_vertical_display),
                            style = PokerAppTheme.typography.labelMedium,
                            color = if (selected) PokerAppTheme.colors.onSurface else PokerAppTheme.colors.onSurfaceVariant,
                        )
                        Spacer(
                            modifier = Modifier
                                .fillMaxWidth()
                                .weight(1f),
                        )
                        Switch(
                            modifier = Modifier
                                .padding(horizontal = 5.dp),
                            checked = selected,
                            onCheckedChange = {
                                selected = it
                                changeVerticalDisplay(it)
                            },
                        )
                    }
                    Text(
                        text = stringResource(Res.string.poker_game_settings_table_layout_vertical_display_description),
                        style = PokerAppTheme.typography.labelSmall,
                        color = if (selected) PokerAppTheme.colors.onSurface else PokerAppTheme.colors.onSurfaceVariant,
                    )
                }
            }
        }
    }
}
