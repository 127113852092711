package studio.lostjoker.smartdealer.ui.poker.devices.table.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import io.kamel.image.KamelImage
import io.kamel.image.asyncPainterResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.domain.toFixed2
import studio.lostjoker.smartdealer.getUserLanguage
import studio.lostjoker.smartdealer.helpers.Platform
import studio.lostjoker.smartdealer.helpers.format
import studio.lostjoker.smartdealer.helpers.getPlatform
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorButtonContainer
import studio.lostjoker.smartdealer.ui.poker.common.helpers.colorButtonContent
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun RingSummary(
    players: List<Player>,
    titleTextStyle: TextStyle = PokerAppTheme.typography.titleLarge,
    descriptionTextStyle: TextStyle = PokerAppTheme.typography.bodyLarge,
    buttonTextStyle: TextStyle = PokerAppTheme.typography.labelLarge,
    payments: () -> Unit = {},
    shareNow: () -> Unit = {},
    leaveGame: () -> Unit = {},
) {
    val platform = getPlatform()
    val userLanguage = getUserLanguage()

    Column(
        modifier = Modifier.fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,
    ) {
        Image(
            modifier = Modifier
                .padding(PokerAppTheme.dimensions.grid_1)
                .fillMaxWidth(0.9f),
            painter = painterResource(Res.drawable.logo_long_text_horizontal_white),
            contentDescription = null,
            contentScale = ContentScale.Fit,
            alpha = 0.7f,
        )
        Column(
            modifier = Modifier
                .fillMaxWidth(0.9f)
                .weight(1f),
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .weight(1 / 13f),
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .fillMaxHeight(1f)
                        .weight(0.15f),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center,
                ) {
                    Text(
                        text = stringResource(Res.string.ring_summary_rank),
                        color = Color.Black,
                        style = titleTextStyle,
                    )
                }
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .fillMaxHeight(1f)
                        .weight(0.10f),
                    horizontalAlignment = Alignment.Start,
                    verticalArrangement = Arrangement.Center,
                ) {
                    Text(
                        text = "#",
                        color = Color.Black,
                        style = titleTextStyle,
                    )
                }
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .fillMaxHeight(1f)
                        .weight(0.30f),
                    horizontalAlignment = Alignment.Start,
                    verticalArrangement = Arrangement.Center,
                ) {
                    Text(
                        text = stringResource(Res.string.ring_summary_player),
                        color = Color.Black,
                        style = titleTextStyle,
                    )
                }
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .fillMaxHeight(1f)
                        .weight(0.175f),
                    horizontalAlignment = Alignment.Start,
                    verticalArrangement = Arrangement.Center,
                ) {
                    Text(
                        text = stringResource(Res.string.ring_summary_buy_in),
                        color = Color.Black,
                        style = titleTextStyle,
                    )
                }
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .fillMaxHeight(1f)
                        .weight(0.225f),
                    horizontalAlignment = Alignment.Start,
                    verticalArrangement = Arrangement.Center,
                ) {
                    Text(
                        text = stringResource(Res.string.ring_summary_cashed_out),
                        color = Color.Black,
                        style = titleTextStyle,
                    )
                }
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .fillMaxHeight(1f)
                        .weight(0.225f),
                    horizontalAlignment = Alignment.Start,
                    verticalArrangement = Arrangement.Center,
                ) {
                    Text(
                        text = stringResource(Res.string.ring_summary_net_profit),
                        color = Color.Black,
                        style = titleTextStyle,
                    )
                }
            }
            HorizontalDivider(modifier = Modifier.fillMaxWidth(), thickness = 1.dp, color = Color.LightGray)
            // TODO use a separate array in the state for the summary that stores the data in a similar way it came in the events
            players.sortedByDescending { it.netProfit }.forEachIndexed { index, player ->
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .weight(1 / 13f),
                ) {
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .fillMaxHeight(1f)
                            .weight(0.15f),
                        horizontalAlignment = Alignment.CenterHorizontally,
                        verticalArrangement = Arrangement.Center,
                    ) {
                        val prizeImage = when (index + 1) {
                            1 -> Res.drawable.prize_first
                            2 -> Res.drawable.prize_second
                            3 -> Res.drawable.prize_third
                            else -> null
                        }
                        prizeImage?.let {
                            Image(
                                modifier = Modifier
                                    .padding(PokerAppTheme.dimensions.grid_0_5),
                                painter = painterResource(prizeImage),
                                contentDescription = null,
                            )
                        }
                    }
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .fillMaxHeight(1f)
                            .weight(0.10f),
                        horizontalAlignment = Alignment.Start,
                        verticalArrangement = Arrangement.Center,
                    ) {
                        Text(
                            text = (index + 1).toString(),
                            color = Color.White,
                            style = descriptionTextStyle,
                        )
                    }
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .fillMaxHeight(1f)
                            .weight(0.30f),
                        horizontalAlignment = Alignment.Start,
                        verticalArrangement = Arrangement.Center,
                    ) {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_0_5, Alignment.Start),
                        ) {
                            if (player.avatarUrl != null) {
                                KamelImage(
                                    modifier = Modifier
                                        .clip(CircleShape)
                                        .size(PokerAppTheme.dimensions.grid_3),
                                    resource = asyncPainterResource(player.avatarUrl),
                                    contentDescription = "Avatar",
                                    contentScale = ContentScale.Crop,
                                    onLoading = { progress ->
                                        CircularProgressIndicator(
                                            progress = { progress },
                                        )
                                    },
                                    onFailure = { _ ->
                                        Image(
                                            painter = painterResource(Res.drawable.avatar_profile),
                                            contentDescription = "Avatar",
                                            modifier = Modifier
                                                .clip(CircleShape)
                                                .size(PokerAppTheme.dimensions.grid_3),
                                        )
                                    },
                                )
                            } else {
                                Image(
                                    painter = if (!player.bot) {
                                        painterResource(Res.drawable.avatar_profile)
                                    } else {
                                        painterResource(Res.drawable.avatar_robot)
                                    },
                                    contentDescription = "Avatar",
                                    modifier = Modifier
                                        .clip(CircleShape)
                                        .size(PokerAppTheme.dimensions.grid_3),
                                )
                            }
                            Text(
                                text = player.screenName ?: stringResource(Res.string.loading_screen_name),
                                color = Color.White,
                                style = descriptionTextStyle,
                                overflow = TextOverflow.Ellipsis,
                            )
                        }
                    }
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .fillMaxHeight(1f)
                            .weight(0.175f),
                        horizontalAlignment = Alignment.Start,
                        verticalArrangement = Arrangement.Center,
                    ) {
                        Text(
                            text = player.buyIn.asDouble().format(2, appLanguage = userLanguage),
                            color = Color.White,
                            style = descriptionTextStyle,
                        )
                    }
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .fillMaxHeight(1f)
                            .weight(0.225f),
                        horizontalAlignment = Alignment.Start,
                        verticalArrangement = Arrangement.Center,
                    ) {
                        Text(
                            text = player.remainingStack.asDouble().format(2, appLanguage = userLanguage),
                            color = Color.White,
                            style = descriptionTextStyle,
                        )
                    }
                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .fillMaxHeight(1f)
                            .weight(0.225f),
                        horizontalAlignment = Alignment.Start,
                        verticalArrangement = Arrangement.Center,
                    ) {
                        Text(
                            text = player.netProfit.asDouble().format(2, appLanguage = userLanguage),
                            color = if (player.netProfit < 0.toFixed2()) Color.Red else Color.White,
                            style = descriptionTextStyle,
                        )
                    }
                }
                HorizontalDivider(modifier = Modifier.fillMaxWidth(), thickness = 1.dp, color = Color.LightGray)
            }
            repeat(10 - players.size) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .weight(1 / 13f),
                ) {
                    // empty row
                }
                HorizontalDivider(modifier = Modifier.fillMaxWidth(),  thickness = 1.dp, color = Color.LightGray)
            }
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .weight(2 / 13f)
                    .padding(vertical = PokerAppTheme.dimensions.grid_0_25),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.spacedBy(space = PokerAppTheme.dimensions.grid_0_5, alignment = Alignment.CenterHorizontally),
            ) {
                Button(
                    onClick = { payments() },
                    colors = ButtonDefaults.buttonColors(
                        containerColor = colorButtonContainer,
                        contentColor = colorButtonContent,
                    ),
                ) {
                    Text(
                        text = stringResource(Res.string.ring_summary_payments),
                        style = buttonTextStyle,
                    )
                }
                if (platform != Platform.WEB) {
                    Button(
                        onClick = { shareNow() },
                        colors = ButtonDefaults.buttonColors(
                            containerColor = colorButtonContainer,
                            contentColor = colorButtonContent,
                        ),
                    ) {
                        Text(
                            text = stringResource(Res.string.ring_summary_share_now),
                            style = buttonTextStyle,
                        )
                    }
                }
                Button(
                    onClick = { leaveGame() },
                    colors = ButtonDefaults.buttonColors(
                        containerColor = colorButtonContainer,
                        contentColor = colorButtonContent,
                    ),
                ) {
                    Text(
                        text = stringResource(Res.string.ring_summary_leave_game),
                        style = buttonTextStyle,
                    )
                }
            }
        }
    }
}
