package studio.lostjoker.smartdealer.ui.theme

import androidx.compose.material3.darkColorScheme
import androidx.compose.material3.lightColorScheme
import androidx.compose.ui.graphics.Color

val md_theme_light_primary = Color(0xFF006E2A)
val md_theme_light_onPrimary = Color(0xFFFFFFFF)
val md_theme_light_primaryContainer = Color(0xFF6AFF87)
val md_theme_light_onPrimaryContainer = Color(0xFF002107)
val md_theme_light_secondary = Color(0xFF006874)
val md_theme_light_onSecondary = Color(0xFFFFFFFF)
val md_theme_light_secondaryContainer = Color(0xFF97F0FF)
val md_theme_light_onSecondaryContainer = Color(0xFF001F24)
val md_theme_light_tertiary = Color(0xFF286C2A)
val md_theme_light_onTertiary = Color(0xFFFFFFFF)
val md_theme_light_tertiaryContainer = Color(0xFFABF5A3)
val md_theme_light_onTertiaryContainer = Color(0xFF002203)
val md_theme_light_error = Color(0xFFBA1A1A)
val md_theme_light_errorContainer = Color(0xFFFFDAD6)
val md_theme_light_onError = Color(0xFFFFFFFF)
val md_theme_light_onErrorContainer = Color(0xFF410002)
val md_theme_light_background = Color(0xFFF5FFF3)
val md_theme_light_onBackground = Color(0xFF00210E)
val md_theme_light_surface = Color(0xFFF5FFF3)
val md_theme_light_onSurface = Color(0xFF00210E)
val md_theme_light_surfaceVariant = Color(0xFFDEE5D9)
val md_theme_light_onSurfaceVariant = Color(0xFF424940)
val md_theme_light_outline = Color(0xFF727970)
val md_theme_light_inverseOnSurface = Color(0xFFC2FFD0)
val md_theme_light_inverseSurface = Color(0xFF00391C)
val md_theme_light_inversePrimary = Color(0xFF3FE26A)
val md_theme_light_shadow = Color(0xFF000000)
val md_theme_light_surfaceTint = Color(0xFF006E2A)
val md_theme_light_outlineVariant = Color(0xFFC1C9BE)
val md_theme_light_scrim = Color(0xFF000000)
val md_theme_light_surface_bright = Color(0xFFDEF1E0)
val md_theme_light_surface_container = md_theme_light_surface_bright
val md_theme_light_surface_container_high = md_theme_light_surface_bright
val md_theme_light_surface_container_highest = md_theme_light_surface_bright
val md_theme_light_surface_container_low = md_theme_light_background
val md_theme_light_surface_container_lowest = md_theme_light_surface_bright
val md_theme_light_surface_dim = md_theme_light_surface_bright

val md_theme_dark_primary = Color(0xFF3FE26A)
val md_theme_dark_onPrimary = Color(0xFF003912)
val md_theme_dark_primaryContainer = Color(0xFF00531D)
val md_theme_dark_onPrimaryContainer = Color(0xFF6AFF87)
val md_theme_dark_secondary = Color(0xFF4FD8EB)
val md_theme_dark_onSecondary = Color(0xFF00363D)
val md_theme_dark_secondaryContainer = Color(0xFF004F58)
val md_theme_dark_onSecondaryContainer = Color(0xFF97F0FF)
val md_theme_dark_tertiary = Color(0xFF90D889)
val md_theme_dark_onTertiary = Color(0xFF003909)
val md_theme_dark_tertiaryContainer = Color(0xFF085314)
val md_theme_dark_onTertiaryContainer = Color(0xFFABF5A3)
val md_theme_dark_error = Color(0xFFFFB4AB)
val md_theme_dark_errorContainer = Color(0xFF93000A)
val md_theme_dark_onError = Color(0xFF690005)
val md_theme_dark_onErrorContainer = Color(0xFFFFDAD6)
val md_theme_dark_background = Color(0xFF00210E)
val md_theme_dark_onBackground = Color(0xFF99F7B5)
val md_theme_dark_surface = Color(0xFF00210E)
val md_theme_dark_onSurface = Color(0xFF99F7B5)
val md_theme_dark_surfaceVariant = Color(0xFF424940)
val md_theme_dark_onSurfaceVariant = Color(0xFFC1C9BE)
val md_theme_dark_outline = Color(0xFF8C9389)
val md_theme_dark_inverseOnSurface = Color(0xFF00210E)
val md_theme_dark_inverseSurface = Color(0xFF99F7B5)
val md_theme_dark_inversePrimary = Color(0xFF006E2A)
val md_theme_dark_shadow = Color(0xFF000000)
val md_theme_dark_surfaceTint = Color(0xFF3FE26A)
val md_theme_dark_outlineVariant = Color(0xFF424940)
val md_theme_dark_scrim = Color(0xFF000000)
val md_theme_dark_surface_bright = Color(0xFF063317)
val md_theme_dark_surface_container = md_theme_dark_surface_bright
val md_theme_dark_surface_container_high = md_theme_dark_surface_bright
val md_theme_dark_surface_container_highest = md_theme_dark_surface_bright
val md_theme_dark_surface_container_low = md_theme_dark_background
val md_theme_dark_surface_container_lowest = md_theme_dark_surface_bright
val md_theme_dark_surface_dim = md_theme_dark_surface_bright

val lightColorScheme = lightColorScheme(
    primary = md_theme_light_primary,
    onPrimary = md_theme_light_onPrimary,
    primaryContainer = md_theme_light_primaryContainer,
    onPrimaryContainer = md_theme_light_onPrimaryContainer,
    secondary = md_theme_light_secondary,
    onSecondary = md_theme_light_onSecondary,
    secondaryContainer = md_theme_light_secondaryContainer,
    onSecondaryContainer = md_theme_light_onSecondaryContainer,
    tertiary = md_theme_light_tertiary,
    onTertiary = md_theme_light_onTertiary,
    tertiaryContainer = md_theme_light_tertiaryContainer,
    onTertiaryContainer = md_theme_light_onTertiaryContainer,
    error = md_theme_light_error,
    errorContainer = md_theme_light_errorContainer,
    onError = md_theme_light_onError,
    onErrorContainer = md_theme_light_onErrorContainer,
    background = md_theme_light_background,
    onBackground = md_theme_light_onBackground,
    surface = md_theme_light_surface,
    onSurface = md_theme_light_onSurface,
    surfaceVariant = md_theme_light_surfaceVariant,
    onSurfaceVariant = md_theme_light_onSurfaceVariant,
    outline = md_theme_light_outline,
    inverseOnSurface = md_theme_light_inverseOnSurface,
    inverseSurface = md_theme_light_inverseSurface,
    inversePrimary = md_theme_light_inversePrimary,
    surfaceTint = md_theme_light_surfaceTint,
    outlineVariant = md_theme_light_outlineVariant,
    scrim = md_theme_light_scrim,
    surfaceBright = md_theme_light_surface_bright,
    surfaceContainer = md_theme_light_surface_container,
    surfaceContainerHigh = md_theme_light_surface_container_high,
    surfaceContainerHighest = md_theme_light_surface_container_highest,
    surfaceContainerLow = md_theme_light_surface_container_low,
    surfaceContainerLowest = md_theme_light_surface_container_lowest,
    surfaceDim = md_theme_light_surface_dim,
)

val darkColorScheme = darkColorScheme(
    primary = md_theme_dark_primary,
    onPrimary = md_theme_dark_onPrimary,
    primaryContainer = md_theme_dark_primaryContainer,
    onPrimaryContainer = md_theme_dark_onPrimaryContainer,
    secondary = md_theme_dark_secondary,
    onSecondary = md_theme_dark_onSecondary,
    secondaryContainer = md_theme_dark_secondaryContainer,
    onSecondaryContainer = md_theme_dark_onSecondaryContainer,
    tertiary = md_theme_dark_tertiary,
    onTertiary = md_theme_dark_onTertiary,
    tertiaryContainer = md_theme_dark_tertiaryContainer,
    onTertiaryContainer = md_theme_dark_onTertiaryContainer,
    error = md_theme_dark_error,
    errorContainer = md_theme_dark_errorContainer,
    onError = md_theme_dark_onError,
    onErrorContainer = md_theme_dark_onErrorContainer,
    background = md_theme_dark_background,
    onBackground = md_theme_dark_onBackground,
    surface = md_theme_dark_surface,
    onSurface = md_theme_dark_onSurface,
    surfaceVariant = md_theme_dark_surfaceVariant,
    onSurfaceVariant = md_theme_dark_onSurfaceVariant,
    outline = md_theme_dark_outline,
    inverseOnSurface = md_theme_dark_inverseOnSurface,
    inverseSurface = md_theme_dark_inverseSurface,
    inversePrimary = md_theme_dark_inversePrimary,
    surfaceTint = md_theme_dark_surfaceTint,
    outlineVariant = md_theme_dark_outlineVariant,
    scrim = md_theme_dark_scrim,
    surfaceBright = md_theme_dark_surface_bright,
    surfaceContainer = md_theme_dark_surface_container,
    surfaceContainerHigh = md_theme_dark_surface_container_high,
    surfaceContainerHighest = md_theme_dark_surface_container_highest,
    surfaceContainerLow = md_theme_dark_surface_container_low,
    surfaceContainerLowest = md_theme_dark_surface_container_lowest,
    surfaceDim = md_theme_dark_surface_dim,
)
