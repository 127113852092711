package studio.lostjoker.smartdealer.notification

import androidx.compose.runtime.Composable

actual class NotificationService {
    actual fun notify(title: String, content: String) {
    }
}


@Composable
actual fun buildNotificationService(): NotificationService {
    return NotificationService()
}
