package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.graphicsLayer
import androidx.compose.ui.unit.Dp
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView.*
import studio.lostjoker.smartdealer.ui.components.Card
import studio.lostjoker.smartdealer.ui.poker.enum.CardBackStyle
import studio.lostjoker.smartdealer.ui.poker.enum.CardLayout
import studio.lostjoker.smartdealer.ui.poker.enum.CardStyle

@Composable
fun FlipCard(
    modifier: Modifier = Modifier,
    dealtCard: DealtRankedCardPlayerView,
    cardLayout: CardLayout,
    cardStyle: CardStyle,
    cardBackStyle: CardBackStyle,
    cardWidth: Dp,
    isFlipped: Boolean = false,
    flipRotation: Float = 0f,
    winningCard: Boolean = false,
    folded: Boolean = false,
) {
    if (isFlipped) {
        Card(
            modifier = modifier,
            dealtCard = dealtCard,
            cardLayout = cardLayout,
            cardStyle = cardStyle,
            cardBackStyle = cardBackStyle,
            cardWidth = cardWidth,
            winningCard = winningCard,
            folded = folded,
        )
    } else {
        Card(
            modifier = modifier
                .graphicsLayer {
                    rotationY = flipRotation
                    cameraDistance = 8 * density
                }
                .graphicsLayer {
                    rotationY = 180f
                },
            dealtCard = if (flipRotation < 90f) FaceDown else dealtCard,
            cardLayout = cardLayout,
            cardStyle = cardStyle,
            cardBackStyle = cardBackStyle,
            cardWidth = cardWidth,
            winningCard = winningCard,
            folded = folded,
        )
    }
}
