package studio.lostjoker.smartdealer.gameplay.protocol.converter

import studio.lostjoker.smartdealer.domain.poker.model.PokerVariant
import studio.lostjoker.smartdealer.domain.poker.model.HighLowMode
import studio.lostjoker.smartdealer.domain.poker.model.BettingLimit
import studio.lostjoker.smartdealer.domain.poker.model.PokerSettings
import studio.lostjoker.smartdealer.domain.poker.model.BotSettings
import studio.lostjoker.smartdealer.domain.poker.LowHandRank
import studio.lostjoker.smartdealer.domain.poker.HighHandRank
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevel
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevelHandCountDuration
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevelTimeSlotDuration
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevelHandCountRange
import studio.lostjoker.smartdealer.domain.poker.model.TournamentSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentStartTrigger
import studio.lostjoker.smartdealer.domain.poker.model.BlindProgression
import studio.lostjoker.smartdealer.domain.poker.model.TournamentRegistrationSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentLateRegistration
import studio.lostjoker.smartdealer.domain.poker.model.TournamentRebuyConfig
import studio.lostjoker.smartdealer.domain.poker.model.RebuyAvailability
import studio.lostjoker.smartdealer.domain.poker.model.TournamentPrizeSettings
import studio.lostjoker.smartdealer.domain.poker.model.Positions
import studio.lostjoker.smartdealer.domain.poker.model.SeatSnapshot
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAction
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAvailableAction
import studio.lostjoker.smartdealer.domain.poker.model.SeatRoundResult
import studio.lostjoker.smartdealer.domain.poker.model.HighLow
import studio.lostjoker.smartdealer.domain.poker.model.PotSharePlayerProjection
import studio.lostjoker.smartdealer.domain.poker.model.SeatHandResult
import studio.lostjoker.smartdealer.domain.poker.model.RingGameSettings
import studio.lostjoker.smartdealer.domain.poker.model.RingBuyInSettings
import studio.lostjoker.smartdealer.domain.poker.model.SittingOutReason

// converters for PokerVariant
fun PokerVariant.toProtocol(): protocol.poker.PokerVariant = when (this) {
	PokerVariant.Texas -> protocol.poker.PokerVariant.Texas
	PokerVariant.Omaha -> protocol.poker.PokerVariant.Omaha
}
fun protocol.poker.PokerVariant.toDomain(): PokerVariant = when (this) {
	protocol.poker.PokerVariant.Texas -> PokerVariant.Texas
	protocol.poker.PokerVariant.Omaha -> PokerVariant.Omaha
}

// converters for HighLowMode
fun HighLowMode.toProtocol(): protocol.poker.HighLowMode = when (this) {
	HighLowMode.High -> protocol.poker.HighLowMode.High
	HighLowMode.HighLow -> protocol.poker.HighLowMode.HighLow
}
fun protocol.poker.HighLowMode.toDomain(): HighLowMode = when (this) {
	protocol.poker.HighLowMode.High -> HighLowMode.High
	protocol.poker.HighLowMode.HighLow -> HighLowMode.HighLow
}

// converters for BettingLimit
fun BettingLimit.toProtocol(): protocol.poker.BettingLimit = when (this) {
	BettingLimit.NoLimit -> protocol.poker.BettingLimit.NoLimit
	BettingLimit.PotLimit -> protocol.poker.BettingLimit.PotLimit
	BettingLimit.FixedLimit -> protocol.poker.BettingLimit.FixedLimit
}
fun protocol.poker.BettingLimit.toDomain(): BettingLimit = when (this) {
	protocol.poker.BettingLimit.NoLimit -> BettingLimit.NoLimit
	protocol.poker.BettingLimit.PotLimit -> BettingLimit.PotLimit
	protocol.poker.BettingLimit.FixedLimit -> BettingLimit.FixedLimit
}

// converters for PokerSettings
fun PokerSettings.toProtocol() = protocol.poker.PokerSettings(
	variant = variant.toProtocol(),
	highLowMode = highLowMode.toProtocol(),
	bettingLimit = bettingLimit.toProtocol(),
	tableSize = tableSize,
	deckStart = deckStart.toProtocol(),
	actionTimeout = actionTimeout,
	blindProgression = blindProgression.toProtocol(),
	awayPlaying = awayPlaying,
)
fun protocol.poker.PokerSettings.toDomain() = PokerSettings(
	variant = variant.toDomain(),
	highLowMode = highLowMode.toDomain(),
	bettingLimit = bettingLimit.toDomain(),
	tableSize = tableSize,
	deckStart = deckStart.toDomain(),
	actionTimeout = actionTimeout,
	blindProgression = blindProgression.toDomain(),
	awayPlaying = awayPlaying,
)

// converters for BotSettings
fun BotSettings.toProtocol() = protocol.poker.BotSettings(
	allowBots = allowBots,
	numberOfBots = numberOfBots,
)
fun protocol.poker.BotSettings.toDomain() = BotSettings(
	allowBots = allowBots,
	numberOfBots = numberOfBots,
)

// converters for LowHandRank
fun LowHandRank.toProtocol(): protocol.poker.LowHandRank = when (this) {
	LowHandRank.Unranked -> protocol.poker.LowHandRank.Unranked
	LowHandRank.EightLow -> protocol.poker.LowHandRank.EightLow
	LowHandRank.SevenLow -> protocol.poker.LowHandRank.SevenLow
	LowHandRank.SixLow -> protocol.poker.LowHandRank.SixLow
	LowHandRank.FiveLow -> protocol.poker.LowHandRank.FiveLow
}
fun protocol.poker.LowHandRank.toDomain(): LowHandRank = when (this) {
	protocol.poker.LowHandRank.Unranked -> LowHandRank.Unranked
	protocol.poker.LowHandRank.EightLow -> LowHandRank.EightLow
	protocol.poker.LowHandRank.SevenLow -> LowHandRank.SevenLow
	protocol.poker.LowHandRank.SixLow -> LowHandRank.SixLow
	protocol.poker.LowHandRank.FiveLow -> LowHandRank.FiveLow
}

// converters for HighHandRank
fun HighHandRank.toProtocol(): protocol.poker.HighHandRank = when (this) {
	HighHandRank.Unranked -> protocol.poker.HighHandRank.Unranked
	HighHandRank.HighCard -> protocol.poker.HighHandRank.HighCard
	HighHandRank.Pair -> protocol.poker.HighHandRank.Pair
	HighHandRank.TwoPair -> protocol.poker.HighHandRank.TwoPair
	HighHandRank.ThreeOfAKind -> protocol.poker.HighHandRank.ThreeOfAKind
	HighHandRank.Straight -> protocol.poker.HighHandRank.Straight
	HighHandRank.Flush -> protocol.poker.HighHandRank.Flush
	HighHandRank.FullHouse -> protocol.poker.HighHandRank.FullHouse
	HighHandRank.FourOfAKind -> protocol.poker.HighHandRank.FourOfAKind
	HighHandRank.StraightFlush -> protocol.poker.HighHandRank.StraightFlush
}
fun protocol.poker.HighHandRank.toDomain(): HighHandRank = when (this) {
	protocol.poker.HighHandRank.Unranked -> HighHandRank.Unranked
	protocol.poker.HighHandRank.HighCard -> HighHandRank.HighCard
	protocol.poker.HighHandRank.Pair -> HighHandRank.Pair
	protocol.poker.HighHandRank.TwoPair -> HighHandRank.TwoPair
	protocol.poker.HighHandRank.ThreeOfAKind -> HighHandRank.ThreeOfAKind
	protocol.poker.HighHandRank.Straight -> HighHandRank.Straight
	protocol.poker.HighHandRank.Flush -> HighHandRank.Flush
	protocol.poker.HighHandRank.FullHouse -> HighHandRank.FullHouse
	protocol.poker.HighHandRank.FourOfAKind -> HighHandRank.FourOfAKind
	protocol.poker.HighHandRank.StraightFlush -> HighHandRank.StraightFlush
}

// converters for BlindLevel
fun BlindLevel.toProtocol() = protocol.poker.BlindLevel(
	ante = ante.toProtocol(),
	smallBlind = smallBlind.toProtocol(),
	bigBlind = bigBlind.toProtocol(),
)
fun protocol.poker.BlindLevel.toDomain() = BlindLevel(
	ante = ante.toDomain(),
	smallBlind = smallBlind.toDomain(),
	bigBlind = bigBlind.toDomain(),
)

// converters for BlindLevelHandCountDuration
fun BlindLevelHandCountDuration.toProtocol() = protocol.poker.BlindLevelHandCountDuration(
	blindLevel = blindLevel.toProtocol(),
	handCount = handCount,
)
fun protocol.poker.BlindLevelHandCountDuration.toDomain() = BlindLevelHandCountDuration(
	blindLevel = blindLevel.toDomain(),
	handCount = handCount,
)

// converters for BlindLevelTimeSlotDuration
fun BlindLevelTimeSlotDuration.toProtocol() = protocol.poker.BlindLevelTimeSlotDuration(
	blindLevel = blindLevel.toProtocol(),
	timeSlot = timeSlot,
)
fun protocol.poker.BlindLevelTimeSlotDuration.toDomain() = BlindLevelTimeSlotDuration(
	blindLevel = blindLevel.toDomain(),
	timeSlot = timeSlot,
)

// converters for BlindLevelHandCountRange
fun BlindLevelHandCountRange.toProtocol() = protocol.poker.BlindLevelHandCountRange(
	start = start,
	endExclusive = endExclusive,
)
fun protocol.poker.BlindLevelHandCountRange.toDomain() = BlindLevelHandCountRange(
	start = start,
	endExclusive = endExclusive,
)

// converters for TournamentSettings
fun TournamentSettings.toProtocol() = protocol.poker.TournamentSettings(
	minPlayers = minPlayers,
	maxPlayers = maxPlayers,
	startTrigger = startTrigger.toProtocol(),
	registrationStack = registrationStack.toProtocol(),
	registration = registration.toProtocol(),
	lateRegistration = lateRegistration.toProtocol(),
	rebuy = rebuy.toProtocol(),
	prize = prize.toProtocol(),
)
fun protocol.poker.TournamentSettings.toDomain() = TournamentSettings(
	minPlayers = minPlayers,
	maxPlayers = maxPlayers,
	startTrigger = startTrigger.toDomain(),
	registrationStack = registrationStack.toDomain(),
	registration = registration.toDomain(),
	lateRegistration = lateRegistration.toDomain(),
	rebuy = rebuy.toDomain(),
	prize = prize.toDomain(),
)

// converters for TournamentStartTrigger
fun TournamentStartTrigger.Manual.toProtocol() = protocol.poker.TournamentStartTrigger.Manual
fun protocol.poker.TournamentStartTrigger.Manual.toDomain() = TournamentStartTrigger.Manual

fun TournamentStartTrigger.toProtocol(): protocol.poker.TournamentStartTrigger = when (this) {
	is TournamentStartTrigger.Manual -> toProtocol()
}
fun protocol.poker.TournamentStartTrigger.toDomain(): TournamentStartTrigger = when (this) {
	is protocol.poker.TournamentStartTrigger.Manual -> toDomain()
}

// converters for BlindProgression
fun BlindProgression.HandCount.toProtocol() = protocol.poker.BlindProgression.HandCount(
	levels = levels.map { it.toProtocol() },
)
fun protocol.poker.BlindProgression.HandCount.toDomain() = BlindProgression.HandCount(
	levels = levels.map { it.toDomain() },
)

fun BlindProgression.Static.toProtocol() = protocol.poker.BlindProgression.Static(
	blindLevel = blindLevel.toProtocol(),
)
fun protocol.poker.BlindProgression.Static.toDomain() = BlindProgression.Static(
	blindLevel = blindLevel.toDomain(),
)

fun BlindProgression.TimeSlot.toProtocol() = protocol.poker.BlindProgression.TimeSlot(
	levels = levels.map { it.toProtocol() },
)
fun protocol.poker.BlindProgression.TimeSlot.toDomain() = BlindProgression.TimeSlot(
	levels = levels.map { it.toDomain() },
)

fun BlindProgression.toProtocol(): protocol.poker.BlindProgression = when (this) {
	is BlindProgression.HandCount -> toProtocol()
	is BlindProgression.Static -> toProtocol()
	is BlindProgression.TimeSlot -> toProtocol()
}
fun protocol.poker.BlindProgression.toDomain(): BlindProgression = when (this) {
	is protocol.poker.BlindProgression.HandCount -> toDomain()
	is protocol.poker.BlindProgression.Static -> toDomain()
	is protocol.poker.BlindProgression.TimeSlot -> toDomain()
}

// converters for TournamentRegistrationSettings
fun TournamentRegistrationSettings.ImplicitBuyIn.toProtocol() = protocol.poker.TournamentRegistrationSettings.ImplicitBuyIn(
	buyIn = buyIn.toProtocol(),
)
fun protocol.poker.TournamentRegistrationSettings.ImplicitBuyIn.toDomain() = TournamentRegistrationSettings.ImplicitBuyIn(
	buyIn = buyIn.toDomain(),
)

fun TournamentRegistrationSettings.toProtocol(): protocol.poker.TournamentRegistrationSettings = when (this) {
	is TournamentRegistrationSettings.ImplicitBuyIn -> toProtocol()
}
fun protocol.poker.TournamentRegistrationSettings.toDomain(): TournamentRegistrationSettings = when (this) {
	is protocol.poker.TournamentRegistrationSettings.ImplicitBuyIn -> toDomain()
}

// converters for TournamentLateRegistration
fun TournamentLateRegistration.BlindLevels.toProtocol() = protocol.poker.TournamentLateRegistration.BlindLevels(
	numberOfLevels = numberOfLevels,
)
fun protocol.poker.TournamentLateRegistration.BlindLevels.toDomain() = TournamentLateRegistration.BlindLevels(
	numberOfLevels = numberOfLevels,
)

fun TournamentLateRegistration.Disabled.toProtocol() = protocol.poker.TournamentLateRegistration.Disabled
fun protocol.poker.TournamentLateRegistration.Disabled.toDomain() = TournamentLateRegistration.Disabled

fun TournamentLateRegistration.toProtocol(): protocol.poker.TournamentLateRegistration = when (this) {
	is TournamentLateRegistration.BlindLevels -> toProtocol()
	is TournamentLateRegistration.Disabled -> toProtocol()
}
fun protocol.poker.TournamentLateRegistration.toDomain(): TournamentLateRegistration = when (this) {
	is protocol.poker.TournamentLateRegistration.BlindLevels -> toDomain()
	is protocol.poker.TournamentLateRegistration.Disabled -> toDomain()
}

// converters for TournamentRebuyConfig
fun TournamentRebuyConfig.Disabled.toProtocol() = protocol.poker.TournamentRebuyConfig.Disabled
fun protocol.poker.TournamentRebuyConfig.Disabled.toDomain() = TournamentRebuyConfig.Disabled

fun TournamentRebuyConfig.Enabled.toProtocol() = protocol.poker.TournamentRebuyConfig.Enabled(
	rebuyAvailability = rebuyAvailability.toProtocol(),
	rebuyLimit = rebuyLimit,
	maxStackForRebuy = maxStackForRebuy.toProtocol(),
)
fun protocol.poker.TournamentRebuyConfig.Enabled.toDomain() = TournamentRebuyConfig.Enabled(
	rebuyAvailability = rebuyAvailability.toDomain(),
	rebuyLimit = rebuyLimit,
	maxStackForRebuy = maxStackForRebuy.toDomain(),
)

fun TournamentRebuyConfig.toProtocol(): protocol.poker.TournamentRebuyConfig = when (this) {
	is TournamentRebuyConfig.Disabled -> toProtocol()
	is TournamentRebuyConfig.Enabled -> toProtocol()
}
fun protocol.poker.TournamentRebuyConfig.toDomain(): TournamentRebuyConfig = when (this) {
	is protocol.poker.TournamentRebuyConfig.Disabled -> toDomain()
	is protocol.poker.TournamentRebuyConfig.Enabled -> toDomain()
}

// converters for RebuyAvailability
fun RebuyAvailability.NumberOfBlindLevels.toProtocol() = protocol.poker.RebuyAvailability.NumberOfBlindLevels(
	number = number,
)
fun protocol.poker.RebuyAvailability.NumberOfBlindLevels.toDomain() = RebuyAvailability.NumberOfBlindLevels(
	number = number,
)

fun RebuyAvailability.Unlimited.toProtocol() = protocol.poker.RebuyAvailability.Unlimited
fun protocol.poker.RebuyAvailability.Unlimited.toDomain() = RebuyAvailability.Unlimited

fun RebuyAvailability.toProtocol(): protocol.poker.RebuyAvailability = when (this) {
	is RebuyAvailability.NumberOfBlindLevels -> toProtocol()
	is RebuyAvailability.Unlimited -> toProtocol()
}
fun protocol.poker.RebuyAvailability.toDomain(): RebuyAvailability = when (this) {
	is protocol.poker.RebuyAvailability.NumberOfBlindLevels -> toDomain()
	is protocol.poker.RebuyAvailability.Unlimited -> toDomain()
}

// converters for TournamentPrizeSettings
fun TournamentPrizeSettings.Payout.toProtocol() = protocol.poker.TournamentPrizeSettings.Payout(
	payoutStructure = payoutStructure.mapValues { it.value.toProtocol() },
)
fun protocol.poker.TournamentPrizeSettings.Payout.toDomain() = TournamentPrizeSettings.Payout(
	payoutStructure = payoutStructure.mapValues { it.value.toDomain() },
)

fun TournamentPrizeSettings.toProtocol(): protocol.poker.TournamentPrizeSettings = when (this) {
	is TournamentPrizeSettings.Payout -> toProtocol()
}
fun protocol.poker.TournamentPrizeSettings.toDomain(): TournamentPrizeSettings = when (this) {
	is protocol.poker.TournamentPrizeSettings.Payout -> toDomain()
}

// converters for Positions
fun Positions.toProtocol() = protocol.poker.Positions(
	dealerSeat = dealerSeat,
	smallBlindSeat = smallBlindSeat,
	bigBlindSeat = bigBlindSeat,
	utgSeat = utgSeat,
	utg1Seat = utg1Seat,
	mp1Seat = mp1Seat,
	mp2Seat = mp2Seat,
	mp3Seat = mp3Seat,
	hijackSeat = hijackSeat,
	cutoffSeat = cutoffSeat,
)
fun protocol.poker.Positions.toDomain() = Positions(
	dealerSeat = dealerSeat,
	smallBlindSeat = smallBlindSeat,
	bigBlindSeat = bigBlindSeat,
	utgSeat = utgSeat,
	utg1Seat = utg1Seat,
	mp1Seat = mp1Seat,
	mp2Seat = mp2Seat,
	mp3Seat = mp3Seat,
	hijackSeat = hijackSeat,
	cutoffSeat = cutoffSeat,
)

// converters for SeatSnapshot
fun SeatSnapshot.toProtocol() = protocol.poker.SeatSnapshot(
	seat = seat,
	stack = stack.toProtocol(),
	folded = folded,
	away = away,
)
fun protocol.poker.SeatSnapshot.toDomain() = SeatSnapshot(
	seat = seat,
	stack = stack.toDomain(),
	folded = folded,
	away = away,
)

// converters for PlayerHandAction
fun PlayerHandAction.Bet.toProtocol() = protocol.poker.PlayerHandAction.Bet(
	amount = amount.toProtocol(),
)
fun protocol.poker.PlayerHandAction.Bet.toDomain() = PlayerHandAction.Bet(
	amount = amount.toDomain(),
)

fun PlayerHandAction.Call.toProtocol() = protocol.poker.PlayerHandAction.Call(
	amount = amount.toProtocol(),
)
fun protocol.poker.PlayerHandAction.Call.toDomain() = PlayerHandAction.Call(
	amount = amount.toDomain(),
)

fun PlayerHandAction.Check.toProtocol() = protocol.poker.PlayerHandAction.Check
fun protocol.poker.PlayerHandAction.Check.toDomain() = PlayerHandAction.Check

fun PlayerHandAction.Fold.toProtocol() = protocol.poker.PlayerHandAction.Fold
fun protocol.poker.PlayerHandAction.Fold.toDomain() = PlayerHandAction.Fold

fun PlayerHandAction.Raise.toProtocol() = protocol.poker.PlayerHandAction.PostBigBlind(
	amount = amount.toProtocol(),
)
fun protocol.poker.PlayerHandAction.PostBigBlind.toDomain() = PlayerHandAction.Raise(
	amount = amount.toDomain(),
)

fun PlayerHandAction.PostBigBlind.toProtocol() = protocol.poker.PlayerHandAction.PostSmallBlind(
	amount = amount.toProtocol(),
)
fun protocol.poker.PlayerHandAction.PostSmallBlind.toDomain() = PlayerHandAction.PostBigBlind(
	amount = amount.toDomain(),
)

fun PlayerHandAction.PostSmallBlind.toProtocol() = protocol.poker.PlayerHandAction.Raise(
	amount = amount.toProtocol(),
)
fun protocol.poker.PlayerHandAction.Raise.toDomain() = PlayerHandAction.PostSmallBlind(
	amount = amount.toDomain(),
)

fun PlayerHandAction.toProtocol(): protocol.poker.PlayerHandAction = when (this) {
	is PlayerHandAction.Bet -> toProtocol()
	is PlayerHandAction.Call -> toProtocol()
	is PlayerHandAction.Check -> toProtocol()
	is PlayerHandAction.Fold -> toProtocol()
	is PlayerHandAction.Raise -> toProtocol()
	is PlayerHandAction.PostBigBlind -> toProtocol()
	is PlayerHandAction.PostSmallBlind -> toProtocol()
}
fun protocol.poker.PlayerHandAction.toDomain(): PlayerHandAction = when (this) {
	is protocol.poker.PlayerHandAction.Bet -> toDomain()
	is protocol.poker.PlayerHandAction.Call -> toDomain()
	is protocol.poker.PlayerHandAction.Check -> toDomain()
	is protocol.poker.PlayerHandAction.Fold -> toDomain()
	is protocol.poker.PlayerHandAction.Raise -> toDomain()
	is protocol.poker.PlayerHandAction.PostBigBlind -> toDomain()
	is protocol.poker.PlayerHandAction.PostSmallBlind -> toDomain()
}

// converters for PlayerHandAvailableAction
fun PlayerHandAvailableAction.Bet.toProtocol() = protocol.poker.PlayerHandAvailableAction.Bet(
	min = min.toProtocol(),
	max = max.toProtocol(),
)
fun protocol.poker.PlayerHandAvailableAction.Bet.toDomain() = PlayerHandAvailableAction.Bet(
	min = min.toDomain(),
	max = max.toDomain(),
)

fun PlayerHandAvailableAction.Call.toProtocol() = protocol.poker.PlayerHandAvailableAction.Call(
	amount = amount.toProtocol(),
)
fun protocol.poker.PlayerHandAvailableAction.Call.toDomain() = PlayerHandAvailableAction.Call(
	amount = amount.toDomain(),
)

fun PlayerHandAvailableAction.Check.toProtocol() = protocol.poker.PlayerHandAvailableAction.Check
fun protocol.poker.PlayerHandAvailableAction.Check.toDomain() = PlayerHandAvailableAction.Check

fun PlayerHandAvailableAction.Fold.toProtocol() = protocol.poker.PlayerHandAvailableAction.Fold
fun protocol.poker.PlayerHandAvailableAction.Fold.toDomain() = PlayerHandAvailableAction.Fold

fun PlayerHandAvailableAction.PostBigBlind.toProtocol() = protocol.poker.PlayerHandAvailableAction.PostBigBlind(
	amount = amount.toProtocol(),
)
fun protocol.poker.PlayerHandAvailableAction.PostBigBlind.toDomain() = PlayerHandAvailableAction.PostBigBlind(
	amount = amount.toDomain(),
)

fun PlayerHandAvailableAction.PostSmallBlind.toProtocol() = protocol.poker.PlayerHandAvailableAction.PostSmallBlind(
	amount = amount.toProtocol(),
)
fun protocol.poker.PlayerHandAvailableAction.PostSmallBlind.toDomain() = PlayerHandAvailableAction.PostSmallBlind(
	amount = amount.toDomain(),
)

fun PlayerHandAvailableAction.Raise.toProtocol() = protocol.poker.PlayerHandAvailableAction.Raise(
	min = min.toProtocol(),
	max = max.toProtocol(),
)
fun protocol.poker.PlayerHandAvailableAction.Raise.toDomain() = PlayerHandAvailableAction.Raise(
	min = min.toDomain(),
	max = max.toDomain(),
)

fun PlayerHandAvailableAction.toProtocol(): protocol.poker.PlayerHandAvailableAction = when (this) {
	is PlayerHandAvailableAction.Bet -> toProtocol()
	is PlayerHandAvailableAction.Call -> toProtocol()
	is PlayerHandAvailableAction.Check -> toProtocol()
	is PlayerHandAvailableAction.Fold -> toProtocol()
	is PlayerHandAvailableAction.PostBigBlind -> toProtocol()
	is PlayerHandAvailableAction.PostSmallBlind -> toProtocol()
	is PlayerHandAvailableAction.Raise -> toProtocol()
}
fun protocol.poker.PlayerHandAvailableAction.toDomain(): PlayerHandAvailableAction = when (this) {
	is protocol.poker.PlayerHandAvailableAction.Bet -> toDomain()
	is protocol.poker.PlayerHandAvailableAction.Call -> toDomain()
	is protocol.poker.PlayerHandAvailableAction.Check -> toDomain()
	is protocol.poker.PlayerHandAvailableAction.Fold -> toDomain()
	is protocol.poker.PlayerHandAvailableAction.PostBigBlind -> toDomain()
	is protocol.poker.PlayerHandAvailableAction.PostSmallBlind -> toDomain()
	is protocol.poker.PlayerHandAvailableAction.Raise -> toDomain()
}

// converters for SeatRoundResult
fun SeatRoundResult.toProtocol() = protocol.poker.SeatRoundResult(
	seat = seat,
	folded = folded,
	betAmount = betAmount.toProtocol(),
	uncalledBetAmount = uncalledBetAmount.toProtocol(),
)
fun protocol.poker.SeatRoundResult.toDomain() = SeatRoundResult(
	seat = seat,
	folded = folded,
	betAmount = betAmount.toDomain(),
	uncalledBetAmount = uncalledBetAmount.toDomain(),
)

// converters for HighLow
fun HighLow.toProtocol(): protocol.poker.HighLow = when (this) {
	HighLow.High -> protocol.poker.HighLow.High
	HighLow.Low -> protocol.poker.HighLow.Low
}
fun protocol.poker.HighLow.toDomain(): HighLow = when (this) {
	protocol.poker.HighLow.High -> HighLow.High
	protocol.poker.HighLow.Low -> HighLow.Low
}

// converters for PotSharePlayerProjection
fun PotSharePlayerProjection.toProtocol() = protocol.poker.PotSharePlayerProjection(
	seat = seat,
	pot = pot,
	run = run,
	highLow = highLow.toProtocol(),
	hand = hand?.map { it.toProtocol() },
	amount = amount.toProtocol(),
)
fun protocol.poker.PotSharePlayerProjection.toDomain() = PotSharePlayerProjection(
	seat = seat,
	pot = pot,
	run = run,
	highLow = highLow.toDomain(),
	hand = hand?.map { it.toDomain() },
	amount = amount.toDomain(),
)

// converters for SeatHandResult
fun SeatHandResult.toProtocol() = protocol.poker.SeatHandResult(
	seat = seat,
	folded = folded,
	betAmount = betAmount.toProtocol(),
	winAmount = winAmount.toProtocol(),
)
fun protocol.poker.SeatHandResult.toDomain() = SeatHandResult(
	seat = seat,
	folded = folded,
	betAmount = betAmount.toDomain(),
	winAmount = winAmount.toDomain(),
)

// converters for RingGameSettings
fun RingGameSettings.toProtocol() = protocol.poker.RingGameSettings(
	allowStraddle = allowStraddle,
	buyIn = buyIn.toProtocol(),
)
fun protocol.poker.RingGameSettings.toDomain() = RingGameSettings(
	allowStraddle = allowStraddle,
	buyIn = buyIn.toDomain(),
)

// converters for RingBuyInSettings
fun RingBuyInSettings.ImplicitBuyIn.toProtocol() = protocol.poker.RingBuyInSettings.ImplicitBuyIn(
	buyIn = buyIn.toProtocol(),
)
fun protocol.poker.RingBuyInSettings.ImplicitBuyIn.toDomain() = RingBuyInSettings.ImplicitBuyIn(
	buyIn = buyIn.toDomain(),
)

fun RingBuyInSettings.RangeBuyIn.toProtocol() = protocol.poker.RingBuyInSettings.RangeBuyIn(
	minBuyIn = minBuyIn.toProtocol(),
	maxBuyIn = maxBuyIn.toProtocol(),
)
fun protocol.poker.RingBuyInSettings.RangeBuyIn.toDomain() = RingBuyInSettings.RangeBuyIn(
	minBuyIn = minBuyIn.toDomain(),
	maxBuyIn = maxBuyIn.toDomain(),
)

fun RingBuyInSettings.toProtocol(): protocol.poker.RingBuyInSettings = when (this) {
	is RingBuyInSettings.ImplicitBuyIn -> toProtocol()
	is RingBuyInSettings.RangeBuyIn -> toProtocol()
}
fun protocol.poker.RingBuyInSettings.toDomain(): RingBuyInSettings = when (this) {
	is protocol.poker.RingBuyInSettings.ImplicitBuyIn -> toDomain()
	is protocol.poker.RingBuyInSettings.RangeBuyIn -> toDomain()
}

// converters for SittingOutReason
fun SittingOutReason.toProtocol(): protocol.poker.SittingOutReason = when (this) {
	SittingOutReason.PlayerDecision -> protocol.poker.SittingOutReason.PlayerDecision
	SittingOutReason.ActionTimeout -> protocol.poker.SittingOutReason.ActionTimeout
}
fun protocol.poker.SittingOutReason.toDomain(): SittingOutReason = when (this) {
	protocol.poker.SittingOutReason.PlayerDecision -> SittingOutReason.PlayerDecision
	protocol.poker.SittingOutReason.ActionTimeout -> SittingOutReason.ActionTimeout
}

