package studio.lostjoker.smartdealer.ui.poker.devices.common.state

import com.benasher44.uuid.Uuid
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.poker.HighHandRank
import studio.lostjoker.smartdealer.domain.poker.LowHandRank
import studio.lostjoker.smartdealer.domain.poker.RankedHand
import studio.lostjoker.smartdealer.domain.poker.model.PlayerHandAction
import studio.lostjoker.smartdealer.domain.toFixed2
import studio.lostjoker.smartdealer.ui.poker.devices.common.model.Position

data class Player(
    val sessionId: Uuid,
    val seat: Int = -1,
    val playerId: String? = null,
    val avatarUrl: String? = null,
    val screenName: String? = null,
    val position: Position? = null,
    val remainingStack: Fixed2 = 0.toFixed2(),
    val bettingStack: Fixed2 = 0.toFixed2(),
    val action: PlayerHandAction? = null,
    val holeCards: List<DealtRankedCardPlayerView> = emptyList(),
    val showdown: Boolean = false,
    val folded: Boolean = true,
    val eliminated: Boolean = false,
    val rank: Int = -1,
    val buyIn: Fixed2 = 0.toFixed2(),
    val prize: Fixed2 = 0.toFixed2(),
    val rebuy: Int = 0,
    val bot: Boolean = false,
) {
    val netProfit: Fixed2
        get() = remainingStack - buyIn

    fun totalStack(): Fixed2 {
        return remainingStack + bettingStack
    }
}

data class PlayerSummary(
    val sessionId: Uuid,
    val seat: Int = -1,
    val playerId: String? = null,
    val screenName: String? = null,
    val position: Position? = null,
    val holeCards: List<DealtRankedCardPlayerView> = emptyList(),
    val folded: Boolean = false,
    val communityCardsInFold: List<DealtRankedCardPlayerView> = emptyList(),
    val rankedHighHand: RankedHand<HighHandRank>? = null,
    val rankedLowHand: RankedHand<LowHandRank>? = null,
    val stackResult: Fixed2 = 0.toFixed2(),
)
