package studio.lostjoker.smartdealer.ui.components

import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

// TODO: Remove dependency from PokerAppTheme's dimensions since this component needs to be generic for all games

@Composable
fun GameCodeInput(
    defaultValues: String,
    readOnly: Boolean = false,
    onChangeGameCode: ((value: String?) -> Unit)?,
) {
    val pattern = remember { Regex("^\\d+\$") }
    val focusManager = LocalFocusManager.current
    val keyboardController = LocalSoftwareKeyboardController.current
    var keyboardAutoClosed by remember(defaultValues) { mutableStateOf(false) }

    BasicTextField(
        modifier = Modifier.height(PokerAppTheme.dimensions.grid_5),
        value = defaultValues,
        onValueChange = {
            if (onChangeGameCode != null && (it.isEmpty() || it.matches(pattern))) {
                onChangeGameCode(it)
            }
        },
        singleLine = true,
        readOnly = readOnly,
        keyboardOptions = KeyboardOptions(
            keyboardType = KeyboardType.Number,
            imeAction = ImeAction.Done,
        ),
        keyboardActions = KeyboardActions(
            onDone = {
                keyboardController?.hide()
                focusManager.clearFocus()
            }
        ),
        decorationBox = {
            Row(
                horizontalArrangement = Arrangement.spacedBy(PokerAppTheme.dimensions.grid_1),
                modifier = Modifier.fillMaxWidth().height(PokerAppTheme.dimensions.grid_5),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                Spacer(modifier = Modifier.weight(1f))
                repeat(6) { index ->
                    val char = when {
                        index >= defaultValues.length -> ""
                        else -> defaultValues[index].toString()
                    }
                    val isFocused = defaultValues.length == index

                    Text(
                        modifier = Modifier
                            .weight(1f)
                            .size(PokerAppTheme.dimensions.grid_5)
                            .border(
                                when (isFocused) {
                                    true -> 2.dp
                                    false -> 1.dp
                                },
                                MaterialTheme.colorScheme.primary,
                                MaterialTheme.shapes.small,
                            ).wrapContentHeight(align = Alignment.CenterVertically),
                        text = char,
                        style = MaterialTheme.typography.bodyMedium,
                        textAlign = TextAlign.Center,
                    )
                }
                Spacer(modifier = Modifier.weight(1f))
            }
        },
    )

    if (defaultValues.length >= 6 && !keyboardAutoClosed) {
        keyboardController?.hide()
        focusManager.clearFocus()
        keyboardAutoClosed = true
    }
}
