package studio.lostjoker.smartdealer.ui.poker.devices.table

import androidx.lifecycle.Lifecycle
import cafe.adriel.voyager.core.model.ScreenModel
import cafe.adriel.voyager.core.model.screenModelScope
import com.benasher44.uuid.Uuid
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import service.PlayerAccountService
import service.poker.TableDeviceSessionService
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.poker.game.projection.DealHandCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.PauseGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.ResumeGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.StartGameCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.TerminateRingCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.UpdateTournamentSettingsCommand
import studio.lostjoker.smartdealer.domain.poker.game.projection.UpdateRingGameSettingsCommand
import studio.lostjoker.smartdealer.domain.poker.model.PokerSettings
import studio.lostjoker.smartdealer.domain.poker.model.RingGameSettings
import studio.lostjoker.smartdealer.domain.poker.model.TournamentSettings
import studio.lostjoker.smartdealer.helpers.connectSessionService
import studio.lostjoker.smartdealer.notification.NotificationService
import studio.lostjoker.smartdealer.preferences.PreferenceDelegate
import studio.lostjoker.smartdealer.preferences.PreferenceEnums
import studio.lostjoker.smartdealer.ui.poker.devices.PokerViewModel
import studio.lostjoker.smartdealer.ui.poker.enum.BottomSheetState
import studio.lostjoker.smartdealer.ui.poker.enum.CardBackStyle
import studio.lostjoker.smartdealer.ui.poker.enum.CardLayout
import studio.lostjoker.smartdealer.ui.poker.enum.CardStyle
import studio.lostjoker.smartdealer.ui.poker.enum.TableFelt

class TableDeviceViewModel(
    val gameId: Uuid,
    override val userId: String,
    notificationService: NotificationService,
    gameplayBaseUrl: String,
    apiBaseUrl: String,
    private val preferenceDelegate: PreferenceDelegate,
) : PokerViewModel(notificationService), ScreenModel {

    val state = _state.asStateFlow()

    private val tableSessionService = TableDeviceSessionService(
        gameplayBaseUrl = gameplayBaseUrl,
        gameId = gameId,
        playerId = userId,
    )

    private val playerAccountService = PlayerAccountService(apiBaseUrl)

    init {
        screenModelScope.connectSessionService(
            sessionService = tableSessionService,
            eventReducer = ::updateStateOnEvent,
            snapshotHandler = ::updateStateOnSnapshot,
            connecting = { connecting -> _state.update { s -> s.copy(connecting = connecting) } },
        )

        val tableFelt = TableFelt.entries[preferenceDelegate.getInt(PreferenceEnums.InGame.TableFelt.key)]
        val cardLayout = CardLayout.entries[preferenceDelegate.getInt(PreferenceEnums.InGame.CardLayout.key)]
        val cardStyle = CardStyle.entries[preferenceDelegate.getInt(PreferenceEnums.InGame.CardStyle.key)]
        val cardBackStyle = CardBackStyle.entries[preferenceDelegate.getInt(PreferenceEnums.InGame.CardBackStyle.key)]
        // These preferences always reset to default value
        preferenceDelegate.putBoolean(PreferenceEnums.InGame.VerticalDisplay.key, false)

        _state.update {
            it.copy(
                settings = it.settings.copy(
                    tableFelt = tableFelt,
                    cardLayout = cardLayout,
                    cardStyle = cardStyle,
                    cardBackStyle = cardBackStyle,
                    verticalDisplay = false,
                ),
            )
        }
    }

    fun changeVerticalDisplay(verticalDisplay: Boolean) {
        preferenceDelegate.putBoolean(PreferenceEnums.InGame.VerticalDisplay.key, verticalDisplay)
        _state.update {
            it.copy(
                settings = it.settings.copy(
                    verticalDisplay = verticalDisplay,
                ),
            )
        }
    }

    fun changeTableFelt(tableFelt: TableFelt) {
        preferenceDelegate.putInt(PreferenceEnums.InGame.TableFelt.key, tableFelt.ordinal)
        _state.update {
            it.copy(
                settings = it.settings.copy(
                    tableFelt = tableFelt,
                ),
            )
        }
    }

    fun changeCardStyle(cardStyle: CardStyle) {
        preferenceDelegate.putInt(PreferenceEnums.InGame.CardStyle.key, cardStyle.ordinal)
        _state.update {
            it.copy(
                settings = it.settings.copy(
                    cardStyle = cardStyle,
                ),
            )
        }
    }

    fun changeCardBackStyle(cardBackStyle: CardBackStyle) {
        preferenceDelegate.putInt(PreferenceEnums.InGame.CardBackStyle.key, cardBackStyle.ordinal)
        _state.update {
            it.copy(
                settings = it.settings.copy(
                    cardBackStyle = cardBackStyle,
                ),
            )
        }
    }

    fun changeBottomSheetState(bottomSheetState: BottomSheetState?) {
        _state.update {
            it.copy(
                bottomSheetState = bottomSheetState,
            )
        }
    }

    fun updateTournamentSettings(tournamentSettings: TournamentSettings, pokerSettings: PokerSettings) {
        screenModelScope.launch {
            tableSessionService.send(
                UpdateTournamentSettingsCommand(
                    tournamentSettings = tournamentSettings,
                    pokerSettings = pokerSettings,
                ),
            )
        }
    }

    fun updateRingGameSettings(ringGameSettings: RingGameSettings, pokerSettings: PokerSettings) {
        screenModelScope.launch {
            tableSessionService.send(
                UpdateRingGameSettingsCommand(
                    ringGameSettings = ringGameSettings,
                    pokerSettings = pokerSettings,
                ),
            )
        }
    }

    fun startTournament() {
        screenModelScope.launch {
            tableSessionService.send(
                StartGameCommand,
            )
        }
    }

    fun pauseTournament() {
        screenModelScope.launch {
            tableSessionService.send(
                PauseGameCommand,
            )
        }
    }


    fun resumeTournament() {
        screenModelScope.launch {
            tableSessionService.send(
                ResumeGameCommand,
            )
        }
    }

    fun deal() {
        screenModelScope.launch {
            val tableId = _state.value.tableId
            if (tableId != null) {
                tableSessionService.send(
                    DealHandCommand(tableId),
                )
            }
        }
    }

    fun updatePlayerAccount(playerId: String, bot: Boolean) {
        screenModelScope.launch {
            val (screenName, avatarUrl) = fetchPlayerAccountDetails(playerId, bot)

            _state.update {
                it.copy(
                    players = it.players.map { player ->
                        if (player.playerId == playerId) {
                            player.copy(screenName = screenName, avatarUrl = avatarUrl)
                        } else {
                            player
                        }
                    },
                )
            }
        }
    }

    fun updatePaymentTransfer(playerId: String, bot: Boolean) {
        screenModelScope.launch {
            val (screenName, _) = fetchPlayerAccountDetails(playerId, bot)

            _state.update {
                it.copy(
                    payments = it.payments.map { payment ->
                        if (payment.debtorPlayerId == playerId) {
                            payment.copy(debtorScreenName = screenName)
                        } else if (payment.creditorPlayerId == playerId) {
                            payment.copy(creditorScreenName = screenName)
                        } else {
                            payment
                        }
                    }
                )
            }
        }
    }

    private suspend fun fetchPlayerAccountDetails(playerId: String, bot: Boolean): Pair<String, String?> {
        if (bot) return Pair(playerId, null)

        val playerAccount = playerAccountService.getPlayerAccount(playerId)

        val screenName = playerAccount?.screenName ?: "(login again)" // TODO do not allow game to start if player is not available in the game service

        val avatarUrl = if (playerAccount?.profileImageUrl != null) {
            playerAccountService.getPlayerAvatarUrl(playerId)
        } else {
            null
        }
        return Pair(screenName, avatarUrl)
    }

    fun showWinner(winningSeat: Int, winningCards: List<DealtRankedCardPlayerView>) {
        screenModelScope.launch {
            _state.update {
                it.copy(
                    winningSeat = winningSeat,
                    winningCards = winningCards,
                )
            }
        }
    }

    fun terminateGame() {
        screenModelScope.launch {
            tableSessionService.send(
                TerminateRingCommand,
            )
        }
    }

    fun leaveGame() {
        println("leaving game")
    }

    override fun onDispose() {
        println("disposing $this")
        screenModelScope.launch {
            closeSession()
        }
    }

    private suspend fun closeSession() {
        println("Closing session ${tableSessionService.id} for game $gameId...")
        tableSessionService.close()
        tableSessionService.finalize()
    }

    fun updateApplicationState(applicationState: Lifecycle.State) {
        _state.update {
            it.copy(
                applicationState = applicationState,
            )
        }
    }
}
