package studio.lostjoker.smartdealer.ui.poker.enum

enum class BottomSheetState {
    HandSummary,
    TournamentOverview,
    RingOverview,
    Settings,
    Help,
    Payments,
}
