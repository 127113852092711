package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ScreenRotation
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_devices_screen_landscape
import smartdealer.appshared.generated.resources.poker_devices_screen_not_supported
import smartdealer.appshared.generated.resources.poker_devices_screen_portrait
import studio.lostjoker.smartdealer.ui.poker.enum.ScreenOrientation
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun ScreenOrientationNotSupported(screenOrientation: ScreenOrientation) {
    Column(
        modifier = Modifier
            .fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(5.dp, Alignment.CenterVertically),
    ) {
        Text(
            text = stringResource(
                Res.string.poker_devices_screen_not_supported,
                when (screenOrientation) {
                    ScreenOrientation.Portrait -> stringResource(Res.string.poker_devices_screen_portrait)
                    ScreenOrientation.Landscape -> stringResource(Res.string.poker_devices_screen_landscape)
                },
            ),
            textAlign = TextAlign.Center,
            style = PokerAppTheme.typography.titleLarge,
            color = Color.White,
        )
        Icon(
            imageVector = Icons.Filled.ScreenRotation,
            contentDescription = null,
            tint = Color.White,
        )
    }
}
