package studio.lostjoker.smartdealer.ui.poker.common.helpers

import studio.lostjoker.smartdealer.ui.poker.host_game.PayoutRange
import studio.lostjoker.smartdealer.ui.poker.host_game.PayoutType

val payoutStructure: Map<PayoutType, Map<PayoutRange, Map<Int, Double>>> = mapOf(
    PayoutType.Conservative to mapOf(
        PayoutRange.Range1 to mapOf(1 to 1.0),
        PayoutRange.Range2 to mapOf(1 to 0.65, 2 to 0.35),
        PayoutRange.Range3 to mapOf(1 to 0.5, 2 to 0.3, 3 to 0.2),
    ),
    PayoutType.Moderate to mapOf(
        PayoutRange.Range1 to mapOf(1 to 1.0),
        PayoutRange.Range2 to mapOf(1 to 0.7, 2 to 0.3),
        PayoutRange.Range3 to mapOf(1 to 0.5, 2 to 0.3, 3 to 0.2),
    ),
    PayoutType.Aggressive to mapOf(
        PayoutRange.Range1 to mapOf(1 to 1.0),
        PayoutRange.Range2 to mapOf(1 to 1.0),
        PayoutRange.Range3 to mapOf(1 to 1.0),
    ),
)
