package studio.lostjoker.smartdealer.game_management.web.contract

enum class GameManagementApiErrorCode {
    Unknown,
    IdNotProvided,
    CodeNotProvided,
    GameNotFound,
    PlayerAccountNotFound,
}

enum class GameType {
    Sueca,
    Poker
}
