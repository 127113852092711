package studio.lostjoker.smartdealer.ui.poker.devices.common.state

data class Payment(
    val debtorPlayerId: String,
    val debtorScreenName: String,
    val creditorPlayerId: String,
    val creditorScreenName: String,
    val amount: Double,
)

data class Transaction(
    val playerId: String,
    val screenName: String,
    var amount: Double,
)
