package studio.lostjoker.smartdealer.preferences

import androidx.compose.runtime.Composable

class JsPreferenceDelegate : PreferenceDelegate {
    override fun init(storageName: String) {
    }

    override fun putInt(key: String, value: Int) {
    }

    override fun putBoolean(key: String, value: Boolean) {
    }

    override fun putString(key: String, value: String) {
    }

    override fun putLong(key: String, value: Long) {
    }

    override fun putFloat(key: String, value: Float) {
    }

    override fun getInt(key: String, defaultValue: Int): Int {
        return 0
    }

    override fun getBoolean(key: String, defaultValue: Boolean): Boolean {
        return true
    }

    override fun getString(key: String, defaultValue: String?): String? {
        return null
    }

    override fun getLong(key: String, defaultValue: Long): Long {
        return 1
    }

    override fun getFloat(key: String, defaultValue: Float): Float {
        return 1f
    }
}

@Composable
actual fun rememberUserPreferences(storageName: String): PreferenceDelegate {
    return JsPreferenceDelegate()
}
