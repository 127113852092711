package studio.lostjoker.smartdealer.gameplay.protocol.converter

import studio.lostjoker.smartdealer.domain.poker.game.projection.TournamentSnapshot
import studio.lostjoker.smartdealer.domain.poker.game.projection.HandSnapshot
import studio.lostjoker.smartdealer.domain.poker.game.projection.HandSnapshotSeat
import studio.lostjoker.smartdealer.domain.poker.game.projection.PlayerSnapshotStack
import studio.lostjoker.smartdealer.domain.poker.game.projection.RoundSnapshot
import studio.lostjoker.smartdealer.domain.poker.game.projection.RoundSnapshotSeat
import studio.lostjoker.smartdealer.domain.poker.game.projection.RingSnapshot

// converters for TournamentSnapshot
fun TournamentSnapshot.toProtocol() = protocol.poker.TournamentSnapshot(
	created = created,
	started = started,
	playerIds = playerIds,
	paused = paused,
	startInstant = startInstant,
	pauseInstant = pauseInstant,
	blindLevel = blindLevel.toProtocol(),
	handCount = handCount,
	handCountRange = handCountRange.toProtocol(),
	lateRegistrationLevelsLeft = lateRegistrationLevelsLeft,
	rebuyLevelsLeft = rebuyLevelsLeft,
	ended = ended,
	ranks = ranks,
	prizes = prizes.mapValues { it.value.toProtocol() },
	rebuys = rebuys,
	hostPlayerId = hostPlayerId,
	tournamentSettings = tournamentSettings.toProtocol(),
	pokerSettings = pokerSettings.toProtocol(),
	rebuyRemainingCount = rebuyRemainingCount,
)
fun protocol.poker.TournamentSnapshot.toDomain() = TournamentSnapshot(
	created = created,
	started = started,
	playerIds = playerIds,
	paused = paused,
	startInstant = startInstant,
	pauseInstant = pauseInstant,
	blindLevel = blindLevel.toDomain(),
	handCount = handCount,
	handCountRange = handCountRange.toDomain(),
	lateRegistrationLevelsLeft = lateRegistrationLevelsLeft,
	rebuyLevelsLeft = rebuyLevelsLeft,
	ended = ended,
	ranks = ranks,
	prizes = prizes.mapValues { it.value.toDomain() },
	rebuys = rebuys,
	hostPlayerId = hostPlayerId,
	tournamentSettings = tournamentSettings.toDomain(),
	pokerSettings = pokerSettings.toDomain(),
	rebuyRemainingCount = rebuyRemainingCount,
)

// converters for HandSnapshot
fun HandSnapshot.toProtocol() = protocol.poker.HandSnapshot(
	positions = positions.toProtocol(),
	seats = seats.map { it.toProtocol() },
	communityCards = communityCards.map { it.toProtocol() },
	ended = ended,
	stacks = stacks.map { it.toProtocol() },
)
fun protocol.poker.HandSnapshot.toDomain() = HandSnapshot(
	positions = positions.toDomain(),
	seats = seats.map { it.toDomain() },
	communityCards = communityCards.map { it.toDomain() },
	ended = ended,
	stacks = stacks.map { it.toDomain() },
)

// converters for HandSnapshotSeat
fun HandSnapshotSeat.toProtocol() = protocol.poker.HandSnapshotSeat(
	seat = seat,
	holeCards = holeCards.map { it.toProtocol() },
	folded = folded,
)
fun protocol.poker.HandSnapshotSeat.toDomain() = HandSnapshotSeat(
	seat = seat,
	holeCards = holeCards.map { it.toDomain() },
	folded = folded,
)

// converters for PlayerSnapshotStack
fun PlayerSnapshotStack.toProtocol() = protocol.poker.PlayerSnapshotStack(
	seat = seat,
	stack = stack.toProtocol(),
)
fun protocol.poker.PlayerSnapshotStack.toDomain() = PlayerSnapshotStack(
	seat = seat,
	stack = stack.toDomain(),
)

// converters for RoundSnapshot
fun RoundSnapshot.toProtocol() = protocol.poker.RoundSnapshot(
	seats = seats.map { it.toProtocol() },
	totalPotSize = totalPotSize.toProtocol(),
)
fun protocol.poker.RoundSnapshot.toDomain() = RoundSnapshot(
	seats = seats.map { it.toDomain() },
	totalPotSize = totalPotSize.toDomain(),
)

// converters for RoundSnapshotSeat
fun RoundSnapshotSeat.toProtocol() = protocol.poker.RoundSnapshotSeat(
	seat = seat,
	lastAction = lastAction.toProtocol(),
	remainingStack = remainingStack.toProtocol(),
	bettingStack = bettingStack.toProtocol(),
)
fun protocol.poker.RoundSnapshotSeat.toDomain() = RoundSnapshotSeat(
	seat = seat,
	lastAction = lastAction.toDomain(),
	remainingStack = remainingStack.toDomain(),
	bettingStack = bettingStack.toDomain(),
)

// converters for RingSnapshot
fun RingSnapshot.toProtocol() = protocol.poker.RingSnapshot(
	created = created,
	started = started,
	playerIds = playerIds,
	paused = paused,
	startInstant = startInstant,
	pauseInstant = pauseInstant,
	blindLevel = blindLevel.toProtocol(),
	handCount = handCount,
	ended = ended,
	buyIn = buyIn.mapValues { it.value.toProtocol() },
	hostPlayerId = hostPlayerId,
	ringGameSettings = ringGameSettings.toProtocol(),
	pokerSettings = pokerSettings.toProtocol(),
)
fun protocol.poker.RingSnapshot.toDomain() = RingSnapshot(
	created = created,
	started = started,
	playerIds = playerIds,
	paused = paused,
	startInstant = startInstant,
	pauseInstant = pauseInstant,
	blindLevel = blindLevel.toDomain(),
	handCount = handCount,
	ended = ended,
	buyIn = buyIn.mapValues { it.value.toDomain() },
	hostPlayerId = hostPlayerId,
	ringGameSettings = ringGameSettings.toDomain(),
	pokerSettings = pokerSettings.toDomain(),
)

