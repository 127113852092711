package studio.lostjoker.smartdealer.ui.components

import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.width
import androidx.compose.material3.HorizontalDivider
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp

@Composable
fun VerticalDivider(color: Color?) {
    if (color == null) {
        HorizontalDivider(
            modifier = Modifier
                .fillMaxHeight()
                .width(2.dp)
        )
    } else {
        HorizontalDivider(
            modifier = Modifier
                .fillMaxHeight()
                .width(2.dp),
            color = color
        )
    }
}
