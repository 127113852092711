package studio.lostjoker.smartdealer.domain.poker.model

import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.toFixed2

data class RingGameSettings(
    val allowStraddle: Boolean,
    val buyIn: RingBuyInSettings,
) {
    companion object {
        internal val Empty = RingGameSettings(
            allowStraddle = false,
            buyIn = RingBuyInSettings.ImplicitBuyIn(0.toFixed2()),
        )
    }
}

sealed interface RingBuyInSettings {
    data class RangeBuyIn(val minBuyIn: Fixed2, val maxBuyIn: Fixed2) : RingBuyInSettings

    data class ImplicitBuyIn(val buyIn: Fixed2) : RingBuyInSettings
}
