package studio.lostjoker.smartdealer.domain.poker.model

import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.toFixed2


data class TournamentSettings(
    val minPlayers: Int,
    val maxPlayers: Int,
    val startTrigger: TournamentStartTrigger,
    val registrationStack: Fixed2,
    val registration: TournamentRegistrationSettings,
    val lateRegistration: TournamentLateRegistration,
    val rebuy: TournamentRebuyConfig,
    val prize: TournamentPrizeSettings,
) {
    companion object {
        internal val Empty = TournamentSettings(
            minPlayers = 0,
            maxPlayers = 0,
            startTrigger = TournamentStartTrigger.Manual,
            registrationStack = 0.toFixed2(),
            registration = TournamentRegistrationSettings.ImplicitBuyIn(0.toFixed2()),
            lateRegistration = TournamentLateRegistration.Disabled,
            rebuy = TournamentRebuyConfig.Disabled,
            prize = TournamentPrizeSettings.Payout(emptyMap()),
        )
    }
}

sealed interface TournamentStartTrigger {
    data object Manual : TournamentStartTrigger

    // we may have OnDemand, Scheduled
}

sealed interface TournamentRegistrationSettings {
    data class ImplicitBuyIn(
        val buyIn: Fixed2,
    ) : TournamentRegistrationSettings
}

sealed interface TournamentLateRegistration {
    data object Disabled : TournamentLateRegistration
    data class BlindLevels(val numberOfLevels: Int) : TournamentLateRegistration
}

sealed interface TournamentRebuyConfig {
    data object Disabled : TournamentRebuyConfig
    data class Enabled(
        val rebuyAvailability: RebuyAvailability,
        val rebuyLimit: Int,
        val maxStackForRebuy: Fixed2,
    ) : TournamentRebuyConfig
}

sealed interface RebuyAvailability {
    data object Unlimited : RebuyAvailability
    data class NumberOfBlindLevels(val number: Int) : RebuyAvailability
}

sealed interface TournamentPrizeSettings {
    data class Payout(
        val payoutStructure: Map<ClosedRange<Int>, Map<Int, Double>>,
    ) : TournamentPrizeSettings
}
