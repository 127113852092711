@file:UseSerializers(
    UuidStringSerializer::class,
    IntClosedRangeStringSerializer::class,
)

package protocol.poker

import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import protocol.IntClosedRangeStringSerializer
import protocol.UuidStringSerializer

@Serializable
data class RingGameSettings(
    val allowStraddle: Boolean,
    val buyIn: RingBuyInSettings,
)

@Serializable
sealed interface RingBuyInSettings {
    @Serializable
    data class RangeBuyIn(val minBuyIn: Fixed2, val maxBuyIn: Fixed2) : RingBuyInSettings

    @Serializable
    data class ImplicitBuyIn(val buyIn: Fixed2) : RingBuyInSettings
}
