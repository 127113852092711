package studio.lostjoker.smartdealer.ui.poker.host_game.components

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.text.selection.LocalTextSelectionColors
import androidx.compose.foundation.text.selection.TextSelectionColors
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.onFocusChanged
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.platform.LocalTextToolbar
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.ui.components.TwoSplitRow
import studio.lostjoker.smartdealer.ui.poker.devices.player.components.EmptyTextToolbar
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun BuyInSelector(
    buyIn: Int,
    onChangeBuyIn: (newBuyIn: Int) -> Unit,
    textFieldFocused: (isFocused: Boolean) -> Unit,
) {
    val focusManager = LocalFocusManager.current
    val keyboardController = LocalSoftwareKeyboardController.current

    var buyInValue by remember(buyIn) { mutableStateOf(buyIn.toString()) }
    val customTextSelectionColors = TextSelectionColors(handleColor = Color.Transparent, backgroundColor = Color.Transparent)

    TwoSplitRow(
        firstColumnContent = {
            Text(
                text = stringResource(Res.string.poker_settings_buy_in),
                style = PokerAppTheme.typography.titleMedium,
            )
        },
        secondColumnContent = {
            Row(
                modifier = Modifier.wrapContentHeight(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center,
            ) {
                CompositionLocalProvider(
                    LocalTextToolbar provides EmptyTextToolbar,
                    LocalTextSelectionColors provides customTextSelectionColors,
                ) {
                    BasicTextField(
                        modifier = Modifier
                            .width(100.dp)
                            .onFocusChanged { focusState ->
                                textFieldFocused(focusState.isFocused)
                                buyInValue = if (focusState.isFocused) {
                                    buyIn.toString()
                                } else {
                                    buyInValue.toIntOrNull()?.let { value ->
                                        if (value > 0) {
                                            onChangeBuyIn(value)
                                            return@onFocusChanged
                                        }
                                    }
                                    buyIn.toString()
                                }
                            },
                        value = buyInValue,
                        onValueChange = {
                            if (it.isEmpty() || it.toIntOrNull() != null) {
                                buyInValue = it
                            }
                        },
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Number,
                            imeAction = ImeAction.Done,
                        ),
                        keyboardActions = KeyboardActions(
                            onDone = {
                                keyboardController?.hide()
                                focusManager.clearFocus()
                            },
                        ),
                        singleLine = true,
                        textStyle = PokerAppTheme.typography.labelLarge.copy(
                            color = PokerAppTheme.colors.onPrimary,
                            textAlign = TextAlign.Center,
                        ),
                        decorationBox = { innerTextField ->
                            Row(
                                modifier = Modifier
                                    .background(
                                        color = PokerAppTheme.colors.primary,
                                        shape = RoundedCornerShape(5.dp),
                                    ),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.Center,
                            ) {
                                innerTextField()
                            }
                        },
                    )
                }
            }
        },
    )
}
