package studio.lostjoker.smartdealer.ui.poker.host_game.components

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.text.BasicTextField
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material.icons.filled.Remove
import androidx.compose.material3.FilledIconButton
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButtonDefaults
import androidx.compose.material3.Switch
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.pluralStringResource
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.helpers.LocalSnackbar
import studio.lostjoker.smartdealer.ui.components.TwoSplitRow
import studio.lostjoker.smartdealer.ui.poker.host_game.RebuyConfig
import studio.lostjoker.smartdealer.ui.poker.host_game.SitAndGoSettings
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun RebuySelector(
    sitAndGoSettings: SitAndGoSettings,
    minRebuys: Int = 1,
    maxRebuys: Int = 6,
    minBlindLevel: Int = 1,
    maxBlindLevel: Int = 10,
    onChangeRebuyConfig: (rebuyConfig: RebuyConfig) -> Unit,
) {
    val snackbarDelegate = LocalSnackbar.current

    var showRebuyConfig by remember { mutableStateOf(sitAndGoSettings.rebuyConfig.checked) }

    var numberOfRebuys by remember(sitAndGoSettings.rebuyConfig) { mutableStateOf(sitAndGoSettings.rebuyConfig.numberOfRebuys) }
    var numberOfLevels by remember(sitAndGoSettings.rebuyConfig) { mutableStateOf(sitAndGoSettings.rebuyConfig.numberOfLevels) }

    val rebuyCannotBeActivated = stringResource(Res.string.poker_settings_rebuy_cannot_be_activated)
    val rebuyCannotBeDeactivated = stringResource(Res.string.poker_settings_rebuy_cannot_be_deactivated)

    Column(
        modifier = Modifier.fillMaxWidth(),
    ) {
        TwoSplitRow(
            firstColumnContent = {
                Text(
                    text = stringResource(Res.string.poker_settings_rebuy),
                    style = PokerAppTheme.typography.titleMedium,
                )
            },
            secondColumnContent = {
                Switch(
                    checked = showRebuyConfig,
                    onCheckedChange = {
                        if (sitAndGoSettings.lateRegistration.numberOfLevels == 0) {
                            snackbarDelegate.showSnackbar(rebuyCannotBeActivated)
                        } else if (minRebuys > 1) {
                            snackbarDelegate.showSnackbar(rebuyCannotBeDeactivated)
                        } else {
                            showRebuyConfig = it
                            onChangeRebuyConfig(sitAndGoSettings.rebuyConfig.copy(checked = it))
                        }
                    },
                )
            },
        )

        if (showRebuyConfig) {
            TwoSplitRow(
                firstColumnContent = {
                    Text(
                        modifier = Modifier.padding(start = PokerAppTheme.dimensions.grid_0_5),
                        text = stringResource(Res.string.poker_settings_rebuy_limit),
                        style = PokerAppTheme.typography.labelLarge,
                    )
                },
                secondColumnContent = {
                    Row(
                        modifier = Modifier.wrapContentHeight(),
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        FilledIconButton(
                            modifier = Modifier.size(PokerAppTheme.dimensions.grid_2),
                            colors = IconButtonDefaults.filledIconButtonColors(containerColor = PokerAppTheme.colors.primary),
                            onClick = {
                                if (numberOfRebuys > minRebuys) {
                                    numberOfRebuys -= 1
                                    onChangeRebuyConfig(sitAndGoSettings.rebuyConfig.copy(numberOfRebuys = numberOfRebuys))
                                }
                            },
                        ) {
                            Icon(Icons.Filled.Remove, "Minus")
                        }
                        BasicTextField(
                            modifier = Modifier.width(100.dp),
                            value = "",
                            onValueChange = { },
                            decorationBox = {
                                Text(
                                    text = if (numberOfRebuys == maxRebuys) {
                                        stringResource(Res.string.poker_settings_rebuy_limit_unlimited)
                                    } else {
                                        pluralStringResource(Res.plurals.poker_settings_rebuy_limit_times, numberOfRebuys, numberOfRebuys)
                                    },
                                    style = PokerAppTheme.typography.labelLarge,
                                    textAlign = TextAlign.Center,
                                )
                            },
                            enabled = false,
                        )
                        FilledIconButton(
                            modifier = Modifier.size(PokerAppTheme.dimensions.grid_2),
                            colors = IconButtonDefaults.filledIconButtonColors(containerColor = PokerAppTheme.colors.primary),
                            onClick = {
                                if (numberOfRebuys < maxRebuys) {
                                    numberOfRebuys += 1
                                    onChangeRebuyConfig(sitAndGoSettings.rebuyConfig.copy(numberOfRebuys = numberOfRebuys))
                                }
                            },
                        ) {
                            Icon(Icons.Filled.Add, "Plus")
                        }
                    }
                },
            )

            // FIXME Rebuy duration is based on late registration's number of levels for now

            /*

            Spacer(modifier = Modifier.height(PokerAppTheme.dimensions.grid_1))

            TwoSplitRow(
                firstColumnContent = {
                    Text(
                        text = stringResource(Res.string.poker_settings_rebuy_duration),
                        style = PokerAppTheme.typography.titleMedium,
                    )
                },
                secondColumnContent = {
                    Row(
                        modifier = Modifier.wrapContentHeight(),
                        verticalAlignment = Alignment.CenterVertically,
                    ) {

                        FilledIconButton(
                            modifier = Modifier.size(PokerAppTheme.dimensions.grid_2),
                            colors = IconButtonDefaults.filledIconButtonColors(containerColor = PokerAppTheme.colors.primary),
                            onClick = {
                                if (numberOfLevels > minLevels) {
                                    numberOfLevels -= 1
                                    changeRebuyConfig(sitAndGoSettings.rebuyConfig.copy(numberOfLevels = numberOfLevels))
                                }
                            },
                        ) {
                            Icon(Icons.Filled.Remove, "Minus")
                        }
                        BasicTextField(
                            modifier = Modifier.width(100.dp),
                            value = "",
                            onValueChange = { },
                            decorationBox = {
                                Text(
                                    text = when (sitAndGoSettings.blindLevelUp) {
                                        BlindLevelUp.Time -> {
                                            "${numberOfLevels * findMinutes(sitAndGoSettings.blindSpeed)} " + stringResource(Res.string.poker_settings_rebuy_duration_times)
                                        }
                                        BlindLevelUp.Hands -> {
                                            "${numberOfLevels * findHands(sitAndGoSettings.blindSpeed)} " + stringResource(Res.string.poker_settings_rebuy_duration_hands)
                                        }
                                    },
                                    style = PokerAppTheme.typography.labelLarge,
                                    textAlign = TextAlign.Center,
                                )
                            },
                            enabled = false,
                        )
                        FilledIconButton(
                            modifier = Modifier.size(PokerAppTheme.dimensions.grid_2),
                            colors = IconButtonDefaults.filledIconButtonColors(containerColor = PokerAppTheme.colors.primary),
                            onClick = {
                                if (numberOfLevels < maxLevels) {
                                    numberOfLevels += 1
                                    changeRebuyConfig(sitAndGoSettings.rebuyConfig.copy(numberOfLevels = numberOfLevels))
                                }
                            },
                        ) {
                            Icon(Icons.Filled.Add, "Plus")
                        }
                    }
                },
            )
            */
        } else {
            onChangeRebuyConfig(RebuyConfig())
        }
    }

}
