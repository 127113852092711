package studio.lostjoker.smartdealer.ui.poker.devices.common.model

import studio.lostjoker.smartdealer.AppLanguage
import studio.lostjoker.smartdealer.domain.Fixed2
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevel
import studio.lostjoker.smartdealer.helpers.format
import studio.lostjoker.smartdealer.helpers.isInteger

enum class Position {
    UTG,
    UTG1,
    MP1,
    MP2,
    MP3,
    HJ,
    CO,
    D,
    SB,
    BB,
}

data class BettingSize(
    val min: Fixed2,
    val max: Fixed2,
    val amount: Fixed2,
)

fun BlindLevel.getRingGameFormat(appLanguage: AppLanguage): String {
    val digits = if (isInteger(smallBlind.asDouble()) && isInteger(bigBlind.asDouble())) 0 else 2
    return "${smallBlind.asDouble().format(digits, appLanguage)}/${bigBlind.asDouble().format(digits, appLanguage)}${
        if (ante.asDouble() > 0) "/${ante.asDouble().format(digits, appLanguage)}" else ""
    }"
}


fun BlindLevel.getTournamentFormat(appLanguage: AppLanguage): String {
    return "${smallBlind.asDouble().format(0, appLanguage)}/${bigBlind.asDouble().format(0, appLanguage)}${
        if (ante.asDouble() > 0) "/${ante.asDouble().format(0, appLanguage)}" else ""
    }"
}

fun BlindLevel.getTournamentFormatWithoutAnte(appLanguage: AppLanguage): String {
    return "${smallBlind.asDouble().format(0, appLanguage)}/${bigBlind.asDouble().format(0, appLanguage)}"
}
