package studio.lostjoker.smartdealer.helpers

import studio.lostjoker.smartdealer.AppLanguage
import kotlin.math.abs
import kotlin.math.floor
import kotlin.math.log10
import kotlin.math.pow

fun Double.format(digits: Int? = null, appLanguage: AppLanguage? = null): String {
    val suffix = charArrayOf(' ', 'k', 'M', 'B', 'T', 'P', 'E')

    val numValue = this.toLong()
    val value = floor(log10(numValue.toDouble())).toInt()
    val base = value / 3

    return if (value > 3 && base < suffix.size) {
        DecimalFormat.format(double = numValue / 10.0.pow((base * 3).toDouble()), digits = digits, appLanguage = appLanguage) + suffix[base]
    } else {
        DecimalFormat.format(double = this, digits = digits, appLanguage = appLanguage)
    }
}

fun isInteger(number: Double): Boolean {
    return abs(number % 1.0) < 0.000001
}
