package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.foundation.Image
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import org.jetbrains.compose.resources.painterResource
import smartdealer.appshared.generated.resources.*
import studio.lostjoker.smartdealer.ui.poker.devices.common.model.Position

@Composable
fun PositionButton(
    modifier: Modifier,
    position: Position,
) {
    val button = when (position) {
        Position.D -> painterResource(Res.drawable.button_dealer)
        Position.SB -> painterResource(Res.drawable.button_sb)
        Position.BB -> painterResource(Res.drawable.button_bb)
        else -> null
    }
    button?.let {
        Image(
            modifier = modifier,
            painter = button,
            contentDescription = "Button",
        )
    }
}
