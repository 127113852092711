package studio.lostjoker.smartdealer.helpers

import androidx.compose.runtime.Composable
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.api_error_game_not_found
import smartdealer.appshared.generated.resources.api_error_unknown
import studio.lostjoker.smartdealer.game_management.web.contract.GameManagementApiErrorCode

@Composable
fun getGameManagementError(code: GameManagementApiErrorCode) : String {
    return when (code) {
        GameManagementApiErrorCode.GameNotFound -> stringResource(Res.string.api_error_game_not_found)
        else -> stringResource(Res.string.api_error_unknown)
    }
}
