package studio.lostjoker.smartdealer.helpers

import androidx.compose.runtime.Composable


class JsScreenshot : ScreenshotInterface {
    override fun takeScreenshot(): String? {
        // todo: implement web screenshot
        return ""
    }
}

@Composable
actual fun screenshotHelper(): ScreenshotInterface {
    return JsScreenshot()
}
