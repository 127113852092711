package studio.lostjoker.smartdealer.ui.poker.devices.common.components

import androidx.compose.animation.AnimatedContent
import androidx.compose.animation.AnimatedContentTransitionScope
import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.tween
import androidx.compose.animation.togetherWith
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.style.TextAlign
import kotlinx.coroutines.delay
import org.jetbrains.compose.resources.stringResource
import smartdealer.appshared.generated.resources.Res
import smartdealer.appshared.generated.resources.poker_high_hand_rank_flush
import smartdealer.appshared.generated.resources.poker_high_hand_rank_four_of_a_kind
import smartdealer.appshared.generated.resources.poker_high_hand_rank_full_house
import smartdealer.appshared.generated.resources.poker_high_hand_rank_high_card
import smartdealer.appshared.generated.resources.poker_high_hand_rank_pair
import smartdealer.appshared.generated.resources.poker_high_hand_rank_royal_flush
import smartdealer.appshared.generated.resources.poker_high_hand_rank_straight
import smartdealer.appshared.generated.resources.poker_high_hand_rank_straight_flush
import smartdealer.appshared.generated.resources.poker_high_hand_rank_three_of_a_kind
import smartdealer.appshared.generated.resources.poker_high_hand_rank_two_pair
import smartdealer.appshared.generated.resources.poker_high_hand_rank_unranked
import smartdealer.appshared.generated.resources.poker_low_hand_rank_eight_low
import smartdealer.appshared.generated.resources.poker_low_hand_rank_five_low
import smartdealer.appshared.generated.resources.poker_low_hand_rank_seven_low
import smartdealer.appshared.generated.resources.poker_low_hand_rank_six_low
import smartdealer.appshared.generated.resources.poker_low_hand_rank_unranked
import smartdealer.appshared.generated.resources.poker_player_notification_collected
import smartdealer.appshared.generated.resources.poker_player_notification_from
import smartdealer.appshared.generated.resources.poker_player_notification_with
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.Rank
import studio.lostjoker.smartdealer.domain.poker.HighHandRank
import studio.lostjoker.smartdealer.domain.poker.LowHandRank
import studio.lostjoker.smartdealer.domain.poker.model.HighLow
import studio.lostjoker.smartdealer.domain.poker.model.Pot
import studio.lostjoker.smartdealer.domain.poker.model.PotSharePlayerProjection
import studio.lostjoker.smartdealer.domain.poker.rankedHighHand
import studio.lostjoker.smartdealer.domain.poker.rankedLowHand
import studio.lostjoker.smartdealer.domain.sumOf
import studio.lostjoker.smartdealer.domain.toFixed2
import studio.lostjoker.smartdealer.getUserLanguage
import studio.lostjoker.smartdealer.helpers.format
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.Player
import studio.lostjoker.smartdealer.ui.poker.devices.common.state.WinningHandDetails
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun Pots(
    tableOnlineLayout: Boolean = false,
    deckStart: Rank = Rank.Two,
    players: List<Player> = emptyList(),
    potContributions: List<Pot> = emptyList(),
    potShares: List<PotSharePlayerProjection> = emptyList(),
    degrees: Float = 0f,
    amountTextStyle: TextStyle = TextStyle(),
    roundEnded: Boolean = false,
    playingHand: Boolean = false,
    fractionDigits: Int = 0,
    updateWinningHandDetails: (WinningHandDetails) -> Unit = {},
) {
    val userLanguage = getUserLanguage()

    val collected = stringResource(Res.string.poker_player_notification_collected)
    val from = stringResource(Res.string.poker_player_notification_from)
    val with = stringResource(Res.string.poker_player_notification_with)
    val highUnranked = stringResource(Res.string.poker_high_hand_rank_unranked)
    val highCard = stringResource(Res.string.poker_high_hand_rank_high_card)
    val pair = stringResource(Res.string.poker_high_hand_rank_pair)
    val twoPair = stringResource(Res.string.poker_high_hand_rank_two_pair)
    val threeOfAKind = stringResource(Res.string.poker_high_hand_rank_three_of_a_kind)
    val straight = stringResource(Res.string.poker_high_hand_rank_straight)
    val flush = stringResource(Res.string.poker_high_hand_rank_flush)
    val fullHouse = stringResource(Res.string.poker_high_hand_rank_full_house)
    val fourOfAKind = stringResource(Res.string.poker_high_hand_rank_four_of_a_kind)
    val royalFlush = stringResource(Res.string.poker_high_hand_rank_royal_flush)
    val straightFlush = stringResource(Res.string.poker_high_hand_rank_straight_flush)

    val lowUnranked = stringResource(Res.string.poker_low_hand_rank_unranked)
    val eightLow = stringResource(Res.string.poker_low_hand_rank_eight_low)
    val sevenLow = stringResource(Res.string.poker_low_hand_rank_seven_low)
    val sixLow = stringResource(Res.string.poker_low_hand_rank_six_low)
    val fiveLow = stringResource(Res.string.poker_low_hand_rank_five_low)

    var count by remember(potShares) { mutableStateOf(0) }
    var notification by remember(potShares) { mutableStateOf("") }

    var potsHistory: List<List<Pot>> by remember { mutableStateOf(emptyList()) }

    if (potsHistory.singleOrNull { it == potContributions } == null) {
        potsHistory += listOf(potContributions)
    }

    val previousPots = if (potsHistory.size > 1) potsHistory.dropLast(1).last() else emptyList()
    val currentPots = potsHistory.last()

    BoxWithConstraints(
        modifier = Modifier
            .fillMaxWidth()
            .rotate(degrees),
        contentAlignment = Alignment.Center,
    ) {
        val minNumberOfPots = 4
        val maxNumberOfPots = maxOf(minNumberOfPots, currentPots.size)
        val potPadding = PokerAppTheme.dimensions.grid_1
        val potMaxWidth = if (!tableOnlineLayout) {
            (maxWidth - potPadding * maxNumberOfPots) / maxNumberOfPots
        } else {
            (maxWidth - potPadding * 2) / 2
        }
        val potMaxHeight = if (!tableOnlineLayout) {
            maxHeight
        } else {
            (maxHeight - potPadding * 5) / 5
        }
        FlowRow(
            modifier = Modifier.fillMaxSize(),
            verticalArrangement = if (!tableOnlineLayout) Arrangement.Center else Arrangement.spacedBy(potPadding, Alignment.Bottom),
            horizontalArrangement = Arrangement.spacedBy(potPadding, Alignment.CenterHorizontally),
        ) {
            currentPots.forEachIndexed { index, currentPot ->
                val previousPot = previousPots.singleOrNull { it.seats == currentPot.seats }
                val potAmount by remember { mutableStateOf(Animatable(0f)) }
                LaunchedEffect(key1 = currentPot, key2 = playingHand) {
                    potAmount.snapTo(
                        if (roundEnded && playingHand) {
                            previousPot?.amount ?: 0.toFixed2()
                        } else {
                            currentPot.amount
                        }.asFloat(),
                    )
                    delay(250)
                    potAmount.animateTo(
                        targetValue = currentPot.amount.asFloat(),
                        animationSpec = tween(durationMillis = 750),
                    )
                    if (!potAmount.isRunning && !playingHand) {
                        potAmount.animateTo(
                            targetValue = 0f,
                            animationSpec = tween(durationMillis = 750),
                        )
                        potsHistory = emptyList()
                    }
                }
                Box(
                    modifier = Modifier
                        .width(potMaxWidth)
                        .height(potMaxHeight)
                        .clip(MaterialTheme.shapes.medium),
                    contentAlignment = Alignment.Center,
                ) {
                    val potIndex = if (maxNumberOfPots == minNumberOfPots || tableOnlineLayout) {
                        if (currentPots.size > 1) {
                            "Pot ${index + 1}: "
                        } else {
                            "Pot: "
                        }
                    } else {
                        ""
                    }
                    Text(
                        text = potIndex + potAmount.value.toDouble().format(digits = fractionDigits, appLanguage = userLanguage),
                        style = amountTextStyle,
                        color = Color.White,
                    )
                }
            }
        }
        if (!playingHand) {
            if (potShares.isNotEmpty()) {
                LaunchedEffect(key1 = count) {
                    val index = count % potShares.size

                    val playerSeat = potShares[index].seat
                    val player = players.single { it.seat == playerSeat }

                    val numberOfPots = potShares.distinctBy { it.pot }.count()
                    val pot = potShares[index].pot
                    val totalPot = potShares.filter { it.pot == pot }.sumOf { it.amount }

                    val handRank = if (potShares[index].highLow == HighLow.High) {
                        when (potShares[index].hand?.rankedHighHand(deckStart)?.rank) {
                            HighHandRank.Unranked -> highUnranked
                            HighHandRank.HighCard -> highCard
                            HighHandRank.Pair -> pair
                            HighHandRank.TwoPair -> twoPair
                            HighHandRank.ThreeOfAKind -> threeOfAKind
                            HighHandRank.Straight -> straight
                            HighHandRank.Flush -> flush
                            HighHandRank.FullHouse -> fullHouse
                            HighHandRank.FourOfAKind -> fourOfAKind
                            HighHandRank.StraightFlush -> {
                                if (potShares[index].hand?.singleOrNull { it.rank == Rank.A } != null) {
                                    royalFlush
                                } else {
                                    straightFlush
                                }
                            }

                            null -> null
                        }
                    } else {
                        when (potShares[index].hand?.rankedLowHand(deckStart)?.rank) {
                            LowHandRank.Unranked -> lowUnranked
                            LowHandRank.EightLow -> eightLow
                            LowHandRank.SevenLow -> sevenLow
                            LowHandRank.SixLow -> sixLow
                            LowHandRank.FiveLow -> fiveLow

                            null -> null
                        }
                    }

                    val timeMillis = if (count == 0) 2000L else 3000L

                    delay(timeMillis)

                    updateWinningHandDetails(
                        WinningHandDetails(
                            seat = potShares[index].seat,
                            cards = potShares[index].hand?.map { card -> DealtRankedCardPlayerView.FaceUp(card) } ?: emptyList(),
                            rank = handRank,
                        )
                    )

                    val potIndex = if (numberOfPots > 1) {
                        "Pot ${pot + 1}"
                    } else {
                        "Pot"
                    }

                    notification = player.screenName +
                            " $collected " + potShares[index].amount.asDouble().format(digits = fractionDigits, appLanguage = userLanguage) +
                            " $from $potIndex (${totalPot.asDouble().format(digits = fractionDigits, appLanguage = userLanguage)}) " +
                            if (handRank != null) "$with $handRank" else ""

                    count++
                }

                AnimatedContent(
                    targetState = notification,
                    transitionSpec = {
                        slideIntoContainer(
                            towards = AnimatedContentTransitionScope.SlideDirection.Up,
                            animationSpec = tween(durationMillis = 500),
                        ) togetherWith
                                slideOutOfContainer(
                                    towards = AnimatedContentTransitionScope.SlideDirection.Up,
                                    animationSpec = tween(durationMillis = 500),
                                    targetOffset = { fullSlideOffset ->
                                        if (!tableOnlineLayout) {
                                            fullSlideOffset
                                        } else {
                                            fullSlideOffset / 4
                                        }
                                    },
                                )
                    },
                    contentAlignment = Alignment.Center,
                ) { target ->
                    Text(
                        text = target,
                        textAlign = TextAlign.Center,
                        style = amountTextStyle,
                        color = Color.White,
                    )
                }
            }
        }
    }
}
