package studio.lostjoker.smartdealer.ui.theme

import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

class Dimensions(
    val grid_0_25: Dp,
    val grid_0_5: Dp,
    val grid_1: Dp,
    val grid_1_5: Dp,
    val grid_2: Dp,
    val grid_2_5: Dp,
    val grid_3: Dp,
    val grid_3_5: Dp,
    val grid_4: Dp,
    val grid_4_5: Dp,
    val grid_5: Dp,
    val grid_5_5: Dp,
    val grid_6: Dp,
    val plane_0: Dp,
    val plane_1: Dp,
    val plane_2: Dp,
    val plane_3: Dp,
    val plane_4: Dp,
    val plane_5: Dp,
    val playerTableSeatPlayerDeviceWidth: Dp,
    val playerTableSeatPlayerDeviceAvatarSize: Dp = playerTableSeatPlayerDeviceWidth * 0.35f,
    val playerTableSeatPlayerDeviceCardSize: Dp = playerTableSeatPlayerDeviceWidth * 0.25f,
    val playerTableSeatPlayerDevicePlayerInfoWidth: Dp = playerTableSeatPlayerDeviceWidth * 0.7f,
    val playerTableSeatPlayerDevicePlayerInfoHeight: Dp = playerTableSeatPlayerDeviceWidth * 0.4f,
    val playerTableSeatPlayerDevicePositionButtonSize: Dp = playerTableSeatPlayerDeviceWidth * 0.20f,
    val playerTableSeatPlayerDeviceActionSize: Dp = playerTableSeatPlayerDeviceWidth * 0.7f,
    val playerTableSeatTableDeviceWidth: Dp,
    val playerTableSeatTableDeviceAvatarSize: Dp = playerTableSeatTableDeviceWidth * 0.4f,
    val playerTableSeatTableDeviceCardSize: Dp = playerTableSeatTableDeviceWidth * 0.25f,
    val playerTableSeatTableDevicePlayerInfoWidth: Dp = playerTableSeatTableDeviceWidth * 0.7f,
    val playerTableSeatTableDevicePlayerInfoHeight: Dp = playerTableSeatTableDeviceWidth * 0.4f,
    val playerTableSeatTableDevicePositionButtonSize: Dp = playerTableSeatTableDeviceWidth * 0.20f,
    val playerTableSeatTableDeviceActionSize: Dp = playerTableSeatTableDeviceWidth * 0.7f,
    val playerDeviceCardSize: Dp,
    val playerDeviceAvatarSize: Dp,
    val playerDevicePlayerInfoSizeWidth: Dp = playerDeviceAvatarSize * 1.5f,
    val playerDevicePlayerInfoSizeHeight: Dp = playerDeviceAvatarSize * 0.8f,
    val playerDevicePositionButtonSize: Dp = playerDeviceAvatarSize * 0.3f,
    val playerDeviceActionSize: Dp,
    val playerDeviceBetSliderWidth: Dp,
    val tableDeviceAvatarSize: Dp,
    val tableDeviceCardSize: Dp = tableDeviceAvatarSize * 0.3f,
    val tableDevicePositionButtonSize: Dp = tableDeviceAvatarSize * 0.3f,
    val tableDeviceScreenNameSize: Dp = tableDeviceAvatarSize * 1f,
    val tableDeviceStackSize: Dp = tableDeviceAvatarSize * 1f,
    val tableDeviceActionSize: Dp = tableDeviceAvatarSize * 1f,
    val tableSeatingAvatarSize: Dp,
    val tableSeatingScreenNameSize: Dp = tableSeatingAvatarSize * 2f,
)

// screenWidthDp less than 360.dp
val smallCompactDimensions = Dimensions(
    grid_0_25 = 1.5f.dp,
    grid_0_5 = 3.dp,
    grid_1 = 6.dp,
    grid_1_5 = 9.dp,
    grid_2 = 12.dp,
    grid_2_5 = 15.dp,
    grid_3 = 18.dp,
    grid_3_5 = 21.dp,
    grid_4 = 24.dp,
    grid_4_5 = 27.dp,
    grid_5 = 30.dp,
    grid_5_5 = 33.dp,
    grid_6 = 36.dp,
    plane_0 = 0.dp,
    plane_1 = 1.dp,
    plane_2 = 2.dp,
    plane_3 = 3.dp,
    plane_4 = 6.dp,
    plane_5 = 12.dp,
    playerTableSeatPlayerDeviceWidth = 70.dp,
    playerTableSeatTableDeviceWidth = 140.dp,
    playerDeviceCardSize = 200.dp,
    playerDeviceAvatarSize = 80.dp,
    playerDeviceActionSize = 100.dp,
    playerDeviceBetSliderWidth = 270.dp,
    tableDeviceAvatarSize = 100.dp,
    tableSeatingAvatarSize = 50.dp,
)

// screenWidthDp at least 360.dp
val compactDimensions = Dimensions(
    grid_0_25 = 2.dp,
    grid_0_5 = 4.dp,
    grid_1 = 8.dp,
    grid_1_5 = 12.dp,
    grid_2 = 16.dp,
    grid_2_5 = 20.dp,
    grid_3 = 24.dp,
    grid_3_5 = 28.dp,
    grid_4 = 32.dp,
    grid_4_5 = 36.dp,
    grid_5 = 40.dp,
    grid_5_5 = 44.dp,
    grid_6 = 48.dp,
    plane_0 = 0.dp,
    plane_1 = 1.dp,
    plane_2 = 2.dp,
    plane_3 = 4.dp,
    plane_4 = 8.dp,
    plane_5 = 16.dp,
    playerTableSeatPlayerDeviceWidth = 70.dp,
    playerTableSeatTableDeviceWidth = 140.dp,
    playerDeviceCardSize = 200.dp,
    playerDeviceAvatarSize = 80.dp,
    playerDeviceActionSize = 100.dp,
    playerDeviceBetSliderWidth = 270.dp,
    tableDeviceAvatarSize = 100.dp,
    tableSeatingAvatarSize = 50.dp,
)

val mediumDimensions = Dimensions(
    grid_0_25 = 2.5f.dp,
    grid_0_5 = 5.dp,
    grid_1 = 10.dp,
    grid_1_5 = 15.dp,
    grid_2 = 20.dp,
    grid_2_5 = 25.dp,
    grid_3 = 30.dp,
    grid_3_5 = 35.dp,
    grid_4 = 40.dp,
    grid_4_5 = 45.dp,
    grid_5 = 50.dp,
    grid_5_5 = 55.dp,
    grid_6 = 60.dp,
    plane_0 = 0.dp,
    plane_1 = 1.dp,
    plane_2 = 3.dp,
    plane_3 = 5.dp,
    plane_4 = 10.dp,
    plane_5 = 20.dp,
    playerTableSeatPlayerDeviceWidth = 70.dp,
    playerTableSeatTableDeviceWidth = 140.dp,
    playerDeviceCardSize = 200.dp,
    playerDeviceAvatarSize = 80.dp,
    playerDeviceActionSize = 100.dp,
    playerDeviceBetSliderWidth = 270.dp,
    tableDeviceAvatarSize = 100.dp,
    tableSeatingAvatarSize = 50.dp,
)

val expandedDimensions = Dimensions(
    grid_0_25 = 3.dp,
    grid_0_5 = 6.dp,
    grid_1 = 12.dp,
    grid_1_5 = 18.dp,
    grid_2 = 24.dp,
    grid_2_5 = 30.dp,
    grid_3 = 36.dp,
    grid_3_5 = 42.dp,
    grid_4 = 48.dp,
    grid_4_5 = 54.dp,
    grid_5 = 60.dp,
    grid_5_5 = 66.dp,
    grid_6 = 62.dp,
    plane_0 = 0.dp,
    plane_1 = 1.dp,
    plane_2 = 4.dp,
    plane_3 = 6.dp,
    plane_4 = 12.dp,
    plane_5 = 24.dp,
    playerTableSeatPlayerDeviceWidth = 70.dp,
    playerTableSeatTableDeviceWidth = 140.dp,
    playerDeviceCardSize = 200.dp,
    playerDeviceAvatarSize = 80.dp,
    playerDeviceActionSize = 100.dp,
    playerDeviceBetSliderWidth = 270.dp,
    tableDeviceAvatarSize = 100.dp,
    tableSeatingAvatarSize = 50.dp,
)
