@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package smartdealer.appshared.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val anonymous_profile: DrawableResource by 
      lazy { init_anonymous_profile() }

  public val apple_logo: DrawableResource by 
      lazy { init_apple_logo() }

  public val avatar_afro: DrawableResource by 
      lazy { init_avatar_afro() }

  public val avatar_alien: DrawableResource by 
      lazy { init_avatar_alien() }

  public val avatar_croupier: DrawableResource by 
      lazy { init_avatar_croupier() }

  public val avatar_gambler: DrawableResource by 
      lazy { init_avatar_gambler() }

  public val avatar_joker: DrawableResource by 
      lazy { init_avatar_joker() }

  public val avatar_ozzy: DrawableResource by 
      lazy { init_avatar_ozzy() }

  public val avatar_profile: DrawableResource by 
      lazy { init_avatar_profile() }

  public val avatar_robot: DrawableResource by 
      lazy { init_avatar_robot() }

  public val avatar_zombie: DrawableResource by 
      lazy { init_avatar_zombie() }

  public val button_bb: DrawableResource by 
      lazy { init_button_bb() }

  public val button_dealer: DrawableResource by 
      lazy { init_button_dealer() }

  public val button_sb: DrawableResource by 
      lazy { init_button_sb() }

  public val card_back_blue: DrawableResource by 
      lazy { init_card_back_blue() }

  public val card_back_blue_cropped: DrawableResource by 
      lazy { init_card_back_blue_cropped() }

  public val card_back_green: DrawableResource by 
      lazy { init_card_back_green() }

  public val card_back_green_cropped: DrawableResource by 
      lazy { init_card_back_green_cropped() }

  public val card_back_orange: DrawableResource by 
      lazy { init_card_back_orange() }

  public val card_back_orange_cropped: DrawableResource by 
      lazy { init_card_back_orange_cropped() }

  public val card_back_red: DrawableResource by 
      lazy { init_card_back_red() }

  public val card_back_red_cropped: DrawableResource by 
      lazy { init_card_back_red_cropped() }

  public val card_value_ace: DrawableResource by 
      lazy { init_card_value_ace() }

  public val card_value_eight: DrawableResource by 
      lazy { init_card_value_eight() }

  public val card_value_five: DrawableResource by 
      lazy { init_card_value_five() }

  public val card_value_four: DrawableResource by 
      lazy { init_card_value_four() }

  public val card_value_jack: DrawableResource by 
      lazy { init_card_value_jack() }

  public val card_value_king: DrawableResource by 
      lazy { init_card_value_king() }

  public val card_value_nine: DrawableResource by 
      lazy { init_card_value_nine() }

  public val card_value_queen: DrawableResource by 
      lazy { init_card_value_queen() }

  public val card_value_seven: DrawableResource by 
      lazy { init_card_value_seven() }

  public val card_value_six: DrawableResource by 
      lazy { init_card_value_six() }

  public val card_value_ten: DrawableResource by 
      lazy { init_card_value_ten() }

  public val card_value_three: DrawableResource by 
      lazy { init_card_value_three() }

  public val card_value_two: DrawableResource by 
      lazy { init_card_value_two() }

  public val clubs_ace: DrawableResource by 
      lazy { init_clubs_ace() }

  public val clubs_eight: DrawableResource by 
      lazy { init_clubs_eight() }

  public val clubs_five: DrawableResource by 
      lazy { init_clubs_five() }

  public val clubs_four: DrawableResource by 
      lazy { init_clubs_four() }

  public val clubs_jack: DrawableResource by 
      lazy { init_clubs_jack() }

  public val clubs_king: DrawableResource by 
      lazy { init_clubs_king() }

  public val clubs_nine: DrawableResource by 
      lazy { init_clubs_nine() }

  public val clubs_queen: DrawableResource by 
      lazy { init_clubs_queen() }

  public val clubs_seven: DrawableResource by 
      lazy { init_clubs_seven() }

  public val clubs_six: DrawableResource by 
      lazy { init_clubs_six() }

  public val clubs_ten: DrawableResource by 
      lazy { init_clubs_ten() }

  public val clubs_three: DrawableResource by 
      lazy { init_clubs_three() }

  public val clubs_two: DrawableResource by 
      lazy { init_clubs_two() }

  public val diamonds_ace: DrawableResource by 
      lazy { init_diamonds_ace() }

  public val diamonds_eight: DrawableResource by 
      lazy { init_diamonds_eight() }

  public val diamonds_five: DrawableResource by 
      lazy { init_diamonds_five() }

  public val diamonds_four: DrawableResource by 
      lazy { init_diamonds_four() }

  public val diamonds_jack: DrawableResource by 
      lazy { init_diamonds_jack() }

  public val diamonds_king: DrawableResource by 
      lazy { init_diamonds_king() }

  public val diamonds_nine: DrawableResource by 
      lazy { init_diamonds_nine() }

  public val diamonds_queen: DrawableResource by 
      lazy { init_diamonds_queen() }

  public val diamonds_seven: DrawableResource by 
      lazy { init_diamonds_seven() }

  public val diamonds_six: DrawableResource by 
      lazy { init_diamonds_six() }

  public val diamonds_ten: DrawableResource by 
      lazy { init_diamonds_ten() }

  public val diamonds_three: DrawableResource by 
      lazy { init_diamonds_three() }

  public val diamonds_two: DrawableResource by 
      lazy { init_diamonds_two() }

  public val facebook_logo: DrawableResource by 
      lazy { init_facebook_logo() }

  public val finger_gesture: DrawableResource by 
      lazy { init_finger_gesture() }

  public val game_not_found: DrawableResource by 
      lazy { init_game_not_found() }

  public val google_logo: DrawableResource by 
      lazy { init_google_logo() }

  public val hearts_ace: DrawableResource by 
      lazy { init_hearts_ace() }

  public val hearts_eight: DrawableResource by 
      lazy { init_hearts_eight() }

  public val hearts_five: DrawableResource by 
      lazy { init_hearts_five() }

  public val hearts_four: DrawableResource by 
      lazy { init_hearts_four() }

  public val hearts_jack: DrawableResource by 
      lazy { init_hearts_jack() }

  public val hearts_king: DrawableResource by 
      lazy { init_hearts_king() }

  public val hearts_nine: DrawableResource by 
      lazy { init_hearts_nine() }

  public val hearts_queen: DrawableResource by 
      lazy { init_hearts_queen() }

  public val hearts_seven: DrawableResource by 
      lazy { init_hearts_seven() }

  public val hearts_six: DrawableResource by 
      lazy { init_hearts_six() }

  public val hearts_ten: DrawableResource by 
      lazy { init_hearts_ten() }

  public val hearts_three: DrawableResource by 
      lazy { init_hearts_three() }

  public val hearts_two: DrawableResource by 
      lazy { init_hearts_two() }

  public val logo: DrawableResource by 
      lazy { init_logo() }

  public val logo_icon: DrawableResource by 
      lazy { init_logo_icon() }

  public val logo_long_text_horizontal_black: DrawableResource by 
      lazy { init_logo_long_text_horizontal_black() }

  public val logo_long_text_horizontal_white: DrawableResource by 
      lazy { init_logo_long_text_horizontal_white() }

  public val logo_long_text_vertical_black: DrawableResource by 
      lazy { init_logo_long_text_vertical_black() }

  public val logo_long_text_vertical_white: DrawableResource by 
      lazy { init_logo_long_text_vertical_white() }

  public val logo_short_text_horizontal_black: DrawableResource by 
      lazy { init_logo_short_text_horizontal_black() }

  public val logo_short_text_horizontal_white: DrawableResource by 
      lazy { init_logo_short_text_horizontal_white() }

  public val playing_cards: DrawableResource by 
      lazy { init_playing_cards() }

  public val poker_chip: DrawableResource by 
      lazy { init_poker_chip() }

  public val poker_game_placeholder: DrawableResource by 
      lazy { init_poker_game_placeholder() }

  public val prize_any: DrawableResource by 
      lazy { init_prize_any() }

  public val prize_first: DrawableResource by 
      lazy { init_prize_first() }

  public val prize_second: DrawableResource by 
      lazy { init_prize_second() }

  public val prize_third: DrawableResource by 
      lazy { init_prize_third() }

  public val spades_ace: DrawableResource by 
      lazy { init_spades_ace() }

  public val spades_eight: DrawableResource by 
      lazy { init_spades_eight() }

  public val spades_five: DrawableResource by 
      lazy { init_spades_five() }

  public val spades_four: DrawableResource by 
      lazy { init_spades_four() }

  public val spades_jack: DrawableResource by 
      lazy { init_spades_jack() }

  public val spades_king: DrawableResource by 
      lazy { init_spades_king() }

  public val spades_nine: DrawableResource by 
      lazy { init_spades_nine() }

  public val spades_queen: DrawableResource by 
      lazy { init_spades_queen() }

  public val spades_seven: DrawableResource by 
      lazy { init_spades_seven() }

  public val spades_six: DrawableResource by 
      lazy { init_spades_six() }

  public val spades_ten: DrawableResource by 
      lazy { init_spades_ten() }

  public val spades_three: DrawableResource by 
      lazy { init_spades_three() }

  public val spades_two: DrawableResource by 
      lazy { init_spades_two() }

  public val suit_clubs: DrawableResource by 
      lazy { init_suit_clubs() }

  public val suit_diamonds: DrawableResource by 
      lazy { init_suit_diamonds() }

  public val suit_hearts: DrawableResource by 
      lazy { init_suit_hearts() }

  public val suit_spades: DrawableResource by 
      lazy { init_suit_spades() }

  public val unknown_error: DrawableResource by 
      lazy { init_unknown_error() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("anonymous_profile", CommonMainDrawable0.anonymous_profile)
  map.put("apple_logo", CommonMainDrawable0.apple_logo)
  map.put("avatar_afro", CommonMainDrawable0.avatar_afro)
  map.put("avatar_alien", CommonMainDrawable0.avatar_alien)
  map.put("avatar_croupier", CommonMainDrawable0.avatar_croupier)
  map.put("avatar_gambler", CommonMainDrawable0.avatar_gambler)
  map.put("avatar_joker", CommonMainDrawable0.avatar_joker)
  map.put("avatar_ozzy", CommonMainDrawable0.avatar_ozzy)
  map.put("avatar_profile", CommonMainDrawable0.avatar_profile)
  map.put("avatar_robot", CommonMainDrawable0.avatar_robot)
  map.put("avatar_zombie", CommonMainDrawable0.avatar_zombie)
  map.put("button_bb", CommonMainDrawable0.button_bb)
  map.put("button_dealer", CommonMainDrawable0.button_dealer)
  map.put("button_sb", CommonMainDrawable0.button_sb)
  map.put("card_back_blue", CommonMainDrawable0.card_back_blue)
  map.put("card_back_blue_cropped", CommonMainDrawable0.card_back_blue_cropped)
  map.put("card_back_green", CommonMainDrawable0.card_back_green)
  map.put("card_back_green_cropped", CommonMainDrawable0.card_back_green_cropped)
  map.put("card_back_orange", CommonMainDrawable0.card_back_orange)
  map.put("card_back_orange_cropped", CommonMainDrawable0.card_back_orange_cropped)
  map.put("card_back_red", CommonMainDrawable0.card_back_red)
  map.put("card_back_red_cropped", CommonMainDrawable0.card_back_red_cropped)
  map.put("card_value_ace", CommonMainDrawable0.card_value_ace)
  map.put("card_value_eight", CommonMainDrawable0.card_value_eight)
  map.put("card_value_five", CommonMainDrawable0.card_value_five)
  map.put("card_value_four", CommonMainDrawable0.card_value_four)
  map.put("card_value_jack", CommonMainDrawable0.card_value_jack)
  map.put("card_value_king", CommonMainDrawable0.card_value_king)
  map.put("card_value_nine", CommonMainDrawable0.card_value_nine)
  map.put("card_value_queen", CommonMainDrawable0.card_value_queen)
  map.put("card_value_seven", CommonMainDrawable0.card_value_seven)
  map.put("card_value_six", CommonMainDrawable0.card_value_six)
  map.put("card_value_ten", CommonMainDrawable0.card_value_ten)
  map.put("card_value_three", CommonMainDrawable0.card_value_three)
  map.put("card_value_two", CommonMainDrawable0.card_value_two)
  map.put("clubs_ace", CommonMainDrawable0.clubs_ace)
  map.put("clubs_eight", CommonMainDrawable0.clubs_eight)
  map.put("clubs_five", CommonMainDrawable0.clubs_five)
  map.put("clubs_four", CommonMainDrawable0.clubs_four)
  map.put("clubs_jack", CommonMainDrawable0.clubs_jack)
  map.put("clubs_king", CommonMainDrawable0.clubs_king)
  map.put("clubs_nine", CommonMainDrawable0.clubs_nine)
  map.put("clubs_queen", CommonMainDrawable0.clubs_queen)
  map.put("clubs_seven", CommonMainDrawable0.clubs_seven)
  map.put("clubs_six", CommonMainDrawable0.clubs_six)
  map.put("clubs_ten", CommonMainDrawable0.clubs_ten)
  map.put("clubs_three", CommonMainDrawable0.clubs_three)
  map.put("clubs_two", CommonMainDrawable0.clubs_two)
  map.put("diamonds_ace", CommonMainDrawable0.diamonds_ace)
  map.put("diamonds_eight", CommonMainDrawable0.diamonds_eight)
  map.put("diamonds_five", CommonMainDrawable0.diamonds_five)
  map.put("diamonds_four", CommonMainDrawable0.diamonds_four)
  map.put("diamonds_jack", CommonMainDrawable0.diamonds_jack)
  map.put("diamonds_king", CommonMainDrawable0.diamonds_king)
  map.put("diamonds_nine", CommonMainDrawable0.diamonds_nine)
  map.put("diamonds_queen", CommonMainDrawable0.diamonds_queen)
  map.put("diamonds_seven", CommonMainDrawable0.diamonds_seven)
  map.put("diamonds_six", CommonMainDrawable0.diamonds_six)
  map.put("diamonds_ten", CommonMainDrawable0.diamonds_ten)
  map.put("diamonds_three", CommonMainDrawable0.diamonds_three)
  map.put("diamonds_two", CommonMainDrawable0.diamonds_two)
  map.put("facebook_logo", CommonMainDrawable0.facebook_logo)
  map.put("finger_gesture", CommonMainDrawable0.finger_gesture)
  map.put("game_not_found", CommonMainDrawable0.game_not_found)
  map.put("google_logo", CommonMainDrawable0.google_logo)
  map.put("hearts_ace", CommonMainDrawable0.hearts_ace)
  map.put("hearts_eight", CommonMainDrawable0.hearts_eight)
  map.put("hearts_five", CommonMainDrawable0.hearts_five)
  map.put("hearts_four", CommonMainDrawable0.hearts_four)
  map.put("hearts_jack", CommonMainDrawable0.hearts_jack)
  map.put("hearts_king", CommonMainDrawable0.hearts_king)
  map.put("hearts_nine", CommonMainDrawable0.hearts_nine)
  map.put("hearts_queen", CommonMainDrawable0.hearts_queen)
  map.put("hearts_seven", CommonMainDrawable0.hearts_seven)
  map.put("hearts_six", CommonMainDrawable0.hearts_six)
  map.put("hearts_ten", CommonMainDrawable0.hearts_ten)
  map.put("hearts_three", CommonMainDrawable0.hearts_three)
  map.put("hearts_two", CommonMainDrawable0.hearts_two)
  map.put("logo", CommonMainDrawable0.logo)
  map.put("logo_icon", CommonMainDrawable0.logo_icon)
  map.put("logo_long_text_horizontal_black", CommonMainDrawable0.logo_long_text_horizontal_black)
  map.put("logo_long_text_horizontal_white", CommonMainDrawable0.logo_long_text_horizontal_white)
  map.put("logo_long_text_vertical_black", CommonMainDrawable0.logo_long_text_vertical_black)
  map.put("logo_long_text_vertical_white", CommonMainDrawable0.logo_long_text_vertical_white)
  map.put("logo_short_text_horizontal_black", CommonMainDrawable0.logo_short_text_horizontal_black)
  map.put("logo_short_text_horizontal_white", CommonMainDrawable0.logo_short_text_horizontal_white)
  map.put("playing_cards", CommonMainDrawable0.playing_cards)
  map.put("poker_chip", CommonMainDrawable0.poker_chip)
  map.put("poker_game_placeholder", CommonMainDrawable0.poker_game_placeholder)
  map.put("prize_any", CommonMainDrawable0.prize_any)
  map.put("prize_first", CommonMainDrawable0.prize_first)
  map.put("prize_second", CommonMainDrawable0.prize_second)
  map.put("prize_third", CommonMainDrawable0.prize_third)
  map.put("spades_ace", CommonMainDrawable0.spades_ace)
  map.put("spades_eight", CommonMainDrawable0.spades_eight)
  map.put("spades_five", CommonMainDrawable0.spades_five)
  map.put("spades_four", CommonMainDrawable0.spades_four)
  map.put("spades_jack", CommonMainDrawable0.spades_jack)
  map.put("spades_king", CommonMainDrawable0.spades_king)
  map.put("spades_nine", CommonMainDrawable0.spades_nine)
  map.put("spades_queen", CommonMainDrawable0.spades_queen)
  map.put("spades_seven", CommonMainDrawable0.spades_seven)
  map.put("spades_six", CommonMainDrawable0.spades_six)
  map.put("spades_ten", CommonMainDrawable0.spades_ten)
  map.put("spades_three", CommonMainDrawable0.spades_three)
  map.put("spades_two", CommonMainDrawable0.spades_two)
  map.put("suit_clubs", CommonMainDrawable0.suit_clubs)
  map.put("suit_diamonds", CommonMainDrawable0.suit_diamonds)
  map.put("suit_hearts", CommonMainDrawable0.suit_hearts)
  map.put("suit_spades", CommonMainDrawable0.suit_spades)
  map.put("unknown_error", CommonMainDrawable0.unknown_error)
}

internal val Res.drawable.anonymous_profile: DrawableResource
  get() = CommonMainDrawable0.anonymous_profile

private fun init_anonymous_profile(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:anonymous_profile",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/anonymous_profile.xml", -1, -1),
    )
)

internal val Res.drawable.apple_logo: DrawableResource
  get() = CommonMainDrawable0.apple_logo

private fun init_apple_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:apple_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/apple_logo.xml", -1, -1),
    )
)

internal val Res.drawable.avatar_afro: DrawableResource
  get() = CommonMainDrawable0.avatar_afro

private fun init_avatar_afro(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_afro",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/avatar_afro.xml", -1, -1),
    )
)

internal val Res.drawable.avatar_alien: DrawableResource
  get() = CommonMainDrawable0.avatar_alien

private fun init_avatar_alien(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_alien",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/avatar_alien.xml", -1, -1),
    )
)

internal val Res.drawable.avatar_croupier: DrawableResource
  get() = CommonMainDrawable0.avatar_croupier

private fun init_avatar_croupier(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_croupier",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/avatar_croupier.xml", -1, -1),
    )
)

internal val Res.drawable.avatar_gambler: DrawableResource
  get() = CommonMainDrawable0.avatar_gambler

private fun init_avatar_gambler(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_gambler",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/avatar_gambler.xml", -1, -1),
    )
)

internal val Res.drawable.avatar_joker: DrawableResource
  get() = CommonMainDrawable0.avatar_joker

private fun init_avatar_joker(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_joker",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/avatar_joker.xml", -1, -1),
    )
)

internal val Res.drawable.avatar_ozzy: DrawableResource
  get() = CommonMainDrawable0.avatar_ozzy

private fun init_avatar_ozzy(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_ozzy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/avatar_ozzy.xml", -1, -1),
    )
)

internal val Res.drawable.avatar_profile: DrawableResource
  get() = CommonMainDrawable0.avatar_profile

private fun init_avatar_profile(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_profile",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/avatar_profile.xml", -1, -1),
    )
)

internal val Res.drawable.avatar_robot: DrawableResource
  get() = CommonMainDrawable0.avatar_robot

private fun init_avatar_robot(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_robot",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/avatar_robot.xml", -1, -1),
    )
)

internal val Res.drawable.avatar_zombie: DrawableResource
  get() = CommonMainDrawable0.avatar_zombie

private fun init_avatar_zombie(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_zombie",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/avatar_zombie.xml", -1, -1),
    )
)

internal val Res.drawable.button_bb: DrawableResource
  get() = CommonMainDrawable0.button_bb

private fun init_button_bb(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:button_bb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/button_bb.xml", -1, -1),
    )
)

internal val Res.drawable.button_dealer: DrawableResource
  get() = CommonMainDrawable0.button_dealer

private fun init_button_dealer(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:button_dealer",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/button_dealer.xml", -1, -1),
    )
)

internal val Res.drawable.button_sb: DrawableResource
  get() = CommonMainDrawable0.button_sb

private fun init_button_sb(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:button_sb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/button_sb.xml", -1, -1),
    )
)

internal val Res.drawable.card_back_blue: DrawableResource
  get() = CommonMainDrawable0.card_back_blue

private fun init_card_back_blue(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_back_blue",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_back_blue.xml", -1, -1),
    )
)

internal val Res.drawable.card_back_blue_cropped: DrawableResource
  get() = CommonMainDrawable0.card_back_blue_cropped

private fun init_card_back_blue_cropped(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_back_blue_cropped",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_back_blue_cropped.xml", -1, -1),
    )
)

internal val Res.drawable.card_back_green: DrawableResource
  get() = CommonMainDrawable0.card_back_green

private fun init_card_back_green(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_back_green",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_back_green.xml", -1, -1),
    )
)

internal val Res.drawable.card_back_green_cropped: DrawableResource
  get() = CommonMainDrawable0.card_back_green_cropped

private fun init_card_back_green_cropped(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_back_green_cropped",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_back_green_cropped.xml", -1, -1),
    )
)

internal val Res.drawable.card_back_orange: DrawableResource
  get() = CommonMainDrawable0.card_back_orange

private fun init_card_back_orange(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_back_orange",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_back_orange.xml", -1, -1),
    )
)

internal val Res.drawable.card_back_orange_cropped: DrawableResource
  get() = CommonMainDrawable0.card_back_orange_cropped

private fun init_card_back_orange_cropped(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_back_orange_cropped",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_back_orange_cropped.xml", -1, -1),
    )
)

internal val Res.drawable.card_back_red: DrawableResource
  get() = CommonMainDrawable0.card_back_red

private fun init_card_back_red(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_back_red",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_back_red.xml", -1, -1),
    )
)

internal val Res.drawable.card_back_red_cropped: DrawableResource
  get() = CommonMainDrawable0.card_back_red_cropped

private fun init_card_back_red_cropped(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_back_red_cropped",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_back_red_cropped.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_ace: DrawableResource
  get() = CommonMainDrawable0.card_value_ace

private fun init_card_value_ace(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_ace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_ace.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_eight: DrawableResource
  get() = CommonMainDrawable0.card_value_eight

private fun init_card_value_eight(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_eight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_eight.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_five: DrawableResource
  get() = CommonMainDrawable0.card_value_five

private fun init_card_value_five(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_five",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_five.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_four: DrawableResource
  get() = CommonMainDrawable0.card_value_four

private fun init_card_value_four(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_four",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_four.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_jack: DrawableResource
  get() = CommonMainDrawable0.card_value_jack

private fun init_card_value_jack(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_jack",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_jack.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_king: DrawableResource
  get() = CommonMainDrawable0.card_value_king

private fun init_card_value_king(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_king",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_king.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_nine: DrawableResource
  get() = CommonMainDrawable0.card_value_nine

private fun init_card_value_nine(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_nine",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_nine.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_queen: DrawableResource
  get() = CommonMainDrawable0.card_value_queen

private fun init_card_value_queen(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_queen",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_queen.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_seven: DrawableResource
  get() = CommonMainDrawable0.card_value_seven

private fun init_card_value_seven(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_seven",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_seven.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_six: DrawableResource
  get() = CommonMainDrawable0.card_value_six

private fun init_card_value_six(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_six",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_six.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_ten: DrawableResource
  get() = CommonMainDrawable0.card_value_ten

private fun init_card_value_ten(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_ten",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_ten.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_three: DrawableResource
  get() = CommonMainDrawable0.card_value_three

private fun init_card_value_three(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_three",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_three.xml", -1, -1),
    )
)

internal val Res.drawable.card_value_two: DrawableResource
  get() = CommonMainDrawable0.card_value_two

private fun init_card_value_two(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:card_value_two",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/card_value_two.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_ace: DrawableResource
  get() = CommonMainDrawable0.clubs_ace

private fun init_clubs_ace(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_ace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_ace.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_eight: DrawableResource
  get() = CommonMainDrawable0.clubs_eight

private fun init_clubs_eight(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_eight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_eight.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_five: DrawableResource
  get() = CommonMainDrawable0.clubs_five

private fun init_clubs_five(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_five",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_five.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_four: DrawableResource
  get() = CommonMainDrawable0.clubs_four

private fun init_clubs_four(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_four",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_four.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_jack: DrawableResource
  get() = CommonMainDrawable0.clubs_jack

private fun init_clubs_jack(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_jack",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_jack.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_king: DrawableResource
  get() = CommonMainDrawable0.clubs_king

private fun init_clubs_king(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_king",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_king.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_nine: DrawableResource
  get() = CommonMainDrawable0.clubs_nine

private fun init_clubs_nine(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_nine",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_nine.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_queen: DrawableResource
  get() = CommonMainDrawable0.clubs_queen

private fun init_clubs_queen(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_queen",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_queen.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_seven: DrawableResource
  get() = CommonMainDrawable0.clubs_seven

private fun init_clubs_seven(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_seven",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_seven.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_six: DrawableResource
  get() = CommonMainDrawable0.clubs_six

private fun init_clubs_six(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_six",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_six.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_ten: DrawableResource
  get() = CommonMainDrawable0.clubs_ten

private fun init_clubs_ten(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_ten",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_ten.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_three: DrawableResource
  get() = CommonMainDrawable0.clubs_three

private fun init_clubs_three(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_three",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_three.xml", -1, -1),
    )
)

internal val Res.drawable.clubs_two: DrawableResource
  get() = CommonMainDrawable0.clubs_two

private fun init_clubs_two(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clubs_two",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/clubs_two.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_ace: DrawableResource
  get() = CommonMainDrawable0.diamonds_ace

private fun init_diamonds_ace(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_ace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_ace.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_eight: DrawableResource
  get() = CommonMainDrawable0.diamonds_eight

private fun init_diamonds_eight(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_eight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_eight.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_five: DrawableResource
  get() = CommonMainDrawable0.diamonds_five

private fun init_diamonds_five(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_five",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_five.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_four: DrawableResource
  get() = CommonMainDrawable0.diamonds_four

private fun init_diamonds_four(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_four",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_four.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_jack: DrawableResource
  get() = CommonMainDrawable0.diamonds_jack

private fun init_diamonds_jack(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_jack",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_jack.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_king: DrawableResource
  get() = CommonMainDrawable0.diamonds_king

private fun init_diamonds_king(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_king",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_king.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_nine: DrawableResource
  get() = CommonMainDrawable0.diamonds_nine

private fun init_diamonds_nine(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_nine",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_nine.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_queen: DrawableResource
  get() = CommonMainDrawable0.diamonds_queen

private fun init_diamonds_queen(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_queen",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_queen.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_seven: DrawableResource
  get() = CommonMainDrawable0.diamonds_seven

private fun init_diamonds_seven(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_seven",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_seven.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_six: DrawableResource
  get() = CommonMainDrawable0.diamonds_six

private fun init_diamonds_six(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_six",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_six.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_ten: DrawableResource
  get() = CommonMainDrawable0.diamonds_ten

private fun init_diamonds_ten(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_ten",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_ten.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_three: DrawableResource
  get() = CommonMainDrawable0.diamonds_three

private fun init_diamonds_three(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_three",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_three.xml", -1, -1),
    )
)

internal val Res.drawable.diamonds_two: DrawableResource
  get() = CommonMainDrawable0.diamonds_two

private fun init_diamonds_two(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:diamonds_two",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/diamonds_two.xml", -1, -1),
    )
)

internal val Res.drawable.facebook_logo: DrawableResource
  get() = CommonMainDrawable0.facebook_logo

private fun init_facebook_logo(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:facebook_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/facebook_logo.xml", -1, -1),
    )
)

internal val Res.drawable.finger_gesture: DrawableResource
  get() = CommonMainDrawable0.finger_gesture

private fun init_finger_gesture(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:finger_gesture",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/finger_gesture.xml", -1, -1),
    )
)

internal val Res.drawable.game_not_found: DrawableResource
  get() = CommonMainDrawable0.game_not_found

private fun init_game_not_found(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:game_not_found",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/game_not_found.xml", -1, -1),
    )
)

internal val Res.drawable.google_logo: DrawableResource
  get() = CommonMainDrawable0.google_logo

private fun init_google_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:google_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/google_logo.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_ace: DrawableResource
  get() = CommonMainDrawable0.hearts_ace

private fun init_hearts_ace(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_ace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_ace.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_eight: DrawableResource
  get() = CommonMainDrawable0.hearts_eight

private fun init_hearts_eight(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_eight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_eight.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_five: DrawableResource
  get() = CommonMainDrawable0.hearts_five

private fun init_hearts_five(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_five",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_five.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_four: DrawableResource
  get() = CommonMainDrawable0.hearts_four

private fun init_hearts_four(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_four",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_four.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_jack: DrawableResource
  get() = CommonMainDrawable0.hearts_jack

private fun init_hearts_jack(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_jack",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_jack.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_king: DrawableResource
  get() = CommonMainDrawable0.hearts_king

private fun init_hearts_king(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_king",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_king.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_nine: DrawableResource
  get() = CommonMainDrawable0.hearts_nine

private fun init_hearts_nine(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_nine",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_nine.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_queen: DrawableResource
  get() = CommonMainDrawable0.hearts_queen

private fun init_hearts_queen(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_queen",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_queen.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_seven: DrawableResource
  get() = CommonMainDrawable0.hearts_seven

private fun init_hearts_seven(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_seven",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_seven.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_six: DrawableResource
  get() = CommonMainDrawable0.hearts_six

private fun init_hearts_six(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_six",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_six.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_ten: DrawableResource
  get() = CommonMainDrawable0.hearts_ten

private fun init_hearts_ten(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_ten",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_ten.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_three: DrawableResource
  get() = CommonMainDrawable0.hearts_three

private fun init_hearts_three(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_three",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_three.xml", -1, -1),
    )
)

internal val Res.drawable.hearts_two: DrawableResource
  get() = CommonMainDrawable0.hearts_two

private fun init_hearts_two(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:hearts_two",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/hearts_two.xml", -1, -1),
    )
)

internal val Res.drawable.logo: DrawableResource
  get() = CommonMainDrawable0.logo

private fun init_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/logo.xml", -1, -1),
    )
)

internal val Res.drawable.logo_icon: DrawableResource
  get() = CommonMainDrawable0.logo_icon

private fun init_logo_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/logo_icon.xml", -1, -1),
    )
)

internal val Res.drawable.logo_long_text_horizontal_black: DrawableResource
  get() = CommonMainDrawable0.logo_long_text_horizontal_black

private fun init_logo_long_text_horizontal_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_long_text_horizontal_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/logo_long_text_horizontal_black.xml", -1, -1),
    )
)

internal val Res.drawable.logo_long_text_horizontal_white: DrawableResource
  get() = CommonMainDrawable0.logo_long_text_horizontal_white

private fun init_logo_long_text_horizontal_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_long_text_horizontal_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/logo_long_text_horizontal_white.xml", -1, -1),
    )
)

internal val Res.drawable.logo_long_text_vertical_black: DrawableResource
  get() = CommonMainDrawable0.logo_long_text_vertical_black

private fun init_logo_long_text_vertical_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_long_text_vertical_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/logo_long_text_vertical_black.xml", -1, -1),
    )
)

internal val Res.drawable.logo_long_text_vertical_white: DrawableResource
  get() = CommonMainDrawable0.logo_long_text_vertical_white

private fun init_logo_long_text_vertical_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_long_text_vertical_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/logo_long_text_vertical_white.xml", -1, -1),
    )
)

internal val Res.drawable.logo_short_text_horizontal_black: DrawableResource
  get() = CommonMainDrawable0.logo_short_text_horizontal_black

private fun init_logo_short_text_horizontal_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_short_text_horizontal_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/logo_short_text_horizontal_black.xml", -1, -1),
    )
)

internal val Res.drawable.logo_short_text_horizontal_white: DrawableResource
  get() = CommonMainDrawable0.logo_short_text_horizontal_white

private fun init_logo_short_text_horizontal_white(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_short_text_horizontal_white",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/logo_short_text_horizontal_white.xml", -1, -1),
    )
)

internal val Res.drawable.playing_cards: DrawableResource
  get() = CommonMainDrawable0.playing_cards

private fun init_playing_cards(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:playing_cards",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/playing_cards.xml", -1, -1),
    )
)

internal val Res.drawable.poker_chip: DrawableResource
  get() = CommonMainDrawable0.poker_chip

private fun init_poker_chip(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:poker_chip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/poker_chip.xml", -1, -1),
    )
)

internal val Res.drawable.poker_game_placeholder: DrawableResource
  get() = CommonMainDrawable0.poker_game_placeholder

private fun init_poker_game_placeholder(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:poker_game_placeholder",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/poker_game_placeholder.xml", -1, -1),
    )
)

internal val Res.drawable.prize_any: DrawableResource
  get() = CommonMainDrawable0.prize_any

private fun init_prize_any(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:prize_any",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/prize_any.xml", -1, -1),
    )
)

internal val Res.drawable.prize_first: DrawableResource
  get() = CommonMainDrawable0.prize_first

private fun init_prize_first(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:prize_first",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/prize_first.xml", -1, -1),
    )
)

internal val Res.drawable.prize_second: DrawableResource
  get() = CommonMainDrawable0.prize_second

private fun init_prize_second(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:prize_second",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/prize_second.xml", -1, -1),
    )
)

internal val Res.drawable.prize_third: DrawableResource
  get() = CommonMainDrawable0.prize_third

private fun init_prize_third(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:prize_third",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/prize_third.xml", -1, -1),
    )
)

internal val Res.drawable.spades_ace: DrawableResource
  get() = CommonMainDrawable0.spades_ace

private fun init_spades_ace(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_ace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_ace.xml", -1, -1),
    )
)

internal val Res.drawable.spades_eight: DrawableResource
  get() = CommonMainDrawable0.spades_eight

private fun init_spades_eight(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_eight",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_eight.xml", -1, -1),
    )
)

internal val Res.drawable.spades_five: DrawableResource
  get() = CommonMainDrawable0.spades_five

private fun init_spades_five(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_five",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_five.xml", -1, -1),
    )
)

internal val Res.drawable.spades_four: DrawableResource
  get() = CommonMainDrawable0.spades_four

private fun init_spades_four(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_four",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_four.xml", -1, -1),
    )
)

internal val Res.drawable.spades_jack: DrawableResource
  get() = CommonMainDrawable0.spades_jack

private fun init_spades_jack(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_jack",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_jack.xml", -1, -1),
    )
)

internal val Res.drawable.spades_king: DrawableResource
  get() = CommonMainDrawable0.spades_king

private fun init_spades_king(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_king",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_king.xml", -1, -1),
    )
)

internal val Res.drawable.spades_nine: DrawableResource
  get() = CommonMainDrawable0.spades_nine

private fun init_spades_nine(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_nine",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_nine.xml", -1, -1),
    )
)

internal val Res.drawable.spades_queen: DrawableResource
  get() = CommonMainDrawable0.spades_queen

private fun init_spades_queen(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_queen",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_queen.xml", -1, -1),
    )
)

internal val Res.drawable.spades_seven: DrawableResource
  get() = CommonMainDrawable0.spades_seven

private fun init_spades_seven(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_seven",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_seven.xml", -1, -1),
    )
)

internal val Res.drawable.spades_six: DrawableResource
  get() = CommonMainDrawable0.spades_six

private fun init_spades_six(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_six",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_six.xml", -1, -1),
    )
)

internal val Res.drawable.spades_ten: DrawableResource
  get() = CommonMainDrawable0.spades_ten

private fun init_spades_ten(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_ten",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_ten.xml", -1, -1),
    )
)

internal val Res.drawable.spades_three: DrawableResource
  get() = CommonMainDrawable0.spades_three

private fun init_spades_three(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_three",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_three.xml", -1, -1),
    )
)

internal val Res.drawable.spades_two: DrawableResource
  get() = CommonMainDrawable0.spades_two

private fun init_spades_two(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spades_two",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/spades_two.xml", -1, -1),
    )
)

internal val Res.drawable.suit_clubs: DrawableResource
  get() = CommonMainDrawable0.suit_clubs

private fun init_suit_clubs(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:suit_clubs",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/suit_clubs.xml", -1, -1),
    )
)

internal val Res.drawable.suit_diamonds: DrawableResource
  get() = CommonMainDrawable0.suit_diamonds

private fun init_suit_diamonds(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:suit_diamonds",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/suit_diamonds.xml", -1, -1),
    )
)

internal val Res.drawable.suit_hearts: DrawableResource
  get() = CommonMainDrawable0.suit_hearts

private fun init_suit_hearts(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:suit_hearts",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/suit_hearts.xml", -1, -1),
    )
)

internal val Res.drawable.suit_spades: DrawableResource
  get() = CommonMainDrawable0.suit_spades

private fun init_suit_spades(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:suit_spades",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/suit_spades.xml", -1, -1),
    )
)

internal val Res.drawable.unknown_error: DrawableResource
  get() = CommonMainDrawable0.unknown_error

private fun init_unknown_error(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:unknown_error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/smartdealer.appshared.generated.resources/drawable/unknown_error.xml", -1, -1),
    )
)
