
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.SnackbarHostState
import androidx.compose.material3.Surface
import androidx.compose.material3.windowsizeclass.WindowHeightSizeClass
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import cafe.adriel.voyager.navigator.CurrentScreen
import cafe.adriel.voyager.navigator.Navigator
import kotlinx.browser.window
import org.w3c.dom.url.URLSearchParams
import studio.lostjoker.smartdealer.Offering
import studio.lostjoker.smartdealer.helpers.LocalSnackbar
import studio.lostjoker.smartdealer.helpers.isDarkTheme
import studio.lostjoker.smartdealer.helpers.rememberSnackBarDelegate
import studio.lostjoker.smartdealer.notification.LocalNotificationService
import studio.lostjoker.smartdealer.notification.rememberNotificationService
import studio.lostjoker.smartdealer.ui.poker.join_game.JsJoinGameScreen
import studio.lostjoker.smartdealer.ui.theme.LocalDarkTheme
import studio.lostjoker.smartdealer.ui.theme.PokerAppTheme

@Composable
fun MainView(
    offering: Offering,
    gameplayBaseUrl: String,
    apiBaseUrl: String,
) {
    val snackbarHostState = SnackbarHostState()
    val snackbarDelegate = rememberSnackBarDelegate(snackbarHostState)
    val notificationService = rememberNotificationService()
    val isDarkTheme = remember { mutableStateOf(isDarkTheme()) }

    val queryString = window.location.search
    val urlParams = URLSearchParams(queryString)
    val gameCode = urlParams.get("gameCode")

    CompositionLocalProvider(
        LocalSnackbar provides snackbarDelegate,
        LocalNotificationService provides notificationService,
        LocalDarkTheme provides isDarkTheme,
    ) {
        PokerAppTheme(WindowWidthSizeClass.Expanded, WindowHeightSizeClass.Expanded, LocalDarkTheme.current.value) {
            Navigator(
                screen = JsJoinGameScreen(gameplayBaseUrl, apiBaseUrl, gameCode),
            ) {
                Surface(modifier = Modifier.fillMaxSize()) {
                    CurrentScreen()
                }
            }
        }
    }
}
