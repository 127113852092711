package studio.lostjoker.smartdealer.domain


sealed interface Seat {
    data object Empty : Seat
    data class Occupied(val player: Player, val away: Boolean = false) : Seat
}

sealed interface Player {
    val id: String

    data class Human(val userId: String) : Player {
        override val id: String
            get() = userId
    }

    data class Bot(val botId: String) : Player {
        override val id: String
            get() = botId
    }
}
