package studio.lostjoker.smartdealer.ui.theme

class FontSizes()

// TODO: Update compactFontSizes
val compactFontSizes = FontSizes()

// TODO: Update mediumFontSizes
val mediumFontSizes = FontSizes()

// TODO: Update expandedFontSizes
val expandedFontSizes = FontSizes()
