package studio.lostjoker.smartdealer.ui.poker.devices.common.state

import com.benasher44.uuid.Uuid
import studio.lostjoker.smartdealer.domain.DealtRankedCardPlayerView
import studio.lostjoker.smartdealer.domain.poker.model.BlindLevel

data class Hand(
    val tableId: Uuid,
    val handId: Uuid,
    val blindLevel: BlindLevel,
    val communityCards: List<DealtRankedCardPlayerView>,
    val players: List<PlayerSummary> = emptyList(),
)
